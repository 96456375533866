import { LoadingOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { useState } from 'react'

import { themedClass } from '../../utils/style-utils'
import { ErrorAlert } from '../Alerts'
import VerticalList from '../VerticalList'

const SelectItem = ({ item }) => item.label

const SelectMenu = ({
  items,
  onItemSelected,
  scrollableContent,
  className,
}) => (
  <VerticalList
    className={className}
    items={items}
    itemCss="text-capitalized"
    scrollableContent={scrollableContent}
    ItemComponent={SelectItem}
    onItemClick={onItemSelected}
  />
)

const SelectPopover = ({
  placeholder,
  selectedItem,
  onChange,
  options,
  itemCss,
  className,
  loading,
  error,
  scrollableContent = true,
  smallOverlay,
  useFlyingLabel = true,
  theme,
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const css = classNames(
    themedClass('popover-selected-item', theme),
    className,
    {
      'popover-select-placeholder': !selectedItem,
    }
  )

  const onItemSelected = (item, event) => {
    event.preventDefault()
    event.stopPropagation()

    setIsOpened(false)
    onChange(item)
  }

  const getPopupContent = () => {
    const itemsCount = Math.min(_.size(options), 8)
    if (loading)
      return (
        <div className="vertical-list">
          <LoadingOutlined />
        </div>
      )

    if (error)
      return (
        <div className="vertical-list">
          <ErrorAlert errorId={'errors.loading-fail'} />
        </div>
      )

    return (
      <SelectMenu
        items={options}
        itemCss={itemCss}
        selectedItem={selectedItem}
        onItemSelected={onItemSelected}
        height={itemsCount}
        scrollableContent={scrollableContent}
        className={`vertical-list-${itemsCount}-items`}
      />
    )
  }

  const popoverCss = classNames(themedClass('popover-select', theme), {
    'popover-select-small-overlay': smallOverlay,
  })

  const labelCss = classNames('flying-label', {
    'focused-input': !!selectedItem,
  })

  let value
  if (selectedItem) value = selectedItem.label
  else value = useFlyingLabel ? '' : placeholder

  return (
    <Popover
      autoAdjustOverflow={false}
      content={getPopupContent()}
      placement="bottomRight"
      overlayClassName={popoverCss}
      open={isOpened}
      trigger={'click'}
      onOpenChange={setIsOpened}
    >
      <p className={css}>
        {useFlyingLabel && <label className={labelCss}>{placeholder}</label>}
        {value}
        <i className="fas fa-caret-down" />
      </p>
    </Popover>
  )
}

export default SelectPopover
