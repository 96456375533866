import { useIntl } from 'react-intl'

import ChangeDriverStateDropPlace from './ChangeDriverStateDropPlace'
import { DriversState } from '../../app/enums/DriverState'
import { themedClass } from '../../utils/style-utils'
import { ColumnDropIcon } from '../Icons'

const DriverColumnDrop = ({ selectedDrivers, type, theme }) => {
  const { formatMessage: f } = useIntl()
  const text =
    type === DriversState.ACTIVE
      ? f({ id: 'drivers.drop-active' })
      : f({ id: 'drivers.drop-inactive' })

  return (
    <ChangeDriverStateDropPlace
      selectedDrivers={selectedDrivers}
      type={type}
      className={themedClass('column-drop-place', theme)}
    >
      <ColumnDropIcon className="text-important column-drop-icon" />
      <p className="column-drop-text">{text}</p>
    </ChangeDriverStateDropPlace>
  )
}

export default DriverColumnDrop
