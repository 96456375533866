import { useApolloClient, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { useActiveStation } from './useActiveStation'
import { useAuthentication } from './useAuthentication'
import { useCardsFilterQuery } from './useCardsFilter'
import { useCurrentStation } from './useCurrentStation'
import { GET_CARDS } from '../app/graphQl/schema/Cards'
import { GET_DRIVERS } from '../app/graphQl/schema/Drivers'
import useStacksList from '../hooks/graphQl/useStacksQuery'

const useHydrateCache = () => {
  const cardsFilter = useCardsFilterQuery()
  const { stationId } = useCurrentStation()
  const { currentStation } = useActiveStation()
  const [currentUser, validToken] = useAuthentication()
  const [prevStation, setPrevStation] = useState(stationId)
  const client = useApolloClient()

  const { loading, error: cardsError } = useQuery(GET_CARDS, {
    notifyOnNetworkStatusChange: true,
    variables: cardsFilter,
    skip: !cardsFilter || !validToken,
  })

  const { loading: loadingDrivers, error: driversError } = useQuery(
    GET_DRIVERS,
    {
      notifyOnNetworkStatusChange: true,
      variables: { filter: { station: stationId } },
      skip: !currentUser || !stationId || !validToken,
    }
  )

  const { loading: stacksLoading, error: stacksError } = useStacksList()

  useEffect(() => {
    if (stationId !== prevStation) {
      client
        .refetchQueries({
          include: 'active',
        })
        .then(() => {
          setPrevStation(stationId)
        })
    }
  }, [stationId, prevStation, client])

  const errors = [driversError, cardsError, stacksError]

  return {
    isLoading: loading || loadingDrivers || stacksLoading,
    hasError: _.some(_.without(errors)),
    errors: [driversError, cardsError, stacksError],
    loadingStarted: !!currentStation,
  }
}

export default useHydrateCache
