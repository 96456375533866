import { Collapse } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import AssignedDriverPanel from './AssignedDriverPanel'
import DriverColumnDrop from './DriverColumnDrop'
import DriversColumn from './DriversColumn'
import { DriversState } from '../../app/enums/DriverState'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { getDisplayName } from '../../utils/driver-utils'
import { themedClass } from '../../utils/style-utils'
import { DriversContext } from '../DriverAssigmentModule'
import { MinusSignIcon, PlusSignIcon, SearchIcon } from '../Icons'
import ScrollArea from '../ScrollArea'
import SearchBox from '../form/SearchBox'

const { Panel } = Collapse

const ASSIGNED_DRIVER = 'assigned'
const ACTIVE_DRIVERS = 'active'
const INACTIVE_DRIVERS = 'inactive'

const getColumnLabel = (isActive, name, drivers) =>
  !isActive ? `${name} (${drivers.length})` : name

const filterDrivers = (drivers, query) => {
  const lowercaseQuery = _.toLower(query)
  return _.filter(
    drivers,
    driver =>
      _.includes(_.toLower(driver.code), lowercaseQuery) ||
      _.includes(getDisplayName(driver), lowercaseQuery)
  )
}

function PanelHeader({
  opened,
  onSwitchPanel,
  label,
  showSearchIcon,
  dropType,
  selectedDrivers,
  onSearchOpened,
  className,
  dataCy,
  theme,
  allowDrop,
  disabled,
}) {
  const css = classNames(className, {
    'column-btn-collapsed': !opened,
  })

  const onSearchClicked = e => {
    if (!disabled) {
      onSearchOpened(true)
    }
  }

  return (
    <div>
      <button
        className={css}
        data-cy={dataCy}
        onClick={onSwitchPanel}
        disabled={disabled}
      >
        {opened ? (
          <MinusSignIcon className="column-btn-icon" />
        ) : (
          <PlusSignIcon className="column-btn-icon" />
        )}
        {label}
        {showSearchIcon && (
          <SearchIcon onClick={onSearchClicked} className="open-search-icon" />
        )}
      </button>
      {!opened && allowDrop && (
        <DriverColumnDrop
          selectedDrivers={selectedDrivers}
          type={dropType}
          theme={theme}
        />
      )}
    </div>
  )
}

function defaultPanel(assignedDriver) {
  return !!assignedDriver ? ASSIGNED_DRIVER : ACTIVE_DRIVERS
}

const DriversAccordion = ({
  estimatedArrivals,
  allDrivers,
  assignedDriver,
  card,
}) => {
  const { formatMessage: f } = useIntl()
  const { selectedDrivers } = useContext(DriversContext)
  const [activeKey, setActiveKey] = useState(defaultPanel(assignedDriver))
  const [searchValue, setSearchValue] = useState('')
  const [filteredDrivers, setFilteredDrivers] = useState(allDrivers)
  const [openedSearch, setOpenedSearch] = useState(false)
  const [theme] = useSelectedTheme()

  useEffect(() => {
    setFilteredDrivers({
      active: filterDrivers(allDrivers.active, searchValue),
      inactive: filterDrivers(allDrivers.inactive, searchValue),
    })
  }, [allDrivers, searchValue])

  useEffect(() => {
    if (assignedDriver) {
      setActiveKey(ASSIGNED_DRIVER)
    } else if (activeKey === ASSIGNED_DRIVER) setActiveKey(ACTIVE_DRIVERS)
  }, [assignedDriver, activeKey])

  const onSearchStringChanged = e => {
    const { target } = e
    setSearchValue(target.value)
  }

  const activeLabel = getColumnLabel(
    activeKey === ACTIVE_DRIVERS,
    f({ id: 'drivers.active' }),
    filteredDrivers.active
  )
  const inactiveLabel = getColumnLabel(
    activeKey === INACTIVE_DRIVERS,
    f({ id: 'drivers.inactive' }),
    filteredDrivers.inactive
  )

  const onSwitchPanel = () => {
    if (assignedDriver) {
      setActiveKey(ASSIGNED_DRIVER)
      return
    }

    setActiveKey(x =>
      x === ACTIVE_DRIVERS ? INACTIVE_DRIVERS : ACTIVE_DRIVERS
    )
  }

  const collapseCss = classNames(themedClass('cards-column-accordion', theme), {
    'assigned-driver-column': !!assignedDriver,
  })

  const columnBtnCss = classNames(
    themedClass('column-btn', theme),
    'text-uppercase',
    {
      'column-btn-search': openedSearch,
    }
  )

  const panelCss = classNames({ 'disabled-panel': assignedDriver })
  const bottomBtnCss = classNames(columnBtnCss, 'drivers-list-bottom-btn')

  if (openedSearch) {
    return (
      <div className={collapseCss}>
        <div className={columnBtnCss}>
          <SearchBox
            startOpened
            value={searchValue}
            className="column-search"
            closable
            placeholder={f({ id: 'drivers.search-placeholder' })}
            onChange={onSearchStringChanged}
            onStateChanged={setOpenedSearch}
          />
        </div>

        <ScrollArea>
          <DriversColumn
            dividerValue={f({ id: 'drivers.active' })}
            drivers={filteredDrivers.active}
            estimatedArrivals={estimatedArrivals}
            className="card-column-search-mode"
          />

          <DriversColumn
            dividerValue={f({ id: 'drivers.inactive' })}
            drivers={filteredDrivers.inactive}
            estimatedArrivals={estimatedArrivals}
            className="card-column-search-mode"
          />
        </ScrollArea>
      </div>
    )
  }

  return (
    <Collapse accordion className={collapseCss} activeKey={activeKey}>
      <Panel key={ASSIGNED_DRIVER} showArrow={false}>
        <AssignedDriverPanel
          theme={theme}
          assignedDriver={assignedDriver}
          card={card}
        />
      </Panel>
      <Panel
        showArrow={false}
        key={ACTIVE_DRIVERS}
        className={panelCss}
        header={
          <PanelHeader
            opened={activeKey === ACTIVE_DRIVERS}
            onSwitchPanel={onSwitchPanel}
            label={activeLabel}
            showSearchIcon
            dropType={DriversState.INACTIVE}
            selectedDrivers={selectedDrivers}
            onSearchOpened={setOpenedSearch}
            className={columnBtnCss}
            theme={theme}
            dataCy="active-drivers-btn"
            allowDrop={!assignedDriver}
            disabled={assignedDriver || card?.archived}
          />
        }
      >
        <DriversColumn
          drivers={filteredDrivers.active}
          estimatedArrivals={estimatedArrivals}
          className="cards-column-upper"
          scrollArea
        />
      </Panel>

      <Panel
        key={INACTIVE_DRIVERS}
        showArrow={false}
        className={panelCss}
        header={
          <PanelHeader
            opened={activeKey === INACTIVE_DRIVERS}
            onSwitchPanel={onSwitchPanel}
            label={inactiveLabel}
            dropType={DriversState.ACTIVE}
            selectedDrivers={selectedDrivers}
            className={bottomBtnCss}
            theme={theme}
            dataCy="inactive-drivers-btn"
            allowDrop={!assignedDriver}
            disabled={assignedDriver || card?.archived}
          />
        }
      >
        <DriversColumn
          scrollArea
          drivers={filteredDrivers.inactive}
          estimatedArrivals={estimatedArrivals}
          className="cards-column-bottom"
        />
      </Panel>
    </Collapse>
  )
}

export default DriversAccordion
