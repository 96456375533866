import { Table } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  LanguagesEnum,
  TemperatureScaleEnum,
  UnitsSystemEnum,
  UserDateFormatsEnum,
} from '../../app/constants'
import { useUserSettings } from '../../hooks/useUserSettings'
import { themedClass } from '../../utils/style-utils'
import { userSettingsToString } from '../../utils/userSettings'
import { Button } from '../form/Buttons'

export const mockPresets = [
  {
    key: 0,
    name: 'US English',
    settingsValues: {
      language: LanguagesEnum.ENGLISH,
      units: UnitsSystemEnum.IMPERIAL,
      temperatureUnits: TemperatureScaleEnum.FAHRENHEIT,
      dateFormat: UserDateFormatsEnum.MDY,
    },
  },
  {
    key: 1,
    name: 'UK English',
    settingsValues: {
      language: LanguagesEnum.ENGLISH,
      units: UnitsSystemEnum.METRIC,
      temperatureUnits: TemperatureScaleEnum.CELSIUS,
      dateFormat: UserDateFormatsEnum.DMY,
    },
  },
  {
    key: 2,
    name: 'UK French',
    settingsValues: {
      language: LanguagesEnum.FRENCH,
      units: UnitsSystemEnum.METRIC,
      temperatureUnits: TemperatureScaleEnum.CELSIUS,
      dateFormat: UserDateFormatsEnum.DMY,
    },
  },
  {
    key: 3,
    name: 'UK German',
    settingsValues: {
      language: LanguagesEnum.GERMAN,
      units: UnitsSystemEnum.METRIC,
      temperatureUnits: TemperatureScaleEnum.CELSIUS,
      dateFormat: UserDateFormatsEnum.DMY,
    },
  },
]

const UserPresets = ({ theme }) => {
  const [currentUserSettings, saveUserSettings] = useUserSettings()
  const { formatMessage: f } = useIntl()

  const columns = [
    {
      title: f({ id: 'user-info.preset-settings-name' }), //'PRESET SETTINGS DISPLAY',
      dataIndex: 'name',
      className: 'preset-name-col',
    },
    {
      title: f({ id: 'user-info.preset-name' }), //'PRESET NAME',
      dataIndex: 'settings',
      className: 'preset-settings-col',
    },
  ]

  return (
    <div className={themedClass('user-presets', theme)}>
      <div className="user-presets-header">
        <h1>
          <FormattedMessage id="user-info.preset-header" />
        </h1>
        <Button type="primary">
          <FormattedMessage id="actions.edit" />
        </Button>
      </div>

      <div>
        <Table
          columns={columns}
          dataSource={mockPresets.map(mc => ({
            key: mc.key,
            name: mc.name,
            settings: userSettingsToString(mc.settingsValues),
          }))}
          pagination={false}
          rowClassName={record =>
            record.key === (currentUserSettings || { preset: 0 }).preset
              ? 'row-active'
              : ''
          }
          onRow={record => ({
            onClick: () => saveUserSettings({ preset: record.key }),
          })}
        />
      </div>
    </div>
  )
}

export default UserPresets
