import classNames from 'classnames'

import { themedClass } from '../../utils/style-utils'
import { ShortDateTimeFormat } from '../DateTimeFormats'

const LastReportedMarker = ({ lastReported, theme, contractor }) => (
  <div
    className={themedClass(
      'vehicle-last-update',
      theme,
      classNames({
        'vehicle-last-update-ic': contractor,
      })
    )}
  >
    <ShortDateTimeFormat date={lastReported} separator="|" dateSeparator="." />
  </div>
)

export default LastReportedMarker
