import { FormattedMessage } from 'react-intl'

import CurrentTime from './CurrentTime'
import { LongWeekDayDate } from './DateTimeFormats'

export const SplashScreenHeader = ({ currentTime }) => (
  <div>
    <div className="splash-screen-date text-uppercase">
      <LongWeekDayDate date={currentTime} />
    </div>
    <h1>
      <FormattedMessage id="app.driver-dispatch" />
    </h1>
    <div className="splash-screen-time">
      <CurrentTime showDate={false} />
    </div>
  </div>
)
