import GoogleMapsComponent from './GoogleMapsComponent'
import { FinishedRouteView } from './MapViews'
import { useSelectedTheme } from '../../hooks/useUserSettings'

const CardPopupMap = ({ pickupPoint, dropPoint, lineColor, route }) => {
  const [theme] = useSelectedTheme()
  return (
    <GoogleMapsComponent theme={theme}>
      <FinishedRouteView
        pickupPoint={pickupPoint}
        dropPoint={dropPoint}
        lineColor={lineColor}
        route={route}
      />
    </GoogleMapsComponent>
  )
}
export default CardPopupMap
