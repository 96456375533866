import { FormattedMessage } from 'react-intl'

import { ArrowCircleLeft } from '../../Icons'

export const ReturnButton = ({ onClick }) => (
  <div onClick={onClick} className="navigation-menu-return-btn clickable-text">
    <ArrowCircleLeft className="text-important navigation-menu-return-icon" />
    <FormattedMessage id="card-popup.close-submenu" />
  </div>
)
