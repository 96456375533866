import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { ShortDateTimeFormat } from '../DateTimeFormats'

export const SensorPanel = ({
  children,
  headerMessageId,
  lastReported,
  className,
}) => {
  const css = classNames(className, 'sensor-panel')
  return (
    <div className={css}>
      <div className="sensor-header">
        <FormattedMessage id={headerMessageId} />
      </div>
      <div className="sensor-content">{children}</div>
      <div className="sensor-footer text-uppercase">
        <FormattedMessage id="sensors.last-report" />:{' '}
        <ShortDateTimeFormat date={new Date(lastReported)} separator={'|'} />
      </div>
    </div>
  )
}
