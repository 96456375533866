import { useQuery } from '@apollo/client'
import { useEffect } from 'react'

import {
  FULL_CARD_SUBSCRIPTION,
  GET_FULL_CARD,
} from '../../app/graphQl/schema/Cards'
import { GET_JOB_DETAIL, JOB_SUBSCRIPTION } from '../../app/graphQl/schema/Jobs'

export function usePopupDetailQuery(currentCard) {
  const currentJobNumber = currentCard?.jobNumber

  const {
    data: { getJobDetail: jobDetail } = {},
    loading: loadingJob,
    error: jobLoadingError,
    subscribeToMore: subscribeToJob,
  } = useQuery(GET_JOB_DETAIL, {
    variables: { jobNumber: currentJobNumber },
    fetchPolicy: 'cache-and-network',
    returnPartialData: true,
    skip: !currentCard,
  })

  const {
    data: { getCard: card } = {},
    loading: loadingCard,
    error: cardLoadingError,
    subscribeToMore,
  } = useQuery(GET_FULL_CARD, {
    variables: { cardId: currentCard?.id },
    returnPartialData: true,
    skip: !currentCard,
  })

  useEffect(() => {
    if (currentJobNumber) {
      return subscribeToMore({
        document: FULL_CARD_SUBSCRIPTION,
        variables: {
          jobSubscription: { jobNumber: currentJobNumber },
        },
      })
    }
  }, [currentJobNumber, subscribeToMore])

  useEffect(() => {
    if (currentJobNumber) {
      return subscribeToJob({
        document: JOB_SUBSCRIPTION,
        variables: {
          jobNumber: currentJobNumber,
        },
      })
    }
  }, [currentJobNumber, subscribeToJob])

  const loadingError = cardLoadingError || jobLoadingError
  const isLoading = loadingCard || (loadingJob && !jobDetail) // dont display as loading when polling for data
  return [card, jobDetail, isLoading, loadingError]
}
