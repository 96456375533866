import classNames from 'classnames'
import _ from 'lodash'
import { forwardRef, memo, useCallback, useMemo } from 'react'

import { CardDragType } from './Card'
import CardsGroup from './CardsGroup'
import MultiPartCard from './MultiPartCard'
import { useCardGroupDrop } from '../../hooks/useCardGroupDrop'
import { isDeclined } from '../../utils/card-utils'
import { themedClass } from '../../utils/style-utils'
import DraggableComponent from '../DraggableComponent'
import { withUserUnits } from '../user-units/withUserUnits'

const DraggableCardComponent = (props, ref) => {
  const {
    data,
    onDragStart,
    onDragEnd,
    dragged,
    onGroupCards,
    DragPreview,
    countBadge,
    userUnits = {},
  } = props
  const [isHovered, cardDrop] = useCardGroupDrop(data, onGroupCards)

  const setRefs = useCallback(
    r => {
      if (_.isFunction(ref)) ref(r)
      cardDrop(r)
    },
    [cardDrop, ref]
  )

  const groupCss = themedClass('cards-group', props.theme)
  const css = classNames(
    { dragged: dragged },
    { 'card-highlight': isHovered },
    { [groupCss]: !!countBadge }
  )
  const dragItem = useMemo(
    () => ({
      id: data.id,
      data,
      currentStack: data.stack,
      type: CardDragType,
    }),
    [data]
  )

  const hasGroupedCards = _.some(data.groupChildren)
  return (
    <DraggableComponent
      CustomPreview={DragPreview}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      className={css}
      dragItem={dragItem}
      ref={setRefs}
      ignoreMouseOffsetY={hasGroupedCards || _.some(data.subjobs)}
      canDrag={!isDeclined(data)}
    >
      {hasGroupedCards ? (
        <CardsGroup {...props} distanceUnit={userUnits.distance} />
      ) : (
        <MultiPartCard {...props} distanceUnit={userUnits.distance} />
      )}
    </DraggableComponent>
  )
}

export default memo(
  forwardRef(withUserUnits(DraggableCardComponent)),
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
)
