/* global google */

import { useGoogleMap } from '@react-google-maps/api'
import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import createHTMLMapMarker from './HTMLMarker'

const HTMLMarkerComponent = ({
  clusterer,
  latLng,
  children,
  onLoad,
  onUnmount,
}) => {
  const map = useGoogleMap()
  const marker = useRef()
  const [markerDiv, setMarkerDiv] = useState()

  useEffect(() => {
    if (!marker.current && latLng) {
      marker.current = createHTMLMapMarker({
        latlng: new google.maps.LatLng(latLng),
        map: map,
        onDrawFinished: setMarkerDiv,
      })
      onLoad && onLoad(marker.current)
      if (clusterer) clusterer.addMarker(marker.current)
    }
  }, [clusterer, latLng, map, onLoad])

  useEffect(() => {
    marker.current.latlng = new google.maps.LatLng(latLng)
    marker.current.draw()
  }, [latLng])

  useEffect(
    () => () => {
      if (marker.current) {
        marker.current.setMap(null)
        if (clusterer) clusterer.removeMarker(marker.current)
        onUnmount && onUnmount()
      }
    },
    [clusterer, onUnmount]
  )

  return markerDiv ? ReactDOM.createPortal(children, markerDiv) : null
}

export default HTMLMarkerComponent
