import classNames from 'classnames'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import useCardStackChange from '../hooks/useCardStackChange'
import { emptyArray } from '../utils/perf-utils'

const CardDropPlace = ({ stack, labelId }) => (
  <CardDropWrap
    className="card-drop-place text-capitalized"
    stack={stack}
    selectedCards={emptyArray}
    confirmEta
  >
    <FormattedMessage id={labelId} />
  </CardDropWrap>
)

export const CardDropWrap = ({
  stack,
  className,
  afterDrop,
  selectedCards,
  children,
}) => {
  const [isHovered, cardDrop, lastDropped] = useCardStackChange(
    stack,
    selectedCards
  )

  useEffect(() => {
    afterDrop && afterDrop(lastDropped)
  }, [lastDropped, afterDrop])

  const css = classNames(className, {
    'card-drop-hovered': isHovered,
  })

  return (
    <>
      <div className={css} ref={cardDrop}>
        {children}
      </div>
    </>
  )
}

export default CardDropPlace
