import { useLazyQuery } from '@apollo/client'

import { GET_LOCATIONS } from '../app/graphQl/schema/Locations'

export function useLocations() {
  const [filterLocations, { data = {} }] = useLazyQuery(GET_LOCATIONS, {
    fetchPolicy: 'network-only',
  })

  return {
    filtering: {
      filterLocations,
      data,
    },
  }
}
