import PropTypes from 'prop-types'
import { FormattedDate, FormattedTimeParts } from 'react-intl'

import { arrayToObject } from '../utils/array-utils'
import { formatHour24Format } from '../utils/date-utils'
import { MISSING_VALUE, padInteger } from '../utils/string-utils'

export const DateFormats = {
  MDY: 'MDY',
  DMY: 'DMY',
}

export const ShortDateFormats = {
  MDY: 'MM/DD/YYYY',
  DMY: 'DD.MM.YYYY',
}

export const FullDateLongMonthFormat = ({ date }) => (
  <FormattedDate value={date} year="numeric" month="long" day="2-digit" />
)

export const YearLongMonthFormat = ({ date }) => {
  if (!date) return null
  return <FormattedDate value={date} year="numeric" month="long" />
}

export const ShortMonthYearFormat = ({ date }) => (
  <FormattedDate value={date} year="numeric" month="short" day="2-digit" />
)

export const ShortDayMonthFormat = ({ date, ignoreOffset }) => {
  const props = {
    value: date,
    month: '2-digit',
    day: '2-digit',
  }

  if (ignoreOffset) {
    props.timeZone = 'utc'
  }

  return <FormattedDate {...props} />
}

export const HourMinuteFormat = ({ date, ignoreOffset }) => {
  if (!date || isNaN(date)) return null

  const props = {
    value: date,
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
    hour12: false,
  }
  if (ignoreOffset) {
    props.timeZone = 'utc'
  }

  return (
    <FormattedTimeParts {...props}>
      {parts => {
        const partsObj = arrayToObject(parts, 'type', 'value')
        const paddedHour = formatHour24Format(partsObj.hour)
        return `${paddedHour}:${partsObj.minute}`
      }}
    </FormattedTimeParts>
  )
}

export const TimeLongDateFormat = ({ date }) => (
  <>
    <HourMinuteFormat date={date} /> | <FullDateLongMonthFormat date={date} />
  </>
)

const WeekDayDate = ({ date }) => {
  if (!date || isNaN(date)) return null

  return (
    <FormattedTimeParts
      value={date}
      weekday="short"
      month="2-digit"
      day="2-digit"
      year="numeric"
    >
      {parts => {
        const timeParts = arrayToObject(parts, 'type', 'value')
        return (
          <>
            {timeParts.weekday} {timeParts.month}/{timeParts.day}/
            {timeParts.year}{' '}
          </>
        )
      }}
    </FormattedTimeParts>
  )
}

export const LongWeekDayDate = ({ date, separator = ' • ' }) => {
  if (!date || isNaN(date)) return null

  return (
    <>
      <FormattedDate value={date} weekday="long" />
      {separator}
      <FullDateLongMonthFormat date={date}></FullDateLongMonthFormat>
    </>
  )
}

export const DayDateTimeFormat = ({ date, timeClassName, separator = '|' }) => {
  if (!date || isNaN(date)) return null
  return (
    <>
      <WeekDayDate date={date} /> {separator}{' '}
      <span className={timeClassName}>
        <HourMinuteFormat date={date} />
      </span>
    </>
  )
}

const CustomDateFormat = ({
  date,
  ignoreOffset,
  dateFormat = DateFormats.MDY,
  separator = '/',
  formaterProps,
}) => {
  if (!date) return null

  const props = {
    value: date,
    ...formaterProps,
  }

  if (ignoreOffset) {
    props.timeZone = 'utc'
  }

  return (
    <FormattedTimeParts value={date} {...props}>
      {parts => {
        const timeParts = arrayToObject(parts, 'type', 'value')
        if (dateFormat === DateFormats.MDY)
          return `${timeParts.month}${separator}${timeParts.day}${separator}${timeParts.year}`
        else
          return `${timeParts.day}${separator}${timeParts.month}${separator}${timeParts.year}`
      }}
    </FormattedTimeParts>
  )
}

export const ShortDateFormat = props => (
  <CustomDateFormat
    {...props}
    formaterProps={{ month: '2-digit', day: '2-digit', year: 'numeric' }}
  />
)

const NarrowDateFormat = props => (
  <CustomDateFormat
    {...props}
    separator=""
    formaterProps={{ month: 'short', day: '2-digit', year: 'numeric' }}
  />
)

export const ShortDateTimeFormat = ({
  date,
  ignoreOffset,
  dateSeparator,
  separator = '-',
}) => {
  if (!date || isNaN(date)) return null

  return (
    <>
      <ShortDateFormat
        date={date}
        ignoreOffset={ignoreOffset}
        separator={dateSeparator}
      />
      {` ${separator} `}
      <HourMinuteFormat date={date} ignoreOffset={ignoreOffset} />
    </>
  )
}

export const ShortMonthTimeFormat = ({
  date,
  ignoreOffset,
  separator = '|',
}) => {
  if (!date) return null
  return (
    <>
      <ShortMonthYearFormat date={date} /> {separator}{' '}
      <HourMinuteFormat date={date} ignoreOffset={ignoreOffset} />
    </>
  )
}

export const NarrowDateTimeFormat = ({
  date,
  ignoreOffset,
  separator = '|',
}) => (
  <>
    <NarrowDateFormat date={date} /> {separator}{' '}
    <HourMinuteFormat date={date} ignoreOffset={ignoreOffset} />
  </>
)

export const FlightDateTimeShort = ({ date }) => {
  if (!date) return MISSING_VALUE
  return (
    <>
      <ShortDayMonthFormat date={date} ignoreOffset /> |{' '}
      <HourMinuteFormat date={date} ignoreOffset />
    </>
  )
}

export const TimeWithTimeZone = ({ date }) => (
  <>
    <FormattedTimeParts
      value={date}
      hour="2-digit"
      minute="2-digit"
      hour12={false}
      timeZoneName="short"
    >
      {parts => {
        const timeParts = arrayToObject(parts, 'type', 'value')
        return (
          <>
            {formatHour24Format(timeParts.hour)}:{timeParts.minute}{' '}
            {timeParts.timeZoneName}
          </>
        )
      }}
    </FormattedTimeParts>
  </>
)

export const DurationTime = ({ duration, secondsClassName }) => (
  <>
    <span>
      {padInteger(Math.floor(duration.as('hours')))}:
      {padInteger(duration.get('minutes'))}
    </span>
    <span className={secondsClassName}>:{padInteger(duration.seconds())}</span>
  </>
)

const commonPropTypes = {
  date: PropTypes.object.isRequired,
}

FullDateLongMonthFormat.propTypes = commonPropTypes
HourMinuteFormat.propTypes = commonPropTypes
YearLongMonthFormat.propTypes = commonPropTypes
TimeLongDateFormat.propTypes = commonPropTypes
