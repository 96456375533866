import { DatePickerInput } from './DatePickerInput'
import { themedClass } from '../../utils/style-utils'
import { ShortDateFormats } from '../DateTimeFormats'

// TODO: date format should be taken from global user settings
const DateRangePicker = ({ range = {}, onRangeChanged, theme }) => {
  const onFromChanged = val => {
    onRangeChanged({ ...range, from: val.startOf('day') })
  }
  const onToChanged = val => {
    onRangeChanged({ ...range, to: val })
  }

  const { from, to } = range
  const css = themedClass('date-range-picker', theme, 'space-between')
  return (
    <div className={css}>
      <DatePickerInput
        label="misc.from"
        value={from}
        onChange={onFromChanged}
        format={ShortDateFormats.MDY}
        theme={theme}
      />
      <DatePickerInput
        label="misc.to"
        value={to}
        onChange={onToChanged}
        format={ShortDateFormats.MDY}
        theme={theme}
      />
    </div>
  )
}

export default DateRangePicker
