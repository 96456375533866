import { gql } from '@apollo/client'

import { MUTATION_RESPONSE } from './Common'

//#region fragments

export const TRACKED_CHANGE_FRAGMENT = gql`
  fragment trackedChange on TrackedChange {
    id
    cardNumber
    acknowledgedBy
    changes {
      path
      previousValue
      currentValue
    }
  }
`

//#endregion

export const ACK_CHANGE = gql`
  mutation acknowledgeChange($changes: [ID]) {
    acknowledgeChange(changes: $changes) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const TRACKED_CHANGE_SUBSCRIPTION = gql`
  subscription trackedChangeSubscription {
    trackedChangeUpdated {
      operation
      changeData {
        ...trackedChange
      }
    }
  }
  ${TRACKED_CHANGE_FRAGMENT}
`
