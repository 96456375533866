import classNames from 'classnames'
import _ from 'lodash'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import DangerousGoodsInfo from './DangerousGoodsInfo'
import { PackagesSwitch } from './PackagesSwitch'
import { isSubJobCard } from '../../utils/card-utils'
import { formatNullableString } from '../../utils/string-utils'
import { TextImportant } from '../Typography'
import { Weight } from '../user-units/WeightUnits'

const PieceDimensions = ({ piece }) => (
  <>
    {piece.width} x {piece.length} x {piece.height}
  </>
)

const SubjobCommodity = ({ card, rowClass }) => {
  const rowCss = classNames(rowClass)
  return (
    <>
      <p className={rowCss}>
        {card.pickupAmount && (
          <>
            <span className="commodity-label">
              <FormattedMessage id="card-dispatch.amount" />:
            </span>
            <Weight weight={card?.pickupAmount} />
          </>
        )}
      </p>
      <p className={rowCss} />
      <p className={rowCss} />
      <p className={rowCss} />
    </>
  )
}

const FullJobCommodity = ({ card, job, rowClass, addDangerousGoodsInfo }) => {
  const [packageIndex, setPackageIndex] = useState(0)
  const rowCss = classNames(rowClass, 'commodity-packages-row')
  const labelCss = 'commodity-label text-uppercase'
  const currentPackage = _.nth(job?.packages, packageIndex)

  return (
    <>
      <div className={rowCss}>
        <span className={labelCss}>
          <FormattedMessage id="card-dispatch.pieces" />:{' '}
          <TextImportant>
            {formatNullableString(currentPackage?.piecesCount)}
          </TextImportant>
        </span>
        <PackagesSwitch
          packages={job?.packages}
          selectedIndex={packageIndex}
          onPieceSelected={setPackageIndex}
        />
      </div>
      <p className={rowCss}>
        <span className={labelCss}>
          <FormattedMessage id="card-dispatch.dimension" />:
        </span>
        {currentPackage && <PieceDimensions piece={currentPackage} />}
      </p>
      <p className={rowCss}>
        <span className={labelCss}>
          <FormattedMessage id="card-dispatch.weight-dim" />:
        </span>
        <Weight weight={currentPackage?.weight} /> /{' '}
        <Weight weight={currentPackage?.dimensionalWeight} />
      </p>
      <p className={`${rowClass} text-ellipsed`}>
        <span className={labelCss}>
          <FormattedMessage id="card-dispatch.commodity" />:
        </span>
        <span
          className="text-capitalized"
          title={currentPackage ? currentPackage.commodity : ''}
        >
          {_.toLower(formatNullableString(currentPackage?.commodity))}
        </span>
      </p>
      {addDangerousGoodsInfo && (
        <DangerousGoodsInfo
          card={card}
          labelCss={labelCss}
          rowClass={rowClass}
        />
      )}
    </>
  )
}

export const CommodityInfo = props => {
  const { card } = props
  return isSubJobCard(card) ? (
    <SubjobCommodity {...props} />
  ) : (
    <FullJobCommodity {...props} />
  )
}
