import { gql } from '@apollo/client'

import { MUTATION_RESPONSE } from './Common'

//#region fragments
const STACK_DETAIL = gql`
  fragment stackDetail on Stack {
    id
    name
    order
    stationId
    dropLocation
  }
`
//#endregion

//#region queries
export const GET_STACKS = gql`
  query getStacks($stationId: ID!) {
    listStacks(stationId: $stationId) {
      ...stackDetail
    }
  }
  ${STACK_DETAIL}
`
//#endregion

//#region mutations
export const CREATE_STACK = gql`
  mutation CreateStack($name: String!, $stationId: ID!) {
    createStack(name: $name, stationId: $stationId) {
      ...stackDetail
    }
  }
  ${STACK_DETAIL}
`

export const RENAME_STACK = gql`
  mutation RenameStack($id: String!, $newName: String!) {
    renameStack(id: $id, newName: $newName) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const REMOVE_STACK = gql`
  mutation RemoveStack($id: String!) {
    removeStack(id: $id) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const REORDER_STACK = gql`
  mutation ReorderStack($newOrder: [ID], $stationId: ID!) {
    reorderStack(newOrder: $newOrder, stationId: $stationId) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`
//#endregion

//#region subscriptions
export const STACK_SUBSCRIPTION = gql`
  subscription subscribeToStacks($station: ID!) {
    stackUpdated(station: $station) {
      operation
      changeData {
        ...stackDetail
      }
    }
  }
  ${STACK_DETAIL}
`
//#endregion
