import { Popover } from 'antd'
import classNames from 'classnames'

import { useSelectedTheme } from '../../hooks/useUserSettings'
import { themedClass } from '../../utils/style-utils'

const MainHeaderPopover = props => {
  const [theme] = useSelectedTheme()
  const { showArrow, overlayClassName, ...popoverProps } = props

  const overlayCss = themedClass(
    'main-header-popover',
    theme,
    classNames(overlayClassName, {
      'arrowless-popover': !showArrow,
    })
  )

  return <Popover overlayClassName={overlayCss} {...popoverProps} />
}

export default MainHeaderPopover
