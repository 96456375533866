import UserPresets, { mockPresets } from './UserPresets'
import { ThemesEnum } from '../../app/enums/Themes'
import { useUserSettings } from '../../hooks/useUserSettings'
import { userSettingsToString } from '../../utils/userSettings'
import MainHeaderPopover from '../main-header/MainHeaderPopover'

export function UserSettingsPopover() {
  const [currentUserSettings] = useUserSettings()

  const currentPreset =
    mockPresets.find(p => p.key === (currentUserSettings || {}).preset) ||
    mockPresets[0]

  const presetLabel = userSettingsToString(currentPreset.settingsValues)

  return (
    <MainHeaderPopover
      trigger="click"
      content={<UserPresets theme={ThemesEnum.DARK} />}
      placement="bottomLeft"
      overlayClassName="user-presets-popover"
    >
      <span className="clickable-text">
        <i className="fas fa-sliders-h main-header-icon" />
        <span>{presetLabel}</span>
      </span>
    </MainHeaderPopover>
  )
}
