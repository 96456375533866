import { useMutation } from '@apollo/client'

import { createResponseNotification } from '../../utils/notifications-utils'
import { useNotificationsContext } from '../useNotificationsContext'

/**
 *
 * @param {*} mutationDoc graphql mutation schema
 * @param {*} operationName name of operation (used for displaying notifications)
 * @param {*} mutationOpts additional mutation options
 */
export const useNonOptimisticMutation = (
  mutationDoc,
  operationName,
  mutationOpts
) => {
  const { addNotification } = useNotificationsContext()

  const [mutation] = useMutation(mutationDoc, {
    ...mutationOpts,
    onError: err => {
      addNotification(createResponseNotification(operationName, err.message))
    },
  })

  const mutationFunc = ({ variables, cacheUpdate }) =>
    mutation({
      variables: variables,
      update: (cache, { data }) => {
        cacheUpdate && cacheUpdate(cache, data)
      },
    })
  return [mutationFunc]
}
