import { useRef } from 'react'

import AlarmScreen from './AlarmScreen'
import CardDriverAssign from './CardDriverAssign'
import { PopupBase } from './PopupBase'
import CardSidePanel from './common/CardSidePanel'
import { MapStyles } from '../../app/constants'
import { useCardContext } from '../../hooks/useCardPopupContext'
import { useDriverAssignContext } from '../../hooks/useDriversAssignContext'
import { ModalContainerContextProvider } from '../../hooks/useModalContainerContext'

export const CardsGroupDetail = ({ onClose, wrap, cardData = {} }) => {
  const elRef = useRef(null)
  const { currentCard } = useCardContext()
  const { mapStyle } = useDriverAssignContext()

  return (
    <PopupBase
      onClose={onClose}
      cardData={cardData}
      wrap={wrap}
      modalContainerRef={elRef}
    >
      <ModalContainerContextProvider container={elRef?.current}>
        <div className="card-detail">
          <div className="flow-window">
            <AlarmScreen card={currentCard} innerModalRef={elRef} />
            <CardDriverAssign card={currentCard} innerModalRef={elRef} />
          </div>

          {mapStyle !== MapStyles.Maximized && (
            <CardSidePanel cardData={currentCard} />
          )}
        </div>
      </ModalContainerContextProvider>
    </PopupBase>
  )
}
