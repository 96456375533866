import { DirectionsService } from '@react-google-maps/api'
import { isEqual } from 'lodash'
import { memo } from 'react'

const DriverDirectionsService = ({
  origin,
  destination,
  waypoints,
  responseRecieved,
}) => {
  const directionsCallback = response => {
    if (response !== null && response.status === 'OK') {
      responseRecieved(response)
    }
  }

  if (!origin || !destination) return null

  return (
    <DirectionsService
      options={{
        destination: destination,
        origin: origin,
        travelMode: 'DRIVING',
        waypoints: waypoints,
        // usefull if we need google to take traffic information into account, but billed higher rate
        // drivingOptions: {
        //   departureTime: new Date(),
        // },
      }}
      callback={directionsCallback}
    />
  )
}

export default memo(DriverDirectionsService, (prevProps, nextProps) => {
  const { origin: prevOrigin, destination: prevDestination } = prevProps
  const { origin: nextOrigin, destination: nextDestination } = nextProps
  return (
    isEqual(prevOrigin, nextOrigin) && isEqual(prevDestination, nextDestination)
  )
})
