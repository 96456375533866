import classNames from 'classnames'

import BorderOutset from './BorderOutset'
import { themedClass } from '../utils/style-utils'

export const HeaderSizes = {
  ExtraSmall: 0,
  Small: 1,
  Medium: 2,
  Large: 3,
}

export const JobDetailHeader = ({ size, children, uppercase, className }) => {
  const css = classNames(
    'job-detail-header',
    { 'text-uppercase': uppercase },
    className
  )
  switch (size) {
    case HeaderSizes.ExtraSmall:
      return <h4 className={css}>{children}</h4>
    case HeaderSizes.Small:
      return <h3 className={css}>{children}</h3>
    case HeaderSizes.Medium:
      return <h2 className={css}>{children}</h2>
    default:
      return <h1 className={css}>{children}</h1>
  }
}

export const FlowContentHeader = ({ className, children, theme }) => {
  const css = themedClass(
    'flow-content-header',
    theme,
    classNames(className, 'text-uppercase')
  )
  return (
    <BorderOutset className={css} theme={theme}>
      {children}
    </BorderOutset>
  )
}

export const FlowContentHeaderSmall = ({ children, className, theme }) => (
  <FlowContentHeader
    theme={theme}
    className={classNames('flow-content-header-small', className)}
  >
    {children}
  </FlowContentHeader>
)

export const JobHeaderLabel = ({ children, className }) => {
  const css = classNames('tiny-label text-uppercase', className)
  return <div className={css}>{children}</div>
}
