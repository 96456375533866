import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import DriverLog from './DriverLog'
import DriversAccordion from './DriversAccordion'
import { FeaturesEnum } from '../../app/enums/FeaturesEnum'
import useDriversList from '../../hooks/graphQl/useDriversList'
import { useCurrentStation } from '../../hooks/useCurrentStation'
import { useDriverAssignContext } from '../../hooks/useDriversAssignContext'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import {
  isControlKeyDown,
  isShiftKeyDown,
  isUtilityKeyDown,
} from '../../utils/dom-utils'
import { isActive } from '../../utils/driver-utils'
import { themedClass } from '../../utils/style-utils'
import { DriversContextProvider } from '../DriverAssigmentModule'
import { isFeatureAllowed } from '../SwitchableFeature'
import {
  ConfirmLoadedToDockModal,
  ConfirmUnloadedFromDockModal,
} from '../modals/ConfirmDateModal'

const DriversList = ({ onDriverJobClick, currentCard, wrapContainer }) => {
  const routeParams = useParams()
  const { stationId } = useCurrentStation()
  const [theme] = useSelectedTheme()
  const [allDrivers, setAllDrivers] = useState({ active: [], inactive: [] })
  const [driverLog, setDriverLog] = useState(null)
  const [confirmFromDock, setConfirmFromDock] = useState(false)
  const [confirmToDock, setConfirmToDock] = useState(false)
  const {
    estimatedArrivals,
    setEstimatedArrivals,
    driversSelection,
    driversChecking,
  } = useDriverAssignContext()

  const wrapperRef = useRef()

  const { drivers: driversData } = useDriversList()

  const [isDragging, setIsDragging] = useState()

  const { selectedItems: selectedDrivers, actions: selectActions } =
    driversSelection

  const { selectedItems: checkedDrivers, actions: checkedDriversActions } =
    driversChecking

  useEffect(() => {
    selectActions.deselectAll()
    checkedDriversActions.deselectAll()
  }, [checkedDriversActions, selectActions, stationId])

  useEffect(() => {
    const ids = _.map(checkedDrivers, v => v.id)
    setEstimatedArrivals(current => _.pick(current, ids))
  }, [checkedDrivers, setEstimatedArrivals])

  useEffect(() => {
    setAllDrivers({
      active: driversData.filter(d => d.isActive),
      inactive: driversData.filter(d => !d.isActive),
    })
  }, [driversData])

  // after navigating, close driver log
  useEffect(() => {
    setDriverLog(null)
  }, [routeParams])

  const onDriversSelected = useCallback(
    drivers => {
      checkedDriversActions.addToSelection(drivers)
    },
    [checkedDriversActions]
  )

  const onDriversDeselected = useCallback(
    drivers => {
      checkedDriversActions.deselectItems(drivers)
    },
    [checkedDriversActions]
  )

  const onDriverClick = useCallback(
    (driver, event) => {
      const wasUtilityKeyPressed = isUtilityKeyDown(event)
      if (isActive(driver) && !wasUtilityKeyPressed) {
        checkedDriversActions.toggleItem(driver)
        return
      }

      if (!isFeatureAllowed(FeaturesEnum.ALLOW_TEST_FEATURES)) return

      if (!wasUtilityKeyPressed) selectActions.selectExclusively([driver])

      if (isShiftKeyDown(event)) selectActions.selectUntil(driver)

      if (isControlKeyDown(event)) selectActions.toggleItem(driver)
    },
    [checkedDriversActions, selectActions]
  )

  const onDriverDragStart = useCallback(
    driver => {
      if (isActive(driver)) {
        checkedDriversActions.addToSelection([driver])
        setIsDragging(true)
        return
      }

      if (isFeatureAllowed(FeaturesEnum.ALLOW_TEST_FEATURES)) {
        selectActions.addToSelection([driver])
        setIsDragging(true)
      }
    },
    [checkedDriversActions, selectActions]
  )

  const onDriverDragEnd = useCallback(() => {
    setIsDragging(false)
  }, [])

  const onHistoryClick = useCallback(driver => {
    setDriverLog(driver)
  }, [])

  const onHistoryClose = useCallback(() => {
    setDriverLog(null)
  }, [])

  const onDropToDock = useCallback(() => {
    setConfirmToDock(true)
  }, [])

  const onPickFromDock = useCallback(() => {
    setConfirmFromDock(true)
  }, [])

  const contextValue = {
    onDriverDragStart,
    onDriverDragEnd,
    onDriverClick,
    onHistoryClick,
    onDriversDeselected,
    onDriversSelected,
    onDropToDock,
    onPickFromDock,
    selectedDrivers,
    isDragging,
    checkedDrivers,
  }

  const assignedDriver = _.find(
    driversData,
    d => d.id === currentCard?.driver?.id
  )

  return (
    <DriversContextProvider value={contextValue}>
      {confirmToDock && (
        <ConfirmLoadedToDockModal
          onClosed={() => setConfirmToDock(false)}
          card={currentCard}
        />
      )}

      {confirmFromDock && (
        <ConfirmUnloadedFromDockModal
          onClosed={() => setConfirmFromDock(false)}
          card={currentCard}
        />
      )}

      <div className={themedClass('drivers-list-wrap', theme)} ref={wrapperRef}>
        {driverLog && (
          <DriverLog
            onClosed={onHistoryClose}
            driver={driverLog}
            wrapperRef={wrapperRef.current}
            onDriverJobClick={onDriverJobClick}
            theme={theme}
          />
        )}
        <DriversAccordion
          estimatedArrivals={estimatedArrivals}
          allDrivers={allDrivers}
          assignedDriver={assignedDriver}
          card={currentCard}
        />
      </div>
    </DriversContextProvider>
  )
}

export default DriversList
