import _ from 'lodash'
import { createContext, useContext, useState } from 'react'

export const CardPopupContext = createContext()

export const CardPopupContextProvider = ({
  children,
  startPage,
  currentCard,
  currentJob,
  onCardChanged,
  readOnly,
}) => {
  const [currentView, setCurrentView] = useState(startPage)
  const [previousView, setPreviousView] = useState()
  const [opened, setOpened] = useState(true)
  const [callbacks, setCallbacks] = useState([])
  const [flowHistory, setFlowHistory] = useState([
    { view: startPage, card: currentCard },
  ])

  const getCurrentIndex = () =>
    _.findIndex(
      flowHistory,
      h => h.view === currentView && h.card.id === currentCard.id
    )

  const changeView = newPage => {
    setPreviousView(currentView)
    setCurrentView(newPage)
  }

  const currentPageChanged = page => {
    changeView(page)
  }

  const moveBack = (pageSelect, defaultPage = null) => {
    const currentIndex = getCurrentIndex()
    let nextView = defaultPage
    let nextPageIndex = null
    if (currentIndex > 0) {
      nextPageIndex = currentIndex - 1
    }

    if (currentIndex === -1) {
      nextPageIndex = flowHistory.length - 1
    }

    if (nextPageIndex !== null) {
      if (pageSelect) {
        nextPageIndex = _.findLastIndex(flowHistory, pageSelect, nextPageIndex)
      }
      if (nextPageIndex > -1) {
        nextView = flowHistory[nextPageIndex].view
        if (flowHistory[nextPageIndex].card.id !== currentCard.id) {
          onCardChanged(flowHistory[nextPageIndex].card)
        }
      }
    }
    if (nextView !== null) {
      changeView(nextView)
    }
  }

  const moveForward = () => {
    const currentIndex = getCurrentIndex()
    if (currentIndex < flowHistory.length - 1) {
      const historyEntry = flowHistory[currentIndex + 1]
      changeView(historyEntry.view)
      if (historyEntry.card.id !== currentCard.id) {
        onCardChanged(historyEntry.card)
      }
    }
  }

  const closePopup = () => {
    setOpened(false)
  }

  const executeAfterClose = callback => {
    setCallbacks(c => [...c, callback])
  }

  const executeCallbacks = () => {
    callbacks.forEach(c => {
      c()
    })
  }

  const addCurrentPageToHistory = () => {
    const flowHistoryIndex = getCurrentIndex()
    if (flowHistoryIndex === -1) {
      const newHistoryEntry = {
        view: currentView,
        card: currentCard,
      }
      setFlowHistory([...flowHistory, newHistoryEntry])
    }
  }

  const currentIndex = getCurrentIndex()
  const canGoBack =
    (currentIndex === -1 && flowHistory.length > 1) || currentIndex > 0
  const canGoForward =
    currentIndex > -1 && currentIndex < flowHistory.length - 1

  console.log('cardPopupContext render')

  return (
    <CardPopupContext.Provider
      value={{
        currentPage: currentView,
        prevPage: previousView,
        canGoBack,
        canGoForward,
        moveBack,
        moveForward,
        changeView: currentPageChanged,
        addCurrentPageToHistory,
        opened,
        closePopup,
        executeAfterClose,
        executeCallbacks,
        currentCard,
        currentJob,
        changeCard: onCardChanged,
        readOnly,
      }}
    >
      {children}
    </CardPopupContext.Provider>
  )
}

export const useCardContext = () => useContext(CardPopupContext)
