import classNames from 'classnames'

const MissingShipmentData = ({ rowClass }) => {
  const css = classNames(rowClass)
  return (
    <>
      <p className={css} />
      <p className={css} />
      <p className={css} />
      <p className={css} />
    </>
  )
}

export default MissingShipmentData
