import classNames from 'classnames'

export const IconBase = ({ icon, className, onClick }) => (
  <i className={classNames(icon, className)} onClick={onClick} />
)

export const ExpandIcon = ({ expanded, rightAlign }) => {
  const iconCss = classNames(
    'fas fa-caret-down expand-icon',
    {
      'expand-icon-opened': !expanded,
    },
    {
      'icon-right': rightAlign,
    }
  )

  return <i className={iconCss} />
}

export const WarningIcon = ({ className }) => {
  const iconCss = classNames('fas fa-exclamation-triangle', className)

  return <i className={iconCss} />
}

export const SearchIcon = props => <IconBase icon="fas fa-search" {...props} />

export const CalendarIcon = ({ className }) => (
  <i className={classNames('far fa-calendar', className)} />
)

export const AngleDownIcon = props => (
  <IconBase icon="fas fa-angle-down" {...props} />
)

export const ClockIcon = ({ className }) => (
  <i className={classNames('far fa-clock', className)} />
)

export const CloseIcon = ({ className, onClick }) => (
  <i
    className={classNames('fas fa-times-circle', className)}
    onClick={onClick}
  />
)

export const CloseIconAlt = props => <IconBase icon="fas fa-times" {...props} />

export const NotAllowedIcon = ({ className, onClick }) => (
  <i className={classNames('fas fa-ban', className)} onClick={onClick} />
)

export const HighlightIcon = ({ className, onClick }) => (
  <i className={classNames('fa fa-eye', className)} onClick={onClick} />
)

export const PlusIcon = ({ className, onClick }) => (
  <i
    className={classNames('fas fa-plus-square', className)}
    onClick={onClick}
  />
)

export const MinusSignIcon = props => (
  <IconBase icon="fas fa-minus" {...props} />
)

export const WindowClose = props => (
  <IconBase icon="fas fa-window-close" {...props} />
)

export const PlusSignIcon = props => <IconBase icon="fas fa-plus" {...props} />

export const TimesIcon = ({ className, onClick, onMouseDown, onMouseUp }) => (
  <i
    className={classNames('fas fa-times-circle', className)}
    onClick={onClick}
    onMouseDown={onMouseDown}
    onMouseUp={onMouseUp}
  />
)

export const ParkingIcon = () => <i className="fas fa-parking" />

export const TollsIcon = () => <i className="fas fa-hand-holding-usd" />

export const StopwatchIcon = props => (
  <IconBase icon="fa fa-stopwatch" {...props} />
)

export const ArrowCircleUpIcon = props => (
  <IconBase icon="far fa-arrow-alt-circle-up" {...props} />
)

export const ArrowCircleDownIcon = props => (
  <IconBase icon="far fa-arrow-alt-circle-down" {...props} />
)

export const HistoryIcon = props => <IconBase icon="fa fa-history" {...props} />

export const CopyIcon = props => <IconBase icon="far fa-copy" {...props} />

export const CheckIconUnchecked = props => (
  <IconBase icon="far fa-square" {...props} />
)

export const CheckIcon = props => (
  <IconBase icon="fas fa-check-square" {...props} />
)

export const CaretUp = props => <IconBase icon="fas fa-caret-up" {...props} />

export const CaretDown = props => (
  <IconBase icon="fas fa-caret-down" {...props} />
)

export const ExtraStopIcon = () => <i className="fab fa-rev" />

export const ColumnDropIcon = props => (
  <IconBase icon="fas fa-arrow-circle-down" {...props} />
)

export const ArrowCircleRight = props => (
  <IconBase icon="fas fa-arrow-alt-circle-right" {...props} />
)

export const ArrowCircleLeft = props => (
  <IconBase icon="fas fa-arrow-alt-circle-left" {...props} />
)

export const ArrowCircleUp = props => (
  <IconBase icon="fas fa-arrow-circle-up" {...props} />
)

export const UserSlash = props => (
  <IconBase icon="fas fa-user-alt-slash" {...props} />
)

export const InfoCircle = props => (
  <IconBase icon="fas fa-info-circle" {...props} />
)

export const TrashIcon = props => <IconBase icon="fas fa-trash" {...props} />

export const UploadIcon = props => (
  <IconBase icon="fas fa-cloud-upload-alt" {...props} />
)

export const CheckCircle = props => (
  <IconBase icon="fas fa-check-circle" {...props} />
)

export const ThumbsUp = props => <IconBase icon="fas fa-thumbs-up" {...props} />
