import { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { ThemesEnum } from '../../app/enums/Themes'
import { logout, useAuthentication } from '../../hooks/useAuthentication'
import { usePopover } from '../../hooks/usePopover'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { themedClass } from '../../utils/style-utils'
import PopoverMenu from '../PopoverMenu'
import MainHeaderPopover from '../main-header/MainHeaderPopover'

const items = [
  {
    key: 'logout',
    label: (
      <div className="text-capitalized">
        <FormattedMessage id="auth.log-out" />
      </div>
    ),
  },
  {
    key: 'settings',
    label: (
      <div className="text-capitalized">
        <FormattedMessage id="user-menu.settings" />
      </div>
    ),
  },
]

const UserMenu = forwardRef(({ theme }, ref) => {
  const onItemClick = ({ key }) => {
    if (key === 'logout') logout()
  }

  return (
    <div className={themedClass('user-menu-content', theme)} ref={ref}>
      <PopoverMenu
        selectedKeys={[]}
        theme={ThemesEnum.DARK}
        items={items}
        onClick={onItemClick}
      />
    </div>
  )
})

const LoggedUserInfo = () => {
  const [currentUser] = useAuthentication()
  const [isMouseOver, setAnchor, setPopup] = usePopover()
  const [theme] = useSelectedTheme()

  if (!currentUser) return null

  return (
    <div className="user-box">
      <div className="user-box-section" ref={setAnchor}>
        <MainHeaderPopover
          content={<UserMenu ref={setPopup} theme={theme} />}
          placement="bottomRight"
          overlayClassName={themedClass('user-menu', theme)}
          open={isMouseOver}
          arrowPointAtCenter
          showArrow
        >
          <i className="fas fa-caret-down" />
        </MainHeaderPopover>
        {currentUser.fullname}
      </div>
    </div>
  )
}

export default LoggedUserInfo
