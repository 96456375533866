import { Button, Dropdown } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { memo, useEffect, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'

import VerticalList from './VerticalList'
import { CardDragType } from './job-cards/Card'
import { useLocalDateTime } from '../hooks/useLocalDateTime'
import { padInteger } from '../utils/string-utils'

const scrollStep = 800

const MinutesDropdown = ({ hour, onMenuClicked, theme }) => (
  <VerticalList
    theme={theme}
    className="dispatch-alert-dropdown"
    onItemClick={onMenuClicked}
    items={[0, 15, 30, 45]}
    itemCss={'dispatch-alert-dropdown-item'}
    ItemComponent={({ item }) => (
      <div>
        {padInteger(hour)}:{padInteger(item)}
      </div>
    )}
  />
)

const DroppableButton = ({
  value,
  isActive,
  onClick,
  onTimeSelected,
  theme,
}) => {
  const [droppedCard, setDroppedCard] = useState()

  const [{ isHovered }, alarmDrop] = useDrop({
    accept: CardDragType,
    collect: monitor => ({
      isHovered: monitor.isOver() && monitor.canDrop(),
    }),
    drop: item => {
      setDroppedCard(item.data)
    },
  })

  const buttonCss = classNames({
    'btn-over': isHovered,
  })

  const onMenuClicked = item => {
    onTimeSelected({ hour: value.id, minute: item, card: droppedCard })
    setDroppedCard(null)
  }

  const onVisibleChange = visible => {
    if (!visible) {
      setDroppedCard(null)
    }
  }

  return (
    <span>
      <Dropdown
        dropdownRender={() => (
          <MinutesDropdown
            hour={value.id}
            onMenuClicked={onMenuClicked}
            theme={theme}
          />
        )}
        overlayClassName="dispatch-alert-dropdown-overlay"
        open={!!droppedCard}
        placement="bottom"
        onOpenChange={onVisibleChange}
      >
        <Button
          ref={r => alarmDrop(r)}
          className={buttonCss}
          onClick={e => onClick(value, e)}
          type={isActive ? 'primary' : 'default'}
        >
          {padInteger(value.id)}:00
        </Button>
      </Dropdown>
    </span>
  )
}

const DispatchAlertButtons = ({
  possibleValues = [],
  selectedValues,
  onButtonClick,
  onAlarmSet,
  theme,
}) => {
  const { getCurrentTime } = useLocalDateTime()
  const [scrollingRight, setScrollingRight] = useState()
  const [scrollingLeft, setScrollingLeft] = useState()
  const wrapperEl = useRef(null)

  const [, leftDrop] = useDrop({
    accept: CardDragType,
    collect: monitor => {
      setScrollingLeft(monitor.isOver())
    },
  })

  const [, rightDrop] = useDrop({
    accept: CardDragType,
    collect: monitor => {
      setScrollingRight(monitor.isOver())
    },
  })

  useEffect(() => {
    let timerId
    if (scrollingRight) {
      scrollRight()
      timerId = setInterval(() => scrollRight(), 400)
    } else if (scrollingLeft) {
      scrollLeft()
      timerId = setInterval(() => scrollLeft(), 400)
    }

    return function cleanup() {
      if (timerId) clearInterval(timerId)
    }
  }, [scrollingRight, scrollingLeft])

  const scrollRight = () => {
    wrapperEl.current.scrollBy({
      left: scrollStep,
      top: 0,
      behavior: 'smooth',
    })
  }

  const scrollLeft = () => {
    wrapperEl.current.scrollBy({
      left: -scrollStep,
      top: 0,
      behavior: 'smooth',
    })
  }

  const startScrollLeft = e => {
    if (!e || (e.buttons & 1) !== 0) {
      setScrollingLeft(true)
    }
  }

  const startScrollRight = e => {
    if (!e || (e.buttons & 1) !== 0) {
      setScrollingRight(true)
    }
  }

  const stopScroll = () => {
    setScrollingRight(false)
    setScrollingLeft(false)
  }

  const onTimeSelected = ({ hour, minute, card }) => {
    const currentTime = getCurrentTime()
      .hour(hour)
      .minute(minute)
      .seconds(0)
      .millisecond(0)

    onAlarmSet(currentTime, card)
  }

  return (
    <div className="sliding-wrap">
      <Button
        ref={r => leftDrop(r)}
        className="scroll-button left"
        onMouseDown={startScrollLeft}
        onMouseEnter={startScrollLeft}
        onMouseLeave={stopScroll}
        onMouseUp={stopScroll}
      >
        <i className="fas fa-caret-left" />
      </Button>

      <div className="sliding-buttons" ref={wrapperEl}>
        {possibleValues.map(n => (
          <DroppableButton
            value={n}
            isActive={_.some(selectedValues, x => x.id === n.id)}
            onClick={onButtonClick}
            onTimeSelected={onTimeSelected}
            key={n.id}
            theme={theme}
          />
        ))}
      </div>

      <Button
        ref={r => rightDrop(r)}
        className="scroll-button right"
        onMouseDown={startScrollRight}
        onMouseEnter={startScrollRight}
        onMouseLeave={stopScroll}
        onMouseUp={stopScroll}
      >
        <i className="fas fa-caret-right" />
      </Button>
    </div>
  )
}

export default memo(DispatchAlertButtons, (next, prev) => _.isEqual(next, prev))
