import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

export const TopHeaderSection = ({ children, labelMessageId, className }) => {
  const css = classNames('main-header-section', className)
  return (
    <div className={css}>
      {labelMessageId && (
        <div className="main-header-section-label text-uppercase">
          <FormattedMessage
            id={labelMessageId}
            defaultMessage={labelMessageId}
          />
        </div>
      )}
      {children}
    </div>
  )
}
