import { useEffect, useState } from 'react'
import { FormattedTimeParts } from 'react-intl'

import { arrayToObject } from '../utils/array-utils'

const CurrentTime = ({ showDate = true }) => {
  const [currentTime, setCurrentTime] = useState(new Date())

  useEffect(() => {
    const timerID = setInterval(() => setCurrentTime(new Date()), 500)

    return function cleanup() {
      clearInterval(timerID)
    }
  }, [])

  return (
    <FormattedTimeParts
      value={currentTime}
      hour="2-digit"
      minute="2-digit"
      second="2-digit"
      hour12={false}
      timeZoneName="short"
      weekday="long"
      month="short"
      day="2-digit"
      year="numeric"
    >
      {parts => {
        const timeParts = arrayToObject(parts, 'type', 'value')
        return (
          <div className="current-time">
            <div className="time-hours">
              <span className="time-hours-value">
                {timeParts.hour}:{timeParts.minute}
              </span>
              <div className="time-hours-seconds-wrap">
                <div className="current-timezone">{timeParts.timeZoneName}</div>
                <div className="time-hours-seconds">:{timeParts.second}</div>
              </div>
            </div>
            {showDate && (
              <p className="main-header-section-label text-uppercase">
                {timeParts.weekday} | {timeParts.month} {timeParts.day},{' '}
                {timeParts.year}
              </p>
            )}
          </div>
        )
      }}
    </FormattedTimeParts>
  )
}

export default CurrentTime
