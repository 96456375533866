import classNames from 'classnames'

const BlockHeader = ({ className, children, onClick }) => {
  const css = classNames(className, 'block-header')
  return (
    <div className={css} onClick={onClick}>
      {children}
    </div>
  )
}

export default BlockHeader
