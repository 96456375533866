import _ from 'lodash'
import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'

import MainHeader from './MainHeader'
import { ColumnsEnum } from '../app/constants'
import LoadingIndicator from '../components/LoadingIndicators'
import NewColumn from '../components/NewColumn'
import ScrollArea from '../components/ScrollArea'
import CardsMinimap from '../components/cards-column/CardsMinimap'
import JobCardsColumnContainer from '../components/cards-column/JobCardsColumnContainer'
import { MultiSelectCardPreview } from '../components/job-cards/MultiSelectCardPreview'
import { useActiveCardsList } from '../hooks/graphQl/useActiveCardsList'
import useStacksList from '../hooks/graphQl/useStacksQuery'
import { useCardsHighlight } from '../hooks/useCardsHighlight'
import { useCurrentStation } from '../hooks/useCurrentStation'
import { useMonitorContext } from '../hooks/useMonitorContext'
import { useStacks } from '../hooks/useStacks'
import { useSelectedTheme } from '../hooks/useUserSettings'
import { emptyArray } from '../utils/perf-utils'
import {
  filterAdditionalDockStacks,
  findDecideStack,
  findDockStack,
  findNewJobsStack,
  groupCardsByStack,
  isStackScrollable,
  sortStacksByOrder,
} from '../utils/stacks-utils'
import { themedClass } from '../utils/style-utils'

const nonEditableColumns = _.values(ColumnsEnum)

const MonitorPage = ({ openPopupCard, isLoading, hasError }) => {
  const [theme] = useSelectedTheme()
  const { formatMessage: f } = useIntl()
  const { stationId } = useCurrentStation()
  const customColumnsRef = useRef(null)
  const isCtrlDown = useRef(false)
  const [highlightInfo, highlightSubjobs, highlightLegs] = useCardsHighlight()

  const {
    currentScroll,
    updateScroll,
    newColumnOpened,
    setNewColumnOpened,
    selectSubJob,
    selectedParent,
    selectedCards,
    selectActions,
  } = useMonitorContext()

  const { reorderStack } = useStacks()
  const { stacksData: listStacks } = useStacksList()
  const { cardsData: cards } = useActiveCardsList()

  const cardsColumns = useMemo(() => groupCardsByStack(cards), [cards])

  useEffect(() => {
    const handleKeyPress = e => {
      if (e.ctrlKey || e.metaKey) {
        isCtrlDown.current = true
      }
    }

    const onResized = () => {
      // safari fix - force scrollbars update on resize
      customColumnsRef.current.update()
    }

    const reset = () => {
      isCtrlDown.current = false
    }

    // if key was pressed before window was active
    window.focus()
    window.addEventListener('keydown', handleKeyPress)
    window.addEventListener('keyup', reset)
    window.addEventListener('resize', onResized)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
      window.removeEventListener('keyup', reset)
      window.removeEventListener('resize', onResized)
    }
  }, [])

  useEffect(() => {
    if (newColumnOpened) {
      customColumnsRef.current.scrollToRight()
    }
  }, [newColumnOpened])

  const scrollableStacks = sortStacksByOrder(
    listStacks.filter(isStackScrollable)
  ).map(s => ({
    stack: s,
    editable: !_.includes(nonEditableColumns, s.name),
  }))

  const newJobsStack = findNewJobsStack(listStacks) || {}
  const decideStack = findDecideStack(listStacks) || {}
  const onDockStack = findDockStack(listStacks) || {}

  const reorderColumn = useCallback(
    (dropped, order) => {
      reorderStack(dropped.id, order, stationId)
    },
    [reorderStack, stationId]
  )

  const selectSingleCard = useCallback(
    card => {
      selectActions.selectExclusively([card])
    },
    [selectActions]
  )

  const onCardClicked = useCallback(
    (card, event, parent) => {
      if (parent) {
        selectSubJob([card], parent)
        if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
          openPopupCard(card)
        }
        return
      }

      if (event.ctrlKey || event.metaKey) {
        selectActions.toggleItem(card)
      } else if (event.shiftKey) {
        selectActions.selectUntil(card)
      }

      if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
        selectSingleCard(card)
        openPopupCard(card)
      }
    },
    [selectActions, selectSingleCard, selectSubJob, openPopupCard]
  )

  const onCardDragStart = useCallback(
    c => {
      if (isCtrlDown.current) {
        selectActions.addToSelection([c])
      } else {
        selectActions.selectIfUnselected(c)
      }
    },
    [selectActions]
  )

  const splitCardsGroup = useCallback(
    cards => {
      selectActions.selectExclusively(cards)
    },
    [selectActions]
  )

  useEffect(() => {
    // call scrollLeft only if scroll was NOT set by using scrollbar (otherwise race conditions cause shaking)
    if (currentScroll.needManualUpdate) {
      customColumnsRef.current.scrollLeft(currentScroll.scrollLeft)
    }
  }, [currentScroll.scrollLeft, currentScroll.needManualUpdate])

  const cardsSelection = useMemo(
    () => ({ selectedCards, selectedParent }),
    [selectedCards, selectedParent]
  )
  const commonColumnsProps = {
    cardClicked: onCardClicked,
    cardsSelection: cardsSelection,
    highlightInfo: highlightInfo,
    onSubJobsHighlight: highlightSubjobs,
    onLegsHighlight: highlightLegs,
    onSplitCardsGroup: splitCardsGroup,
    onCardDragStart: onCardDragStart,
    DragPreview: MultiSelectCardPreview,
    onGroupCreated: selectSingleCard,
  }

  const staticColumnCss = themedClass(
    'monitor-columns-static',
    theme,
    'monitor-columns'
  )

  return (
    <>
      <MainHeader testId="monitor-header" onCardOpen={openPopupCard} />
      <LoadingIndicator
        className="main-content"
        loading={isLoading}
        error={hasError && 'errors.loading-fail'}
      >
        <div className={staticColumnCss}>
          <JobCardsColumnContainer
            {...commonColumnsProps}
            topLabel={f({ id: 'card-columns.new-job' })}
            cards={cardsColumns}
            stack={newJobsStack}
            topComponent={<CardsMinimap cardColumns={cardsColumns} />}
            addMinimap
          />
          <JobCardsColumnContainer
            {...commonColumnsProps}
            topLabel={f({ id: 'card-columns.decide' })}
            cards={cardsColumns}
            stack={decideStack}
          />
        </div>

        <ScrollArea
          className="monitor-columns monitor-columns-reorderable"
          ref={customColumnsRef}
          onUpdate={updateScroll}
          scrollOnEdge
        >
          {scrollableStacks.map(({ stack: s, editable }) => {
            let additionalStacks = emptyArray
            if (s.id === onDockStack.id) {
              additionalStacks = filterAdditionalDockStacks(listStacks)
            }

            return (
              <JobCardsColumnContainer
                {...commonColumnsProps}
                reorderable
                onReorder={reorderColumn}
                key={s.id}
                topLabel={s.name}
                stack={s}
                cards={cardsColumns}
                editable={editable}
                additionalStacks={additionalStacks}
              />
            )
          })}
          <NewColumn onNewColumnToggled={setNewColumnOpened} />
        </ScrollArea>
      </LoadingIndicator>
    </>
  )
}

export default memo(MonitorPage, _.isEqual)
