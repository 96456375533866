import { Component } from 'react'

import { ErrorAlert } from './Alerts'

function RenderError() {
  return <ErrorAlert errorMessage="Error occured. Try refreshing page." />
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: undefined }
  }
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error('React error occured', {
      errorInfo,
      message: error.message,
      stack: error.stack,
    })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    return hasError ? (
      <>
        <div className="header" />
        <div className="main-body">
          <RenderError />
        </div>
        <div className="footer" />
      </>
    ) : (
      children
    )
  }
}
