import classNames from 'classnames'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { AssignedDriverFormatted } from './AssignedDriverFormatted'
import {
  canBeDeclined,
  needAcknowledgeInstructions,
} from '../../utils/card-utils'
import { ContentPanel } from '../ContentPanel'
import { ExpandIcon, UserSlash } from '../Icons'
import { HeaderSizes, JobDetailHeader } from '../ModalsTypography'
import DeclineCardButton from '../action-buttons/DeclineCardButton'
import SpecialInstructions from '../card-popup/common/SpecialInstructions'

const AssignedDriverBox = ({ card, className, theme }) => {
  const [showInstructions, setShowInstruction] = useState(false)

  const topCss = classNames(
    className,
    'driver-info',
    'driver-info-panel',
    'text-important'
  )

  const instructionsCellCss = classNames('special-instructions-floating', {
    'hidden-instructions': !showInstructions,
  })

  const panelCss = classNames('assigned-driver-content-panel', {
    expanded: showInstructions,
  })

  const needAcknowledge = needAcknowledgeInstructions(card?.specialInstructions)

  return (
    <>
      <ContentPanel className={panelCss} darkShadow theme={theme}>
        <div className={topCss}>
          <JobDetailHeader size={HeaderSizes.Medium} className="text-uppercase">
            <FormattedMessage id="card-popup.driver-assign" />
          </JobDetailHeader>
          {card?.driver ? (
            <AssignedDriverFormatted driver={card?.driver} />
          ) : (
            <UserSlash />
          )}
        </div>

        {canBeDeclined(card) && <span className="separator-pipe" />}
        <DeclineCardButton card={card} className="decline-btn-small" />

        <div
          className="clickable-text collapsible-special-instructions"
          onClick={() => setShowInstruction(val => !val)}
        >
          <ExpandIcon expanded={showInstructions} />
          <span className="text-uppercase" data-cy="special-instructions">
            <FormattedMessage id="card.special-instructions" />
          </span>
        </div>
      </ContentPanel>

      <ContentPanel
        className={instructionsCellCss}
        danger={needAcknowledge}
        theme={theme}
      >
        <SpecialInstructions card={card} theme={theme} />
      </ContentPanel>
    </>
  )
}

export default AssignedDriverBox
