import _ from 'lodash'
import { useCallback, useEffect, useMemo, useReducer } from 'react'

const SELECT_ITEMS = 'SELECT_ITEMS'
const TOGGLE_ITEM = 'TOGGLE_ITEM'
const SELECT_UNTIL = 'SELECT_UNTIL'
const DESELECT_ITEMS = 'DESELECT_ITEMS'
const DESELECT_ALL = 'DESELECT_ALL'
const UPDATE_SELECTION = 'UPDATE_SELECTION'
const SELECT_EXCLUSIVELY = 'SELECT_EXCLUSIVELY'
const SELECT_IF_UNSELECTED = 'SELECT_IF_UNSELECTED'

const initialState = {
  selectedItems: [],
  lastShiftSelected: null,
  lastSelected: null,
}

function getItemsSplice(items, from, to) {
  const currentIndex = _.findIndex(items, x => x.id === to.id)
  const lastIndex = Math.max(
    0,
    from ? _.findIndex(items, x => x.id === from.id) : 0
  )
  return _.slice(
    items,
    Math.min(lastIndex, currentIndex),
    Math.max(lastIndex, currentIndex) + 1
  )
}

function checkSameCategory(item1, item2, compareFunc) {
  if (!item1 || !compareFunc) return item1
  return compareFunc(item1, item2) ? item1 : null
}

function createState(newState, clickedItem, groupFunc, singleCategory) {
  if (!singleCategory || !clickedItem) return newState

  return {
    ...newState,
    selectedItems: _.filter(newState.selectedItems, x =>
      groupFunc(x, clickedItem)
    ),
  }
}

/**
 *
 * @param {*} selectableItems array of all selectable items
 * @param {*} groupingFunc optional function used to decide items group (used for example in selecting using SHIFT key to select only items from same group)
 */
function useMultipleSelection(selectableItems, groupingFunc, singleCategory) {
  const [state, dispatch] = useReducer((state, action) => {
    let nextState
    let clickedItem = _.isArray(action.payload)
      ? _.head(action.payload)
      : action.payload

    switch (action.type) {
      case SELECT_UNTIL: {
        const item = action.payload

        const lastShift = checkSameCategory(
          state.lastShiftSelected,
          item,
          groupingFunc
        )

        const lastSelected = checkSameCategory(
          state.lastSelected,
          item,
          groupingFunc
        )

        let selected = state.selectedItems
        if (lastShift) {
          // remove all previously selected using SHIFT
          selected = _.difference(
            state.selectedItems,
            getItemsSplice(selectableItems, lastShift, item)
          )
        }

        let selectedBetween = getItemsSplice(
          selectableItems,
          lastSelected,
          item
        )
        if (groupingFunc) {
          selectedBetween = _.filter(selectedBetween, c =>
            groupingFunc(c, item)
          )
        }

        nextState = {
          lastSelected,
          lastShiftSelected: item,
          selectedItems: _.unionBy(selected, selectedBetween, 'id'),
        }
        break
      }
      case TOGGLE_ITEM: {
        nextState = {
          lastSelected: action.payload,
          lastShiftSelected: null,
          selectedItems: _.xorBy(state.selectedItems, [action.payload], 'id'),
        }
        break
      }
      case DESELECT_ALL: {
        return {
          lastSelected: action.payload,
          lastShiftSelected: null,
          selectedItems: [],
        }
      }
      case DESELECT_ITEMS: {
        clickedItem = null
        nextState = {
          lastSelected: null,
          lastShiftSelected: null,
          selectedItems: _.differenceBy(
            state.selectedItems,
            action.payload,
            'id'
          ),
        }
        break
      }
      case SELECT_ITEMS: {
        nextState = {
          lastSelected: _.head(action.payload),
          lastShiftSelected: null,
          selectedItems: _.union(action.payload, state.selectedItems),
        }
        break
      }
      case SELECT_EXCLUSIVELY: {
        nextState = {
          lastSelected: _.head(action.payload),
          lastShiftSelected: null,
          selectedItems: action.payload,
        }
        break
      }
      case SELECT_IF_UNSELECTED: {
        if (_.some(state.selectedItems, i => i.id === action.payload.id)) {
          nextState = {
            ...state,
            lastSelected: action.payload,
          }
        } else {
          nextState = {
            lastSelected: action.payload,
            lastShiftSelected: null,
            selectedItems: [action.payload],
          }
        }
        break
      }
      case UPDATE_SELECTION: {
        clickedItem = state.lastSelected
          ? _.find(action.payload, item => item.id === state.lastSelected.id)
          : null
        nextState = {
          ...state,
          selectedItems: _.intersectionBy(
            action.payload,
            state.selectedItems,
            'id'
          ),
        }
        break
      }
      default:
        throw new Error()
    }

    return createState(nextState, clickedItem, groupingFunc, singleCategory)
  }, initialState)

  useEffect(() => {
    dispatch({ type: UPDATE_SELECTION, payload: selectableItems })
  }, [selectableItems])

  const toggleItem = useCallback(item => {
    dispatch({ type: TOGGLE_ITEM, payload: item })
  }, [])

  const selectUntil = useCallback(item => {
    dispatch({ type: SELECT_UNTIL, payload: item })
  }, [])

  const deselectItems = useCallback(item => {
    dispatch({ type: DESELECT_ITEMS, payload: item })
  }, [])

  const deselectAll = useCallback(() => {
    dispatch({ type: DESELECT_ALL })
  }, [])

  const addToSelection = useCallback(items => {
    dispatch({ type: SELECT_ITEMS, payload: items })
  }, [])

  const selectExclusively = useCallback(items => {
    dispatch({ type: SELECT_EXCLUSIVELY, payload: items })
  }, [])

  const selectIfUnselected = useCallback(item => {
    dispatch({ type: SELECT_IF_UNSELECTED, payload: item })
  }, [])

  const actions = useMemo(
    () => ({
      toggleItem,
      selectUntil,
      addToSelection,
      deselectItems,
      deselectAll,
      selectExclusively,
      selectIfUnselected,
    }),
    [
      addToSelection,
      deselectAll,
      deselectItems,
      selectExclusively,
      selectUntil,
      toggleItem,
      selectIfUnselected,
    ]
  )
  const result = useMemo(
    () => ({
      selectedItems: state.selectedItems,
      actions: actions,
    }),
    [state.selectedItems, actions]
  )

  return result
}

export default useMultipleSelection
