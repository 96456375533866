import { Checkbox, Collapse } from 'antd'
import _ from 'lodash'
import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'

import { DriverCardsList } from './DriverCardsList'
import { DriverStatusesEnum } from '../../app/constants'
import { DriverTypesEnum } from '../../app/enums/DriverType'
import { useDriverAssignContext } from '../../hooks/useDriversAssignContext'
import { createToggleFunc } from '../../utils/array-utils'
import { themedClass } from '../../utils/style-utils'
import ColumnDropdown from '../ColumnDropdown'
import { DriversContext } from '../DriverAssigmentModule'
import { ExpandIcon } from '../Icons'

const { Panel } = Collapse

export const DriverStatusFilter = {
  EMPTY: { id: 'EMPTY', label: 'driver-status.empty' },
  NON_EMPTY: { id: 'NON_EMPTY', label: 'driver-status.non_empty' },
}

export const DriverEmploymentFilter = {
  COMPANY: { id: DriverTypesEnum.EMPLOYEE, label: 'driver-employment.company' },
  CONTRACTOR: {
    id: DriverTypesEnum.INDEPENDENT_CONTRACTOR,
    label: 'driver-employment.contractor',
  },
  LSP: {
    id: DriverTypesEnum.LOGISTIC_SERVICE_PROVIDER,
    label: 'driver-employment.lsp',
  },
}

export const filterGroupKey = 'FILTER'
export const statusGroupKey = 'STATUS'
export const employmentGroupKey = 'DRIVER'

const createMenuItems = vehicleTypes => [
  {
    id: filterGroupKey,
    name: 'vehicle-type.group-name',
    items: vehicleTypes,
  },
  {
    id: statusGroupKey,
    name: 'driver-status.group-name',
    items: [DriverStatusFilter.EMPTY, DriverStatusFilter.NON_EMPTY],
  },
  {
    id: employmentGroupKey,
    name: 'driver-employment.group-name',
    items: [
      DriverEmploymentFilter.COMPANY,
      DriverEmploymentFilter.CONTRACTOR,
      DriverEmploymentFilter.LSP,
    ],
  },
]

export const DriversColumnPanel = ({
  drivers,
  panelKey,
  vehicleTypes,
  onToggle,
  onSelectAllChanged,
  expanded,
  theme,
  ...rest
}) => {
  const { checkedDrivers } = useContext(DriversContext)

  const { estimatedArrivals } = useDriverAssignContext()
  const { formatMessage: f } = useIntl()
  const [selectedVehicleTypes, setSelectedVehicleTypes] = useState(
    vehicleTypes.map(v => v.id)
  )

  const [selectedVehicleStatuses, setSelectedVehicleStatuses] = useState([
    DriverStatusFilter.EMPTY.id,
    DriverStatusFilter.NON_EMPTY.id,
  ])

  const [selectedDriverEmployments, setSelectedDriverEmployments] = useState([
    DriverEmploymentFilter.COMPANY.id,
    DriverEmploymentFilter.CONTRACTOR.id,
    DriverEmploymentFilter.LSP.id,
  ])

  const toggleVehicleType = createToggleFunc(
    selectedVehicleTypes,
    setSelectedVehicleTypes
  )

  const toggleVehicleStatus = createToggleFunc(
    selectedVehicleStatuses,
    setSelectedVehicleStatuses
  )

  const toggleEmployment = createToggleFunc(
    selectedDriverEmployments,
    setSelectedDriverEmployments
  )

  const menuItems = createMenuItems(vehicleTypes)

  const hasSelectedStatus = d =>
    _.includes(
      selectedVehicleStatuses,
      d.status === DriverStatusesEnum.NONE
        ? DriverStatusFilter.EMPTY.id
        : DriverStatusFilter.NON_EMPTY.id
    )

  const hasSelectedEmployment = d =>
    _.includes(selectedDriverEmployments, d.driverType)

  const filteredDrivers = _.filter(
    drivers,
    d =>
      hasSelectedStatus(d) &&
      hasSelectedEmployment(d) &&
      _.includes(selectedVehicleTypes, d.vehicle.type)
  )

  const onMenuItemClicked = (item, group) => {
    if (group === filterGroupKey) {
      toggleVehicleType(item)
    }
    if (group === statusGroupKey) {
      toggleVehicleStatus(item)
    }
    if (group === employmentGroupKey) {
      toggleEmployment(item)
    }
  }

  const isChecked = id => _.some(checkedDrivers, d => d.id === id)

  const allSelected = _.every(filteredDrivers, x => isChecked(x.id))

  const onSelectAllToggle = e => {
    onSelectAllChanged(e, filteredDrivers)
  }

  const onPanelToggle = useCallback(() => {
    onToggle(panelKey)
  }, [panelKey, onToggle])

  return (
    <Panel
      {...rest}
      showArrow={false}
      header={
        <>
          <div className="column-accordion" onClick={onPanelToggle}>
            <ExpandIcon expanded={expanded} />
            {vehicleTypes.map(v => f({ id: v.label })).join(' / ')}
          </div>

          <Checkbox
            onChange={onSelectAllToggle}
            checked={allSelected}
            className={themedClass('column-checkbox', theme)}
          />

          <div className="cards-column-menu">
            <ColumnDropdown
              multiple
              theme={theme}
              items={menuItems}
              onItemClick={onMenuItemClicked}
              selectedValues={{
                [filterGroupKey]: selectedVehicleTypes,
                [statusGroupKey]: selectedVehicleStatuses,
                [employmentGroupKey]: selectedDriverEmployments,
              }}
              placement="bottomLeft"
            />
          </div>
        </>
      }
    >
      <DriverCardsList
        drivers={filteredDrivers}
        estimatedArrivals={estimatedArrivals}
        theme={theme}
      />
    </Panel>
  )
}
