import moment from 'moment-timezone'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { fromServerDate } from '../utils/date-utils'

export function useLocalDateTime() {
  const { timeZone } = useIntl()

  const getCurrentTime = useCallback(() => moment().tz(timeZone), [timeZone])

  const parseStoredValue = useCallback(
    val => moment(val).tz(timeZone),
    [timeZone]
  )

  const fromServerValue = useCallback(
    val => {
      if (!val) return null
      return fromServerDate(val).tz(timeZone)
    },
    [timeZone]
  )

  return {
    getCurrentTime,
    parseStoredValue,
    fromServerValue,
    currentTimeZone: timeZone,
  }
}
