import { useCallback, useState } from 'react'

import { extractTopParent } from '../utils/card-utils'

export const useCardsHighlight = () => {
  const [highlighting, setHighlighting] = useState({})

  const onSubJobsHighlight = useCallback(
    c => {
      const newHighlight = extractTopParent(c)
      setHighlighting({
        parentId:
          newHighlight.id === highlighting.parentId ? null : newHighlight.id,
      })
    },
    [highlighting.parentId]
  )

  const onLegHighlight = useCallback(
    c => {
      setHighlighting({
        jobNumber: c.jobNumber === highlighting.jobNumber ? null : c.jobNumber,
      })
    },
    [highlighting.jobNumber]
  )

  return [highlighting, onSubJobsHighlight, onLegHighlight]
}
