import { Collapse } from 'antd'
import _ from 'lodash'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import DispatcherNotesList, {
  CTNote,
  DispatcherNote,
  DispatcherNoteEditor,
} from './DispatcherNotesList'
import { SidePanelSectionHeader } from './SidePanelSectionHeader'
import { NoteTypesEnum } from '../../../app/enums/NoteTypesEnum'
import useJobs from '../../../hooks/graphQl/useJobs'
import { useCards } from '../../../hooks/useCards'
import { themedClass } from '../../../utils/style-utils'
import { ExpandIcon, PlusIcon } from '../../Icons'
import ScrollArea from '../../ScrollArea'

const { Panel } = Collapse

const MAX_NOTE_LENGTH = 280
const DESK_NOTES_PANEL = 1
const CT_NOTES_PANEL = 2

const NotesPanelHeader = ({ messageId, notesCount, onAddClick, dataCy }) => (
  <div className="column-accordion space-between" data-cy={dataCy}>
    <span>
      <FormattedMessage id={messageId} /> ({notesCount})
    </span>
    <PlusIcon onClick={onAddClick} />
  </div>
)

const DispatcherNotesCollapse = ({ card, job, theme }) => {
  const { addDispatcherNote, removeDispatcherNote } = useCards()
  const { addCTNote } = useJobs()
  const [addingDeskNote, setAddingDeskNote] = useState(false)
  const [addingCTNote, setAddingCTNote] = useState(false)
  const [openedKeys, setOpenedKeys] = useState([
    DESK_NOTES_PANEL,
    CT_NOTES_PANEL,
  ])

  const startAddingNote = e => {
    e.stopPropagation()
    e.preventDefault()
    setAddingDeskNote(true)
    setOpenedKeys(currentState => _.uniq([...currentState, DESK_NOTES_PANEL]))
  }

  const startAddingCTNote = e => {
    e.stopPropagation()
    e.preventDefault()
    setAddingCTNote(true)
    setOpenedKeys(currentState => _.uniq([...currentState, CT_NOTES_PANEL]))
  }

  const closeAddingDeskNote = () => {
    setAddingDeskNote(false)
  }

  const closeAddingCTNote = () => {
    setAddingCTNote(false)
  }

  const removeNote = id => {
    removeDispatcherNote(card, id)
  }

  const addDeskNote = value => {
    if (value) {
      setAddingDeskNote(false)
      addDispatcherNote(card, _.truncate(value, { length: MAX_NOTE_LENGTH }))
    }
  }

  const onCTNoteAdded = value => {
    if (value) {
      setAddingCTNote(false)
      addCTNote(job, value)
    }
  }

  return (
    <>
      <SidePanelSectionHeader
        messageId="card-popup.dispatcher-notes-heading"
        theme={theme}
      />
      <ScrollArea
        className="notes-scrollarea"
        wrapClassName="notes-scrollarea-wrapper"
      >
        <Collapse
          className={themedClass('notes-collapse', theme)}
          activeKey={openedKeys}
          expandIcon={({ isActive }) => <ExpandIcon expanded={isActive} />}
          onChange={setOpenedKeys}
        >
          <Panel
            key={DESK_NOTES_PANEL}
            header={
              <NotesPanelHeader
                messageId="card-popup.desk-notes"
                onAddClick={startAddingNote}
                notesCount={_.size(card?.dispatcherNotes)}
                dataCy="desk-notes"
              />
            }
          >
            {addingDeskNote && (
              <DispatcherNoteEditor
                onClosed={closeAddingDeskNote}
                onSave={addDeskNote}
                maxLength={MAX_NOTE_LENGTH}
                type={NoteTypesEnum.DESK}
                theme={theme}
              />
            )}
            <DispatcherNotesList
              cardData={card}
              notes={card?.dispatcherNotes}
              onRemoved={removeNote}
              NoteComponent={DispatcherNote}
              theme={theme}
            />
          </Panel>

          <Panel
            key={CT_NOTES_PANEL}
            header={
              <NotesPanelHeader
                messageId="card-popup.ct-notes"
                onAddClick={startAddingCTNote}
                notesCount={_.size(job?.ctNotes)}
                dataCy="ct-notes"
              />
            }
          >
            {addingCTNote && (
              <DispatcherNoteEditor
                onClosed={closeAddingCTNote}
                onSave={onCTNoteAdded}
                maxLength={MAX_NOTE_LENGTH}
                type={NoteTypesEnum.CT}
                theme={theme}
              />
            )}
            <DispatcherNotesList
              cardData={job}
              NoteComponent={CTNote}
              notes={job?.ctNotes}
              theme={theme}
            />
          </Panel>
        </Collapse>
      </ScrollArea>
    </>
  )
}

export default DispatcherNotesCollapse
