import { Modal } from 'antd'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import FlowControls from './FlowControls'
import CardHistory from './action-log/CardHistory'
import { CardPopupState } from '../../app/enums/CardPopupState'
import { useActiveStation } from '../../hooks/useActiveStation'
import { useBackgroundBlur } from '../../hooks/useBackgroundBlur'
import { useCardContext } from '../../hooks/useCardPopupContext'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { isSubJobCard } from '../../utils/card-utils'
import { themedClass } from '../../utils/style-utils'
import LoadingIndicator from '../LoadingIndicators'
import StationAcknowledge from '../modals/StationAcknowledge'

const pagesWithoutHistory = [
  CardPopupState.CreateReIce,
  CardPopupState.CreatePurchaseItem,
  CardPopupState.ReIce,
  CardPopupState.PurchaseItem,
  CardPopupState.ReIceEdit,
  CardPopupState.PurchaseItemEdit,
  CardPopupState.ReIceDriver,
  CardPopupState.PurchaseDriver,
]

export const PopupBase = ({
  onClose,
  children,
  wrap,
  modalContainerRef,
  loading,
  error,
}) => {
  const { opened, closePopup, executeCallbacks, currentCard, currentPage } =
    useCardContext()
  useBackgroundBlur(opened)
  const [theme] = useSelectedTheme()
  const [showStationInfo, setShowStationInfo] = useState(false)
  const { changeStation, currentStation } = useActiveStation()

  const onClosed = () => {
    executeCallbacks()
    onClose && onClose()
  }

  const closeWindow = () => {
    closePopup()
  }

  useEffect(() => {
    if (!loading && currentCard.station.id !== currentStation?.id) {
      changeStation(currentCard.station.id)
      setShowStationInfo(true)
    }
  }, [currentCard, currentStation, changeStation, loading])

  const showHistory = !_.some(
    pagesWithoutHistory,
    page => page.Page === currentPage
  )

  return (
    <Modal
      theme={theme}
      className={themedClass('card-modal', theme, 'closeable-modal')}
      open={opened}
      width={'auto'}
      footer={null}
      centered={true}
      afterClose={onClosed}
      onCancel={closeWindow}
      closeIcon={<i className="fas fa-times-circle" />}
      getContainer={wrap}
      maskStyle={{ zIndex: -1, position: 'fixed' }}
      maskProps={{
        className: themedClass('parallax-mask', theme, 'ant-modal-mask'),
      }}
      data-testid="card-details-modal"
    >
      <div className="inner-modal-wrap" ref={modalContainerRef} />

      {showStationInfo && (
        <StationAcknowledge
          station={currentStation}
          onOk={setShowStationInfo}
        />
      )}
      <LoadingIndicator
        className="modal-spinner blurable-content"
        loading={loading}
        error={error && 'errors.loading-fail'}
      >
        {showHistory && <CardHistory card={currentCard} />}
        {!isSubJobCard(currentCard) && <FlowControls />}
        {children}
      </LoadingIndicator>
    </Modal>
  )
}
