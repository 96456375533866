import { FormattedMessage } from 'react-intl'

import { formatNullableString } from '../../utils/string-utils'

const MetricWeightUnitsEnum = {
  Kilogram: 'units.kg',
}

export const SelectedWeightUnit = () => (
  // todo: read unit selected by user on top of page
  <FormattedMessage id={MetricWeightUnitsEnum.Kilogram} />
)

export const Weight = ({ weight }) => {
  if (!weight && weight !== 0) return formatNullableString(weight)

  return (
    <>
      {weight} <SelectedWeightUnit />
    </>
  )
}
