import { FormattedMessage } from 'react-intl'

import { useSelectedTheme } from '../../hooks/useUserSettings'
import { HourMinuteFormat, ShortMonthYearFormat } from '../DateTimeFormats'
import { CaretDown } from '../Icons'
import { CalendarPicker } from '../calendar-picker/CalendarPicker'
import TimePickerSlider from '../calendar-picker/TimePickerSlider'
import MainHeaderPopover from '../main-header/MainHeaderPopover'

const DateTimePicker = ({ value, onChange, placeholder }) => {
  const [theme] = useSelectedTheme()

  const onClearClick = e => {
    onChange(null)
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <MainHeaderPopover
      trigger="click"
      placement="bottomLeft"
      overlayClassName="main-header-datepicker"
      content={
        <div className="date-picker-popover">
          <CalendarPicker
            onValueChanged={onChange}
            value={value}
            theme={theme}
            className="calendar-picker-popover"
          />
          <div className="time-selector">
            <TimePickerSlider
              value={value}
              theme={theme}
              onChanged={onChange}
            />
          </div>
        </div>
      }
    >
      <span className="date-picker-popover-value">
        {value ? (
          <span className="text-uppercase">
            <ShortMonthYearFormat date={value} />{' '}
            <HourMinuteFormat date={value} />
          </span>
        ) : (
          <FormattedMessage id={placeholder} />
        )}
        <CaretDown className="date-picker-suffix" />
        <i
          className="fas fa-times-circle datetime-clear-icon"
          onClick={onClearClick}
        />
      </span>
    </MainHeaderPopover>
  )
}

export default DateTimePicker
