import _ from 'lodash'

export const missingDataPlaceholder = 'TBD'

export const padInteger = (num, digits = 2) =>
  num.toString().padStart(digits, '0')

export const padDecimal = (num, digits = 2) => num.toFixed(digits)

export const removeChar = (str, charToRemove) =>
  _.replace(_.trim(str), charToRemove, '')

export const MISSING_VALUE = '-'
export const formatNullableString = str => str || MISSING_VALUE

export function extractFirstLine(s) {
  if (!s) return MISSING_VALUE
  const lines = s.split('\n')
  if (_.some(lines)) return _.head(lines)

  return MISSING_VALUE
}

export function areEqual(str1, str2) {
  return str1.localeCompare(str2, undefined, { sensitivity: 'base' }) === 0
}
