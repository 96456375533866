import { useApolloClient } from '@apollo/client'
import { useEffect } from 'react'

export function useWatchedQuery(query, variables, onChanged) {
  const client = useApolloClient()

  useEffect(() => {
    const subscription = client
      .watchQuery({
        query: query,
        variables: variables,
        fetchPolicy: 'cache-only',
      })
      .subscribe({
        next: () => {
          onChanged()
        },
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [client, query, variables, onChanged])
}
