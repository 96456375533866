import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { themedClass } from '../../../utils/style-utils'
import { BorderOutsetSeparator } from '../../BorderOutset'

export const SidePanelSectionHeader = ({
  messageId,
  rightIcon,
  bottomBorder,
  theme,
}) => {
  const css = classNames(themedClass('notes-section-heading', theme), {
    'notes-section-heading-bottom': bottomBorder,
  })
  return (
    <div className={css}>
      <BorderOutsetSeparator theme={theme} />
      <p className="notes-section-heading-text text-uppercase">
        <FormattedMessage id={messageId} />
        {rightIcon}
      </p>
    </div>
  )
}
