import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { TextDangerous, TextImportant } from '../Typography'

const DangerousGoodsValue = ({ value }) => {
  if (!value) {
    return (
      <TextImportant className="text-uppercase">
        <FormattedMessage id={'bool.false'} />
      </TextImportant>
    )
  }
  return (
    <TextDangerous className="text-uppercase">
      <FormattedMessage id={'bool.true'} />
    </TextDangerous>
  )
}

const DangerousGoodsInfo = ({ card, labelCss, rowClass }) => {
  const unNumberCss = classNames(rowClass, 'text-ellipsed')
  const topRowCss = classNames(rowClass, 'shipment-info-dg-row', {
    'shipment-info-row-borderless': card.isDangerousGoods,
  })
  return (
    <>
      <p className={topRowCss}>
        <span className={labelCss}>
          <FormattedMessage id="card-dispatch.dangerous-goods" />:
        </span>
        <DangerousGoodsValue value={card.isDangerousGoods} />
      </p>
      {card.isDangerousGoods && (
        <p className={unNumberCss} style={{ lineHeight: '20px' }}>
          <span className="un-number">
            <FormattedMessage
              id="card-dispatch.dangerous-goods-un"
              values={{ unNumber: card.unNumber }}
            />
          </span>
          <span className="text-ellipsed">{card.unNumberDescription}</span>
        </p>
      )}
    </>
  )
}

export default DangerousGoodsInfo
