import { themedClass } from '../../utils/style-utils'
import { BadgeSmall } from '../JobBadges'
import PredictedDistance from '../card-popup/common/PredictedDistance'

const WaypointLine = ({ card, theme }) => (
  <div className="waypoint-line-wrapper">
    <i className="far fa-circle waypoint-icon waypoint-icon-top" />
    <i className="fas fa-dot-circle waypoint-icon waypoint-icon-bottom" />
    <div className={themedClass('waypoint-line', theme)}>
      <BadgeSmall inverted className="waypoint-badge">
        <PredictedDistance card={card} />
      </BadgeSmall>
    </div>
  </div>
)

export default WaypointLine
