import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

import { LayoutColumn, LayoutGrid, LayoutRow } from './common/MultiColumnLayout'
import PlaceholderRepeater from './common/PlaceholderRepeater'
import { ValueWithLabel } from './common/ValueWithLabel'
import { fromServerDate } from '../../utils/date-utils'
import { getFlightAirline } from '../../utils/flight-utils'
import { areEqual, formatNullableString } from '../../utils/string-utils'
import { FlightDateTimeShort } from '../DateTimeFormats'
import { HintTooltip } from '../HintTooltip'
import { FlowContentHeaderSmall, JobHeaderLabel } from '../ModalsTypography'
import ScrollArea from '../ScrollArea'
import { TextDangerous } from '../Typography'

const DELAYED_FLIGHT_STATUS = 'delayed'
const MIN_ROWS_COUNT = 2

const displayActualRow = flight =>
  flight.departureActual || flight.arrivalActual

const DateCell = ({ value }) => (
  <p>
    <FlightDateTimeShort date={fromServerDate(value?.iso)} />
  </p>
)

const FlightInfoCell = ({ value, labelId }) => (
  <ValueWithLabel labelId={labelId} className="flight-info-cell">
    {formatNullableString(value)}
  </ValueWithLabel>
)

function FlightStatusAndBill({ flight, theme }) {
  const status = formatNullableString(flight.status)
  return (
    <>
      <div className="flight-status-bill">
        <JobHeaderLabel className="flex-full-width">
          <FormattedMessage id="flights.status" />:{' '}
        </JobHeaderLabel>
        {areEqual(status, DELAYED_FLIGHT_STATUS) ? (
          <TextDangerous className="text-uppercase">{status}</TextDangerous>
        ) : (
          <strong>{status}</strong>
        )}
      </div>

      <div className="flight-status-bill airway-bill text-uppercase">
        <FormattedMessage id="card-popup.airline.awb" />:{' '}
        <span className="text-important">{flight.airwayBill}</span> |{' '}
        <FormattedMessage id="card-popup.airline.type" />:{' '}
        <HintTooltip
          tooltip={<span>{flight.airlineServiceTypeDesc}</span>}
          placement="bottom"
          size="tiny"
          theme={theme}
        >
          <span className="text-important">{flight.airlineServiceType}</span>
        </HintTooltip>
      </div>
    </>
  )
}

const FlightTimeTableColumn = ({
  showActualRow,
  scheduled,
  estimated,
  actual,
  labelId,
  style,
}) => (
  <div style={style}>
    <JobHeaderLabel>
      <FormattedMessage id={labelId} />
    </JobHeaderLabel>
    <DateCell value={scheduled} />
    <DateCell value={estimated} />
    {showActualRow && <DateCell value={actual} />}
  </div>
)

const StatusLabel = ({ labelId }) => (
  <p className="flight-status-label text-uppercase">
    <FormattedMessage id={labelId} />
  </p>
)

const FlightBasicInformation = ({ flight, badgeValue }) => (
  <div className="flight-information-row">
    <div className="flight-badge-cell">
      <div className="flight-order-badge">{badgeValue}</div>
    </div>
    <FlightInfoCell
      value={getFlightAirline(flight)}
      labelId="flights.airline"
    />
    <FlightInfoCell value={flight.flightNumber} labelId="flights.flight" />
    <FlightInfoCell value={flight.origin} labelId="flights.origin" />
    <FlightInfoCell value={flight.destination} labelId="flights.destination" />
  </div>
)

const EmptyRowPlaceholder = () => (
  <LayoutRow className="job-flight-row job-flight-row-empty">
    <LayoutColumn />
    <LayoutColumn bordered />
  </LayoutRow>
)

const FlightsTable = ({ flights, theme }) => (
  <div className="job-detail-section">
    <FlowContentHeaderSmall theme={theme}>
      <FormattedMessage id="flights.itinerary" />
    </FlowContentHeaderSmall>
    <ScrollArea style={{ flexGrow: 0 }} autoHeight autoHeightMax={150}>
      <LayoutGrid>
        {_.map(flights, (flight, index) => {
          const showActualRow = displayActualRow(flight)
          return (
            <LayoutRow className="job-flight-row" key={index}>
              <LayoutColumn className="space-between flight-basic-info">
                <FlightStatusAndBill flight={flight} theme={theme} />
                <FlightBasicInformation
                  flight={flight}
                  badgeValue={index + 1}
                />
              </LayoutColumn>

              <LayoutColumn bordered className="space-between flight-timetable">
                <div>
                  <StatusLabel labelId="flights.scheduled" />
                  <StatusLabel labelId="flights.estimated" />
                  {showActualRow && <StatusLabel labelId="flights.actual" />}
                </div>

                <FlightTimeTableColumn
                  labelId="flights.depart"
                  scheduled={flight.departureScheduled}
                  estimated={flight.departure}
                  actual={flight.departureActual}
                  showActualRow={showActualRow}
                />

                <FlightTimeTableColumn
                  labelId="flights.arrive"
                  scheduled={flight.arrivalScheduled}
                  estimated={flight.arrival}
                  actual={flight.arrivalActual}
                  showActualRow={showActualRow}
                  style={{ paddingRight: 10 }}
                />
              </LayoutColumn>
            </LayoutRow>
          )
        })}
        <PlaceholderRepeater
          totalCount={MIN_ROWS_COUNT}
          existingCount={_.size(flights)}
          ItemComponent={EmptyRowPlaceholder}
        />
      </LayoutGrid>
    </ScrollArea>
  </div>
)

export default FlightsTable
