import classNames from 'classnames'

import { ShortDateFormat } from '../DateTimeFormats'

export const ColumnDividerString = ({ label, className }) => {
  const css = classNames('card-column-divider-value text-uppercase', className)
  return (
    <>
      <p className="card-column-divider" />
      <p className={css}>{label}</p>
    </>
  )
}

export const CardsDateDivider = ({ value }) => (
  <>
    <p className="card-column-divider" />
    <p className="card-column-divider-value text-uppercase">
      <ShortDateFormat date={value} />
    </p>
  </>
)
