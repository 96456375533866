import { gql } from '@apollo/client'
import _ from 'lodash'

import { GET_CARDS, UPDATE_SUBJOB_FRAGMENT } from './schema/Cards'
import { GET_DRIVERS } from './schema/Drivers'
import { GET_STACKS } from './schema/Stacks'

export function updateStacksList(cache, stacks, station) {
  cache.writeQuery({
    query: GET_STACKS,
    variables: { stationId: station },
    data: {
      listStacks: stacks,
    },
  })
}

export function updateStackName(cache, id, name) {
  cache.writeFragment({
    id: `Stack:${id}`,
    fragment: gql`
      fragment stackRename on Stack {
        name
      }
    `,
    data: {
      name: name,
      __typename: 'Stack',
    },
  })
}

export function updateDriversList(cache, drivers, station) {
  cache.writeQuery({
    query: GET_DRIVERS,
    variables: { filter: { station: station } },
    data: {
      listDrivers: drivers.sort((a, b) =>
        a.id.localeCompare(b.id, undefined, { numeric: true })
      ),
    },
  })
}

export function updateCardsList(cache, cards, filter, query = GET_CARDS) {
  cache.writeQuery({
    query: query,
    variables: filter,
    data: {
      listCards: createCardsGroups(cards),
    },
  })
}

export function createCardsGroups(cards) {
  const subjobs = _.compact(_.flatten(_.map(cards, c => c.subjobs)))
  const allCards = _.unionBy(cards, subjobs, 'id')
  const collapsed = _.groupBy(
    _.filter(allCards, ls => ls.groupParent !== ls.id),
    c => c.groupParent
  )
  return _.map(cards, c => ({
    ...c,
    groupChildren: _.unionBy(collapsed[c.id], c.groupChildren, 'id'),
  }))
}

export function updateSingleSubjob(cache, updatedCard) {
  cache.writeFragment({
    id: `Card:${updatedCard.id}`,
    fragment: UPDATE_SUBJOB_FRAGMENT,
    data: updatedCard,
  })
}
