import { config } from '../app/config'
import { LogLevel } from '../app/enums/LogLevel'

const timestamp = () => new Date().toISOString()
const getMethodName = method => (method === 'log' ? 'debug' : method)

function getConsoleCommands() {
  console.log(config)
  if (!config.logLevel) return []
  const allCommands = ['log', 'debug', 'info', 'warn', 'error']
  let commandIndex
  switch (config.logLevel) {
    case LogLevel.INFO:
      commandIndex = 2
      break
    case LogLevel.WARN:
      commandIndex = 3
      break
    case LogLevel.ERROR:
      commandIndex = 4
      break

    default:
      commandIndex = 0
      break
  }

  return allCommands.slice(commandIndex)
}

function sendLogEntry(logEntry) {
  fetch('/analytics/collect-logs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(logEntry),
  })
}

function interceptConsole() {
  console.log('intercepting console')

  const commandsToOverride = getConsoleCommands()
  commandsToOverride.forEach(method => {
    const origMethod = console[method]
    console[method] = (...args) => {
      sendLogEntry({
        ts: timestamp(),
        level: getMethodName(method),
        args: args,
        browser: navigator.userAgent,
        URL: window.location.href,
      })
      origMethod(...args)
    }
  })
}

export default interceptConsole
