import _ from 'lodash'
import { useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import AlarmScreen from './AlarmScreen'
import CardDriverAssign from './CardDriverAssign'
import CardReIce from './CardReIce'
import ChargeReview from './ChargeReview'
import JobDetailScreen from './JobDetailScreen'
import { PopupBase } from './PopupBase'
import PurchaseItemSubjob from './PurchaseItemSubjob'
import CardSidePanel from './common/CardSidePanel'
import { MapStyles } from '../../app/constants'
import { CardPopupState } from '../../app/enums/CardPopupState'
import { useCardContext } from '../../hooks/useCardPopupContext'
import { useDriverAssignContext } from '../../hooks/useDriversAssignContext'
import { ModalContainerContextProvider } from '../../hooks/useModalContainerContext'
import { getUnacknowledgedChanges } from '../../utils/card-utils'
import { CardUpdateNotification } from '../modals/CardUpdateNotification'

export const emptyJob = {
  station: {},
  shipper: { transport: { time: {} } },
  consignee: { transport: { time: {} } },
  shipment: {},
  billing: {},
}

export const SingleCardDetail = ({
  onClose,
  loading,
  error,
  wrap,
  archived,
}) => {
  const elRef = useRef(null)
  const { currentCard, currentJob, closePopup } = useCardContext()
  const { mapStyle } = useDriverAssignContext()

  const unackChanges = getUnacknowledgedChanges(currentCard?.trackedChanges)

  return (
    <ModalContainerContextProvider container={elRef?.current}>
      <PopupBase
        onClose={onClose}
        cardData={currentCard}
        wrap={wrap}
        modalContainerRef={elRef}
        loading={loading}
        error={error}
      >
        {archived && (
          <div className="archived-job-stripe">
            <div className="space-between archived-job-stripe-content">
              <span>
                <FormattedMessage id="archived" />
              </span>
              <span>
                <FormattedMessage id="archived" />
              </span>
            </div>
          </div>
        )}

        {_.some(unackChanges) && (
          <CardUpdateNotification onIgnore={closePopup} card={currentCard} />
        )}

        <div className="card-detail">
          <div className="flow-window">
            <JobDetailScreen card={currentCard} job={currentJob} />
            <AlarmScreen card={currentCard} />

            <CardDriverAssign card={currentCard} job={currentJob} />
            <CardReIce card={currentCard} page={CardPopupState.ReIce.Page} />
            <PurchaseItemSubjob
              card={currentCard}
              page={CardPopupState.PurchaseItem.Page}
            />
            <ChargeReview card={currentCard} />

            {/* subjob screens */}
            <CardDriverAssign
              card={currentCard}
              job={currentJob}
              page={CardPopupState.ReIceDriver.Page}
            />
            <CardReIce
              card={currentCard}
              page={CardPopupState.ReIceEdit.Page}
            />
            <PurchaseItemSubjob
              card={currentCard}
              page={CardPopupState.PurchaseItemEdit.Page}
            />
          </div>

          {mapStyle !== MapStyles.Maximized && (
            <CardSidePanel
              cardData={currentCard}
              job={currentJob}
              innerModalRef={elRef}
              archived={archived}
            />
          )}
        </div>
      </PopupBase>
    </ModalContainerContextProvider>
  )
}
