import { FlowColumn, FlowToolbar } from './FlowLayout'
import { useSelectedTheme } from '../../../hooks/useUserSettings'
import { extractTopParent } from '../../../utils/card-utils'
import { themedClass } from '../../../utils/style-utils'
import { ContentPanel } from '../../ContentPanel'
import JobOverview from '../../job-detail/JobOverview'
import FlowTransition from '../FlowTransition'

const FlowWindow = ({
  children,
  header,
  card,
  page,
  className,
  contentClassName,
  toolbarPanelMessage,
}) => {
  const [theme] = useSelectedTheme()
  const contentCss = themedClass('flow-content', theme, contentClassName)
  return (
    <FlowTransition page={page} className={className}>
      <JobOverview card={extractTopParent(card)} theme={theme} />

      <FlowColumn
        className="flow-specific-column"
        extended={!!toolbarPanelMessage}
      >
        <FlowToolbar toolbarPanelMessage={toolbarPanelMessage} theme={theme}>
          {header}
        </FlowToolbar>
        <ContentPanel className={contentCss} theme={theme}>
          {children}
        </ContentPanel>
      </FlowColumn>
    </FlowTransition>
  )
}

export default FlowWindow
