import { CssValues } from '../../app/constants'
import Transition, { TransitionTypes } from '../Transition'

const CardRemovalTransition = ({ children, className, visible, onExited }) => {
  const onTransitionExited = () => {
    onExited && onExited()
  }

  return (
    <Transition
      opened={visible}
      type={TransitionTypes.ScaleDown}
      duration={CssValues.cardRemovalDuration}
      className={className}
      onExited={onTransitionExited}
    >
      {children}
    </Transition>
  )
}

export default CardRemovalTransition
