import _ from 'lodash'

import PhonePopover from './PhonePopover'
import useDriverCards from '../../hooks/graphQl/useDriverCards'
import { countLoadedCards, isLoaded } from '../../utils/driver-utils'
import { themedClass } from '../../utils/style-utils'
import ScrollArea from '../ScrollArea'
import DraggableJobCard from '../job-cards/DraggableJobCard'

const CARD_HEIGHT = 110
const MAX_HEIGHT = CARD_HEIGHT * 3

export const AssignedCardsMapPopover = ({
  driver,
  theme,
  onMouseOver,
  onMouseLeave,
}) => {
  const { cards, loading } = useDriverCards(driver)
  if (!driver) return null

  if (isLoaded(driver))
    return (
      <div onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        <PhonePopover
          phone={driver.phone}
          theme={theme}
          className="map-card-phone-popover"
        />
        <AssignedCardsList
          loading={loading}
          theme={theme}
          cards={cards}
          addArrow
          driver={driver}
        />
      </div>
    )
  else {
    return (
      <>
        <PhonePopover
          phone={driver.phone}
          theme={theme}
          onHoverStart={onMouseOver}
          onHoverEnd={onMouseLeave}
        >
          <div className={themedClass('ant-popover-arrow', theme)} />
        </PhonePopover>
      </>
    )
  }
}

export const AssignedCardsPopover = ({ driver, theme, popoverRef }) => {
  const { cards, loading } = useDriverCards(driver)
  if (!driver) return null

  const updatePopover = () => {
    if (popoverRef.current) popoverRef.current.forceUpdate()
  }
  return (
    <AssignedCardsList
      loading={loading}
      theme={theme}
      cards={cards}
      driver={driver}
      onCardExpandToggle={updatePopover}
    />
  )
}

const AssignedCardsList = ({
  loading,
  theme,
  cards,
  addArrow,
  driver,
  onCardExpandToggle,
}) => {
  const nonGroupedCards = _.filter(cards, card => !card.groupParent)
  const cardsCount = countLoadedCards(driver)

  if (loading) {
    return (
      <div
        className={themedClass(
          'assigned-cards-list',
          theme,
          'assigned-cards-list-loading'
        )}
        style={{
          height: Math.min(cardsCount * CARD_HEIGHT, MAX_HEIGHT),
        }}
      />
    )
  }

  return (
    <div className={themedClass('assigned-cards-list', theme)}>
      {addArrow && (
        <div
          className={themedClass('ant-popover-arrow', theme, 'card-arrow')}
        />
      )}
      <ScrollArea
        hideHorizontal
        autoHeight
        autoHeightMin={CARD_HEIGHT}
        autoHeightMax={MAX_HEIGHT}
      >
        <div className="cards-list">
          {_.map(nonGroupedCards, (card, index) => (
            <DraggableJobCard
              forceExpand
              data={card}
              theme={theme}
              key={card.id}
              onCardExpandToggle={onCardExpandToggle}
              roundedCorners={
                index === 0 || index === _.size(nonGroupedCards) - 1
              }
            />
          ))}
        </div>
      </ScrollArea>
    </div>
  )
}
