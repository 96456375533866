import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import ChooseAlarmHeader from './common/ChooseAlarmHeader'
import FlowWindow from './common/FlowWindow'
import { ModifyHeader } from './common/ModifyHeader'
import useStacksList from '../../hooks/graphQl/useStacksQuery'
import useDirtyState from '../../hooks/useDirtyState'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { useModalWindow } from '../../hooks/useModalWindow'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import {
  getAlarmDateTime,
  getSubjobNumber,
  hasClosableAlarm,
  isSubJobCard,
} from '../../utils/card-utils'
import {
  getRequiredFieldRules,
  orderStacksForDropdown,
} from '../../utils/common-utils'
import { areDatesEqual } from '../../utils/date-utils'
import { findNewJobsStack, stackToSelectOption } from '../../utils/stacks-utils'
import { AlarmBadge } from '../AlarmBadge'
import LocationsAutocomplete from '../LocationsAutocomplete'
import { FlowContentHeaderSmall } from '../ModalsTypography'
import CardDateTimePicker from '../calendar-picker/CardDateTimePicker'
import ConfirmAlarmModal from '../modals/ConfirmAlarmModal'

function SubjobFormImpl({
  theme,
  onFromSelected,
  fromValue,
  onToSelected,
  toValue,
  readonly,
  form,
  children,
}) {
  const { formatMessage: f } = useIntl()
  const { getFieldDecorator, validateFields, setFieldsValue } = form

  useEffect(() => {
    validateFields()
  }, [validateFields, fromValue, toValue])

  useEffect(() => {
    setFieldsValue({
      from: fromValue,
      to: toValue,
    })
    validateFields()
  }, [fromValue, toValue, setFieldsValue, validateFields])

  return (
    <>
      <div className="space-between">
        <div className="subjob-form-column">
          <FlowContentHeaderSmall theme={theme}>
            <FormattedMessage id="card-popup.from" />
          </FlowContentHeaderSmall>
          <Form.Item>
            {getFieldDecorator('from', {
              rules: getRequiredFieldRules(f),
            })(
              <LocationsAutocomplete
                onSelected={onFromSelected}
                readOnly={readonly}
                dataCy="from-autocomplete"
                theme={theme}
              />
            )}
          </Form.Item>
        </div>

        <div className="subjob-form-column">
          <FlowContentHeaderSmall theme={theme}>
            <FormattedMessage id="card-popup.to" />
          </FlowContentHeaderSmall>

          <Form.Item>
            {getFieldDecorator('to', {
              rules: getRequiredFieldRules(f),
            })(
              <LocationsAutocomplete
                onSelected={onToSelected}
                readOnly={readonly}
                dataCy="to-autocomplete"
                theme={theme}
              />
            )}
          </Form.Item>
        </div>
      </div>

      {children && children({ form })}
    </>
  )
}

const SubJobBase = ({
  card,
  onConfirmClick,
  children,
  header,
  onCancel,
  page,
  subjobLabel,
  confirmDisabled,
  onEditingChanged,
  canCancel,
}) => {
  const { getCurrentTime, fromServerValue } = useLocalDateTime()
  const [theme] = useSelectedTheme()

  const [editing, setEditing] = useState(!isSubJobCard(card) || !card.reminder)

  const [fromLocation, setFromLocation, fromDirty, resetFrom] =
    useDirtyState('')

  const [toLocation, setToLocation, toDirty, resetTo] = useDirtyState('')

  const [selectedDate, setSelectedDate, dateDirty, resetDate] = useDirtyState(
    getCurrentTime().add(1, 'hour').startOf('hour'),
    areDatesEqual
  )
  const [showConfirmModal, openConfirm, closeConfirm] = useModalWindow()
  const {
    stacksData: listStacks,
    loading: loadingStacks,
    error: stacksError,
  } = useStacksList()

  const resetForm = useCallback(() => {
    if (isSubJobCard(card) && card?.reminder) {
      resetFrom(card.from)
      resetTo(card.to)
      resetDate(
        getAlarmDateTime(card.reminder, getCurrentTime(), fromServerValue)
      )
    } else {
      resetFrom('')
      resetTo('')
      resetDate(null)
    }
  }, [card, fromServerValue, getCurrentTime, resetFrom, resetTo, resetDate])

  useEffect(() => {
    if (!editing) resetForm()
  }, [card, editing, resetForm])

  useEffect(() => {
    if (isSubJobCard(card)) setEditing(false)
    else setEditing(true)
  }, [card, resetForm])

  useEffect(() => {
    onEditingChanged && onEditingChanged(editing)
  }, [editing, onEditingChanged])

  const canCloseAlarm = isSubJobCard(card) && hasClosableAlarm(card)

  const onFromSelected = value => {
    setFromLocation(value)
  }

  const onToSelected = value => {
    setToLocation(value)
  }

  const onDateChange = val => {
    setSelectedDate(val)
  }

  const onCancelClick = () => {
    onCancel && onCancel()
    resetForm()
    if (isSubJobCard(card)) setEditing(false)
  }

  const stacksToAssign =
    orderStacksForDropdown(listStacks).map(stackToSelectOption)

  const confirmClicked = (cardId, stackId) => {
    onConfirmClick(cardId, stackId, {
      from: fromLocation?.value,
      to: toLocation?.value,
      date: selectedDate,
    })
    setEditing(false)
  }

  const onModifyClicked = () => {
    setEditing(true)
  }

  const defaultStack = isSubJobCard(card)
    ? card.stack
    : findNewJobsStack(listStacks)

  const isDisabled = confirmDisabled || !fromLocation || !toLocation
  const confirmMsg = isSubJobCard(card) ? 'confirm.update' : 'confirm.create'
  const subjobNumber = isSubJobCard(card) ? getSubjobNumber(card.id) : ''
  const enabledCancel =
    canCancel ||
    fromDirty ||
    toDirty ||
    dateDirty ||
    (editing && isSubJobCard(card))

  return (
    <FlowWindow
      card={card}
      page={page}
      contentClassName="flow-content-subjob"
      toolbarPanelMessage={
        <FormattedMessage id={subjobLabel} values={{ subjobNumber }} />
      }
      header={
        <ModifyHeader
          canCancel={enabledCancel}
          onConfirm={openConfirm}
          onCancel={onCancelClick}
          onModify={onModifyClicked}
          messageId={header}
          secondaryMessageId={!editing ? 'alarm-modal.alarm-confirmed' : null}
          editing={editing}
          confirmMsg={confirmMsg}
          confirmBtnProps={{
            disabled: isDisabled,
          }}
        >
          {!editing && canCloseAlarm && (
            <AlarmBadge value={card.reminder.iso} inline />
          )}
        </ModifyHeader>
      }
    >
      {showConfirmModal && (
        <ConfirmAlarmModal
          visible={showConfirmModal}
          onClosed={closeConfirm}
          dateTime={selectedDate}
          card={card}
          allowedStacks={stacksToAssign}
          onConfirm={confirmClicked}
          loading={loadingStacks}
          error={stacksError}
          defaultStack={defaultStack}
          headerMsg="alarm-settings.confirm-subjob-alarm"
        />
      )}

      <Form>
        <SubjobForm
          theme={theme}
          onFromSelected={onFromSelected}
          fromValue={fromLocation}
          readonly={!editing}
          onToSelected={onToSelected}
          toValue={toLocation}
        >
          {children}
        </SubjobForm>
      </Form>
      <ChooseAlarmHeader card={card} isEditing={editing} theme={theme} />
      <CardDateTimePicker
        value={selectedDate}
        onValueChanged={onDateChange}
        collapsed={!editing}
        theme={theme}
        dirty={dateDirty}
      />
    </FlowWindow>
  )
}

export default SubJobBase

const SubjobForm = Form.create({})(SubjobFormImpl)
