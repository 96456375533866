import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { HourMinuteFormat } from './DateTimeFormats'
import { useLocalDateTime } from '../hooks/useLocalDateTime'

export const AlarmBadge = ({ value, forceFired, inline }) => {
  const { fromServerValue, getCurrentTime } = useLocalDateTime()

  let fired = forceFired
  if (!fired) {
    const alarmValue = fromServerValue(value)
    fired = alarmValue.isSameOrBefore(getCurrentTime(), 'minute')
  }
  const css = classNames('card-status-box', {
    'card-status-box-inline': inline,
  })

  const debugTitle = forceFired ? 'Automatic alarm' : 'Alarm set by user'
  return (
    <div className={css} title={debugTitle}>
      <div className="card-status-type text-uppercase">
        <FormattedMessage id="card.badge-action" />
      </div>
      {fired ? (
        <div className={'text-uppercase card-status card-status-danger'}>
          <FormattedMessage id="card.alarm-now" />
        </div>
      ) : (
        <div className={'text-capitalized card-status card-status-success'}>
          <HourMinuteFormat date={fromServerValue(value)} />
        </div>
      )}
    </div>
  )
}
