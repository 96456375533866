import classNames from 'classnames'

const PanelHandle = ({ children, className, onClick, inverted }) => {
  const controlCss = classNames(
    'modal-handle',
    { 'modal-handle-bottom': inverted },
    { 'modal-handle-top': !inverted },
    className
  )
  return (
    <div className={controlCss} onClick={onClick}>
      {children}
    </div>
  )
}

export default PanelHandle
