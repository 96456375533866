import classNames from 'classnames'
import _ from 'lodash'

import MissingShipmentData from './MissingShipmentData'
import { joinStrings } from '../../utils/array-utils'
import { TextImportant } from '../Typography'

export const LocationInfo = ({
  company,
  rowClass,
  highlightTopRow = true,
  fixedRowsCount,
}) => {
  if (!company) return <MissingShipmentData />

  const topCss = classNames(rowClass, 'text-ellipsed', 'text-capitalized')
  const css = classNames(rowClass, 'text-ellipsed')
  const name = _.toLower(company.name)
  const streetArray = _.compact(company.address?.street)
  return (
    <>
      <p className={topCss} title={company.name}>
        {highlightTopRow ? <TextImportant>{name}</TextImportant> : name}
      </p>
      {company.address && (
        <>
          <p className={css}>{_.head(company.address.street)}</p>
          {_.size(streetArray) > 1 && (
            <p className={css}>{_.join(_.tail(company.address.street), ' ')}</p>
          )}
          <p className={css}>
            {joinStrings(company.address.city, company.address.state)}{' '}
            {company.address.zip}
          </p>
        </>
      )}
      {fixedRowsCount && _.size(streetArray) <= 1 && <p className={css} />}
    </>
  )
}
