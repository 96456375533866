import { useQuery } from '@apollo/client'
import { useEffect } from 'react'

import { GET_CARDS_ARCHIVED } from '../../app/graphQl/schema/Cards'

export const useArchivedCards = ({ filter, skip }) => {
  const { data, loading, error, stopPolling } = useQuery(GET_CARDS_ARCHIVED, {
    partialRefetch: true,
    skip: skip,
    variables: { filter },
    pollInterval: 60000,
  })

  useEffect(() => {
    if (!filter) {
      stopPolling()
    }
  }, [filter, stopPolling])

  const cards = data?.filterArchivedJobs.map(x => x.card) || []
  return { cards, loading, error }
}
