import { createContext, useContext, useMemo, useState } from 'react'

const DragInfoContext = createContext()
export const DragInfoContextProvider = ({ children }) => {
  const [hoveredElement, setHoveredElement] = useState(null)
  const [draggedElement, setDraggedElement] = useState(null)

  const val = useMemo(
    () => [
      hoveredElement,
      setHoveredElement,
      draggedElement,
      setDraggedElement,
    ],
    [draggedElement, hoveredElement]
  )

  return (
    <DragInfoContext.Provider value={val}>{children}</DragInfoContext.Provider>
  )
}

export const useDragInfoContext = () => useContext(DragInfoContext)
