import classNames from 'classnames'
import { memo, useCallback, useState } from 'react'

import HTMLMarkerComponent from './HTMLMarkerComponent'
import VehicleRoute from './VehicleRoute'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { getMapMarkerColor } from '../../utils/driver-utils'
import { EtaMarker } from '../drivers/EtaMarker'
import LastReportedMarker from '../drivers/LastReportedMarker'
import VehicleIcon from '../drivers/VehicleIcon'

const LAST_REPORT_LIMIT = 30

const VehicleMapMarker = ({
  driver,
  onHoverStart,
  onHoverEnd,
  eta,
  etaType,
  clusterer,
  directionData,
  theme,
}) => {
  const { fromServerValue, getCurrentTime } = useLocalDateTime()
  const [loadedMarker, setLoadedMarker] = useState(null)
  const [defaultMarker, setDefaultMarker] = useState(null)

  const onMarkerHovered = useCallback(() => {
    onHoverStart && onHoverStart(loadedMarker || defaultMarker, driver)
  }, [defaultMarker, driver, loadedMarker, onHoverStart])

  const onMarkerLeave = useCallback(() => {
    onHoverEnd && onHoverEnd()
  }, [onHoverEnd])

  const onLoadedUnmount = useCallback(() => {
    setLoadedMarker(null)
  }, [])

  if (!driver.position) return null

  const lastReportedDt = driver.lastReported
    ? fromServerValue(driver.lastReported.iso)
    : null
  const subtractedNow = getCurrentTime().subtract(LAST_REPORT_LIMIT, 'minutes')
  const displayLastReported = lastReportedDt?.isBefore(subtractedNow)

  const mainColor = getMapMarkerColor(driver, theme)
  const markerCss = classNames('vehicle-icon-map', {
    'vehicle-icon-map-multiline': displayLastReported,
  })
  return (
    <>
      <HTMLMarkerComponent
        clusterer={clusterer}
        value={driver.id}
        latLng={driver.position}
        onLoad={r => setDefaultMarker(r)}
        onUnmount={onLoadedUnmount}
      >
        <div
          className={markerCss}
          onMouseOver={onMarkerHovered}
          onMouseLeave={onMarkerLeave}
        >
          <VehicleIcon
            hideLabel
            displayDriverCode
            driver={driver}
            theme={theme}
          />
          <EtaMarker
            eta={eta}
            etaType={etaType}
            displayLastReported={displayLastReported}
            lastReportedDt={displayLastReported}
            contractor={driver.contractor}
            theme={theme}
          />
          {displayLastReported && (
            <LastReportedMarker
              lastReported={lastReportedDt}
              theme={theme}
              contractor={driver.contractor}
            />
          )}
        </div>
      </HTMLMarkerComponent>

      {directionData && (
        <VehicleRoute
          lineColor={mainColor}
          key={driver.code}
          directionData={directionData}
        />
      )}
    </>
  )
}

export default memo(VehicleMapMarker)
