import classNames from 'classnames'

import { currentVersion } from '../../app/config'
import { themedClass } from '../../utils/style-utils'

export const VersionNumber = ({ onClick, theme }) => {
  const css = classNames(themedClass('version', theme), {
    clickable: !!onClick,
  })
  return (
    <div className={css} onClick={onClick}>
      {currentVersion}
    </div>
  )
}
