import _ from 'lodash'

import { FeaturesEnum } from './enums/FeaturesEnum'
import { LogLevel } from './enums/LogLevel'

const { version } = require('../../package.json')

export const currentVersion = version

export const config = {
  logLevel: process.env.REACT_APP_QDD_APP_LOG_LEVEL || LogLevel.WARN,
  [FeaturesEnum.ALLOW_TEST_FEATURES]:
    _.toUpper(process.env.REACT_APP_QDD_ALLOW_TEST_FEATURES) === 'TRUE',
  googleMapsApiKey: process.env.REACT_APP_QDD_GOOGLE_API_KEY,
  tokenRefreshInterval: parseInt(
    process.env.REACT_APP_QDD_TOKEN_REFRESH_INTERVAL
  ),
}
