import { gql } from '@apollo/client'

export const GET_STATIONS = gql`
  query getStations($stations: [ID]) {
    listStations(stations: $stations) {
      id
      address {
        city
        street
      }
      defaultVendor
      gpsCoordinates {
        lat
        lng
      }
      timeZone
      dropLocations {
        id
        name
      }
    }
  }
`
