import classNames from 'classnames'
import { clamp, range } from 'lodash'

import { SensorPanel } from './SensorPanel'

export const BATTERY_NUM_PARTS = 6
const BATTERY_PART_MAX_PERCENTAGE = 100 / BATTERY_NUM_PARTS

export function getBatterySegmentPercentage(segmentIndex, batteryPercentage) {
  const percentageSkip = BATTERY_PART_MAX_PERCENTAGE * segmentIndex
  const totalPercentageInThisPart = clamp(
    batteryPercentage - percentageSkip,
    0,
    BATTERY_PART_MAX_PERCENTAGE
  )
  return (totalPercentageInThisPart * 100) / BATTERY_PART_MAX_PERCENTAGE
}

export const BatterySensor = ({ lastReported, percentage }) => (
  <SensorPanel
    headerMessageId="sensors.battery"
    lastReported={lastReported}
    className={classNames({
      'battery-sensor-high': percentage > 70,
      'battery-sensor-medium': percentage > 20 && percentage <= 70,
      'battery-sensor-low': percentage <= 20,
    })}
  >
    <div className="battery-sensor-value">{percentage}%</div>
    <div className="battery-icon">
      <div className="battery-icon-outline">
        <div className="battery-icon-state">
          {range(0, BATTERY_NUM_PARTS).map(i => (
            <div className="battery-icon-segment" key={i}>
              <div
                className="battery-icon-segment-inner"
                style={{
                  width: `${getBatterySegmentPercentage(i, percentage)}%`,
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="battery-icon-cap" />
    </div>
  </SensorPanel>
)
