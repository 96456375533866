import _ from 'lodash'

const PlaceholderRepeater = ({ existingCount, totalCount, ItemComponent }) => {
  const rowsToAdd = totalCount - existingCount
  if (rowsToAdd <= 0) return null

  return _.map(_.range(rowsToAdd), (_, index) => <ItemComponent key={index} />)
}

export default PlaceholderRepeater
