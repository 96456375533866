export const ActionEnum = {
  ASSIGN_DRIVER: 'ASSIGNDRIVER',
  UNASSIGN_DRIVER: 'UNASSIGNDRIVER',
  ACCEPT_DRIVER: 'ACCEPTDRIVER',
  SET_ALARM: 'SETALARM',
  CLOSE_ALARM: 'CLOSEALARM',
  CONFIRM_ALERT: 'CONFIRMALERT',
  ACKNOWLEDGE_CARD: 'ACK!SPECIAL.INSTRUCTIONS',
  DISPUTE_CHARGES: 'DISPUTECHARGES',
  CONFIRM_CHARGES: 'CONFIRMCHARGES',
  CREATE_SUB_JOB: 'ADDSUBJOB',
  DECLINE_CARD: 'DECLINELEG',
  ADD_CHARGES: 'ADDCHARGES',
  ADD_CS_NOTE: 'ADDCSNOTE',
  UPLOAD_DOCUMENT: 'UPLOAD',
  DOWNLOAD_DOCUMENT: 'DOWNLOAD',
  ACKNOWLEDGE_TRACKED_CHANGE: 'ACKCTUPDATE',
}

export const actionToTranslationMessage = (action, args) => {
  const base = 'action-log.action-'
  switch (action) {
    case ActionEnum.ASSIGN_DRIVER:
      return base + 'assign_driver'
    case ActionEnum.UNASSIGN_DRIVER:
      return base + 'unassign_driver'
    case ActionEnum.ACCEPT_DRIVER:
      return base + 'accept_driver'
    case ActionEnum.SET_ALARM:
      return args?.prevDateTime ? base + 'modify-alarm' : base + 'set_alarm'
    case ActionEnum.CLOSE_ALARM:
      return base + 'close_alarm'
    case ActionEnum.CONFIRM_ALERT:
      return base + 'confirm_alert'
    case ActionEnum.ACKNOWLEDGE_CARD:
      return base + 'acknowledge_card'
    case ActionEnum.DISPUTE_CHARGES:
      return base + 'dispute_charges'
    case ActionEnum.CONFIRM_CHARGES:
      return base + 'confirm_charges'
    case ActionEnum.CREATE_SUB_JOB:
      return base + 'create_sub_job'
    case ActionEnum.DECLINE_CARD:
      return base + 'decline_card'
    case ActionEnum.ADD_CHARGES:
      return base + 'add_charges'
    case ActionEnum.ADD_CS_NOTE:
      return base + 'add_cs_note'
    case ActionEnum.ACKNOWLEDGE_TRACKED_CHANGE:
      return base + 'acknowledge_tracked_change'
    case ActionEnum.UPLOAD_DOCUMENT:
      return base + 'uploaded-document'
    case ActionEnum.DOWNLOAD_DOCUMENT:
      return base + 'downloaded-document'
    default:
      return base + 'action-log.action-unknown'
  }
}
