import { WarningIcon } from '../Icons'

const NotImplementedWarning = () => (
  <div className="card-modal-notification">
    <WarningIcon className="text-warning" /> NOTE: Sub-Jobs Implementation Not
    Finished
  </div>
)

export default NotImplementedWarning
