import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input } from 'antd'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import SubJobBase from './SubJobBase'
import { CardPopupState } from '../../app/enums/CardPopupState'
import { useCardContext } from '../../hooks/useCardPopupContext'
import { useCards } from '../../hooks/useCards'
import useDirtyState from '../../hooks/useDirtyState'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { isSubJobCard } from '../../utils/card-utils'
import { getRequiredFieldRules } from '../../utils/common-utils'
import { toDateTimeVariable } from '../../utils/date-utils'
import { FlowContentHeaderSmall } from '../ModalsTypography'
import { SelectedWeightUnit } from '../user-units/WeightUnits'

function ReIceForm({ theme, onPickupChange, pickupAmount, editing, form }) {
  const { formatMessage: f } = useIntl()
  const { getFieldDecorator, setFieldsValue } = form

  useEffect(() => {
    setFieldsValue({
      amount: pickupAmount,
    })
  }, [pickupAmount, setFieldsValue])

  return (
    <>
      <FlowContentHeaderSmall theme={theme}>
        <FormattedMessage id="card-popup.pickup-amount" />
      </FlowContentHeaderSmall>
      <div className="space-between">
        <div className="subjob-form-column">
          <div className="subjob-form-row">
            <Form.Item>
              {getFieldDecorator('amount', {
                rules: getRequiredFieldRules(f),
              })(
                <Input
                  size="small"
                  onChange={onPickupChange}
                  readOnly={!editing}
                  placeholder={f({
                    id: 'form.enter-amount',
                  })}
                />
              )}
            </Form.Item>
            <label>
              <SelectedWeightUnit />
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

const CardReIce = ({ card, page }) => {
  const { currentTimeZone } = useLocalDateTime()
  const { changeView, addCurrentPageToHistory } = useCardContext()
  const [pickupAmount, setPickupAmount, amountDirty, resetAmount] =
    useDirtyState('')
  const [editing, setEditing] = useState(false)
  const [theme] = useSelectedTheme()

  const { createReiceSubJob, updateReiceSubJob } = useCards()

  useEffect(() => {
    resetAmount(card.pickupAmount || '')
  }, [card, resetAmount])

  const onPickupChange = ({ target }) => {
    setPickupAmount(target.value)
  }

  const onConfirmClick = (cardId, stackId, parameters) => {
    if (isSubJobCard(card)) {
      updateReiceSubJob({
        id: card.id,
        from: parameters.from,
        to: parameters.to,
        stack: stackId,
        pickupAmount: pickupAmount,
        reminder: toDateTimeVariable(parameters.date, currentTimeZone),
      })
      addCurrentPageToHistory()
    } else {
      createReiceSubJob({
        subjobType: 'REICE',
        parentId: cardId,
        stack: stackId,
        from: parameters.from,
        to: parameters.to,
        pickupAmount: pickupAmount,
        reminder: toDateTimeVariable(parameters.date, currentTimeZone),
      })
      changeView(CardPopupState.Overview.Page)
    }
  }

  const onCancel = () => {
    resetAmount()
  }

  return (
    <SubJobBase
      card={card}
      onConfirmClick={onConfirmClick}
      header="card-popup.sub-job-pud"
      onCancel={onCancel}
      page={page}
      subjobLabel="card-popup.reice-subjob"
      confirmDisabled={!pickupAmount}
      onEditingChanged={setEditing}
      canCancel={amountDirty}
    >
      {({ form }) => (
        <ReIceForm
          theme={theme}
          onPickupChange={onPickupChange}
          editing={editing}
          form={form}
          pickupAmount={pickupAmount}
        />
      )}
    </SubJobBase>
  )
}

export default CardReIce
