import Gauge, { OptimalRangeGaugeOuterAxis } from './Gauge'
import { SensorPanel } from './SensorPanel'
import { Temperature } from '../user-units/TemperatureUnits'

const TemperatureSensor = ({ value, lastReported }) => (
  <SensorPanel
    headerMessageId="sensors.temperature"
    lastReported={lastReported}
  >
    <Gauge
      value={value}
      minValue={-200}
      maxValue={60}
      formatValue={val => <Temperature value={val} />}
      outerAxisComponent={OptimalRangeGaugeOuterAxis}
      outerAxisProps={{ range: [-180, -90] }}
    />
  </SensorPanel>
)

export default TemperatureSensor
