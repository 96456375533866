import classNames from 'classnames'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import ConfirmModalBase from './ConfirmModalBase'
import { useDocumentTypes } from '../../hooks/graphQl/useDocumentTypes'
import { useDocumentsUploadSubscription } from '../../hooks/graphQl/useDocumentsUpload'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { CheckIcon, TrashIcon, UploadIcon } from '../Icons'
import { DotsLoader } from '../LoadingIndicators'
import FileUpload from '../form/FileUpload'
import { SelectComponent } from '../form/FormInputs'

const UploadStatusEnum = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

const FilePreview = ({
  file: { file, type, status, error },
  onChange,
  onRemove,
  loading,
  possibleTypes = [],
  theme,
}) => {
  const [typesFilter, setTypesFilter] = useState('')
  const { formatMessage: f } = useIntl()

  const onDocumentTypeChanged = value => {
    onChange(
      file,
      _.find(possibleTypes, type => type.id === value)
    )
  }

  const onRemoveDocumentClick = () => {
    onRemove(file)
  }

  const filteredTypes = _.filter(possibleTypes, type =>
    _.includes(_.toLower(type.displayName), _.toLower(typesFilter))
  )
  const options = _.map(filteredTypes, type => ({
    value: type.id,
    label: type.displayName,
  }))

  const css = classNames('space-between', 'file-preview', {
    'text-danger': status === UploadStatusEnum.ERROR,
  })

  return (
    <>
      <div className={css}>
        <span className="file-preview-name text-ellipsed">
          {status === UploadStatusEnum.SUCCESS && (
            <CheckIcon className="text-success" />
          )}
          {status === UploadStatusEnum.PENDING && <DotsLoader />}
          {(!status || status === UploadStatusEnum.ERROR) && (
            <>
              <TrashIcon
                className="text-important clickable-text"
                onClick={onRemoveDocumentClick}
              />
            </>
          )}
          <span className="file-preview-filename">{file.name}</span>
        </span>
        <div className="file-preview-select">
          <SelectComponent
            onChange={onDocumentTypeChanged}
            value={type?.id}
            options={options}
            placeholder={f({ id: 'form.select-document-type' })}
            className="full-width"
            data-cy="new-charge-select"
            size="small"
            loading={loading}
            onFilterItems={setTypesFilter}
            theme={theme}
          />
        </div>
      </div>
      {status === UploadStatusEnum.ERROR && error && (
        <p className="upload-error text-danger">{error}</p>
      )}
    </>
  )
}

const UploadDocumentsModal = ({ onClosed, job }) => {
  const { formatMessage: f } = useIntl()
  const [documentsToUpload, setDocumentsToUpload] = useState([])
  const [finishedUpload, setFinishedUpload] = useState(false)
  const { loading, types } = useDocumentTypes()
  const [theme] = useSelectedTheme()

  const setDocument = (document, newValue) => {
    setDocumentsToUpload(current =>
      _.unionBy([{ ...document, ...newValue }], current, x => x.file.uid)
    )
  }

  const [uploadFiles] = useDocumentsUploadSubscription(
    job,
    documentsToUpload,
    setDocument
  )

  useEffect(() => {
    if (
      _.some(documentsToUpload) &&
      _.every(documentsToUpload, doc => doc.status === UploadStatusEnum.SUCCESS)
    ) {
      setFinishedUpload(true)
    }
  }, [documentsToUpload])

  const onFilesChange = e => {
    const newFileList = _.uniqBy(
      _.map([...e.fileList, ...documentsToUpload], doc => {
        const storedFile = _.find(
          documentsToUpload,
          f => f.file.uid === doc.file?.uid
        )
        if (storedFile) return storedFile
        return { file: doc }
      }),
      x => x.file.uid
    )
    setDocumentsToUpload(newFileList)
  }

  const onFileRemoved = file => {
    setDocumentsToUpload(
      _.filter(documentsToUpload, d => d.file.uid !== file.uid)
    )
  }

  const onTypeChanged = (file, type) => {
    setDocumentsToUpload(
      _.unionBy([{ file, type }], documentsToUpload, x => x.file.uid)
    )
  }

  const onUploadClick = () => {
    uploadFiles()
  }

  const sortedFiles = _.sortBy(documentsToUpload, file => file.file.uid)
  return (
    <ConfirmModalBase
      className={'upload-documents-popup'}
      width={520}
      title={<UploadIcon className="opaque-icon text-important" />}
      onCancel={onClosed}
      onOk={onUploadClick}
      okText={f({ id: 'form.upload' })}
      noFooter={!_.some(documentsToUpload)}
      closeAfterConfirm={false}
      forceClose={finishedUpload}
    >
      <h1>
        <FormattedMessage id="documents-center.upload-document" />
      </h1>
      <FileUpload
        showUploadList={false}
        onChange={onFilesChange}
        fileList={sortedFiles}
        beforeUpload={() => false}
        uploadHintId="documents-center.upload-document-hint"
        multiple
        renderPreview={document => (
          <FilePreview
            key={document.file.uid}
            file={document}
            onChange={onTypeChanged}
            onRemove={onFileRemoved}
            possibleTypes={types}
            loading={loading}
            theme={theme}
          />
        )}
        theme={theme}
      />
    </ConfirmModalBase>
  )
}

export default UploadDocumentsModal
