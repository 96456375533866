import { gql } from '@apollo/client'

import {
  COMPANY_INFO,
  DROP_POINT,
  FLIGHT_OVERVIEW,
  MUTATION_RESPONSE,
  PICKUP_POINT,
} from './Common'
import { LOCATION_DETAIL } from './Locations'
import { TRACKED_CHANGE_FRAGMENT } from './TrackedChanges'

//#region fragments

// keep in sync with health-checks.js
export const CARD_DETAIL_BASE = gql`
  fragment cardDetailBase on Card {
    id
    jobNumber
    station {
      id
      defaultVendor
      dropLocations {
        id
        name
      }
    }
    controlStation {
      abbreviation
    }
    driver {
      id
      code
      name
    }
    requestedVehicleType
    function
    stack {
      id
      name
    }
    pickup {
      ...pickupPoint
    }
    drop {
      ...dropPoint
    }
    flights {
      ...flightOverview
    }
    airOrigin
    airDestination
    service
    commodity
    pieces
    weight
    createdDate {
      iso
    }
    dimensions
    badge1 {
      label
      iso
    }
    jobBadge1 {
      label
      iso
    }
    jobBadge2 {
      label
      iso
    }
    milestone {
      code
    }
    status
    reminder {
      iso
    }
    lockout {
      iso
    }
    ready {
      iso
    }
    groupParent
    agent
    lockData {
      isLocked
      byWhom
    }
    isDangerousGoods
    unNumber

    legsCount

    subjobType
    parent {
      id
      function
      jobNumber
    }
    from {
      ...locationDetail
    }
    to {
      ...locationDetail
    }
    pickupAmount
    finished
    specialInstructions {
      text
      wasAcknowledged
    }
    archived

    # if true, next subscription will be ignored
    skipNextUpdate @client

    # renders milestone as pending
    pendingUpdate @client

    trackedChanges {
      ...trackedChange
    }
  }
  ${PICKUP_POINT}
  ${DROP_POINT}
  ${LOCATION_DETAIL}
  ${FLIGHT_OVERVIEW}
  ${TRACKED_CHANGE_FRAGMENT}
`

export const UPDATE_SUBJOB_FRAGMENT = gql`
  fragment subjobUpdate on Card {
    id
    pickupAmount
    stack {
      id
    }
    reminder {
      iso
    }
  }
`

export const UPDATE_CHANGES_FRAGMENT = gql`
  fragment changesUpdate on Card {
    id
    trackedChanges {
      ...trackedChange
    }
  }
  ${TRACKED_CHANGE_FRAGMENT}
`

export const CARD_DETAIL = gql`
  fragment cardDetail on Card {
    ...cardDetailBase
    groupChildren {
      ...cardDetailBase
      subjobs {
        ...cardDetailBase
      }
    }
    subjobs {
      ...cardDetailBase
    }
  }
  ${CARD_DETAIL_BASE}
`

const SUB_JOB_PARENT = gql`
  fragment subJobParent on Card {
    id
    jobNumber
    station {
      id
      defaultVendor
    }
    badge1 {
      iso
      label
    }
    function
    service
    status
    commodity
    pieces
    weight
    dimensions
    ready {
      iso
    }
    lockout {
      iso
    }
    pickup {
      ...pickupPoint
    }
    drop {
      ...dropPoint
    }
    flights {
      ...flightOverview
    }
  }
  ${PICKUP_POINT}
  ${DROP_POINT}
  ${FLIGHT_OVERVIEW}
`

const FULL_CARD_FIELDS = gql`
  fragment fullCardDetailFields on Card {
    id
    jobNumber
    driver {
      id
      name
      driverType
      contractor @client
    }
    unNumberDescription
    dispatcherNotes {
      id
      text
    }
    agentCharges {
      type
      amount
      loading @client
    }
    totalCharges
    totalExcessWeightCharge
    totalWaitCharge
    totalDriveCharge
    totalWeekendCharge
    totalHolidayCharge
    deadline {
      iso
    }
    loaded {
      iso
    }
    unloaded {
      iso
    }
    predictedDistance {
      local
      pick
      drop
    }
    parent {
      ...subJobParent
    }
  }
  ${SUB_JOB_PARENT}
`

export const FULL_CARD_DETAIL = gql`
  fragment fullCardDetail on Card {
    ...cardDetail
    ...fullCardDetailFields
  }
  ${CARD_DETAIL}
  ${FULL_CARD_FIELDS}
`

//#endregion

//#region queries
export const GET_CARDS = gql`
  query getCards($filter: CardsFilter!) {
    listCards(filter: $filter) {
      ...cardDetail
    }
  }
  ${CARD_DETAIL}
`

export const GET_FULL_CARD = gql`
  query getCard($cardId: ID!) {
    getCard(itemId: $cardId) {
      ...fullCardDetail
    }
  }
  ${FULL_CARD_DETAIL}
`

export const GET_CARD = gql`
  query getCard($itemId: ID!) {
    getCard(itemId: $itemId) {
      ...cardDetail
    }
  }
  ${CARD_DETAIL}
`

export const GET_ARCHIVED_CARD = gql`
  query getArchivedCard($itemId: ID!) {
    getArchivedCard(itemId: $itemId) {
      card {
        ...fullCardDetail
      }
      job {
        id
        shipper {
          ...companyInfo
        }
        consignee {
          ...companyInfo
        }
        packages {
          piecesCount
          commodity
          weight
          dimensionalWeight
          height
          length
          width
        }
      }
    }
  }
  ${COMPANY_INFO}
  ${FULL_CARD_DETAIL}
`

export const GET_DRIVER_CARDS_ARCHIVED = gql`
  query filterArchivedCards($filter: ArchivedCardsFilter) {
    filterArchivedJobs(filter: $filter) {
      card {
        ...cardDetail
      }
    }
  }
  ${CARD_DETAIL}
`

export const GET_DRIVER_CARDS = gql`
  query getDriverCards($driverId: ID!) {
    getDriverCards(driverId: $driverId) {
      ...cardDetailBase
    }
  }
  ${CARD_DETAIL_BASE}
`

export const GET_CARDS_ARCHIVED = gql`
  query filterArchivedCards($filter: ArchivedCardsFilter) {
    filterArchivedJobs(filter: $filter) {
      card {
        ...cardDetailBase
      }
    }
  }
  ${CARD_DETAIL_BASE}
`

export const GET_CARDS_FOR_BOARD = gql`
  query getCardsForBoard($filter: CardsFilter!) {
    getCardsForBoard(filter: $filter) @client {
      ...cardDetail
    }
  }
  ${CARD_DETAIL}
`

//#endregion

//#region mutations
export const ASSIGN_DRIVER = gql`
  mutation AssignDriver(
    $cardId: ID!
    $driverId: ID!
    $stack: ID!
    $destination: String
    $location: String
  ) {
    assignDriver(
      cardId: $cardId
      driverId: $driverId
      stack: $stack
      destination: $destination
      location: $location
    ) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const UNASSIGN_DRIVER = gql`
  mutation UnassignDriver($cardId: ID!) {
    unassignDriver(cardId: $cardId) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const ACCEPT_DRIVER = gql`
  mutation AcceptDriver($cardId: ID!, $driverId: ID!) {
    acceptDriver(cardId: $cardId, driverId: $driverId) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const UPDATE_STACK = gql`
  mutation UpdateStack($cardIds: [ID], $stack: ID!) {
    moveToStack(cardIds: $cardIds, stack: $stack) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const SET_ALARM = gql`
  mutation SetAlarm(
    $cardId: ID!
    $dateTime: ISODateWithTimezone!
    $stackId: ID!
  ) {
    setAlarm(cardId: $cardId, dateTime: $dateTime, stackId: $stackId) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const CLOSE_ALARM = gql`
  mutation CloseAlarm($cardId: ID!) {
    closeAlarm(cardId: $cardId) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const GROUP_CARDS = gql`
  mutation GroupCards($parentCard: ID!, $childCards: [ID]) {
    groupCards(parentCard: $parentCard, childCards: $childCards) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const SPLIT_CARDS_GROUP = gql`
  mutation SplitGroup($parentCard: ID!) {
    splitCardsGroup(parentCard: $parentCard) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const CONFIRM_ALERT = gql`
  mutation ConfirmAlert(
    $cardIds: [ID]
    $reminder: ISODateWithTimezone
    $stackId: ID
    $pickupEta: ISODateWithTimezone
  ) {
    confirmAlert(
      cardIds: $cardIds
      reminder: $reminder
      stackId: $stackId
      pickupEta: $pickupEta
    ) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const CREATE_SUBJOB = gql`
  mutation CreateSubJob($subjob: NewSubJob!) {
    createSubJob(subjob: $subjob) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const UPDATE_SUBJOB = gql`
  mutation UpdateSubJob($subjob: UpdatedSubJob!) {
    updateSubJob(subjob: $subjob) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const CREATE_REICE_SUBJOB = gql`
  mutation CreateReiceSubJob($subjob: NewSubJob!) {
    createReiceSubJob(subjob: $subjob) {
      ...cardDetail
    }
  }
  ${CARD_DETAIL}
`

export const UPDATE_REICE_SUBJOB = gql`
  mutation UpdateReiceSubJob($subjob: UpdatedSubJob!) {
    updateReiceSubJob(subjob: $subjob) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const ADD_DISPATCHER_NOTE = gql`
  mutation AddDispatcherNote($cardId: ID, $note: String) {
    addDispatcherNote(cardId: $cardId, note: $note) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const REMOVE_DISPATCHER_NOTE = gql`
  mutation RemoveDispatcherNote($cardId: ID, $noteId: String) {
    removeDispatcherNote(cardId: $cardId, noteId: $noteId) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const CONFIRM_CHARGES = gql`
  mutation ConfirmCharges($cardId: ID) {
    confirmCharges(cardId: $cardId) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const ARCHIVE_CARD = gql`
  mutation ArchiveCard($cardId: ID) {
    archiveCard(cardId: $cardId) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const DISPUTE_CHARGES = gql`
  mutation DisputeCharges($cardId: ID, $comment: String) {
    disputeCharges(cardId: $cardId, comment: $comment) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const ADD_CHARGES = gql`
  mutation AddCharges($cardId: ID, $charges: [CardCharge]) {
    addCharges(cardId: $cardId, charges: $charges) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const DECLINE_CARD = gql`
  mutation DeclineCard($cardId: ID, $comment: String) {
    declineCard(cardId: $cardId, comment: $comment) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const ACKNOWLEDGE_INSTRUCTIONS = gql`
  mutation AcknowledgeInstructions($cardId: ID) {
    acknowledgeInstructions(cardId: $cardId) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const PICK_FROM_DOCK = gql`
  mutation PickFromDock(
    $cardId: ID!
    $driverId: ID!
    $date: ISODateWithTimezone
    $pieces: String
  ) {
    pickFromDock(
      cardId: $cardId
      driverId: $driverId
      date: $date
      pieces: $pieces
    ) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

export const DROP_TO_DOCK = gql`
  mutation DropToDock(
    $cardId: ID!
    $driverId: ID!
    $date: ISODateWithTimezone
    $pieces: String
  ) {
    dropToDock(
      cardId: $cardId
      driverId: $driverId
      date: $date
      pieces: $pieces
    ) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

//#endregion

//#region subscriptions
export const CARD_SUBSCRIPTION = gql`
  subscription cardSubscription($filter: CardsFilter) {
    cardUpdated(filter: { cardsFilter: $filter }) {
      operation
      changeData {
        ...cardDetail
      }
    }
  }
  ${CARD_DETAIL}
`

export const FULL_CARD_SUBSCRIPTION = gql`
  subscription cardSubscription($jobSubscription: SingleJobSubscription) {
    cardUpdated(filter: { singleJob: $jobSubscription }) {
      operation
      changeData {
        ...fullCardDetailFields
      }
    }
  }
  ${FULL_CARD_FIELDS}
`
//#endregion
