import { useQuery } from '@apollo/client'

import { GET_STATIONS } from '../../app/graphQl/schema/Stations'
import { emptyArray } from '../../utils/perf-utils'
import { useAuthentication } from '../useAuthentication'

const useStationsList = () => {
  const [currentUser, validToken] = useAuthentication()

  const {
    data: { listStations } = { listStations: emptyArray },
    loading,
    error,
  } = useQuery(GET_STATIONS, {
    skip: !currentUser || !validToken,
    variables: {
      stations: currentUser ? currentUser.allowedStations : emptyArray,
    },
  })

  return {
    data: listStations,
    loading,
    error,
  }
}

export default useStationsList
