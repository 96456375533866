import { TimePicker } from 'antd'

import { ClockIcon } from '../Icons'

const TimePickerInput = ({
  onChange,
  format = 'HH:mm',
  iconClass,
  ...props
}) => (
  <div style={{ position: 'relative' }}>
    <div className={iconClass}>{<ClockIcon />}</div>
    <TimePicker onChange={onChange} format={format} {...props} />
  </div>
)

export default TimePickerInput
