import classNames from 'classnames'

import { SplashScreenHeader } from '../components/SplashScreenHeader'

const SplashScreenBase = ({
  className,
  children,
  currentTime,
  handle,
  hideTimePanel,
}) => {
  const splashPanelCss = classNames('splash-screen-panel', {
    'splash-screen-panel-lg': hideTimePanel,
  })
  const contentCss = classNames('splash-content', className)
  return (
    <div className="splash-page">
      <div className={contentCss}>
        {!hideTimePanel && <SplashScreenHeader currentTime={currentTime} />}
        {handle}
        <div className={splashPanelCss}>{children}</div>
      </div>
    </div>
  )
}

export default SplashScreenBase
