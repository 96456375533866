import { memo } from 'react'
import { useIntl } from 'react-intl'

import CurrentTime from '../components/CurrentTime'
import ThemeSwitch from '../components/ThemeSwitch'
import ViewSwitch from '../components/ViewSwitch'
import CardsRangeFilter from '../components/main-header/CardsFilter'
import CardsSearch from '../components/main-header/CardsSearch'
import { TopHeaderSection } from '../components/main-header/TopHeaderSection'
import LoggedUserInfo from '../components/user-info/LoggedUserInfo'
import { StationSelect } from '../components/user-info/StationSelect'
import { UserSettingsPopover } from '../components/user-info/UserSettingsPopover'
import useStationsList from '../hooks/graphQl/useStationsQuery'
import { useActiveStation } from '../hooks/useActiveStation'
import useCardsFilter from '../hooks/useCardsFilter'
import { useSelectedTheme } from '../hooks/useUserSettings'
import logoImg from '../styles/img/quick-logo'
import { themedClass } from '../utils/style-utils'

const MainHeader = ({ onCardOpen, testId }) => {
  const { formatMessage: f } = useIntl()
  const [cardsFilter, changeCardFilter] = useCardsFilter()
  const { currentStation: station } = useActiveStation()
  const [theme] = useSelectedTheme()

  const { data: stationsList } = useStationsList()

  const onJobFilterChanged = val =>
    changeCardFilter({ ...cardsFilter, jobId: val })

  return (
    <>
      <div data-testid={testId} className={themedClass('main-header', theme)}>
        <TopHeaderSection className="search-job-section">
          <div className="main-header-logo">
            <img src={logoImg} alt="quick-logo" />
          </div>
          <CardsSearch
            onBlur={onJobFilterChanged}
            value={cardsFilter.jobId}
            placeholder={f({ id: 'inputs.search-placeholder' })}
            onCardOpen={onCardOpen}
          />
        </TopHeaderSection>

        <CardsRangeFilter />

        <TopHeaderSection className="clock-section">
          <CurrentTime station={station} />
        </TopHeaderSection>

        <TopHeaderSection
          labelMessageId="main-header.station"
          className="station-select-section"
        >
          <StationSelect stations={stationsList} />
        </TopHeaderSection>
        <TopHeaderSection
          labelMessageId="main-header.units-measures"
          className="units-select-section"
        >
          <UserSettingsPopover />
        </TopHeaderSection>

        <TopHeaderSection className="navigation-section">
          <ViewSwitch />
        </TopHeaderSection>
      </div>
      <div className={themedClass('secondary-header', theme)}>
        <div className="space-between">
          <ThemeSwitch />
          <LoggedUserInfo />
        </div>
      </div>
    </>
  )
}

export default memo(MainHeader)
