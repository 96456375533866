import classNames from 'classnames'

import DriverCard from './DriverCard'
import DraggableComponent from '../DraggableComponent'

export const DriverDragType = 'DRIVER'

const DraggableDriverCard = props => {
  const { driver, onDragStart, onDragEnd, isDragged, DragPreview } = props

  const css = classNames('driver-card-draggable', {
    dragged: isDragged,
  })

  return (
    <DraggableComponent
      dragItem={{ data: driver, type: DriverDragType }}
      className={css}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      CustomPreview={DragPreview}
    >
      <DriverCard {...props} />
    </DraggableComponent>
  )
}

export default DraggableDriverCard
