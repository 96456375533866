import TopBadge from './TopBadge'
import { BadgeEnum } from '../../app/enums/BadgesEnum'
import { AlarmBadge } from '../AlarmBadge'

const CardBadge = ({ card }) => {
  const { badge1, reminder } = card

  if (reminder) {
    return <AlarmBadge value={reminder.iso} />
  }

  if (badge1 && badge1.label === BadgeEnum.ACTION) {
    return <AlarmBadge forceFired />
  }

  return <TopBadge badge={badge1} />
}

export default CardBadge
