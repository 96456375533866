import { gql } from '@apollo/client'

export const GET_DRIVER_ROUTE_QUERY = gql`
  query getDriverRoute($cardId: ID!) {
    getDriverRoute(cardId: $cardId) {
      id
      routePoints {
        coords {
          lat
          lng
        }
      }
    }
  }
`
