import classNames from 'classnames'
import _ from 'lodash'
import { forwardRef, useCallback, useEffect, useState } from 'react'

import Card from './Card'
import { LegsHeader, ParentHeader } from './CardHeaders'
import SubJobCard from './SubJobCard'
import {
  getSubjobsCount,
  hasAlarmFired,
  isHighlighted,
  isSubJobCard,
} from '../../utils/card-utils'
import { getCardDate } from '../../utils/common-utils'

const MultiPartCard = (props, ref) => {
  const {
    data,
    header,
    countBadge,
    forceExpand,
    onSubJobsHighlight,
    onLegsHighlight,
    highlightInfo,
    onClick,
    selectedChildren,
    onCardExpandToggle,
  } = props
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (forceExpand) setExpanded(true)
  }, [forceExpand])

  const isGroupSelected = _.some(selectedChildren)
  const groupCss = classNames(
    { 'card-group-collapsed': !expanded },
    { 'card-group-expanded': expanded },
    { 'card-selected': !expanded && (isGroupSelected || props.selected) },
    { 'cards-list': expanded }
  )

  const onCardExpand = useCallback(
    e => {
      setExpanded(e => !e)
      onCardExpandToggle && onCardExpandToggle(e)
      e.preventDefault()
      e.stopPropagation()
    },
    [onCardExpandToggle]
  )

  const onHighlightClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onSubJobsHighlight && onSubJobsHighlight(data)
    },
    [data, onSubJobsHighlight]
  )

  const onLegHighlightClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onLegsHighlight && onLegsHighlight(data)
    },
    [data, onLegsHighlight]
  )

  const onSubCardClick = useCallback(
    (card, event) => {
      onClick && onClick(card, event, data)
    },
    [data, onClick]
  )

  const highlighted = isHighlighted(data, highlightInfo)

  if (isSubJobCard(data)) {
    return <SubJobCard {...props} standAlone highlighted={highlighted} />
  }

  if (!_.some(data.subjobs)) {
    const legHeader = data.legsCount > 1 && (
      <LegsHeader cardData={data} onLegsHighlight={onLegHighlightClick} />
    )
    return (
      <Card {...props} highlighted={highlighted} header={header || legHeader} />
    )
  }

  const parentHeader = header || (
    <ParentHeader
      onToggleClick={onCardExpand}
      expanded={expanded}
      alarmed={hasAlarmFired(data) || _.some(data.subjobs, hasAlarmFired)}
      onSubJobsHighlight={onHighlightClick}
      dangerousGoods={data.isDangerousGoods}
    />
  )

  const expandedCards = _.orderBy(data.subjobs, getCardDate, ['desc'])
  const badgeNumber = countBadge || getSubjobsCount(data)

  return (
    <div className={groupCss} ref={ref}>
      {expanded &&
        expandedCards.map(g => (
          <SubJobCard
            {...props}
            onClick={onSubCardClick}
            data={g}
            selected={_.some(selectedChildren, c => c.id === g.id)}
            key={g.id}
            highlighted={highlighted}
          />
        ))}

      <Card
        {...props}
        header={parentHeader}
        countBadge={expanded ? null : badgeNumber}
        highlighted={highlighted}
      />
    </div>
  )
}

export default forwardRef(MultiPartCard)
