import { useQuery } from '@apollo/client'

import { GET_DRIVERS } from '../../app/graphQl/schema/Drivers'
import { emptyArray } from '../../utils/perf-utils'
import { useCurrentStation } from '../useCurrentStation'

const useDriversList = () => {
  const { stationId } = useCurrentStation()

  const { data, loading, error } = useQuery(GET_DRIVERS, {
    variables: { filter: { station: stationId } },
    skip: !stationId,
  })

  return {
    drivers: data?.listDrivers || emptyArray,
    loading,
    error,
  }
}

export default useDriversList
