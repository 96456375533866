import { useApolloClient } from '@apollo/client'
import _ from 'lodash'
import { useCallback } from 'react'

import { useOptimisticMutation } from './graphQl/useOptimisticMutation'
import { useCurrentStation } from './useCurrentStation'
import { updateStackName, updateStacksList } from '../app/graphQl/cacheManager'
import {
  GET_STACKS,
  REMOVE_STACK,
  RENAME_STACK,
  REORDER_STACK,
} from '../app/graphQl/schema/Stacks'

const reoderStacks = (reoderingStack, position, allStackList) => {
  const ordered = _.sortBy(allStackList, 'order')
  const newStacks = ordered.filter(s => s.id !== reoderingStack)
  const droppedIndex = ordered.findIndex(s => s.order === position)

  const result = [
    ...newStacks.slice(0, droppedIndex),
    ordered.find(s => s.id === reoderingStack),
    ...newStacks.slice(droppedIndex),
  ].map((s, i) => ({ ...s, order: i }))

  return result
}

export function useStacks() {
  const { stationId } = useCurrentStation()
  const client = useApolloClient()

  const [renameMutation] = useOptimisticMutation(RENAME_STACK, 'renameStack')
  const [removeMutation] = useOptimisticMutation(REMOVE_STACK, 'removeStack')
  const [reorderMutation] = useOptimisticMutation(REORDER_STACK, 'reorderStack')

  const stackExists = useCallback(
    (name, id) => {
      if (!name) return false
      const { listStacks } = client.readQuery({
        query: GET_STACKS,
        variables: { stationId },
      })

      return _.some(
        listStacks,
        s => s.name.toLowerCase() === name.trim().toLowerCase() && s.id !== id
      )
    },
    [stationId, client]
  )

  const renameStack = useCallback(
    (stackId, newName) => {
      renameMutation({
        variables: {
          id: stackId,
          newName: newName,
        },
        cacheUpdate: cache => {
          updateStackName(cache, stackId, newName)
        },
      })
    },
    [renameMutation]
  )

  const removeStack = useCallback(
    stackId => {
      removeMutation({
        variables: {
          id: stackId,
        },
        cacheUpdate: cache => {
          const { listStacks } = cache.readQuery({
            query: GET_STACKS,
            variables: { stationId },
          })
          updateStacksList(
            cache,
            listStacks.filter(s => s.id !== stackId),
            stationId
          )
        },
      })
    },
    [removeMutation, stationId]
  )

  const reorderStack = useCallback(
    (stackId, droppedPosition, stationId) => {
      const { listStacks: currentStacks } = client.readQuery(
        {
          query: GET_STACKS,
          variables: { stationId },
        },
        true // we are using optimistic cache to get same order as user sees on screen
      )

      const newStacksList = reoderStacks(
        stackId,
        droppedPosition,
        currentStacks
      )

      reorderMutation({
        variables: {
          newOrder: _.map(newStacksList, x => x.id),
          stationId: stationId,
        },
        cacheUpdate: cache => {
          updateStacksList(cache, newStacksList, stationId)
        },
      })
    },
    [client, reorderMutation]
  )

  return {
    stackExists,
    renameStack,
    removeStack,
    reorderStack,
  }
}
