import { gql } from '@apollo/client'

//#region fragment

export const DOCUMENT_ACTION = gql`
  fragment documentAction on DocumentAction {
    id
    jobNumber
    result
    documentId
  }
`

export const DOCUMENT = gql`
  fragment document on Document {
    id
    documentType
    documentTypeDesc
  }
`

export const DOCUMENT_TYPE = gql`
  fragment documentType on DocumentType {
    id
    name
    displayName
  }
`
//#endregion

//#region queries
export const GET_DOCUMENT_TYPES = gql`
  query listDocumentTypes {
    listDocumentTypes {
      ...documentType
    }
  }
  ${DOCUMENT_TYPE}
`
//#endregion

//#region mutations

export const DOCUMENT_ACTION_SUBSCRIPTION = gql`
  subscription documentActionSubscription($jobNumber: ID) {
    documentActionUpdated(jobNumber: $jobNumber) {
      operation
      changeData {
        ...documentAction
      }
    }
  }
  ${DOCUMENT_ACTION}
`

//#endregion
