import { useQuery } from '@apollo/client'

import { GET_DOCUMENT_TYPES } from '../../app/graphQl/schema/Documents'

export function useDocumentTypes() {
  const { loading, data, error } = useQuery(GET_DOCUMENT_TYPES)

  return {
    types: data?.listDocumentTypes,
    loading,
    error,
  }
}
