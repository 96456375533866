import { SwitchControl } from './form/SwitchControl'
import { ThemesEnum } from '../app/enums/Themes'
import { useSelectedTheme } from '../hooks/useUserSettings'

const ThemeSwitch = () => {
  const [theme, setTheme] = useSelectedTheme()
  return (
    <SwitchControl
      className="theme-switch"
      size={'small'}
      checked={theme === ThemesEnum.DARK}
      onChange={() =>
        setTheme(theme === ThemesEnum.DARK ? ThemesEnum.LIGHT : ThemesEnum.DARK)
      }
    />
  )
}

export default ThemeSwitch
