import { useQuery } from '@apollo/client'
import { useRef } from 'react'

import ParallaxScroll from './ParalaxScroll'
import { SingleCardDetail } from './SingleCardDetail'
import { CardPopupState } from '../../app/enums/CardPopupState'
import { GET_ARCHIVED_CARD } from '../../app/graphQl/schema/Cards'
import { CardPopupContextProvider } from '../../hooks/useCardPopupContext'
import { DriversAssignContextProvider } from '../../hooks/useDriversAssignContext'

const ArchivedCardDetail = ({ onClose, cardData }) => {
  const scrollBarsRef = useRef(null)
  const {
    data: { getArchivedCard } = { getArchivedCard: { card: cardData } },
    loading,
  } = useQuery(GET_ARCHIVED_CARD, {
    variables: { itemId: cardData?.id },
    returnPartialData: true,
    skip: !cardData,
  })

  const { card, job } = getArchivedCard || {}
  return (
    <>
      <ParallaxScroll ref={scrollBarsRef} visible={!!card}>
        {card && (
          <CardPopupContextProvider
            startPage={CardPopupState.Overview.Page}
            currentCard={card}
            currentJob={job}
            readOnly={true}
          >
            <DriversAssignContextProvider>
              <SingleCardDetail
                wrap={scrollBarsRef.current?.view}
                onClose={onClose}
                archived
                loading={loading}
              />
            </DriversAssignContextProvider>
          </CardPopupContextProvider>
        )}
      </ParallaxScroll>
    </>
  )
}

export default ArchivedCardDetail
