import _ from 'lodash'
import moment from 'moment'

import { getStationVendorLabel } from './common-utils'
import { fromServerDate } from './date-utils'
import { formatNullableString } from './string-utils'
import { ColumnsEnum } from '../app/constants'
import { BadgeEnum } from '../app/enums/BadgesEnum'
import { CardDestinationsEnum } from '../app/enums/CardDestinationsEnum'
import CardFunctions from '../app/enums/CardFunctionsEnum'
import { MilestonesEnum } from '../app/enums/MilestonesEnum'
import { SubJobTypeEnum } from '../app/enums/SubJobTypeEnum'

export const createReminderField = iso => ({ iso: iso, __typename: 'DateTime' })

/* checks if more then one card is being currently dragged */
export function areMultipleDragged(draggedCards) {
  return draggedCards.length > 1
}

/* returns count of all cards in group */
export function getCardsGroupSize(topCard, cards) {
  return _.size(_.filter(cards, c => c.id !== topCard.id)) + 1
}

/* returns number of card's subjobs */
export function getSubjobsCount(card) {
  return _.size(card.subjobs)
}

export function isHighlighted(card, highlightInfo) {
  if (!highlightInfo) return false

  if (highlightInfo.parentId)
    return (
      card.id === highlightInfo.parentId ||
      (card.parent && card.parent.id === highlightInfo.parentId)
    )

  if (highlightInfo.jobNumber) {
    return card.jobNumber === highlightInfo.jobNumber
  }
}

export function isSubJobCard(card) {
  if (!card) return false
  return card.subjobType && card.subjobType !== SubJobTypeEnum.NONE
}

function getDefaultAlarm(currentTime) {
  return currentTime.add(1, 'hour').startOf('hour')
}

export function getAlarmDateTime(cardAction, currentDate, parseDateFunc) {
  return !cardAction
    ? getDefaultAlarm(currentDate)
    : parseDateFunc(cardAction.iso)
}

export function isInGroup(card) {
  return _.some(card.groupChildren) || card.groupParent
}

export function extractTopParent(card) {
  return card.parent ? card.parent : card
}

export function getDriverLabel(card) {
  return formatNullableString(card.driver ? card.driver.id : null)
}

export function isCardFinished(card) {
  return card.finished
}

export function getCardIdLabel(card) {
  if (!card) return ''
  return `${card.function} - ${card.jobNumber}`
}

export function isDeclined(card) {
  return card?.milestone?.code === MilestonesEnum.DECLINED
}

export function canBeDeclined(card) {
  return (
    card &&
    !isInGroup(card) &&
    !isSubJobCard(card) &&
    card.stack?.name === ColumnsEnum.NEWJOBS
  )
}

export function companyToTextField(company) {
  if (!company) return ''
  return _.join(
    [company.name, _.join(company.address.street, '\n'), company.address.city],
    '\n'
  )
}

export function isInManifest(card) {
  return _.some(card.flights, flight => flight.manifest)
}

export function getAirwayBill(card) {
  const flightWithAWB = _.find(card.flights, flight => flight.airwayBill)
  return flightWithAWB?.airwayBill
}

export function compareCharges(a, b) {
  return a.type === b.type && parseFloat(a.amount) === parseFloat(b.amount)
}

// returns total number of cards including grouped cards
export const getTotalCount = cards =>
  _.sumBy(cards, c => _.size(c.groupChildren)) + _.size(cards)

// only takes into account reminder field (alarm set by user) and ignores badge1 label
export function hasUserAlarmFired(card) {
  return (
    card.reminder &&
    fromServerDate(card.reminder.iso).isSameOrBefore(moment(), 'minute')
  )
}

// takes into account both reminder field (alarm set by user) and badge1 label
export function hasAlarmFired(card) {
  if (card.reminder) return hasUserAlarmFired(card)
  return card.badge1 && card.badge1.label === BadgeEnum.ACTION
}

export const getCardFilterDate = card => {
  if (card.reminder) return card.reminder.iso
  if (card.createdDate) return card.createdDate.iso
  return null
}

export const getConsignee = (card, job) => {
  if (isSubJobCard(card)) return card.to || {}
  if (job) return job.consignee
  return {}
}

export const hasClosableAlarm = card => hasUserAlarmFired(card) && card.driver

export const needAcknowledgeInstructions = instructions =>
  instructions?.text && !instructions?.wasAcknowledged

export const getFlights = cards =>
  _.isArray(cards)
    ? _.compact(_.flatten(_.map(cards, c => c.flights)))
    : cards.flights

export function parseSubJobCompany(value) {
  const parts = _.split(value, '\n')
  let result = {
    address: {
      street: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      __typename: 'Address',
    },
  }

  if (parts.length > 0) {
    result.name = _.head(parts)
  }

  if (parts.length > 1) {
    result.address.street = _.initial(_.tail(parts))
  }

  if (parts.length > 2) {
    result.address.city = _.last(parts)
  }
  return result
}

export function updateSubjobCard(updatedValues) {
  return {
    id: updatedValues.id,
    pickupAmount: updatedValues.pickupAmount || 0,
    reminder: createReminderField(updatedValues.reminder.date),
    stack: {
      id: updatedValues.stack,
      __typename: 'Stack',
    },
    __typename: 'Card',
  }
}

export const isUnloaded = card =>
  card?.milestone?.code === MilestonesEnum.UNLOADED

export const isArchived = card => card?.archived

export const getPredictedDistance = card => {
  const { predictedDistance, function: cardFunction } = card

  if (cardFunction === CardFunctions.PICKUP) {
    return formatNullableString(predictedDistance?.pick)
  }

  if (cardFunction === CardFunctions.DELIVERY) {
    return formatNullableString(predictedDistance?.drop)
  }

  if (
    cardFunction === CardFunctions.TRANSFER ||
    cardFunction === CardFunctions.LOCAL
  ) {
    return formatNullableString(predictedDistance?.local)
  }
  return null
}

export const getSubjobNumber = cardNumber => _.last(cardNumber.split('.'))

export const getUnacknowledgedChanges = changes =>
  _.filter(changes, change => !change.acknowledgedBy)

export const getOfficeLocation = (card, location) => {
  if (!card) return ''

  if (location.dockLocation) {
    const dockLocation = _.find(
      card.station?.dropLocations,
      loc => loc.id === location.dockLocation
    )
    if (dockLocation) return dockLocation.name
    return location.dockLocation
  }

  return getStationVendorLabel(card.station)
}

export const canBeLoadedToDock = card =>
  card?.pickup?.type === CardDestinationsEnum.Office &&
  (card?.milestone?.code === MilestonesEnum.OFFERED ||
    card?.milestone?.code === MilestonesEnum.ACCEPTED)

export const canBeUnloadedToDock = card =>
  card?.drop?.type === CardDestinationsEnum.Office &&
  card?.milestone?.code === MilestonesEnum.LOADED
