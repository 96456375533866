import classNames from 'classnames'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { ReturnButton } from './ReturnButton'
import VerticalMenu from './VerticalMenu'
import { CardPopupState } from '../../../app/enums/CardPopupState'
import { SubJobTypeEnum } from '../../../app/enums/SubJobTypeEnum'
import { useCardContext } from '../../../hooks/useCardPopupContext'
import { useSelectedTheme } from '../../../hooks/useUserSettings'
import {
  getSubjobNumber,
  isInGroup,
  isSubJobCard,
} from '../../../utils/card-utils'
import Transition, { TransitionTypes } from '../../Transition'

const staticColumnAllowed = [
  CardPopupState.Overview,
  CardPopupState.Alarm,
  CardPopupState.DriverAssign,
]

const newSubjobSubmenu = [
  CardPopupState.CreateReIce,
  CardPopupState.CreatePurchaseItem,
]

const editIceMenu = [CardPopupState.ReIceEdit, CardPopupState.ReIceDriver]

const editPurchaseMenu = [
  CardPopupState.PurchaseItemEdit,
  CardPopupState.PurchaseDriver,
]

const cardGroupMenu = [CardPopupState.Alarm, CardPopupState.DriverAssign]

const getEditSubjobMenu = type =>
  type === SubJobTypeEnum.REICE ? editIceMenu : editPurchaseMenu

const getSubjobPage = type =>
  type === SubJobTypeEnum.REICE
    ? CardPopupState.ReIceEdit
    : CardPopupState.PurchaseItemEdit

const getSubjobLabel = (subjob, multiple, formatMessage) => {
  if (!multiple)
    return formatMessage({ id: 'card.subjob-' + subjob.subjobType })

  return `${formatMessage({
    id: 'card.subjob-' + subjob.subjobType,
  })} ${getSubjobNumber(subjob.id)}`
}

const getTopCardMenu = (card, formatMessage, readOnly) => {
  if (!card) return []

  if (isInGroup(card)) return cardGroupMenu

  if (!card.driver) return staticColumnAllowed

  if (readOnly) return [...staticColumnAllowed, CardPopupState.Resolution]

  const subjobTypes = _.groupBy(card.subjobs, 'subjobType')

  const subjobsItems = _.map(card.subjobs, subjob => {
    const hasMultiple = _.size(subjobTypes[subjob.subjobType]) > 1
    return {
      ...getSubjobPage(subjob.subjobType),
      card: subjob,
      Label: getSubjobLabel(subjob, hasMultiple, formatMessage),
    }
  })

  return [
    ...staticColumnAllowed,
    CardPopupState.SubJob,
    ...subjobsItems,
    CardPopupState.Resolution,
  ]
}

const getDefaultMenu = card =>
  isSubJobCard(card)
    ? MenuLevelsEnum.SubjobMenu
    : MenuLevelsEnum.OrdinaryCardMenu

const MenuLevelsEnum = {
  OrdinaryCardMenu: 0,
  NewSubjobMenu: 1,
  SubjobMenu: 2,
}

export const NavigationMenu = ({ parentCard: topCard }) => {
  const { formatMessage: f } = useIntl()
  const {
    currentCard: card,
    currentPage,
    changeView,
    changeCard,
    readOnly,
  } = useCardContext()
  const [theme] = useSelectedTheme()

  const [parentMenu, setParentMenu] = useState(
    getTopCardMenu(topCard, f, readOnly)
  )
  const [subjobMenu, setSubjobMenu] = useState(
    getEditSubjobMenu(card.subjobType)
  )
  const [menuLevel, setMenuLevel] = useState(getDefaultMenu(card))

  useEffect(() => {
    setParentMenu(getTopCardMenu(topCard, f, readOnly))
  }, [topCard, f, readOnly])

  useEffect(() => {
    if (_.some(staticColumnAllowed, page => page.Page === currentPage))
      setMenuLevel(MenuLevelsEnum.OrdinaryCardMenu)
  }, [currentPage])

  const goToNewSubjobMenu = menu => {
    setMenuLevel(MenuLevelsEnum.SubjobMenu)
    setSubjobMenu(menu)
    changeView(_.head(menu).Page)
    changeCard(topCard)
  }

  const onBackClicked = () => {
    setMenuLevel(MenuLevelsEnum.OrdinaryCardMenu)
  }

  const itemClicked = item => {
    if (isItemDisabled(item)) return

    switch (item.Page) {
      case CardPopupState.SubJob.Page:
        setMenuLevel(MenuLevelsEnum.NewSubjobMenu)
        break
      case CardPopupState.CreateReIce.Page:
        goToNewSubjobMenu(editIceMenu)
        break
      case CardPopupState.CreatePurchaseItem.Page:
        goToNewSubjobMenu(editPurchaseMenu)
        break
      default: {
        if (item.card) {
          setMenuLevel(MenuLevelsEnum.SubjobMenu)
          changeCard(item.card)
          setSubjobMenu(getEditSubjobMenu(item.card.subjobType))
        } else if (isSubJobCard(card) && _.includes(parentMenu, item)) {
          changeCard(topCard)
        }
        if (item.card?.id !== card.id) changeView(item.Page)
      }
    }
  }

  const isItemDisabled = item =>
    !isSubJobCard(card) &&
    (item === CardPopupState.ReIceDriver ||
      item === CardPopupState.PurchaseDriver)

  const isItemActive = item => {
    if (item.card) {
      return item.card.id === card.id
    }
    return item.Page === currentPage
  }

  const getItemCss = item => {
    if (item === CardPopupState.SubJob) return 'navigation-menu-subjob'
    if (item.card) return 'navigation-menu-level-2'
  }

  let sideMenuHeight
  if (menuLevel !== MenuLevelsEnum.OrdinaryCardMenu)
    sideMenuHeight = _.size(newSubjobSubmenu)
  else sideMenuHeight = _.size(parentMenu)

  const css = classNames(
    'navigation-menu-wrap',
    {
      'navigation-menu-wrap-offset':
        menuLevel === MenuLevelsEnum.OrdinaryCardMenu,
    },
    {
      'navigation-menu-wrap-negative-offset':
        menuLevel !== MenuLevelsEnum.OrdinaryCardMenu,
    },
    `navigation-menu-wrap-${sideMenuHeight}-items`
  )

  return (
    <>
      {menuLevel !== MenuLevelsEnum.OrdinaryCardMenu && (
        <ReturnButton
          onClick={onBackClicked}
          visible={menuLevel !== MenuLevelsEnum.OrdinaryCardMenu}
        />
      )}
      <div className={css} data-cy="card-popup-menu">
        <Transition
          type={TransitionTypes.FadeFromTop}
          opened={menuLevel === MenuLevelsEnum.OrdinaryCardMenu}
        >
          <VerticalMenu
            isActive={isItemActive}
            onItemClick={itemClicked}
            items={parentMenu}
            getItemCss={getItemCss}
            theme={theme}
          />
        </Transition>

        <Transition
          type={TransitionTypes.FadeFromTop}
          opened={menuLevel === MenuLevelsEnum.NewSubjobMenu}
        >
          <VerticalMenu
            isActive={isItemActive}
            onItemClick={itemClicked}
            items={newSubjobSubmenu}
            theme={theme}
          />
        </Transition>

        <Transition
          type={TransitionTypes.FadeFromTop}
          opened={menuLevel === MenuLevelsEnum.SubjobMenu}
        >
          <VerticalMenu
            isActive={isItemActive}
            onItemClick={itemClicked}
            isDisabled={isItemDisabled}
            items={subjobMenu}
            theme={theme}
          />
        </Transition>
      </div>
    </>
  )
}
