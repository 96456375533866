import classNames from 'classnames'

import { ThemesEnum } from '../app/enums/Themes'

export function getTransitionDuration(property) {
  return geIntValue(property)
}

export function geIntValue(property) {
  const cssValue = getComputedStyle(document.documentElement).getPropertyValue(
    property
  )
  return parseInt(cssValue, 10)
}

export function getStringValue(property) {
  return getComputedStyle(document.documentElement).getPropertyValue(property)
}

export function themedClass(className, theme, additionalClassNames) {
  const darkClass = `${className}-dark`
  return classNames(className, additionalClassNames, {
    [darkClass]: theme === ThemesEnum.DARK,
  })
}
