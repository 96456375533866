import classNames from 'classnames'
import _ from 'lodash'
import { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { isDarkTheme } from '../../app/enums/Themes'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { setHour } from '../../utils/date-utils'
import { scrollDown, scrollUp } from '../../utils/dom-utils'
import { padInteger } from '../../utils/string-utils'
import { themedClass } from '../../utils/style-utils'
import { CaretDown, CaretUp } from '../Icons'

const DaytimeEnum = {
  AM: 'AM',
  PM: 'PM',
}

const hours = _.range(0, 24)
const scrollStep = 600

const TimePickerSlider = ({ value, onChanged, theme }) => {
  const { getCurrentTime } = useLocalDateTime()
  const [daytime, setDaytime] = useState(DaytimeEnum.AM)

  const wrapperEl = useRef(null)

  const renderCell = val => {
    const css = themedClass('time-picker-slider-cell', theme, {
      'time-picker-slider-cell-selected': val === value?.hour(),
      'time-picker-slider-cell-dark': isDarkTheme(theme),
    })

    return (
      <p key={val} className={css} onClick={() => onBtnClick(val)}>
        {padInteger(val)}
      </p>
    )
  }

  const onUpClick = () => {
    scrollUp(wrapperEl.current, scrollStep)
    setDaytime(DaytimeEnum.AM)
  }

  const onDownClick = () => {
    scrollDown(wrapperEl.current, scrollStep)
    setDaytime(DaytimeEnum.PM)
  }

  const onBtnClick = val => {
    onChanged(setHour(value || getCurrentTime(), val))
  }

  const upBtnCss = themedClass(
    'time-picker-slider-btn',
    theme,
    'clickable-text'
  )
  const downBtnCss = classNames(upBtnCss, 'time-picker-slider-btn-down')

  return (
    <div className={themedClass('time-picker-slider', theme)}>
      <div className={themedClass('time-slider-header', theme)}>
        <FormattedMessage
          id="time-picker.hour-datetime"
          values={{ daytime: daytime }}
        />
      </div>
      <button className={upBtnCss} onClick={onUpClick}>
        <CaretUp className="time-picker-slider-btn-icon" />
      </button>
      <div className="time-slider-body" ref={wrapperEl}>
        {_.map(hours, renderCell)}
      </div>
      <button className={downBtnCss} onClick={onDownClick}>
        <CaretDown className="time-picker-slider-btn-icon" />
      </button>
    </div>
  )
}

export default TimePickerSlider
