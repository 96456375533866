import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { CardsGroupDetail } from './CardsGroupDetail'
import { SingleCardDetail } from './SingleCardDetail'
import { CardPopupState } from '../../app/enums/CardPopupState'
import { SubJobTypeEnum } from '../../app/enums/SubJobTypeEnum'
import { usePopupDetailQuery } from '../../hooks/graphQl/usePopupDetailQuery'
import { CardPopupContextProvider } from '../../hooks/useCardPopupContext'
import { DriversAssignContextProvider } from '../../hooks/useDriversAssignContext'
import { isInGroup, isSubJobCard } from '../../utils/card-utils'
import ScrollArea from '../ScrollArea'

function getDefaultPage(card) {
  if (isInGroup(card)) {
    return CardPopupState.Alarm.Page
  }

  if (isSubJobCard(card)) {
    switch (card.subjobType) {
      case SubJobTypeEnum.REICE: {
        return CardPopupState.ReIceEdit.Page
      }
      case SubJobTypeEnum.PURCHASE: {
        return CardPopupState.PurchaseItemEdit.Page
      }
      default: {
        return CardPopupState.ReIce.Page
      }
    }
  }

  return CardPopupState.Overview.Page
}

const CardDetailPopup = props => {
  const { cardData, ...rest } = props
  const [currentCard, setCurrentCard] = useState(cardData)

  const [card, jobDetail, loading, error] = usePopupDetailQuery(currentCard)

  useEffect(() => {
    setCurrentCard(cardData)
  }, [cardData])

  const scrollBarsRef = useRef(null)

  const onCardChanged = card => {
    setCurrentCard(card)
  }

  const contentProps = {
    ...rest,
    cardData: card,
    loading: loading,
    error: error,
    wrap: scrollBarsRef.current?.view,
  }
  let content = null

  if (card) {
    if (isInGroup(card)) {
      content = <CardsGroupDetail {...contentProps} />
    } else {
      content = <SingleCardDetail {...contentProps} />
    }
  }

  const wrapCss = classNames('paralax-scroll', {
    'paralax-scroll-hidden': !cardData,
  })

  return ReactDOM.createPortal(
    <ScrollArea ref={scrollBarsRef} wrapClassName={wrapCss}>
      {card && (
        <CardPopupContextProvider
          startPage={getDefaultPage(card)}
          currentCard={card}
          currentJob={jobDetail}
          onCardChanged={onCardChanged}
        >
          <DriversAssignContextProvider>{content}</DriversAssignContextProvider>
        </CardPopupContextProvider>
      )}
    </ScrollArea>,
    document.body
  )
}

export default CardDetailPopup
