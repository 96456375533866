import Gauge, { GaugeAxisAngle } from './Gauge'
import { SensorPanel } from './SensorPanel'

const TiltSensor = ({ value, lastReported }) => (
  <SensorPanel
    headerMessageId="sensors.orientation"
    lastReported={lastReported}
  >
    <Gauge
      value={value}
      minValue={0}
      maxValue={180}
      formatValue={val => <div>{val}°</div>}
      angleType={GaugeAxisAngle.HALF_CIRCLE}
    />
  </SensorPanel>
)

export default TiltSensor
