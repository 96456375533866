import { Popover } from 'antd'

import { WarningAlertFloating } from './Alerts'

export const HintTooltip = ({
  children,
  tooltip,
  theme,
  arrow,
  size,
  placement,
}) => {
  const css = arrow ? 'tooltip-overlay' : 'tooltip-overlay-arrowless'
  return (
    <Popover
      overlayClassName={css}
      placement={placement}
      content={
        <WarningAlertFloating size={size} theme={theme}>
          {tooltip}
        </WarningAlertFloating>
      }
    >
      {children}
    </Popover>
  )
}
