import _ from 'lodash'

export const AddressArray = ({ address, fixedRowsCount, rowCss }) => (
  <>
    {address && (
      <>
        <p className={rowCss}>{_.head(address)}</p>
        {_.size(address) > 1 && <p className={rowCss}>{address[1]}</p>}
        {_.size(address) > 2 && (
          <p className={rowCss}>{_.join(_.drop(address, 2), ', ')}</p>
        )}
      </>
    )}
    {fixedRowsCount && _.size(address) <= 2 && <p className={rowCss} />}
  </>
)
