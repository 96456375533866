import _ from 'lodash'
import { useEffect, useState } from 'react'

import { DispatchPageConstants } from '../../app/constants'
import { orderByKeysOrder } from '../../utils/array-utils'
import { getCardDate } from '../../utils/common-utils'
import { emptyObj } from '../../utils/perf-utils'
import {
  buildStacksOrderMap,
  groupByAlarm,
  groupCardsByStack,
} from '../../utils/stacks-utils'
import { useActiveCardsList } from '../graphQl/useActiveCardsList'
import useStacksList from '../graphQl/useStacksQuery'
import { useLocalDateTime } from '../useLocalDateTime'

function filterByDate(cards, selectedHours, fromServerValue) {
  if (_.isEmpty(selectedHours)) return cards

  return _.filter(cards, c =>
    _.some(selectedHours, i => i.id === fromServerValue(getCardDate(c)).hour())
  )
}

export function useFilterDispatchPageCards(
  selectedHours,
  selectedStatuses,
  stack
) {
  const { fromServerValue } = useLocalDateTime()
  const [cardsByStack, setCardsByStack] = useState(emptyObj)
  const { stacksData: stacks } = useStacksList()
  const { cardsData: cards } = useActiveCardsList()

  useEffect(() => {
    if (!stack) return {}

    const grouped = groupByAlarm(cards)
    let orderedAlarms = grouped.alarmed
    let orderedUpdates = grouped.changed

    let nonAlarmedFiltered = filterByDate(
      grouped.nonAlarmed,
      selectedHours,
      fromServerValue
    )

    if (_.includes(selectedStatuses, DispatchPageConstants.unassignedItemId)) {
      nonAlarmedFiltered = _.filter(nonAlarmedFiltered, x => !x.driver)
      orderedAlarms = _.filter(orderedAlarms, x => !x.driver)
      orderedUpdates = _.filter(orderedUpdates, x => !x.driver)
    }

    const stackGrouped = groupCardsByStack(nonAlarmedFiltered)
    const stacksOrder = buildStacksOrderMap(stacks)

    setCardsByStack({
      ...stackGrouped,
      [stack.id]: {
        alarmed: orderByKeysOrder(orderedAlarms, stacksOrder),
        changed: orderByKeysOrder(orderedUpdates, stacksOrder),
        nonAlarmed: stackGrouped[stack.id]?.nonAlarmed || [],
      },
    })
  }, [cards, selectedHours, fromServerValue, selectedStatuses, stack, stacks])

  return cardsByStack
}
