import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { formatNullableString } from '../../utils/string-utils'
import { ContentPanel } from '../ContentPanel'
import CopyJobNumber from '../CopyJobNumber'
import { BadgeLarge } from '../JobBadges'
import {
  HeaderSizes,
  JobDetailHeader,
  JobHeaderLabel,
} from '../ModalsTypography'

const Section = ({ labelId, children, className }) => (
  <div className={classNames('job-number-panel-section', className)}>
    <JobHeaderLabel>
      <FormattedMessage id={labelId} />
    </JobHeaderLabel>
    <JobDetailHeader size={HeaderSizes.Medium}>{children}</JobDetailHeader>
  </div>
)

export const JobNumberPanel = props => {
  const { card, children, ...rest } = props
  const cardData = card || {}
  const { service, controlStation } = cardData
  return (
    <PanelWithJobNumber {...rest} card={cardData}>
      <Section labelId="card-dispatch.service-type-short">
        {formatNullableString(service)}
      </Section>
      <Section labelId="job.control-tower" className="visible-small">
        {formatNullableString(controlStation?.abbreviation)}
      </Section>
      <Section labelId="job.control-tower-long" className="hidden-small">
        {formatNullableString(controlStation?.abbreviation)}
      </Section>
      {children}
    </PanelWithJobNumber>
  )
}

const PanelWithJobNumber = ({
  children,
  darkShadow,
  className,
  card,
  ...rest
}) => {
  const { jobNumber, function: jobFunction } = card
  const css = classNames(className, 'job-number-panel')
  return (
    <ContentPanel darkShadow={darkShadow} className={css} {...rest}>
      <Section labelId="shipment-info.job-number">
        <BadgeLarge>{formatNullableString(jobFunction)}</BadgeLarge>
        {formatNullableString(jobNumber)}
        <CopyJobNumber jobNumber={jobNumber} withMargins theme={rest.theme} />
      </Section>
      {children}
    </ContentPanel>
  )
}
