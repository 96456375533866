import _ from 'lodash'

export const arrayToObject = (arr, keyField, valueField) =>
  arr.reduce((obj, item) => {
    obj[item[keyField]] = valueField ? item[valueField] : item
    return obj
  }, {})

export const objectToArray = (obj, keyField = 'key') =>
  Object.keys(obj).map(k => ({ [keyField]: k, ...obj[k] }))

export const createToggleFunc =
  (valuesArray, setterFunc, selector) => valueToToggle => {
    if (selector) {
      setterFunc(
        _.xorWith(
          valuesArray,
          [valueToToggle],
          (a, b) => selector(a) === selector(b)
        )
      )
    } else {
      setterFunc(_.xor(valuesArray, [valueToToggle]))
    }
  }

export const mergeArrays = (array1, array2, compareFunc) => {
  if (!array1) return array2
  const arrayCopy = [...array2]
  _.forEach(array1, el => {
    _.pull(
      arrayCopy,
      _.find(arrayCopy, item => compareFunc(item, el))
    )
  })
  return [...array1, ...arrayCopy]
}

// updates values from second array, while keeping order of first array
export const stableUnion = (firstArray, secondArray) =>
  _.map(firstArray, card => {
    const updatedCard = secondArray.find(d => d.id === card.id)
    return updatedCard || card
  })

// return array without first and last element
export const middleElements = arr => _.tail(_.initial(arr))

export const secondLastElement = arr => _.last(_.initial(arr))

export const joinStrings = (...args) => _.join(_.compact(args), ', ')

export const orderByKeysOrder = (cards, orderMap) =>
  _.sortBy(cards, c => orderMap[c.stack.id]?.index)
