import TextArea from 'antd/lib/input/TextArea'
import { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { themedClass } from '../../utils/style-utils'

export const TextInputMultiline = forwardRef(
  ({ placeholder, labelId, value, onChange, theme, ...rest }, ref) => {
    const wrapperCss = themedClass('text-area-wrapper', theme)
    return (
      <div className={wrapperCss}>
        {labelId && (
          <label>
            <FormattedMessage id={labelId} />
          </label>
        )}
        <TextArea
          ref={ref}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...rest}
        />
      </div>
    )
  }
)
