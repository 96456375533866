import { FormattedMessage, useIntl } from 'react-intl'

import ConfirmModalBase from './ConfirmModalBase'
import { getStationVendorLabel } from '../../utils/common-utils'
import { WarningIcon } from '../Icons'

const StationAcknowledge = ({ station, onOk }) => {
  const { formatMessage: f } = useIntl()

  return (
    <ConfirmModalBase
      className="station-acknowledge"
      width={360}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText={f({ id: 'changelog.acknowledge' })}
      title={<WarningIcon className="opaque-icon text-warning" />}
      maskClosable={false}
      afterClose={onOk}
    >
      <div>
        <h1>Attention</h1>
        <h2 className="text-uppercase">
          <FormattedMessage
            id="stations.changed"
            values={{ station: getStationVendorLabel(station) }}
          />
        </h2>
      </div>
    </ConfirmModalBase>
  )
}

export default StationAcknowledge
