import { gql } from '@apollo/client'

//#region fragments
export const LOCATION_DETAIL = gql`
  fragment locationDetail on Location {
    id
    name
    address {
      street
      city
      state
      country
      zip
    }
  }
`
//#endregion

//#region queries
export const GET_LOCATIONS = gql`
  query getLocations($filter: LocationsFilter!) {
    listLocations(filter: $filter) {
      ...locationDetail
    }
  }
  ${LOCATION_DETAIL}
`
//#endregion

//#region subscriptions
export const LOCATION_SUBSCRIPTION = gql`
  subscription locationSubscription {
    locationUpdated {
      operation
      changeData {
        ...locationDetail
      }
    }
  }
  ${LOCATION_DETAIL}
`
//#endregion
