import { gql } from '@apollo/client'
import _ from 'lodash'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { WarningAlertFloating } from './Alerts'
import SwitchableFeature from './SwitchableFeature'
import { Button } from './form/Buttons'
import { FeaturesEnum } from '../app/enums/FeaturesEnum'
import { MUTATION_RESPONSE } from '../app/graphQl/schema/Common'
import { useNonOptimisticMutation } from '../hooks/graphQl/useNonOptimisticMutation'
import { useCurrentStation } from '../hooks/useCurrentStation'
import { useSelectedTheme } from '../hooks/useUserSettings'
import { themedClass } from '../utils/style-utils'

export const CREATE_TEST_CARD = gql`
  mutation CreateTestCard($stationId: ID!) {
    createTestCard(stationId: $stationId) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`

const NewCardButton = () => {
  const { stationId } = useCurrentStation()
  const [theme] = useSelectedTheme()

  const [createTestCard] = useNonOptimisticMutation(
    CREATE_TEST_CARD,
    'createTestCard'
  )
  const [cardsOnFly, setCardsOnFly] = useState(0)

  const onClick = useCallback(() => {
    const mutationPromise = createTestCard({
      variables: {
        stationId: stationId,
      },
    }).then(() => {
      setCardsOnFly(current => current - 1)
    })

    if (mutationPromise) {
      setCardsOnFly(current => current + 1)
    }
  }, [createTestCard, stationId])

  return (
    <div className={themedClass('new-job-wrapper', theme)}>
      {_.range(0, cardsOnFly).map(number => (
        <WarningAlertFloating size="small" key={number} theme={theme}>
          <i className="fas fa-cog" />{' '}
          <FormattedMessage id="test-card.in-progress" />
        </WarningAlertFloating>
      ))}
      <Button onClick={onClick}>
        <i className="fas fa-plus-square" />
        <FormattedMessage id="test-card.create" tagName="span" />
      </Button>
    </div>
  )
}

const SwitchableNewCardButton = () => (
  <SwitchableFeature configKey={FeaturesEnum.ALLOW_TEST_FEATURES}>
    <NewCardButton />
  </SwitchableFeature>
)
export default SwitchableNewCardButton
