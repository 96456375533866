import classNames from 'classnames'

import { AssignedDriverFormatted } from './AssignedDriverFormatted'

const AssignedDriver = ({ card, className }) => {
  if (!card.driver) return null
  const topCss = classNames(className, 'driver-info')
  return (
    <div className={topCss}>
      <AssignedDriverFormatted driver={card?.driver} />
    </div>
  )
}

export default AssignedDriver
