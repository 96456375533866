import { DistanceUnit, DistanceUnits } from '../user-units/DistanceUnits'

const DistanceBadge = ({ distance, unit }) => (
  <div className="card-status-box card-text-highlight distance-badge">
    <DistanceUnit
      unit={unit}
      value={distance}
      sourceUnit={DistanceUnits.Miles}
    />
  </div>
)

export default DistanceBadge
