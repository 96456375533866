import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import _ from 'lodash'
import { memo, useCallback, useState } from 'react'

import { JobCardsDispatchColumn } from './JobCardsDispatchColumn'
import MainHeader from './MainHeader'
import { MapStyles } from '../app/constants'
import { GET_JOB_OVERVIEW } from '../app/graphQl/schema/Jobs'
import CardDropPlace from '../components/CardDropPlace'
import { ContentPanel } from '../components/ContentPanel'
import DispatchAlertButtons from '../components/DispatchAlertButtons'
import DriverAssigmentModule from '../components/DriverAssigmentModule'
import LoadingIndicator from '../components/LoadingIndicators'
import RequestedVehicleType from '../components/RequestedVehicleType'
import AssignedDriverBox from '../components/drivers/AssignedDriverBox'
import DriversList from '../components/drivers/DriversList'
import JobDetailBox from '../components/job-detail/JobDetailBox'
import CardAlarmModal from '../components/modals/CardAlarmModal'
import { CardUpdateNotification } from '../components/modals/CardUpdateNotification'
import { useGetCard } from '../hooks/graphQl/useGetCard'
import useStacksList from '../hooks/graphQl/useStacksQuery'
import { useDriverAssignContext } from '../hooks/useDriversAssignContext'
import useMultipleSelection from '../hooks/useMultipleSelection'
import { useSelectedTheme } from '../hooks/useUserSettings'
import { getUnacknowledgedChanges } from '../utils/card-utils'
import { findDecideStack } from '../utils/stacks-utils'
import { themedClass } from '../utils/style-utils'

const possibleHours = _.map(_.range(0, 24), r => ({
  id: r,
}))

const DispatchPage = ({ openPopupCard, isLoading, hasError, mapStyle }) => {
  const [theme] = useSelectedTheme()
  const [cardsSelection, setCardsSelection] = useState({
    selectedCards: [],
    selectedParent: null,
  })
  const selectedCard = _.head(cardsSelection.selectedCards)
  const [alarmUpdate, setAlarmUpdate] = useState()
  const { selectedItems: selectedHours, actions } =
    useMultipleSelection(possibleHours)

  const { card: cardDetail = selectedCard } = useGetCard(selectedCard?.id)

  const { data: { getJobDetail: jobDetail } = { getJobDetail: null }, error } =
    useQuery(GET_JOB_OVERVIEW, {
      skip: !cardDetail,
      variables: { itemId: cardDetail?.jobNumber },
    })
  const { stacksData: listStacks } = useStacksList()

  const setAlarm = useCallback((dateTime, card) => {
    setAlarmUpdate({ card, dateTime })
  }, [])

  const mainContentCss = classNames('main-content', 'dispatch-page', {
    'map-expanded': mapStyle === MapStyles.Maximized,
  })

  const alarmWindowClosed = () => {
    setAlarmUpdate(null)
  }

  const onButtonClick = (value, event) => {
    if (event.shiftKey) {
      actions.selectUntil(value)
      return
    }

    if (event.ctrlKey || event.metaKey) {
      actions.toggleItem(value)
      return
    }

    if (_.size(selectedHours) === 1 && selectedHours[0].id === value.id) {
      actions.deselectAll()
    } else {
      actions.selectExclusively([value])
    }
  }

  const selectCard = useCallback((card, parent) => {
    if (!card) {
      setCardsSelection({ selectedCards: [] })
      return
    }
    setCardsSelection({
      selectedCards: [card],
      selectedParent: parent,
    })
  }, [])

  const dispatchButtonsRow = themedClass('dispatch-buttons-row', theme)

  const unackChanges = getUnacknowledgedChanges(cardDetail?.trackedChanges)

  return (
    <>
      <CardAlarmModal
        visible={!!alarmUpdate}
        dateTime={alarmUpdate && alarmUpdate.dateTime}
        onClosed={alarmWindowClosed}
        card={alarmUpdate ? alarmUpdate.card : null}
        headerMsg="alarm-settings.confirm-alarm"
      />

      {_.some(unackChanges) && (
        <CardUpdateNotification card={cardDetail} onIgnore={selectCard} />
      )}

      {mapStyle !== MapStyles.Maximized && (
        <MainHeader onCardOpen={openPopupCard} />
      )}
      {mapStyle !== MapStyles.Maximized && (
        <div className={dispatchButtonsRow}>
          <CardDropPlace
            stack={findDecideStack(listStacks)}
            labelId="drop-place.decide-column"
          />
          <DispatchAlertButtons
            possibleValues={possibleHours}
            selectedValues={selectedHours}
            onAlarmSet={setAlarm}
            onButtonClick={onButtonClick}
            theme={theme}
          />
        </div>
      )}

      <LoadingIndicator
        loading={isLoading}
        error={(hasError || error) && 'errors.loading-fail'}
        className={mainContentCss}
      >
        <JobCardsDispatchColumn
          theme={theme}
          cardDetail={cardDetail}
          cardsSelection={cardsSelection}
          selectCard={selectCard}
          selectedHours={selectedHours}
          hidden={mapStyle === MapStyles.Maximized}
        />
        <div className="flexbox-multiline dispatch-layout-column">
          <ContentPanel
            theme={theme}
            darkShadow
            className="drivers-list-panel drivers-list-panel-dispatch-page"
          >
            <RequestedVehicleType card={cardDetail} />
          </ContentPanel>

          <DriversList
            onDriverJobClick={openPopupCard}
            currentCard={cardDetail}
          />
        </div>

        <DriverAssigmentModule cardDetail={cardDetail} theme={theme}>
          {mapStyle === MapStyles.Default && (
            <>
              <AssignedDriverBox card={cardDetail} theme={theme} />
              <ContentPanel
                className={themedClass('job-overview-horizontal', theme)}
                theme={theme}
              >
                <JobDetailBox
                  specialInstructions
                  cardData={cardDetail}
                  jobDetail={jobDetail}
                  hidden={mapStyle !== MapStyles.Default}
                />
              </ContentPanel>
            </>
          )}
        </DriverAssigmentModule>
      </LoadingIndicator>
    </>
  )
}
const MemoizedDispatchPage = memo(DispatchPage, _.isEqual)

const DispatchPageWithMapStyle = props => {
  const { mapStyle } = useDriverAssignContext()
  return (
    <MemoizedDispatchPage {...props} mapStyle={mapStyle}></MemoizedDispatchPage>
  )
}

export default DispatchPageWithMapStyle
