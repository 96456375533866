import { Collapse } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { forwardRef, useContext, useState } from 'react'

import { DriversColumnPanel } from './DriversColumnPanel'
import { VehicleTypesEnum } from '../../app/enums/VehicleTypes'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { createToggleFunc } from '../../utils/array-utils'
import { themedClass } from '../../utils/style-utils'
import CardsColumnInner from '../CardsColumnInner'
import { DriversContext } from '../DriverAssigmentModule'
import { ColumnDividerString } from '../cards-column/CardsColumnDivider'

const VehiclesPanels = [
  {
    key: '0',
    vehicleTypes: [
      VehicleTypesEnum.Car,
      VehicleTypesEnum.Suv,
      VehicleTypesEnum.Minivan,
      VehicleTypesEnum.Pickup,
    ],
  },
  {
    key: '1',
    vehicleTypes: [
      VehicleTypesEnum.Cargo,
      VehicleTypesEnum.Sprinter,
      VehicleTypesEnum.LargeCargo,
    ],
  },
]

const DriversColumn = (
  { topLabel, drivers, estimatedArrivals, className, scrollArea, dividerValue },
  ref
) => {
  const [theme] = useSelectedTheme()
  const { onDriversDeselected, onDriversSelected } = useContext(DriversContext)
  const [expandedPanels, setExpandedPanels] = useState(['0', '1'])

  const toggleExpandedPanel = createToggleFunc(
    expandedPanels,
    setExpandedPanels
  )

  const onSelectAllChanged = ({ target }, driversList) => {
    if (target.checked) {
      onDriversSelected(driversList)
    } else {
      onDriversDeselected(driversList)
    }
  }

  const renderVehiclesPanel = panel => {
    const panelDrivers = drivers.filter(d =>
      panel.vehicleTypes.find(type => type.id === d.vehicle.type)
    )

    return (
      <DriversColumnPanel
        key={panel.key}
        panelKey={panel.key}
        drivers={panelDrivers}
        vehicleTypes={panel.vehicleTypes}
        onToggle={toggleExpandedPanel}
        onSelectAllChanged={onSelectAllChanged}
        expanded={_.includes(expandedPanels, panel.key)}
        theme={theme}
      />
    )
  }

  const css = themedClass(
    'cards-column',
    theme,
    classNames('cards-column-no-shadow', className)
  )

  return (
    <>
      {dividerValue && <ColumnDividerString label={dividerValue} />}

      <div className={css} ref={ref} data-testid="active-drivers">
        {topLabel && (
          <div>
            <p className="cards-column-label">{topLabel}</p>
          </div>
        )}

        <CardsColumnInner scrollable={scrollArea} theme={theme}>
          <Collapse activeKey={expandedPanels}>
            {VehiclesPanels.map(renderVehiclesPanel)}
          </Collapse>
        </CardsColumnInner>
      </div>
    </>
  )
}

export default forwardRef(DriversColumn)
