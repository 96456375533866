import { saveAs } from 'file-saver'
import { map } from 'lodash'
import _ from 'lodash'
import { useState } from 'react'

import Sitemap from '../../app/sitemap'
import { useAuthentication } from '../../hooks/useAuthentication'
import {
  NotificationsTypeEnum,
  useNotificationsContext,
} from '../../hooks/useNotificationsContext'
import { doAuthorizedRequest } from '../../utils/rest-utils'
import ScrollArea from '../ScrollArea'
import { Button } from '../form/Buttons'

const DocumentCenter = ({ jobNumber, documents }) => {
  const [currentUser] = useAuthentication()
  const { addNotification } = useNotificationsContext()
  const [pendingDownloads, setPendingDownloads] = useState([])

  const onDocumentClick = async (e, doc) => {
    e.stopPropagation()
    e.preventDefault()

    setPendingDownloads(current => [...current, doc.id])
    const response = await doAuthorizedRequest(
      currentUser,
      Sitemap.createDocumentFileLink(jobNumber, doc.id, {
        docType: doc.documentType,
      })
    ).catch(error => ({ error }))

    if (response.ok && !response.error) {
      const data = await response.blob()
      saveAs(data, response.headers.get('X-Filename'))
    } else {
      const errorMsg = response?.json && (await response?.json())
      addNotification({
        titleId: 'documents-center.download-failed',
        messageId: errorMsg?.error,
        type: NotificationsTypeEnum.ERROR,
      })
    }
    setPendingDownloads(current => _.filter(current, c => c !== doc.id))
  }

  return (
    <ScrollArea
      className="document-center"
      wrapClassName="documents-scrollarea"
    >
      {map(documents, doc => (
        <Button
          type="link"
          key={doc.id}
          className="documents-item"
          onClick={e => onDocumentClick(e, doc)}
          disabled={pendingDownloads.includes(doc.id)}
        >
          {doc.documentTypeDesc}
        </Button>
      ))}
    </ScrollArea>
  )
}

export default DocumentCenter
