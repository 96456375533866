import _ from 'lodash'
import { cloneElement, useContext } from 'react'

import { useDragInfoContext } from '../../hooks/dragAndDrop/useDragInfoContext'
import { isHoveredDispatchMap } from '../../utils/drag-utils'
import { DriversContext } from '../DriverAssigmentModule'

const MultipleDriversPreview = ({ children }) => {
  const { selectedDrivers } = useContext(DriversContext)
  const [itemHovered] = useDragInfoContext()

  const selectedDriversCount = _.size(selectedDrivers)
  const newProps = {
    isSelected: false,
  }

  if (isHoveredDispatchMap(itemHovered)) {
    newProps.countBadge = selectedDriversCount > 1 ? selectedDriversCount : 0
  }

  return cloneElement(children, newProps)
}

export default MultipleDriversPreview
