import { useEffect, useState } from 'react'

import { getCurrentUser } from '../../hooks/useAuthentication'
import { doAuthorizedRequest } from '../../utils/rest-utils'
import BorderOutset from '../BorderOutset'
import { ShortMonthYearFormat } from '../DateTimeFormats'
import LoadingIndicator from '../LoadingIndicators'
import ScrollArea from '../ScrollArea'

const url = '/api/changelog'

const ChangelogContent = ({ theme }) => {
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState()

  useEffect(() => {
    if (!content) {
      const user = getCurrentUser()
      setLoading(true)

      doAuthorizedRequest(user, url)
        .then(response => response.json())
        .then(json => {
          setContent(json)
          setLoading(false)
        })
    }
  }, [content])

  return (
    <>
      <BorderOutset
        className="changelog-version-header text-uppercase"
        theme={theme}
      >
        {content?.title} (
        <ShortMonthYearFormat date={content?.history?.createdDate} />)
      </BorderOutset>
      <LoadingIndicator className="changelog" loading={loading} theme={theme}>
        <ScrollArea>
          <div
            style={{ padding: '0 40px' }}
            dangerouslySetInnerHTML={{ __html: content?.body?.view?.value }}
          />
        </ScrollArea>
      </LoadingIndicator>
    </>
  )
}

export default ChangelogContent
