import { FormattedMessage } from 'react-intl'

import { useSelectedTheme } from '../../../hooks/useUserSettings'
import { HeaderSizes, JobDetailHeader } from '../../ModalsTypography'
import { Button } from '../../form/Buttons'

const EditButtons = ({
  onCancelClick,
  readOnly,
  editing,
  onConfirm,
  confirmBtnProps,
  cancelBtnProps,
  onModify,
  theme,
  cancelMsg = 'cancel',
  confirmMsg = 'confirm.set',
  modifyMsg = 'confirm.modify',
  canCancel,
}) => {
  const commonBtnProps = {
    size: 'large',
    type: 'primary',
    'data-cy': 'popup-toolbar-confirm-btn',
    disabled: readOnly,
    theme: theme,
  }

  return (
    <div>
      <Button
        ghost
        size="large"
        onClick={onCancelClick}
        data-cy="popup-toolbar-cancel-btn"
        disabled={!canCancel}
        theme={theme}
        {...cancelBtnProps}
      >
        <FormattedMessage id={cancelMsg} />
      </Button>
      {editing ? (
        <Button {...commonBtnProps} {...confirmBtnProps} onClick={onConfirm}>
          <FormattedMessage id={confirmMsg} />
        </Button>
      ) : (
        <Button {...commonBtnProps} onClick={onModify}>
          <FormattedMessage id={modifyMsg} />
        </Button>
      )}
    </div>
  )
}

export const ModifyHeader = ({
  onCancel,
  messageId,
  messageParams,
  secondaryMessageId,
  children,
  noButtons,
  ...rest
}) => {
  const [theme] = useSelectedTheme()

  const onCancelClick = () => {
    onCancel && onCancel()
  }

  return (
    <>
      <div className="toolbar-content">
        <JobDetailHeader size={HeaderSizes.Large}>
          <FormattedMessage id={messageId} values={messageParams} />
          {secondaryMessageId && (
            <>
              {' | '}
              <span className="text-important text-uppercase">
                <FormattedMessage id={secondaryMessageId} />
              </span>
            </>
          )}
          {children}
        </JobDetailHeader>
        {!noButtons && (
          <EditButtons {...rest} theme={theme} onCancelClick={onCancelClick} />
        )}
      </div>
    </>
  )
}
