import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input } from 'antd'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { DotsLoader } from './LoadingIndicators'
import { Button } from './form/Buttons'
import { ColumnsEnum } from '../app/constants'
import { updateStacksList } from '../app/graphQl/cacheManager'
import { CREATE_STACK, GET_STACKS } from '../app/graphQl/schema/Stacks'
import { useNonOptimisticMutation } from '../hooks/graphQl/useNonOptimisticMutation'
import { useCurrentStation } from '../hooks/useCurrentStation'
import { useStacks } from '../hooks/useStacks'
import { getRequiredFieldRules } from '../utils/common-utils'

function NewStackFormImpl({ onSubmit, inputRef, onReset, inProgress, form }) {
  const { formatMessage: f } = useIntl()
  const { stackExists } = useStacks()
  const { getFieldDecorator, validateFields } = form

  useEffect(() => {
    validateFields()
  }, [validateFields])

  const reset = () => {
    onReset()
  }

  const onFormSubmit = e => {
    e?.preventDefault()

    if (inProgress) return

    form.validateFields().then(x => {
      onSubmit(x)
    })
  }

  return (
    <Form data-testid={'monitor-stack-form'} onSubmit={onFormSubmit}>
      <Form.Item>
        {getFieldDecorator('stackName', {
          validateFirst: true,
          rules: getRequiredFieldRules(f, [
            {
              validator: (_rule, value, cb) => {
                if (_.startsWith(_.toUpper(value), ColumnsEnum.ONDOCK))
                  cb(f({ id: 'card-columns.new-column-invalid' }))
                else cb()
              },
            },
            {
              validator: (_rule, value, cb) => {
                if (stackExists(value))
                  cb(f({ id: 'card-columns.new-column-existing' }))
                else cb()
              },
            },
          ]),
        })(
          <Input
            size="large"
            placeholder={f({ id: 'card-columns.new-column-placeholder' })}
            ref={inputRef}
          />
        )}
      </Form.Item>
      <Button htmlType="reset" onClick={reset} ghost>
        <FormattedMessage id="cancel" />
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        disabled={_.some(form?.getFieldsError())}
      >
        <FormattedMessage id="save" />
        {inProgress && <DotsLoader />}
      </Button>
    </Form>
  )
}

const NewColumn = ({ onNewColumnToggled }) => {
  const { stationId } = useCurrentStation()
  const [opened, setOpened] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const inputRef = useRef(null)
  const [createStack] = useNonOptimisticMutation(CREATE_STACK, 'createStack', {
    onCompleted: () => reset(),
  })

  useEffect(() => {
    if (opened && inputRef.current) inputRef.current.focus()
  }, [opened])

  const onNewColumnClicked = () => {
    setOpened(true)
    onNewColumnToggled(true)
  }

  const reset = () => {
    setOpened(false)
    onNewColumnToggled(false)
    setInProgress(false)
  }

  const onSubmit = ({ stackName }) => {
    const nameVariable = stackName.trim()
    if (nameVariable.length === 0) return

    setInProgress(true)

    createStack({
      variables: { name: nameVariable, stationId: stationId },
      cacheUpdate: (cache, { createStack }) => {
        const { listStacks } = cache.readQuery({
          query: GET_STACKS,
          variables: { stationId },
        })
        updateStacksList(
          cache,
          _.unionBy([createStack], listStacks, 'id'),
          stationId
        )
      },
    })
  }

  if (!opened) {
    return (
      <div>
        <i
          className="fas fa-plus-circle add-column-btn"
          onClick={onNewColumnClicked}
        />
      </div>
    )
  }
  return (
    <div className="cards-new-column">
      <NewColumnForm
        onSubmit={onSubmit}
        inputRef={inputRef}
        onReset={reset}
        inProgress={inProgress}
      />
    </div>
  )
}

export default NewColumn

const NewColumnForm = Form.create({})(NewStackFormImpl)
