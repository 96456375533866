import _ from 'lodash'

import { isSubJobCard } from './card-utils'
import { MISSING_VALUE } from './string-utils'
import { ColumnsEnum } from '../app/constants'

export function canBeCardDropped(card, stackToDropTo) {
  const currentStack = (card.currentStack || {}).name

  if (currentStack === stackToDropTo) return false

  if (currentStack === ColumnsEnum.DECIDE) return false

  if (!currentStack || currentStack === ColumnsEnum.NEWJOBS)
    return stackToDropTo === ColumnsEnum.DECIDE

  return [ColumnsEnum.NEWJOBS, ColumnsEnum.DECIDE].indexOf(stackToDropTo) < 0
}

export function orderStacksForDropdown(stacks) {
  const fixedIndices = [
    ColumnsEnum.NEWJOBS,
    ColumnsEnum.DECIDE,
    ColumnsEnum.PICK,
    ColumnsEnum.DELIVERY,
    ColumnsEnum.TRANSFER,
    ColumnsEnum.ONDOCK,
  ]

  return _.sortBy(stacks, function (item) {
    const index = fixedIndices.indexOf(item.name)
    return index > -1 ? index : item.order + fixedIndices.length
  })
}

export function formatFlightNumber(flight, separator = '') {
  if (!flight?.airline) return ''

  return `${flight.airline.slice(0, 2)}${separator}${flight.flightNumber}`
}

export const getCardDate = card => {
  if (card.reminder) return card.reminder.iso
  if (card.badge1) return card.badge1.iso
  return null
}

export const getStationVendorLabel = station =>
  station ? `Q${station.defaultVendor.substring(0, 3)}` : MISSING_VALUE

// filter subjobs from newCards and add them to correct parent `subjobs` field
// returns array updated cards (does not mutate original array)
export const updateCardsSubjobs = (allCards, newCards, removedCards = []) => {
  const updatedCards = []
  const newParents = _.groupBy(
    _.filter(newCards, isSubJobCard),
    d => d.parent.id
  )
  const removedCardsParents = _.groupBy(
    _.filter(removedCards, isSubJobCard),
    d => d.parent.id
  )

  const allUpdated = [..._.keys(newParents), ..._.keys(removedCardsParents)]
  _.forEach(allUpdated, k => {
    const parentCard = _.find(allCards, c => c.id === k)
    if (!parentCard) return

    const newSubjobs = _.unionBy(newParents[k], parentCard.subjobs, 'id')
    updatedCards.push({
      ...parentCard,
      subjobs: _.differenceBy(newSubjobs, removedCardsParents[k], 'id'),
    })
  })

  return updatedCards
}

export const getRequiredFieldRules = (formatMessage, rules = []) => [
  {
    required: true,
    message: formatMessage({ id: 'form.required' }),
  },
  ...rules,
]
