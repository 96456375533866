import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { SensorPanel } from './SensorPanel'
import bulbDefault from '../../styles/img/lightBulb.png'
import bulbLighted from '../../styles/img/lightBulbOn.png'

const LightSensor = ({ value, lastReported }) => {
  const messageId = value ? 'sensors.event-detected' : 'sensors.no-events'
  const labelCss = classNames('light-sensor-label', {
    'text-danger': value,
  })

  return (
    <SensorPanel
      headerMessageId="sensors.light-detection"
      lastReported={lastReported}
    >
      <div className={labelCss}>
        <FormattedMessage id={messageId} />
      </div>
      <img src={value ? bulbLighted : bulbDefault} alt="light-sensor" />
    </SensorPanel>
  )
}

export default LightSensor
