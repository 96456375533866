import { CSSTransition } from 'react-transition-group'

import { CssValues } from '../app/constants'

export const TransitionTypes = {
  SlideFromLeft: 'slide-from-left',
  SlideFromRight: 'slide-from-right',
  FadeOut: 'fade-out',
  ExpandLeft: 'expand-left',
  ExpandFromTop: 'expand-from-top',
  FadeFromTop: 'fade-from-top',
  ScaleDown: 'scale-down',
  ScaleUp: 'scale-up',
}

const Transition = ({
  opened,
  children,
  type,
  className,
  duration = CssValues.transitionDuration,
  onExited,
}) => (
  <CSSTransition
    in={opened}
    timeout={duration}
    classNames={type}
    appear={true}
    mountOnEnter={true}
    onExited={onExited}
  >
    <div className={className}>{children}</div>
  </CSSTransition>
)

export default Transition
