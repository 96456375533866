import { FormattedMessage } from 'react-intl'

import { SubJobTypeEnum } from '../../../app/enums/SubJobTypeEnum'
import { isSubJobCard } from '../../../utils/card-utils'
import { FlowContentHeaderSmall } from '../../ModalsTypography'

const getDateTimeHeader = card => {
  if (!isSubJobCard(card)) {
    return 'alarm-settings.confirmed-alarm'
  }

  if (card.subjobType === SubJobTypeEnum.REICE) {
    return 'alarm-settings.confirmed-ice'
  }

  if (card.subjobType === SubJobTypeEnum.PURCHASE) {
    return 'alarm-settings.confirmed-purchase'
  }
}

const ChooseAlarmHeader = ({ card, isEditing, theme }) => {
  const headerMessage = isEditing
    ? 'alarm-settings.alarm'
    : getDateTimeHeader(card)

  return (
    <FlowContentHeaderSmall theme={theme}>
      <FormattedMessage id={headerMessage} />
    </FlowContentHeaderSmall>
  )
}

export default ChooseAlarmHeader
