const mediumBreakpoint = 1590
const largeBreakpoint = 1980

export const WindowHeightEnum = {
  Large: 'lg',
  Small: 'sm',
}

export const WindowSizesEnum = {
  Large: 'lg',
  Medium: 'md',
  Small: 'sm',
}

export function windowWidthToSizeEnum(windowWidth) {
  if (windowWidth < mediumBreakpoint) {
    return WindowSizesEnum.Small
  } else if (windowWidth < largeBreakpoint) {
    return WindowSizesEnum.Medium
  } else {
    return WindowSizesEnum.Large
  }
}
