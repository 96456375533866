import { Popover } from 'antd'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

import { ActionEnum } from './actionsEnum'
import { ChargeTypesLabels } from '../../../app/enums/ChargeTypes'
import { CURRENCIES } from '../../../app/enums/Currencies'
import { fromServerDate } from '../../../utils/date-utils'
import { getDisplayName } from '../../../utils/driver-utils'
import { ShortDateFormat, TimeWithTimeZone } from '../../DateTimeFormats'
import CurrencyFormat from '../../user-units/CurrencyFormat'

const CommentTooltip = ({ text, children }) => (
  <Popover
    placement={'right'}
    overlayClassName="comment-overlay-popover"
    content={<div className="comment-overlay">{text}</div>}
  >
    {children}
  </Popover>
)

export const ActionLogArgsColumn = ({ value, documentTypes }) => {
  const getDocName = type =>
    _.find(documentTypes, t => t.id === type)?.displayName || '-'

  switch (value.action) {
    case ActionEnum.ASSIGN_DRIVER:
    case ActionEnum.UNASSIGN_DRIVER:
    case ActionEnum.ACCEPT_DRIVER:
      return (
        <div className="text-capitalized">
          <FormattedMessage id="drivers.driver" />: {value.args.driver?.code} |{' '}
          {getDisplayName(value.args.driver)}
        </div>
      )
    case ActionEnum.SET_ALARM:
      if (value.args.prevDateTime)
        return (
          <div>
            <span className="text-capitalized">
              <FormattedMessage id="misc.from" />
            </span>{' '}
            <ShortDateFormat
              date={fromServerDate(value.args.prevDateTime?.iso)}
            />{' '}
            <TimeWithTimeZone
              date={fromServerDate(value.args.prevDateTime?.iso)}
            />{' '}
            <FormattedMessage id="misc.to" />{' '}
            <ShortDateFormat
              date={fromServerDate(value.args.newDateTime?.iso)}
            />{' '}
            <TimeWithTimeZone
              date={fromServerDate(value.args.newDateTime?.iso)}
            />
          </div>
        )
      else
        return (
          <div>
            <FormattedMessage id="card-action-log.set-to" />:{' '}
            <ShortDateFormat
              date={fromServerDate(value.args.newDateTime?.iso)}
            />{' '}
            <TimeWithTimeZone
              date={fromServerDate(value.args.newDateTime?.iso)}
            />
          </div>
        )
    case ActionEnum.CLOSE_ALARM:
      return <FormattedMessage id="card-action-log.closed-alarm" />
    case ActionEnum.CONFIRM_ALERT:
      return <FormattedMessage id="card-action-log.confirmed" />
    // ack special instr.
    case ActionEnum.ACKNOWLEDGE_CARD:
      return <FormattedMessage id="card-action-log.confirmed-si" />
    case ActionEnum.DISPUTE_CHARGES:
      return (
        <div>
          <CommentTooltip text={value.args.comment}>
            <FormattedMessage id="card-action-log.disputed-job" />{' '}
            <u>
              <FormattedMessage id="card-action-log.comment" />
            </u>
          </CommentTooltip>
        </div>
      )
    case ActionEnum.CONFIRM_CHARGES:
      return <FormattedMessage id="card-action-log.accepted" />
    case ActionEnum.CREATE_SUB_JOB:
      return (
        <span>
          <FormattedMessage
            id="card-action-log.new-subjob"
            values={{ type: _.startCase(value.args.type) }}
          />
        </span>
      )
    case ActionEnum.DECLINE_CARD:
      return (
        <div>
          <CommentTooltip text={value.args.comment}>
            <FormattedMessage id="card-action-log.declined-job" />{' '}
            <u>
              <FormattedMessage id="card-action-log.comment" />
            </u>
          </CommentTooltip>
        </div>
      )
    case ActionEnum.ADD_CHARGES:
      return (
        <div>
          <FormattedMessage id="card-action-log.added" />
          <FormattedMessage
            defaultMessage={value.args.type}
            id={ChargeTypesLabels[value.args.type] || value.args.type}
          />
          :{' '}
          <CurrencyFormat
            value={value.args.amount}
            currency={CURRENCIES.US_DOLLAR}
          />
        </div>
      )
    case ActionEnum.ADD_CS_NOTE:
      return (
        <div>
          <CommentTooltip text={value.args.comment}>
            <FormattedMessage id="card-action-log.added" />{' '}
            <u className="text-uppercase">CT NOTES</u>
          </CommentTooltip>
        </div>
      )
    case ActionEnum.ACKNOWLEDGE_TRACKED_CHANGE:
      return <FormattedMessage id="card-action-log.ct-update" />
    case ActionEnum.UPLOAD_DOCUMENT:
      return (
        <div>
          <FormattedMessage id="card-action-log.uploaded" />:{' '}
          {getDocName(value.args.document)}
        </div>
      )
    case ActionEnum.DOWNLOAD_DOCUMENT:
      return (
        <div>
          <FormattedMessage id="card-action-log.downloaded" />:{' '}
          {getDocName(value.args.document)}
        </div>
      )
    default:
      return <div></div>
  }
}
