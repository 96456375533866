import { useCallback, useState } from 'react'

const useDirtyState = (defaultValue, compareFunc) => {
  const [currentDefault, setCurrentDefault] = useState(defaultValue)
  const [value, setValue] = useState(defaultValue)
  const [isDirty, setIsDirty] = useState(false)

  const resetValue = useCallback(
    newDefault => {
      if (newDefault !== null && newDefault !== undefined) {
        setValue(newDefault)

        const areEqual = compareFunc
          ? compareFunc(newDefault, currentDefault)
          : newDefault === currentDefault

        if (!areEqual) setCurrentDefault(newDefault)
      } else {
        setValue(currentDefault)
      }

      setIsDirty(false)
    },
    [currentDefault, compareFunc]
  )

  const changeValue = useCallback(newValue => {
    setValue(newValue)
    setIsDirty(true)
  }, [])

  return [value, changeValue, isDirty, resetValue]
}

export default useDirtyState
