import _ from 'lodash'

import { useOptimisticMutation } from './useOptimisticMutation'
import { UPDATE_CHANGES_FRAGMENT } from '../../app/graphQl/schema/Cards'
import { ACK_CHANGE } from '../../app/graphQl/schema/TrackedChanges'
import { useAuthentication } from '../useAuthentication'

export const useCardChangesTracking = () => {
  const [currentUser] = useAuthentication()
  const [ackNotificationMutation] = useOptimisticMutation(
    ACK_CHANGE,
    'acknowledgeChange'
  )

  const acknowledgeChange = (card, changes) => {
    ackNotificationMutation({
      variables: { changes: _.map(changes, change => change.id) },
      cacheUpdate: cache => {
        cache.writeFragment({
          id: `Card:${card.id}`,
          fragment: UPDATE_CHANGES_FRAGMENT,
          fragmentName: 'changesUpdate',
          data: {
            __typename: card.__typename,
            id: card.id,
            trackedChanges: _.map(card.trackedChanges, change => ({
              ...change,
              acknowledgedBy: currentUser?.username,
            })),
          },
        })
      },
    })
  }

  return { acknowledgeChange }
}
