import { useEffect } from 'react'

// hook to set background-clur class to root element / parent element of modal wrapper
// aim is to simulate background-blur for modals on unsopported browsers (Firefox))
export function useBackgroundBlur(visible, element) {
  useEffect(() => {
    let el
    if (!element) {
      // of no element was specified, we assume window is in root component
      el = document.getElementById('root')
    } else {
      // if modal is inside other element, we set blur to parent
      el = element.parentElement
    }
    if (!el) return

    if (visible) {
      el.classList.add('background-blur')
    }

    return () => {
      el.classList.remove('background-blur')
    }
  }, [element, visible])
}
