import _ from 'lodash'
import { useCallback, useContext, useEffect, useState } from 'react'
import * as React from 'react'

import { useActiveCardsList } from './graphQl/useActiveCardsList'
import useMultipleSelection from './useMultipleSelection'
import { isDeclined } from '../utils/card-utils'

export const MonitorRootContext = React.createContext()

function areScrollValuesEqual(current, previous) {
  return (
    Math.abs(current.scrollLeft - previous.scrollLeft) < 5 &&
    current.clientWidth === previous.clientWidth &&
    current.scrollWidth === previous.scrollWidth
  )
}

export const MonitorContextProvider = ({ children }) => {
  const [currentScroll, setCurrentScroll] = useState({ left: 0, width: 0 })
  const [newColumnOpened, setNewColumnOpened] = useState(false)
  const [selectedParent, setSelectedParent] = useState()
  const [lastSubjob, setLastSubjob] = useState()
  const [selectableCards, setSelectableCards] = useState()

  const { cardsData: allCards } = useActiveCardsList()

  const { selectedItems, actions } = useMultipleSelection(
    selectableCards,
    (a, b) => a.stack.id === b.stack.id,
    true
  )

  const updateScroll = React.useCallback(val => {
    setCurrentScroll(current => {
      const newVal = {
        ...val,
        scrollLeft: _.clamp(
          val.scrollLeft,
          0,
          val.scrollWidth - val.clientWidth
        ),
      }
      return areScrollValuesEqual(current, newVal) ? current : newVal
    })
  }, [])

  const selectSubJob = useCallback(
    (cards, parent) => {
      actions.selectExclusively(cards)
      setSelectedParent(parent)
      setLastSubjob(_.head(cards))
    },
    [actions]
  )

  useEffect(() => {
    setSelectableCards(_.filter(allCards, c => !isDeclined(c)))
  }, [allCards])

  useEffect(() => {
    if (!selectedParent || !lastSubjob) return

    // if other card was clicked, remove selection
    if (_.some(selectedItems, i => i.id !== lastSubjob.id)) {
      setSelectedParent(null)
      setLastSubjob(null)
      actions.deselectItems([lastSubjob])
    }
  }, [actions, lastSubjob, selectedItems, selectedParent])

  const value = React.useMemo(
    () => ({
      currentScroll,
      updateScroll,
      newColumnOpened,
      setNewColumnOpened,
      selectSubJob,
      selectedParent,
      selectedCards: selectedItems,
      selectActions: actions,
    }),
    [
      currentScroll,
      updateScroll,
      newColumnOpened,
      selectSubJob,
      selectedParent,
      selectedItems,
      actions,
    ]
  )

  return (
    <MonitorRootContext.Provider value={value}>
      {children}
    </MonitorRootContext.Provider>
  )
}

export const useMonitorContext = () => useContext(MonitorRootContext)
