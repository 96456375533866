import { useQuery } from '@apollo/client'

import { GET_ACTION_LOG } from '../../app/graphQl/schema/ActionLog'

const useGetActionLog = cardId => {
  const { data, loading, error } = useQuery(GET_ACTION_LOG, {
    skip: !cardId,
    variables: { cardId: cardId },
    pollInterval: 60000,
    fetchPolicy: 'no-cache',
  })

  return { data: data?.getCardActionLog, loading, error }
}

export default useGetActionLog
