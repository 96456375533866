import { Collapse } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDrop } from 'react-dnd'
import { useIntl } from 'react-intl'

import CardsColumn from './CardsColumn'
import CardsColumnHeader from './CardsColumnHeader'
import { ColumnsEnum } from '../../app/constants'
import { useCards } from '../../hooks/useCards'
import useCardsFilter from '../../hooks/useCardsFilter'
import { useStacks } from '../../hooks/useStacks'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { countCards, getStackLabel } from '../../utils/stacks-utils'
import { themedClass } from '../../utils/style-utils'
import { CardDropWrap } from '../CardDropPlace'
import DraggableComponent from '../DraggableComponent'
import { ExpandIcon } from '../Icons'
import WarningWindow from '../modals/WarningWindow'
const { Panel } = Collapse

const ColumnDrag = 'COLUMN_DRAG'

const AdditionalOnDockHeader = ({ stackName, isActive, count }) => (
  <div>
    {stackName}
    {!isActive ? ` (${count})` : ''}
  </div>
)

const DroppableColumn = ({ stack, cardsSelection, cards, ...rest }) => {
  const [lastDropped, setLastDropped] = useState()
  return (
    <CardDropWrap
      stack={stack}
      selectedCards={cardsSelection.selectedCards}
      afterDrop={setLastDropped}
      confirmEta={stack.name === ColumnsEnum.DECIDE}
      className="card-drop-column"
    >
      <CardsColumn
        {...rest}
        cards={cards[stack.id]}
        stack={stack}
        cardsSelection={cardsSelection}
        lastDroppedCard={lastDropped}
      />
    </CardDropWrap>
  )
}

const JobCardsColumnContainer = props => {
  const {
    cards,
    stack,
    onReorder,
    className,
    reorderable,
    editable,
    cardsSelection = {},
    topLabel,
    topComponent,
    onGroupCreated,
    onSplitCardsGroup,
    additionalStacks,
  } = props
  const [theme] = useSelectedTheme()
  const [cardToGroup, setCardToGroup] = useState(null)
  const [showWarning, setShowWarning] = useState(false)
  const [activePanels, setActivePanels] = useState([stack.id])
  const [cardsFilter] = useCardsFilter()

  const stackId = stack ? stack.id : null
  const stackCards = cards[stackId]

  const { formatMessage: f } = useIntl()
  const { groupCards, splitCardsGroup } = useCards()
  const { removeStack } = useStacks()

  const [{ isHovered: isColumnHovered }, columnDrop] = useDrop({
    accept: ColumnDrag,
    drop: item => onReorder(item, stack.order),
    collect: monitor => ({
      isHovered: monitor.isOver() && monitor.canDrop(),
    }),
    canDrop: item => item.id !== stackId,
  })

  const setRefs = useCallback(
    ref => {
      const refFunctions = []

      if (reorderable) refFunctions.push(columnDrop)

      return _.flow(refFunctions)(ref)
    },
    [columnDrop, reorderable]
  )

  useEffect(() => {
    if (cardToGroup) {
      groupCards(cardToGroup.id, cardsSelection.selectedCards)
      setCardToGroup(null)
      onGroupCreated && onGroupCreated(cardToGroup)
    }
  }, [cardToGroup, groupCards, cardsSelection.selectedCards, onGroupCreated])

  const cssClass = themedClass(
    'cards-column',
    theme,
    classNames(className, {
      'cards-column-highlight': isColumnHovered,
    })
  )

  const onRemoveClick = () => {
    if (!editable) return

    if (countCards(stackCards, stack) > 0) {
      console.log(`cards in stack: ${topLabel}`, stackCards)
      setShowWarning(true)
    } else {
      removeStack(stackId)
    }
  }

  const onSplitGroupClick = useCallback(
    (cards, data) => {
      splitCardsGroup(data)
      onSplitCardsGroup && onSplitCardsGroup(cards)
    },
    [onSplitCardsGroup, splitCardsGroup]
  )

  const onPanelsChanged = panels => {
    setActivePanels(_.isEmpty(panels) ? [stack.id] : panels)
  }

  const renderDividers = !cardsFilter?.from?.isSame(cardsFilter.to, 'day')
  const columnProps = {
    ...props,
    cardsSelection: cardsSelection,
    onGroupCards: setCardToGroup,
    renderDateDividers: renderDividers,
    onSplitCardsGroup: onSplitGroupClick,
    theme: theme,
  }

  const allStacks = [stack, ...(additionalStacks || [])]
  const allCardsCount = _.fromPairs(
    _.map(allStacks, s => [s.id, countCards(cards[s.id], s)])
  )

  const innerColumn = (
    <>
      <WarningWindow
        header={f({ id: 'card-columns.remove-not-allowed' })}
        body={f({ id: 'card-columns.cannot-remove' })}
        onClosed={() => setShowWarning(false)}
        visible={showWarning}
        theme={theme}
      />

      {topLabel && (
        <CardsColumnHeader
          reorderable={reorderable}
          editable={editable}
          onRemove={onRemoveClick}
          label={topLabel}
          stackId={stackId}
          cardsCount={_.sum(_.values(allCardsCount))}
        />
      )}

      {topComponent}

      {_.some(additionalStacks) ? (
        <Collapse
          accordion
          className="flex-column"
          activeKey={activePanels}
          onChange={onPanelsChanged}
          expandIcon={({ isActive }) => (
            <ExpandIcon expanded={isActive} rightAlign />
          )}
        >
          {_.map(allStacks, s => (
            <Panel
              key={s.id}
              header={
                <AdditionalOnDockHeader
                  stackName={getStackLabel(s)}
                  count={allCardsCount[s.id]}
                  isActive={_.includes(activePanels, s.id)}
                />
              }
              headerClass="collapse-header-small"
            >
              <DroppableColumn {...columnProps} stack={s} />
            </Panel>
          ))}
        </Collapse>
      ) : (
        <DroppableColumn {...columnProps} stack={stack} />
      )}
    </>
  )

  if (!reorderable)
    return (
      <div className={cssClass} ref={setRefs}>
        {innerColumn}
      </div>
    )

  return (
    <DraggableComponent
      dragItem={{ id: stackId, type: ColumnDrag }}
      className={cssClass}
      ref={setRefs}
    >
      {innerColumn}
    </DraggableComponent>
  )
}

export default JobCardsColumnContainer
