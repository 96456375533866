/* global google */
import { GoogleMap, LoadScriptNext, useGoogleMap } from '@react-google-maps/api'
import { forwardRef, useRef } from 'react'

import { config } from '../../app/config'
import { ThemesEnum } from '../../app/enums/Themes'
import { themedClass } from '../../utils/style-utils'
import { MinusSignIcon, PlusSignIcon } from '../Icons'

const mapStyles = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
]

// https://developers.google.com/maps/documentation/javascript/examples/control-replacement
function initZoomControl(map, controlsElement) {
  map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
    controlsElement.current
  )
}

const darkStyle = [
  ...mapStyles,
  { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#263c3f' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6b9a76' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#38414e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#212a37' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9ca5b3' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#746855' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#1f2835' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#f3d19c' }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#2f3948' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#17263c' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#515c6d' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#17263c' }],
  },
]

const ZoomControls = forwardRef((_props, ref) => {
  const map = useGoogleMap()

  return (
    <div style={{ display: 'none' }}>
      <div className="controls zoom-control" ref={ref}>
        <button
          className="zoom-control-in"
          title="Zoom In"
          onClick={() => {
            map.setZoom(map.getZoom() + 1)
          }}
        >
          <PlusSignIcon />
        </button>
        <button
          className="zoom-control-out"
          title="Zoom Out"
          onClick={() => {
            map.setZoom(map.getZoom() - 1)
          }}
        >
          <MinusSignIcon />
        </button>
      </div>
    </div>
  )
})

const GoogleMapsComponent = ({ center, children, mapOptions, theme }) => {
  const zoomRef = useRef()
  return (
    <LoadScriptNext googleMapsApiKey={config.googleMapsApiKey}>
      <GoogleMap
        mapContainerClassName={themedClass('map-container', theme)}
        zoom={14}
        center={center}
        streetViewControl={false}
        options={{
          maxZoom: 17,
          gestureHandling: 'greedy',
          clickableIcons: false,
          streetViewControl: false,
          mapTypeControl: false,
          styles: theme === ThemesEnum.DARK ? darkStyle : mapStyles,
          fullscreenControl: false,
          disableDefaultUI: true,
          zoomControl: false,
          ...mapOptions,
        }}
        onLoad={map => {
          initZoomControl(map, zoomRef)
        }}
      >
        <ZoomControls ref={zoomRef} />
        {children}
      </GoogleMap>
    </LoadScriptNext>
  )
}

export default GoogleMapsComponent
