import _ from 'lodash'

import CardPopupMap from './CardPopupMap'
import { useDriverRoute } from '../../hooks/graphQl/useDriverRoute'
import { getMapMarkerColor } from '../../utils/driver-utils'
import LoadingIndicator from '../LoadingIndicators'

export const ChargeReviewMap = ({ card }) => {
  const [routeData, loadingMap, mapError] = useDriverRoute(card)
  const pickupCoords = card?.pickup?.position
  const dropCoords = card?.drop?.position
  const routePoints = _.map(routeData?.routePoints, point => ({
    lat: point.coords.lat,
    lng: point.coords.lng,
  }))

  return (
    <LoadingIndicator
      loading={loadingMap}
      error={mapError}
      className="charges-map"
    >
      <CardPopupMap
        center={pickupCoords}
        pickupPoint={pickupCoords}
        dropPoint={dropCoords}
        lineColor={getMapMarkerColor(card.driver)}
        route={routePoints}
      />
    </LoadingIndicator>
  )
}
