import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { themedClass } from '../utils/style-utils'

const Alert = ({ level, children, dataCy }) => (
  <div data-testid={dataCy} className={'alert alert-' + level}>
    {children}
  </div>
)

Alert.propTypes = {
  level: PropTypes.oneOf(['success', 'danger']),
}

export const ErrorAlert = ({ errorId, errorMessage }) => {
  if (!errorId && !errorMessage) return null

  return (
    <Alert level="danger" dataCy="error-loading">
      {errorId && <FormattedMessage id={errorId} />}
      {errorMessage}
    </Alert>
  )
}

export const WarningAlertFloating = ({ children, size, theme }) => {
  const css = classNames(
    themedClass('warning-alert', theme),
    {
      'warning-alert-tiny': size === 'tiny',
    },
    {
      'warning-alert-small': size === 'small',
    }
  )

  return <div className={css}>{children}</div>
}
