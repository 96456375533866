/* global google */
import { MarkerClusterer } from '@react-google-maps/api'
import { useGoogleMap } from '@react-google-maps/api'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import DriverDirectionsService from './DriverDirectionsService'
import { DriverRoute } from './DriverRoute'
import DropMarker from './DropMarker'
import PickupMarker from './PickupMarker'
import VehicleMapMarker from './VehicleMapMarker'
import { DriverTypesEnum } from '../../app/enums/DriverType'
import { useDriverAssignContext } from '../../hooks/useDriversAssignContext'
import useEqualState from '../../hooks/useEqualState'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { arePointsEqual, googlePointToObject } from '../../utils/map-utils'
import LSPDriversList from '../drivers/LSPDriversList'

function fitBounds(map, coords) {
  const currentBounds = new google.maps.LatLngBounds()
  let setBounds = false

  _.forEach(_.compact(coords), coord => {
    if (!!coord && !currentBounds.contains(coord)) {
      setBounds = true
      currentBounds.extend(coord)
    }
  })
  if (setBounds) map.fitBounds(currentBounds)
}

function calculateETA(legs, currentTime) {
  return moment(currentTime).add(_.sumBy(legs, 'duration.value'), 'seconds')
}
// inspired by https://github.com/gmaps-marker-clusterer/gmaps-marker-clusterer/blob/c9d448d62b5634db4e76b6b7e3d913703cbed303/src/markerclusterer.js#L458
function calculateCluster(markers, num) {
  let index = 0
  const count = markers.length

  let dv = count
  while (dv !== 0) {
    dv = parseInt(dv / 10, 10)
    index++
  }

  index = Math.min(index, num)

  return {
    text: markers.length,
    index: index,
  }
}

// view showing multiple drivers with route to pickup point
export const MultipleDriversMapView = ({
  pickDestination,
  dropDestination,
  vehicles,
  onMarkerHover,
  onMarkerHoverEnd,
  etaType,
}) => {
  const map = useGoogleMap()
  const [theme] = useSelectedTheme()
  const [currentMarkerer, setCurrentMarkerer] = useState()
  const [needRedraw, setNeedRedraw] = useState(false)
  const pickCoords = useEqualState(pickDestination, _.isEqual)
  const { estimatedArrivals, setEstimatedArrivals } = useDriverAssignContext()
  const { getCurrentTime } = useLocalDateTime()
  const [pickDropDuration, setPickDropDuration] = useState(0)

  const [driversIds, setDriversIds] = useState(_.map(vehicles, v => v.id))
  const [vehiclesPositions, setVehiclesPositions] = useState(
    _.compact(_.map(vehicles, v => v.position))
  )

  useEffect(() => {
    const ids = _.map(vehicles, v => v.id)
    if (_.xor(ids, driversIds).length > 0) {
      setDriversIds(ids)
      setVehiclesPositions(_.compact(_.map(vehicles, v => v.position)))
    }
  }, [driversIds, vehicles])

  useEffect(() => {
    // bounds are recalculated only if lest of selected drivers change, not after every update of driver location
    fitBounds(map, [...vehiclesPositions, pickCoords])
  }, [pickCoords, map, vehiclesPositions])

  useEffect(() => {
    const redrawClusterer = () => {
      if (currentMarkerer && needRedraw) {
        currentMarkerer.repaint()
        setNeedRedraw(false)
      }
    }
    const listener = google.maps.event.addListenerOnce(
      map,
      'idle',
      redrawClusterer
    )

    return () => {
      if (listener) google.maps.event.removeListener(listener)
    }
  }, [currentMarkerer, map, needRedraw])

  const routeReceived = (vehicle, r) => {
    const legs = r.routes[0].legs
    setEstimatedArrivals(current => ({
      ...current,
      [vehicle.id]: {
        route: r,
        eta: {
          pickupEta: calculateETA(legs, getCurrentTime()),
        },
      },
    }))
  }

  const pickDropRouteRecieved = r => {
    const legs = r.routes[0].legs
    const { origin, destination } = r.request
    const totalDuration = _.sumBy(legs, 'duration.value')
    if (
      arePointsEqual(pickDestination, googlePointToObject(origin.location)) &&
      arePointsEqual(dropDestination, googlePointToObject(destination.location))
    ) {
      setPickDropDuration(totalDuration)
    }
  }

  const createEtaObject = eta => {
    // duration of route from pick to drop point cannot be calculated with driver directions
    // because google api returns only single line for whole route and we need to draw only line between vehice and pick point
    if (!eta || !eta.pickupEta) return null

    return {
      pickupEta: eta.pickupEta,
      jobEta: moment(eta.pickupEta).add(pickDropDuration, 'seconds'),
      etaOrder: eta.etaOrder,
    }
  }
  const vehiclesOnMap = _.filter(
    vehicles,
    v => v.driverType !== DriverTypesEnum.LOGISTIC_SERVICE_PROVIDER
  )
  return (
    <>
      <LSPDriversList drivers={vehicles} />

      {pickCoords && <PickupMarker position={pickCoords} />}

      {vehiclesOnMap.length > 0 && (
        <MarkerClusterer
          averageCenter={true}
          minimumClusterSize={2}
          calculator={calculateCluster}
          onLoad={setCurrentMarkerer}
        >
          {clusterer =>
            vehiclesOnMap.map(v => (
              <VehicleMapMarker
                eta={createEtaObject(estimatedArrivals[v.id]?.eta)}
                etaType={etaType}
                key={v.id}
                driver={v}
                destination={pickCoords}
                onHoverStart={onMarkerHover}
                onHoverEnd={onMarkerHoverEnd}
                clusterer={clusterer}
                directionData={estimatedArrivals[v.id]?.route}
                theme={theme}
              />
            ))
          }
        </MarkerClusterer>
      )}

      {vehiclesOnMap.map(v => (
        <DriverDirectionsService
          key={v.id}
          destination={pickCoords}
          origin={v.position}
          responseRecieved={r => {
            routeReceived(v, r)
          }}
        />
      ))}

      <DriverDirectionsService
        destination={dropDestination}
        origin={pickDestination}
        responseRecieved={pickDropRouteRecieved}
      />
    </>
  )
}

// view showing full route driver-pickup-drop
export const FullRouteView = ({
  pickDestination,
  dropDestination,
  vehicle,
  onMarkerHover,
  onMarkerHoverEnd,
  etaType,
}) => {
  const map = useGoogleMap()
  const [theme] = useSelectedTheme()
  const [currentVehicle, setCurrentVehicle] = useState(vehicle)
  const pickCoords = useEqualState(pickDestination, _.isEqual)
  const dropCoords = useEqualState(dropDestination, _.isEqual)
  const { getCurrentTime } = useLocalDateTime()
  const [directionsRoute, setRoutes] = useState({})

  useEffect(() => {
    if (!vehicle) {
      setCurrentVehicle(null)
      return
    }

    if (currentVehicle?.id !== vehicle.id) {
      setCurrentVehicle(vehicle)
    }
  }, [currentVehicle, vehicle])

  useEffect(() => {
    fitBounds(map, [currentVehicle?.position, dropCoords, pickCoords])
  }, [currentVehicle, dropCoords, map, pickCoords])

  const routeReceived = r => {
    const legs = r.routes[0].legs
    setRoutes({
      route: r,
      eta: {
        jobEta: calculateETA(legs, getCurrentTime()),
        pickupEta: calculateETA(_.take(legs, 1), getCurrentTime()),
      },
    })
  }

  return (
    <>
      {pickDestination && <PickupMarker position={pickDestination} />}
      {dropDestination && <DropMarker position={dropDestination} />}
      {vehicle &&
        (vehicle.driverType !== DriverTypesEnum.LOGISTIC_SERVICE_PROVIDER ? (
          <>
            <VehicleMapMarker
              eta={directionsRoute?.eta}
              etaType={etaType}
              driver={vehicle}
              destination={dropDestination}
              onHoverStart={onMarkerHover}
              onHoverEnd={onMarkerHoverEnd}
              waypoints={[{ location: pickDestination }]}
              directionData={directionsRoute?.route}
              theme={theme}
            />
            <DriverDirectionsService
              key={vehicle.id}
              destination={dropDestination}
              origin={vehicle.position}
              responseRecieved={routeReceived}
              waypoints={[{ location: pickDestination }]}
            />
          </>
        ) : (
          <LSPDriversList drivers={[vehicle]} />
        ))}
      ))
    </>
  )
}

export const FinishedRouteView = ({
  pickupPoint,
  dropPoint,
  route = [],
  lineColor,
}) => {
  const map = useGoogleMap()

  useEffect(() => {
    fitBounds(map, [pickupPoint, dropPoint, ...route])
  }, [pickupPoint, dropPoint, route, map])

  return (
    <>
      {pickupPoint && <PickupMarker position={pickupPoint} />}
      {dropPoint && <DropMarker position={dropPoint} />}
      {route && <DriverRoute points={route} lineColor={lineColor} />}
    </>
  )
}
