import { useIntl } from 'react-intl'

import { fromServerDate } from '../../utils/date-utils'
import { formatNullableString } from '../../utils/string-utils'
import CardsStatus from '../CardStatus'

const getBadgeTime = (badgeIso, formatFunc, options) => {
  if (badgeIso) {
    const badgeDate = fromServerDate(badgeIso)
    if (!isNaN(badgeDate)) return formatFunc(badgeDate, options)
  }
  return formatNullableString(badgeIso)
}

const TopBadge = ({ badge }) => {
  const { formatTime } = useIntl()

  const bageTime = badge?.iso
    ? getBadgeTime(badge?.iso, formatTime, {
        hour12: false,
      })
    : null

  return (
    <CardsStatus
      label={formatNullableString(badge?.label)}
      value={bageTime}
      valueStatus="success"
    />
  )
}

export default TopBadge
