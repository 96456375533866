import { useCallback, useState } from 'react'
export function useModalWindow() {
  const [showModal, setShowModal] = useState(false)

  const closeAlarmModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const openAlarmModal = useCallback(() => {
    setShowModal(true)
  }, [])

  return [showModal, openAlarmModal, closeAlarmModal]
}
