import { Dropdown } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import PopoverMenu from './PopoverMenu'

export const DropdownButtonStyle = {
  SMALL: 0,
  LARGE: 1,
}

const DropDownMenu = ({
  items,
  onItemClick,
  allowMultiple,
  isItemEnabled,
  selectedValues = {},
  theme,
}) => {
  const { formatMessage: f } = useIntl()
  const css = allowMultiple ? 'multiple-dropdown' : ''

  const isSelected = (i, group) =>
    selectedValues[group] && selectedValues[group].indexOf(i.id) > -1

  const itemClicked = item => {
    const group = _.find(items, g => _.some(g.items, it => it.id === item.key))
    if (!group) return

    if (!isItemEnabled || isItemEnabled({ id: item.key }, group.id)) {
      onItemClick(item.key, group.id)
    }
  }
  const menuItems = items.map((group, idx) => {
    const subItems = group.items.map(item => ({
      key: item.id,
      disabled: isItemEnabled && !isItemEnabled(item, group.id),
      label: (
        <div className="text-capitalized">
          <FormattedMessage id={item.label} defaultMessage={item.label} />
          {isSelected(item, group.id) && <i className="fas fa-check-circle" />}
        </div>
      ),
    }))
    if (idx < items.length - 1) {
      subItems.push({
        type: 'divider',
        className: 'column-menu-divider',
      })
    }
    return {
      type: 'group',
      label: <span className="text-uppercase">{f({ id: group.name })}</span>,
      key: group.id,
      children: subItems,
    }
  })

  return (
    <PopoverMenu
      theme={theme}
      className={css}
      items={menuItems}
      onClick={itemClicked}
    />
  )
}

const ColumnDropdown = ({
  onItemClick,
  items,
  multiple,
  selectedValues,
  placement = 'bottomLeft',
  iconStyle = DropdownButtonStyle.SMALL,
  isItemEnabled,
  theme,
}) => {
  const [opened, setOpened] = useState(false)

  const clickCallback = (item, group) => {
    if (!multiple) setOpened(false)
    onItemClick && onItemClick(item, group)
  }

  const handleVisibleChange = isOpen => {
    setOpened(isOpen)
  }

  const btnCss = classNames('btn-dropdown', {
    'btn-dropdown-large': iconStyle === DropdownButtonStyle.LARGE,
  })

  return (
    <Dropdown
      dropdownRender={() => (
        <DropDownMenu
          items={items}
          onItemClick={clickCallback}
          allowMultiple={multiple}
          selectedValues={selectedValues}
          isItemEnabled={isItemEnabled}
          theme={theme}
        />
      )}
      trigger={['click']}
      placement={placement}
      overlayClassName="column-dropdown"
      onOpenChange={handleVisibleChange}
      open={opened}
    >
      <button className={btnCss}>
        <i className="fas fa-ellipsis-v" />
      </button>
    </Dropdown>
  )
}

ColumnDropdown.propTypes = {
  onClick: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })
      ).isRequired,
    })
  ),
}

export default ColumnDropdown
