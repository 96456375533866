import { FormattedMessage } from 'react-intl'

import { fromServerDate } from '../../utils/date-utils'
import BorderOutset from '../BorderOutset'
import { DayDateTimeFormat } from '../DateTimeFormats'
import { BadgeSmall } from '../JobBadges'
import { HeaderSizes, JobDetailHeader } from '../ModalsTypography'

export const JobActionDate = ({ badge = {}, borderless, theme }) => {
  const { label, iso } = badge

  const innerContent = (
    <>
      <BadgeSmall>
        {label && <FormattedMessage id={label} defaultMessage={label} />}
      </BadgeSmall>
      <JobDetailHeader size={HeaderSizes.Small} uppercase>
        {iso && (
          <DayDateTimeFormat
            date={fromServerDate(iso)}
            timeClassName="text-important"
          />
        )}
      </JobDetailHeader>
    </>
  )

  if (borderless)
    return <div className="shipment-info-action">{innerContent}</div>

  return (
    <BorderOutset inverted className="shipment-info-action" theme={theme}>
      {innerContent}
    </BorderOutset>
  )
}
