const UserTextPreview = ({ text = '' }) => {
  if (!text) return ''

  const lines = text.split('\n')

  return lines.map((l, i) => (
    <span key={i}>
      {l}
      <br />
    </span>
  ))
}

export default UserTextPreview
