/* global google */
import { Marker } from '@react-google-maps/api'

const DropMarker = ({ position }) => (
  <Marker
    position={position}
    zIndex={1}
    icon={{
      url: '',
      labelOrigin: { x: 27, y: 15 }, // {15, 15} is middle of marker, need move 12px to right so flagpole is in middle
      size: google && new google.maps.Size(30, 30),
    }}
    label={{
      color: '#E94335',
      fontFamily: "'Font Awesome 5 Free'",
      fontWeight: '900',
      fontSize: '30px',
      text: '\uf11e',
    }}
  />
)

export default DropMarker
