import { useState } from 'react'
import { useIntl } from 'react-intl'

import ChangelogContent from './ChangelogContent'
import { VersionNumber } from './VersionNumber'
import { currentVersion } from '../../app/config'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { themedClass } from '../../utils/style-utils'
import { CloseIcon } from '../Icons'
import ThemableModal from '../modals/ThemableModal'

const ChangelogModal = () => {
  const { formatMessage: f } = useIntl()
  const [theme] = useSelectedTheme()
  const [, setLastSeenVersion] = useLocalStorage('qdd-last-version')
  const [hiddenControls, setHiddenControls] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const onOkClicked = () => {
    setIsVisible(false)
    setLastSeenVersion(currentVersion)
  }

  const onCancelClicked = () => {
    setIsVisible(false)
  }

  const onShowChangelog = () => {
    setIsVisible(true)
    setHiddenControls(true)
  }

  const modalProps = {
    className: themedClass('changelog-modal', theme),
    open: isVisible,
    cancelText: f({ id: 'changelog.read-later' }),
    okText: f({ id: 'changelog.acknowledge' }),
    width: 810,
    centered: true,
    closeIcon: <CloseIcon />,
    onCancel: onCancelClicked,
    onOk: onOkClicked,
    cancelButtonProps: { ghost: true },
  }
  if (hiddenControls) modalProps.footer = null

  return (
    <>
      <ThemableModal {...modalProps} theme={theme}>
        <ChangelogContent theme={theme} />
      </ThemableModal>
      <VersionNumber onClick={onShowChangelog} theme={theme} />
    </>
  )
}

export default ChangelogModal
