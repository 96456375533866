import classNames from 'classnames'

import { DriversState } from '../../app/enums/DriverState'
import useChangeDriverStateDrop from '../../hooks/useChangeDriverStateDrop'

const ChangeDriverStateDropPlace = ({
  type,
  selectedDrivers,
  children,
  className,
}) => {
  const [hovered, driverDrop] = useChangeDriverStateDrop(
    type !== DriversState.ACTIVE,
    selectedDrivers
  )

  const css = classNames(className, { 'driver-column-hover': hovered })
  return (
    <div ref={driverDrop} className={css}>
      {children}
    </div>
  )
}

export default ChangeDriverStateDropPlace
