import _ from 'lodash'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import ConfirmModalBase from './ConfirmModalBase'
import { useAuthentication } from '../../hooks/useAuthentication'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { ContentPanel } from '../ContentPanel'
import { TimeLongDateFormat } from '../DateTimeFormats'
import { StopwatchIcon } from '../Icons'
import { HeaderSizes, JobDetailHeader } from '../ModalsTypography'
import SelectPopover from '../form/SelectPopover'

export const ConfirmAlarmModal = ({
  card,
  allowedStacks,
  onConfirm,
  defaultStack,
  dateTime,
  loading,
  error,
  onClosed,
  headerMsg,
}) => {
  const { formatMessage: f } = useIntl()
  const [theme] = useSelectedTheme()

  const defaultSelected = defaultStack
    ? _.find(allowedStacks, s => defaultStack.id === s.value)
    : null
  const [selectedStack, setSelectedStack] = useState(defaultSelected)

  const [currentUser] = useAuthentication()

  const onStackChanged = s => {
    setSelectedStack(s)
  }

  const onConfirmed = () => {
    onConfirm(card.id, selectedStack.value)
  }

  if (!currentUser) return null
  return (
    <ConfirmModalBase
      className="confirm-alarm-modal"
      width={480}
      okText={f({ id: 'confirm.confirm' })}
      onOk={onConfirmed}
      onCancel={onClosed}
      canConfirm={!!selectedStack}
      title={<StopwatchIcon className="opaque-icon text-warning" />}
    >
      <h1>
        <FormattedMessage id={headerMsg} />
      </h1>

      <ContentPanel className="selected-datetime">
        <JobDetailHeader size={HeaderSizes.Medium} uppercase>
          <FormattedMessage id="alarm-modal.alarm" />
          {': '}
        </JobDetailHeader>
        <div className="selected-date-important text-important text-uppercase">
          {' '}
          <TimeLongDateFormat date={dateTime} />
        </div>
      </ContentPanel>

      <SelectPopover
        loading={loading}
        error={error}
        placeholder={f({ id: 'form.assign-stack' })}
        options={allowedStacks}
        selectedItem={selectedStack}
        onChange={onStackChanged}
        itemCss="text-capitalized"
        className="text-capitalized"
        theme={theme}
      />
    </ConfirmModalBase>
  )
}

export default ConfirmAlarmModal
