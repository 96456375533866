import { gql } from '@apollo/client'

export const GET_ACTION_LOG = gql`
  query getCardActionLog($cardId: ID!) {
    getCardActionLog(cardId: $cardId) {
      id
      datetime {
        iso
      }
      action
      user
      userFullName
      args {
        type
        amount
        driver {
          id
          code
          name
          nickname
        }
        driverName
        comment
        document
        newDateTime {
          iso
        }
        prevDateTime {
          iso
        }
      }
    }
  }
`
