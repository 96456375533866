import { Popover } from 'antd'
import classnames from 'classnames'
import _ from 'lodash'
import { useState } from 'react'

import { padInteger } from '../../utils/string-utils'
import { themedClass } from '../../utils/style-utils'

const DayPeriodsEnum = {
  AM: 'AM',
  PM: 'PM',
}

const TimePickerGrid = ({ value, onTimeSelected, theme }) => {
  const [type, setType] = useState(
    value.hour() < 12 ? DayPeriodsEnum.AM : DayPeriodsEnum.PM
  )

  const onCellClick = (hours, minutes) => {
    onTimeSelected(hours, minutes)
  }

  const toggleType = () => {
    setType(type === DayPeriodsEnum.AM ? DayPeriodsEnum.PM : DayPeriodsEnum.AM)
  }

  const hoursList =
    type === DayPeriodsEnum.AM ? _.range(0, 12) : _.range(12, 24)

  return (
    <div className={themedClass('time-picker', theme)}>
      <div className="time-picker-controls datetime-calendar-controls">
        <i className="fas fa-angle-left" onClick={toggleType} />
        <span>{type}</span>
        <i className="fas fa-angle-right" onClick={toggleType} />
      </div>
      <div className="time-picker-body">
        {hoursList.map(hour => {
          const isSelected = hour === value.hour()
          const cellCss = classnames('time-picker-cell', {
            'time-picker-cell-selected': isSelected,
          })

          return (
            <div className={cellCss} key={hour}>
              <Popover
                content={[15, 30, 45].map(m => {
                  const css = classnames('time-picker-minutes', {
                    'time-picker-cell-selected':
                      isSelected && value.minutes() === m,
                  })

                  return (
                    <p
                      className={css}
                      key={m}
                      onClick={() => onCellClick(hour, m)}
                    >
                      :{padInteger(m)}
                    </p>
                  )
                })}
                placement="right"
                arrowPointAtCenter
                overlayClassName={themedClass('time-picker-popover', theme)}
                trigger={['hover']}
              >
                <div
                  className="time-picker-cell-value"
                  onClick={() => onCellClick(hour, 0)}
                >
                  {padInteger(hour)}:{padInteger(0)}
                </div>
              </Popover>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TimePickerGrid
