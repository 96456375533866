import { Input, Select } from 'antd'
import classNames from 'classnames'
import { forwardRef, useState } from 'react'

import { themedClass } from '../../utils/style-utils'
import * as Icons from '../Icons'
import LoadingIndicator from '../LoadingIndicators'
import ScrollArea from '../ScrollArea'

const { Option } = Select

// TODO add non-flying label posiblity and move all other Form components here
export const TextBox = forwardRef((props, ref) => {
  const [isFocused, setIsFocused] = useState(false)
  const { className, labelText, ...inputProps } = props

  const onBlur = () => {
    setIsFocused(false)
    props.onBlur && props.onBlur()
  }

  const onFocus = () => {
    setIsFocused(true)
    props.onFocus && props.onFocus()
  }

  const labelCss = classNames('flying-label', {
    'focused-input': isFocused,
  })

  return (
    <div className={className} style={{ position: 'relative' }}>
      <label className={labelCss}>{labelText}</label>
      <Input ref={ref} {...inputProps} onFocus={onFocus} onBlur={onBlur} />
    </div>
  )
})

export const SelectComponent = forwardRef((props, ref) => {
  const {
    className,
    options,
    labelText,
    loading,
    onFilterItems,
    theme,
    ...inputProps
  } = props

  const labelCss = classNames(themedClass('flying-label', theme), {
    'focused-input': !!props.value,
  })

  const onSearch = value => {
    onFilterItems && onFilterItems(value)
  }

  return (
    <div className={className} style={{ position: 'relative' }}>
      <label className={labelCss}>{labelText}</label>
      <Select
        ref={ref}
        className={themedClass('input-select', theme)}
        suffixIcon={<Icons.ExpandIcon expanded={true} />}
        dropdownRender={menuNode => (
          <LoadingIndicator
            loading={loading}
            className={themedClass('input-select-dropdown', theme)}
          >
            <ScrollArea autoHeight>{menuNode}</ScrollArea>
          </LoadingIndicator>
        )}
        showSearch={!!onFilterItems}
        onSearch={onSearch}
        filterOption={() => true}
        {...inputProps}
      >
        {options.map(option => (
          <Option value={option.value} key={option.value}>
            <div className="select-option">{option.label}</div>
          </Option>
        ))}
      </Select>
    </div>
  )
})
