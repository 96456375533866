import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormattedMessage, useIntl } from 'react-intl'
import { Redirect } from 'react-router-dom'

import SplashScreenBase from './SplashScreenBase'
import { PAGE_TITLE } from '../app/constants'
import Sitemap from '../app/sitemap'
import { ErrorAlert } from '../components/Alerts'
import { DotsLoader } from '../components/LoadingIndicators'
import { Button } from '../components/form/Buttons'
import { VersionNumber } from '../components/version-info/VersionNumber'
import { login, useAuthentication } from '../hooks/useAuthentication'
import useCardsFilter from '../hooks/useCardsFilter'
import { useCurrentStation } from '../hooks/useCurrentStation'

const LoginPage = () => {
  const [currentUser] = useAuthentication()
  const { stationId } = useCurrentStation()
  const [loginError, setLoginError] = useState(null)
  const [, , resetFilter] = useCardsFilter()
  const [inProgress, setInProgress] = useState(false)

  const { formatMessage: f } = useIntl()

  useEffect(() => {
    resetFilter() // always reset filter after user changed
  }, [currentUser, resetFilter])

  const formSubmit = async e => {
    e.preventDefault()
    if (inProgress) return

    setInProgress(true)
    const loginSuccess = await login(
      new URLSearchParams(new FormData(e.target))
    )

    setInProgress(false)
    if (!loginSuccess) {
      setLoginError(true)
    }
  }

  if (currentUser) {
    return <Redirect to={Sitemap.createMonitorLink(stationId)} />
  }

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE}</title>
      </Helmet>
      <div className="header" />
      <div className="main-body">
        <SplashScreenBase currentTime={moment()}>
          <div className="login-header">
            <FormattedMessage id="auth.login-page-header" />
          </div>

          <Form onSubmit={formSubmit} className="login-form">
            <Form.Item>
              <Input
                size="large"
                name="username"
                placeholder={f({ id: 'auth.username' })}
              />
            </Form.Item>

            <Form.Item>
              <Input
                size="large"
                type="password"
                placeholder={f({ id: 'auth.password' })}
                name="password"
              />
            </Form.Item>

            {loginError && <ErrorAlert errorId="auth.error" />}

            <Button block type="primary" htmlType="submit" size="large">
              <FormattedMessage id="auth.log-in" />
              {inProgress && <DotsLoader />}
            </Button>
          </Form>
        </SplashScreenBase>
      </div>
      <div className="footer">
        <VersionNumber />
      </div>
    </>
  )
}

export default LoginPage
