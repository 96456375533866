export const DriverTypesEnum = {
  EMPLOYEE: 'E',
  LOGISTIC_SERVICE_PROVIDER: 'D',
  INDEPENDENT_CONTRACTOR: 'Q',
}

export const driverTypeToMessageId = (type, intl) => {
  switch (type) {
    case DriverTypesEnum.LOGISTIC_SERVICE_PROVIDER:
      return intl.formatMessage({ id: 'driver-employment.lsp' })
    case DriverTypesEnum.INDEPENDENT_CONTRACTOR:
      return intl.formatMessage({ id: 'driver-employment.contractor-short' })
    default:
      return intl.formatMessage({ id: 'app.company-name' })
  }
}
