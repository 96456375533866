import { useApolloClient } from '@apollo/client'
import { gql } from '@apollo/client'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'

import { useNonOptimisticMutation } from './graphQl/useNonOptimisticMutation'
import { useOptimisticMutation } from './graphQl/useOptimisticMutation'
import useCardsFilter from './useCardsFilter'
import { useCurrentStation } from './useCurrentStation'
import { useLocalDateTime } from './useLocalDateTime'
import { ColumnsEnum } from '../app/constants'
import { MilestonesEnum } from '../app/enums/MilestonesEnum'
import {
  updateCardsList,
  updateSingleSubjob,
} from '../app/graphQl/cacheManager'
import {
  ACCEPT_DRIVER,
  ACKNOWLEDGE_INSTRUCTIONS,
  ADD_CHARGES,
  ADD_DISPATCHER_NOTE,
  ARCHIVE_CARD,
  ASSIGN_DRIVER,
  CARD_DETAIL_BASE,
  CLOSE_ALARM,
  CONFIRM_ALERT,
  CONFIRM_CHARGES,
  CREATE_REICE_SUBJOB,
  CREATE_SUBJOB,
  DECLINE_CARD,
  DISPUTE_CHARGES,
  DROP_TO_DOCK,
  GROUP_CARDS,
  PICK_FROM_DOCK,
  REMOVE_DISPATCHER_NOTE,
  SET_ALARM,
  SPLIT_CARDS_GROUP,
  UNASSIGN_DRIVER,
  UPDATE_REICE_SUBJOB,
  UPDATE_STACK,
  UPDATE_SUBJOB,
} from '../app/graphQl/schema/Cards'
import { GET_CARDS } from '../app/graphQl/schema/Cards'
import { GET_STACKS } from '../app/graphQl/schema/Stacks'
import { createReminderField, updateSubjobCard } from '../utils/card-utils'
import { updateCardsSubjobs } from '../utils/common-utils'
import { toDateTimeVariable } from '../utils/date-utils'
import { convertFilterToQuery } from '../utils/filter-utils'

const UPDATE_DRIVER = gql`
  query getCards($filter: CardsFilter!) {
    listCards(filter: $filter) {
      stack {
        id
      }
      driver {
        id
      }
    }
  }
`

const UPDATE_MILESTONE = gql`
  query getCards($filter: CardsFilter!) {
    listCards(filter: $filter) {
      milestone {
        code
      }
    }
  }
`

const SET_PENDING_MILESTONE = gql`
  query getCards($filter: CardsFilter!) {
    listCards(filter: $filter) {
      id
      skipNextUpdate @client
      pendingUpdate @client
    }
  }
`
const SET_REMINDER = gql`
  query getCards($filter: CardsFilter!) {
    listCards(filter: $filter) {
      id
      stack {
        id
      }
      reminder {
        iso
      }
    }
  }
`
const SET_GROUP_PARENT = gql`
  query getCards($filter: CardsFilter!) {
    listCards(filter: $filter) {
      id
      groupParent
      groupChildren {
        ...cardDetailBase
      }
    }
  }
  ${CARD_DETAIL_BASE}
`
const REMOVE_REMINDER = gql`
  query getCards($filter: CardsFilter!) {
    listCards(filter: $filter) {
      id
      reminder {
        iso
      }
    }
  }
`
const UPDATE_NOTES_FRAGMENT = gql`
  fragment updateDispatcherNotes on Card {
    id
    dispatcherNotes {
      id
      text
    }
  }
`
const UPDATE_CHARGES_FRAGMENT = gql`
  fragment updateCharges on Card {
    id
    agentCharges {
      type
      amount
      loading @client
    }
  }
`
const ACK_INSTRUCTIONS_FRAGMENT = gql`
  fragment ackInstructions on Card {
    id
    specialInstructions {
      text
      wasAcknowledged
    }
  }
`
// TODO: notifications about failures
export function useCards() {
  const { currentTimeZone } = useLocalDateTime()
  const [cardsFilter] = useCardsFilter()
  const { stationId } = useCurrentStation()
  const client = useApolloClient()

  const [updateStack] = useOptimisticMutation(UPDATE_STACK, 'moveToStack')
  const [assignDriverMutation] = useOptimisticMutation(
    ASSIGN_DRIVER,
    'assignDriver'
  )
  const [unassignDriverMutation] = useOptimisticMutation(
    UNASSIGN_DRIVER,
    'unassignDriver'
  )
  const [acceptDriverMutation] = useOptimisticMutation(
    ACCEPT_DRIVER,
    'acceptDriver'
  )
  const [setAlarmMutation] = useOptimisticMutation(SET_ALARM, 'setAlarm')
  const [groupCardsMutation] = useOptimisticMutation(GROUP_CARDS, 'groupCards')
  const [splitCardsGroupMutation] = useOptimisticMutation(
    SPLIT_CARDS_GROUP,
    'splitCardsGroup'
  )
  const [createSubJobMutation] = useNonOptimisticMutation(
    CREATE_SUBJOB,
    'createSubJob'
  )
  const [updateSubJobMutation] = useOptimisticMutation(
    UPDATE_SUBJOB,
    'updateSubJob'
  )
  const [createReiceMutation] = useNonOptimisticMutation(
    CREATE_REICE_SUBJOB,
    'createReiceSubJob'
  )
  const [updateReiceMutation] = useOptimisticMutation(
    UPDATE_REICE_SUBJOB,
    'updateReiceSubJob'
  )
  const [confirmAlertMutation] = useOptimisticMutation(
    CONFIRM_ALERT,
    'confirmAlert'
  )
  const [addDispatcherNoteMutation] = useOptimisticMutation(
    ADD_DISPATCHER_NOTE,
    'addDispatcherNote'
  )
  const [removeDispatcherNoteMutation] = useOptimisticMutation(
    REMOVE_DISPATCHER_NOTE,
    'removeDispatcherNote'
  )
  const [declineCardMutation] = useOptimisticMutation(
    DECLINE_CARD,
    'declineCard'
  )
  const [confirmChargesMutation] = useOptimisticMutation(
    CONFIRM_CHARGES,
    'confirmCharges'
  )
  const [archiveCardMutation] = useOptimisticMutation(
    ARCHIVE_CARD,
    'archiveCard'
  )
  const [disputeChargesMutation] = useOptimisticMutation(
    DISPUTE_CHARGES,
    'disputeCharges'
  )
  const [ackInstructionsMutation] = useOptimisticMutation(
    ACKNOWLEDGE_INSTRUCTIONS,
    'acknowledgeInstructions'
  )

  const [addChargesMutation] = useOptimisticMutation(ADD_CHARGES, 'addCharges')
  const [closeAlarmMutation] = useOptimisticMutation(CLOSE_ALARM, 'closeAlarm')

  const [pickFromDockMutation] = useOptimisticMutation(
    PICK_FROM_DOCK,
    'pickFromDock'
  )

  const [dropToDockMutation] = useOptimisticMutation(DROP_TO_DOCK, 'dropToDock')

  const queryFilter = useMemo(
    () => convertFilterToQuery(cardsFilter, stationId),
    [cardsFilter, stationId]
  )

  const getAllCards = useCallback(
    cache => {
      const result = cache.readQuery({
        query: GET_CARDS,
        variables: queryFilter,
      })
      if (!result) {
        console.log('no data in cache')
        return []
      }
      return result.listCards
    },
    [queryFilter]
  )

  const getCard = (cache, id) => _.find(getAllCards(cache), c => c.id === id)
  const getTopCard = (cache, card) =>
    card.groupParent ? getCard(cache, card.groupParent) : card

  const getAllStacks = cache => {
    const { listStacks } = cache.readQuery({
      query: GET_STACKS,
      variables: { stationId: stationId },
    })
    return listStacks
  }

  const updateCachedCardsStack = (cache, cardsToMove, newStack, reminder) => {
    const dataToUpdate = _.compact(cardsToMove).map(d => ({
      ...d,
      stack: newStack ? newStack : d.stack,
      reminder: reminder ? createReminderField(reminder) : d.reminder,
    }))
    updateCardsList(
      cache,
      _.unionBy(dataToUpdate, getAllCards(cache), 'id'),
      queryFilter,
      SET_REMINDER
    )
  }

  const setPendingMilestone = (cache, cards, isPending) => {
    const updatedList = _.map(cards, card => ({
      id: card.id,
      skipNextUpdate: isPending,
      pendingUpdate: isPending,
      __typename: card.__typename,
    }))
    updateCardsList(
      cache,
      _.unionBy(updatedList, getAllCards(cache), 'id'),
      queryFilter,
      SET_PENDING_MILESTONE
    )
  }

  const updateCardNotes = (cache, card, notes) => {
    cache.writeFragment({
      id: `Card:${card.id}`,
      fragment: UPDATE_NOTES_FRAGMENT,
      data: {
        __typename: card.__typename,
        id: card.id,
        dispatcherNotes: notes,
      },
    })
  }

  const updateAgentCharges = (cache, card, charges) => {
    cache.writeFragment({
      id: `Card:${card.id}`,
      fragment: UPDATE_CHARGES_FRAGMENT,
      data: {
        __typename: card.__typename,
        id: card.id,
        agentCharges: _.map(charges, ch => ({
          __typename: 'AgentCharge',
          ...ch,
        })),
      },
    })
  }

  function updateMilestone(cardsToUpdate, cache, newMilestone) {
    const updated = _.map(cardsToUpdate, card => ({
      id: card.id,
      milestone: {
        ...card.milestone,
        code: newMilestone,
      },
      __typename: card.__typename,
    }))

    updateCardsList(
      cache,
      _.unionBy(updated, getAllCards(client), 'id'),
      queryFilter,
      UPDATE_MILESTONE
    )
  }

  const confirmAlert = (cards, reminder, stack, pickupEta) => {
    const cardIds = _.map(cards, c => c.id)
    const cardsToMove = [
      ..._.flatten(_.map(cards, c => c.groupChildren)),
      ...cards,
    ]

    setPendingMilestone(client, cardsToMove, true)

    confirmAlertMutation({
      variables: {
        cardIds: cardIds,
        stackId: stack ? stack.id : null,
        reminder: reminder
          ? toDateTimeVariable(reminder, currentTimeZone)
          : null,
        pickupEta: pickupEta
          ? toDateTimeVariable(pickupEta, currentTimeZone)
          : null,
      },
      cacheUpdate: cache => {
        let newStack = stack
        if (!newStack) {
          const stacks = getAllStacks(cache)
          newStack = _.find(stacks, s => s.name === ColumnsEnum.DECIDE)
        }
        updateCachedCardsStack(cache, cardsToMove, newStack, reminder)
      },
      onFinished: response => {
        if (!response || !response.data.confirmAlert.success)
          setPendingMilestone(client, cardsToMove, false)
      },
    })
  }

  const moveToStack = (cards, newStack) => {
    const cardIds = _.map(cards, c => c.id)
    const parents = _.compact(_.map(cards, c => c.groupParent))
    updateStack({
      variables: {
        cardIds: _.uniq([...cardIds, ...parents]),
        stack: newStack.id,
      },
      cacheUpdate: cache => {
        const cardsToMove = [
          ..._.flatten(_.map(cards, c => c.groupChildren)),
          ..._.map(parents, p => getCard(cache, p)),
          ...cards,
        ]
        updateCachedCardsStack(cache, cardsToMove, newStack)
      },
    })
  }

  const assignDriver = (card, driverId, stackId, destination, location) => {
    const topCard = card.groupParent ? getCard(client, card.groupParent) : card
    const cardsToUpdate = [topCard, ...topCard.groupChildren]
    setPendingMilestone(client, cardsToUpdate, true)

    assignDriverMutation({
      variables: {
        cardId: card.id,
        driverId: driverId,
        stack: stackId,
        destination: destination,
        location,
      },
      cacheUpdate: cache => {
        const updated = _.map(cardsToUpdate, card => ({
          id: card.id,
          __typename: card.__typename,
          stack: {
            id: stackId,
            __typename: 'Stack',
          },
          driver: {
            id: driverId,
            __typename: 'Driver',
          },
        }))

        updateCardsList(
          cache,
          _.unionBy(updated, getAllCards(client), 'id'),
          queryFilter,
          UPDATE_DRIVER
        )
      },
      onFinished: response => {
        if (!response || !response.data.assignDriver.success)
          setPendingMilestone(client, cardsToUpdate, false)
      },
    })
  }

  const unassignDriver = card => {
    const topCard = card.groupParent ? getCard(client, card.groupParent) : card
    const cardsToUpdate = [topCard, ...topCard.groupChildren]

    unassignDriverMutation({
      variables: {
        cardId: card.id,
      },
      cacheUpdate: cache => {
        const updated = _.map(cardsToUpdate, card => ({
          id: card.id,
          driver: null,
          __typename: card.__typename,
        }))

        updateCardsList(
          cache,
          _.unionBy(updated, getAllCards(client), 'id'),
          queryFilter,
          UPDATE_DRIVER
        )
      },
    })
  }

  const acceptDriver = card => {
    const topCard = card.groupParent ? getCard(client, card.groupParent) : card
    const cardsToUpdate = [topCard, ...topCard.groupChildren]
    setPendingMilestone(client, cardsToUpdate, true)

    acceptDriverMutation({
      variables: {
        cardId: card.id,
        driverId: card.driver.code,
      },
      cacheUpdate: cache => {
        updateMilestone(cardsToUpdate, cache, MilestonesEnum.ACCEPTED)
      },
      onFinished: response => {
        if (!response?.data?.acceptDriver?.success)
          setPendingMilestone(client, cardsToUpdate, false)
      },
    })
  }

  const setAlarm = (card, dateTime, stack) => {
    setAlarmMutation({
      variables: {
        cardId: card.id,
        dateTime: toDateTimeVariable(dateTime, currentTimeZone),
        stackId: stack.id,
      },
      cacheUpdate: cache => {
        const topCard = getTopCard(cache, card)
        const cardsToUpdate = [topCard, ...topCard.groupChildren]
        updateCachedCardsStack(cache, cardsToUpdate, stack, dateTime)
      },
    })
  }

  const closeAlarm = card => {
    closeAlarmMutation({
      variables: { cardId: card.id },
      cacheUpdate: cache => {
        const topCard = getTopCard(cache, card)
        const cardsToUpdate = [topCard, ...topCard.groupChildren]
        const updated = _.map(cardsToUpdate, card => ({
          id: card.id,
          __typename: card.__typename,
          reminder: null,
        }))
        updateCardsList(
          cache,
          _.unionBy(updated, getAllCards(client), 'id'),
          queryFilter,
          REMOVE_REMINDER
        )
      },
    })
  }

  const groupCards = (cardId, childCards) => {
    const newChildren = _.filter(childCards, c => c.id !== cardId)
    groupCardsMutation({
      variables: {
        parentCard: cardId,
        childCards: _.map(newChildren, c => c.id),
      },
      cacheUpdate: cache => {
        const listCards = getAllCards(cache)
        const updated = _.map(newChildren, c => ({
          ...c,
          groupParent: cardId,
        }))

        updateCardsList(
          cache,
          _.unionBy(updated, listCards, 'id'),
          queryFilter,
          SET_GROUP_PARENT
        )
      },
    })
  }

  const splitCardsGroup = useCallback(
    card => {
      splitCardsGroupMutation({
        variables: { parentCard: card.id },
        cacheUpdate: cache => {
          const listCards = getAllCards(cache)
          const updated = _.map(card.groupChildren, c => ({
            ...c,
            groupParent: null,
          }))

          updated.push({ ...card, groupChildren: [] })

          updateCardsList(
            cache,
            _.unionBy(updated, listCards, 'id'),
            queryFilter,
            SET_GROUP_PARENT
          )
        },
      })
    },
    [getAllCards, queryFilter, splitCardsGroupMutation]
  )

  const declineCard = (card, comment) => {
    setPendingMilestone(client, [card], true)

    declineCardMutation({
      variables: {
        cardId: card.id,
        comment: comment,
      },
      cacheUpdate: () => {},
      onFinished: response => {
        if (!response || !response.data.declineCard.success)
          setPendingMilestone(client, [card], false)
      },
    })
  }

  const createSubJob = subjob => {
    createSubJobMutation({
      variables: { subjob: subjob },
      cacheUpdate: (cache, { createSubJob }) => {
        // TODO: can be removed
        const listCards = getAllCards(cache)
        const parentCards = updateCardsSubjobs(listCards, [createSubJob])
        parentCards.push(createSubJob)
        const newData = _.unionBy(parentCards, listCards, 'id')
        updateCardsList(cache, newData, queryFilter)
      },
    })
  }

  const updateSubJob = subjob => {
    updateSubJobMutation({
      variables: { subjob: subjob },
      cacheUpdate: cache => {
        const editedCard = updateSubjobCard(subjob)
        updateSingleSubjob(cache, editedCard)
      },
    })
  }

  const createReiceSubJob = subjob => {
    createReiceMutation({
      variables: { subjob: subjob },
      cacheUpdate: (cache, data) => {
        console.log(data)
        const { createReiceSubJob } = data
        const listCards = getAllCards(cache)
        const parentCards = updateCardsSubjobs(listCards, [createReiceSubJob])
        parentCards.push(createReiceSubJob)
        const newData = _.unionBy(parentCards, listCards, 'id')
        updateCardsList(cache, newData, queryFilter)
      },
    })
  }

  const updateReiceSubJob = subjob => {
    updateReiceMutation({
      variables: { subjob: subjob },
      cacheUpdate: cache => {
        const editedCard = updateSubjobCard(subjob)
        updateSingleSubjob(cache, editedCard)
      },
    })
  }

  const changeStack = (cardsToMove, toStack, pickupEta) => {
    const fromStack = _.head(cardsToMove).stack
    if (
      fromStack.name === ColumnsEnum.NEWJOBS &&
      toStack.name === ColumnsEnum.DECIDE
    ) {
      confirmAlert(cardsToMove, null, toStack, pickupEta)
    } else {
      moveToStack(cardsToMove, toStack)
    }
  }

  const updateReminder = (card, dateTime, stack) => {
    if (card.stack.name === ColumnsEnum.NEWJOBS) {
      confirmAlert([card], dateTime, stack)
    } else {
      setAlarm(card, dateTime, stack)
    }
  }

  const addDispatcherNote = (card, note) => {
    addDispatcherNoteMutation({
      variables: { cardId: card.id, note },
      cacheUpdate: cache => {
        updateCardNotes(cache, card, [
          ...card.dispatcherNotes,
          {
            id: null,
            text: note,
            __typename: 'DispatcherNote',
          },
        ])
      },
    })
  }

  const removeDispatcherNote = (card, noteId) => {
    removeDispatcherNoteMutation({
      variables: { cardId: card.id, noteId },
      cacheUpdate: cache => {
        updateCardNotes(
          cache,
          card,
          _.filter(card.dispatcherNotes, note => note.id !== noteId)
        )
      },
    })
  }

  const removeFromCache = useCallback(
    cards => {
      const listCards = getAllCards(client)
      let parents = updateCardsSubjobs(listCards, [], cards)
      const newCards = _.differenceBy(listCards, cards, 'id')
      updateCardsList(client, _.unionBy(parents, newCards, 'id'), queryFilter)
    },
    [client, getAllCards, queryFilter]
  )

  const markFinished = (card, cache, finished = true) => {
    const updatedCard = {
      ...card,
      finished: finished,
      skipNextUpdate: finished,
    }
    const newData = _.unionBy([updatedCard], getAllCards(cache), 'id')
    updateCardsList(cache, newData, queryFilter)
  }

  const confirmCharges = card => {
    markFinished(card, client)

    confirmChargesMutation({
      variables: { cardId: card.id },
      cacheUpdate: () => {},
      onFinished: response => {
        if (!response || !response.data.confirmCharges.success)
          markFinished(card, client, false)
      },
    })
  }

  const disputeCharges = (card, comment) => {
    markFinished(card, client)

    disputeChargesMutation({
      variables: { cardId: card.id, comment },
      cacheUpdate: () => {},
      onFinished: response => {
        if (!response || !response.data.disputeCharges.success)
          markFinished(card, client, false)
      },
    })
  }

  const archiveJob = card => {
    markFinished(card, client)

    archiveCardMutation({
      variables: { cardId: card.id },
      cacheUpdate: () => {},
      onFinished: response => {
        if (!response || !response.data.confirmCharges.success)
          markFinished(card, client, false)
      },
    })
  }

  const addCharges = (card, charges) => {
    const newCharges = _.map(charges, ch => ({ ...ch, loading: true }))
    updateAgentCharges(client, card, [...card.agentCharges, ...newCharges])

    addChargesMutation({
      variables: { cardId: card.id, charges },
      cacheUpdate: () => {},
      onFinished: response => {
        if (!response || !response.data?.addCharges?.success)
          updateAgentCharges(client, card, card.agentCharges)
      },
    })
  }

  const acknowledgeInstructions = card => {
    ackInstructionsMutation({
      variables: { cardId: card.id },
      cacheUpdate: cache => {
        cache.writeFragment({
          id: `Card:${card.id}`,
          fragment: ACK_INSTRUCTIONS_FRAGMENT,
          data: {
            __typename: card.__typename,
            id: card.id,
            specialInstructions: {
              ...card.specialInstructions,
              wasAcknowledged: true,
            },
          },
        })
      },
    })
  }

  const pickFromDock = (card, date) => {
    if (!card) return

    const topCard = card.groupParent ? getCard(client, card.groupParent) : card
    const cardsToUpdate = [topCard, ...topCard.groupChildren]
    setPendingMilestone(client, cardsToUpdate, true)

    pickFromDockMutation({
      variables: {
        cardId: card.id,
        driverId: card.driver?.code,
        date: toDateTimeVariable(date, currentTimeZone),
        pieces: card?.pieces,
      },
      cacheUpdate: cache => {
        updateMilestone(cardsToUpdate, cache, MilestonesEnum.LOADED)
      },
      onFinished: response => {
        if (!response?.data?.pickFromDock?.success)
          setPendingMilestone(client, cardsToUpdate, false)
      },
    })
  }

  const dropToDock = (card, date) => {
    if (!card) return

    const topCard = card.groupParent ? getCard(client, card.groupParent) : card
    const cardsToUpdate = [topCard, ...topCard.groupChildren]
    setPendingMilestone(client, cardsToUpdate, true)

    dropToDockMutation({
      variables: {
        cardId: card.id,
        driverId: card.driver?.code,
        date: toDateTimeVariable(date, currentTimeZone),
        pieces: card?.pieces,
      },
      cacheUpdate: cache => {
        const topCard = getTopCard(cache, card)
        const cardsToUpdate = [topCard, ...topCard.groupChildren]
        updateMilestone(cardsToUpdate, cache, MilestonesEnum.UNLOADED)
      },
      onFinished: response => {
        if (!response?.data?.dropToDock?.success)
          setPendingMilestone(client, cardsToUpdate, false)
      },
    })
  }

  return {
    changeStack,
    assignDriver,
    unassignDriver,
    acceptDriver,
    updateReminder,
    closeAlarm,
    groupCards,
    splitCardsGroup,
    createSubJob,
    updateSubJob,
    addDispatcherNote,
    removeDispatcherNote,
    createReiceSubJob,
    updateReiceSubJob,
    removeFromCache,
    declineCard,
    confirmCharges,
    disputeCharges,
    archiveJob,
    addCharges,
    acknowledgeInstructions,
    pickFromDock,
    dropToDock,
  }
}
