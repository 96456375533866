import { gql } from '@apollo/client'

import { MUTATION_RESPONSE } from './Common'

//#region fragments
export const DRIVER_DETAIL = gql`
  fragment driverDetail on Driver {
    id
    code
    name
    nickname
    assignedCards {
      id
      milestone {
        code
      }
      groupParent
    }
    phone
    status
    driverType
    contractor @client
    isActive
    availability {
      from {
        hours
        minutes
      }
      to {
        hours
        minutes
      }
    }
    vehicle {
      dimensions
      type
    }
    position {
      lat
      lng
    }
    lastReported {
      iso
    }
  }
`
//#endregion

//#region queries
export const GET_DRIVERS = gql`
  query getDrivers($filter: DriversFilter!) {
    listDrivers(filter: $filter) {
      ...driverDetail
    }
  }
  ${DRIVER_DETAIL}
`

export const GET_DRIVER = gql`
  query getDriver($driverId: ID!) {
    getDriver(driverId: $driverId) @client {
      ...driverDetail
    }
  }
  ${DRIVER_DETAIL}
`

//#endregion

//#region mutations
export const SET_DRIVERS_STATE = gql`
  mutation SetDriversState($driverIds: [ID], $isActive: Boolean!) {
    setDriversState(driverIds: $driverIds, isActive: $isActive) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`
//#endregion

//#region subscriptions
export const DRIVER_SUBSCRIPTION = gql`
  subscription driverUpdated($filter: DriversFilter) {
    driverUpdated(filter: $filter) {
      operation
      changeData {
        ...driverDetail
      }
    }
  }
  ${DRIVER_DETAIL}
`
//#endregion
