import classNames from 'classnames'
import { forwardRef } from 'react'
import ReactDOM from 'react-dom'

import ScrollArea from '../ScrollArea'

const ParallaxScroll = forwardRef(({ children, visible }, ref) => {
  const wrapCss = classNames('paralax-scroll', {
    'paralax-scroll-hidden': !visible,
  })

  return ReactDOM.createPortal(
    <ScrollArea ref={ref} wrapClassName={wrapCss}>
      {children}
    </ScrollArea>,
    document.body
  )
})

export default ParallaxScroll
