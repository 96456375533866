import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import AdditionalCharges from './AdditionalCharges'
import AuditChargesBox from './AuditChargesBox'
import FlowWindow from './common/FlowWindow'
import { ModifyHeader } from './common/ModifyHeader'
import { CardPopupState } from '../../app/enums/CardPopupState'
import { CURRENCIES } from '../../app/enums/Currencies'
import {
  DriverTypesEnum,
  driverTypeToMessageId,
} from '../../app/enums/DriverType'
import { GET_ADDITIONAL_CHARGES } from '../../app/graphQl/schema/AdditionalCharges'
import { useCardContext } from '../../hooks/useCardPopupContext'
import { useCards } from '../../hooks/useCards'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { isUnloaded } from '../../utils/card-utils'
import { fromServerDate } from '../../utils/date-utils'
import { MISSING_VALUE, missingDataPlaceholder } from '../../utils/string-utils'
import { ShortMonthTimeFormat } from '../DateTimeFormats'
import { FlowContentHeader, FlowContentHeaderSmall } from '../ModalsTypography'
import ScrollArea from '../ScrollArea'
import AssignedDriver from '../drivers/AssignedDriver'
import { Button } from '../form/Buttons'
import { ChargeReviewMap } from '../maps/ChargeReviewMap'
import CurrencyFormat from '../user-units/CurrencyFormat'

const chargeReviewTimeout = 250

const ChargeCalculationsRow = ({
  headerId,
  units,
  unitPrice,
  totalSum,
  alwaysVisible,
}) => {
  let unitsText = '—'
  if (units) {
    unitsText = (
      <p>
        {units} @{' '}
        <CurrencyFormat value={unitPrice} currency={CURRENCIES.US_DOLLAR} />
      </p>
    )
  }

  if (!alwaysVisible && (!totalSum || totalSum === 0)) return null

  return (
    <div className="expenses-row">
      <div className="expenses-value">
        <h4 className="text-uppercase">
          <FormattedMessage id={headerId} />
        </h4>
        <div className="multi-part-value">
          {unitsText}
          <p>
            <CurrencyFormat value={totalSum} currency={CURRENCIES.US_DOLLAR} />
          </p>
        </div>
      </div>
    </div>
  )
}

const ChargeHistoryRow = ({ children, headerId, uppercase, theme }) => {
  const css = classNames('charges-history-row text-important', {
    'text-uppercase': uppercase,
  })

  return (
    <>
      <FlowContentHeaderSmall theme={theme}>
        <FormattedMessage id={headerId} />
      </FlowContentHeaderSmall>
      <div className={css}>{children}</div>
    </>
  )
}

const ChargeReview = ({ card }) => {
  const intl = useIntl()
  const { closePopup, executeAfterClose, readOnly } = useCardContext()
  const { confirmCharges, disputeCharges, addCharges, archiveJob } = useCards()
  const [auditOpened, setAuditOpened] = useState(false)
  const [theme] = useSelectedTheme()
  const { data } = useQuery(GET_ADDITIONAL_CHARGES, {
    variables: {
      station: card?.station?.id,
    },
  })

  const archiveVersion =
    card?.driver?.driverType === DriverTypesEnum.LOGISTIC_SERVICE_PROVIDER

  const onAuditOpened = () => {
    setAuditOpened(true)
  }

  const onAuditClosed = () => {
    setAuditOpened(false)
  }

  const onAcceptClick = () => {
    executeAfterClose(() => {
      setTimeout(() => {
        confirmCharges(card)
      }, [chargeReviewTimeout])
    })
    closePopup()
  }

  const onArchiveClick = () => {
    executeAfterClose(() => {
      setTimeout(() => {
        archiveJob(card)
      }, [chargeReviewTimeout])
    })
    closePopup()
  }

  const onDisputeSubmitted = comment => {
    executeAfterClose(() => {
      setTimeout(() => {
        disputeCharges(card, comment)
      }, [chargeReviewTimeout])
    })
    closePopup()
  }

  const onChargeAdded = charges => {
    addCharges(card, charges)
  }

  const tooltipMessage = 'card-popup.disabled-charges'
  const isCardUnloaded = isUnloaded(card)
  const confirmFunc = archiveVersion ? onArchiveClick : onAcceptClick

  const confirmBtnLabel = archiveVersion
    ? 'card-popup.archive-job'
    : 'card-popup.accept-charges'

  const auditChargesDisabled = !isCardUnloaded || readOnly || archiveVersion
  const driverTypeMsg = driverTypeToMessageId(card?.driver?.driverType, intl)
  const headerMessage = isEmpty(driverTypeMsg)
    ? 'card-popup.resolution'
    : 'card-popup.resolution-type'

  return (
    <FlowWindow
      card={card}
      page={CardPopupState.Resolution.Page}
      contentClassName="flow-content-charge-review"
      header={
        <ModifyHeader
          messageId={headerMessage}
          messageParams={{
            driverType: driverTypeMsg,
          }}
          onConfirm={confirmFunc}
          editing={true}
          confirmMsg={confirmBtnLabel}
          readOnly={readOnly}
          cancelBtnProps={{ style: { display: 'none' } }}
          confirmBtnProps={{
            disabled: readOnly || !isCardUnloaded,
            disabledTooltip: <FormattedMessage id={tooltipMessage} />,
          }}
        />
      }
    >
      {auditOpened && (
        <AuditChargesBox
          onSubmit={onDisputeSubmitted}
          onCancel={onAuditClosed}
        />
      )}

      <AssignedDriver card={card} />

      <div className="charges-delivery-history">
        <ChargeReviewMap card={card} />

        <div className="charges-delivery-overview">
          <ChargeHistoryRow
            headerId="card-popup.charges-distance"
            theme={theme}
          >
            {missingDataPlaceholder}
          </ChargeHistoryRow>

          <ChargeHistoryRow headerId="card.loaded-date" uppercase theme={theme}>
            {card.loaded ? (
              <ShortMonthTimeFormat date={fromServerDate(card.loaded.iso)} />
            ) : (
              MISSING_VALUE
            )}
          </ChargeHistoryRow>

          <ChargeHistoryRow
            headerId="card.unloaded-date"
            uppercase
            theme={theme}
          >
            {card.unloaded ? (
              <ShortMonthTimeFormat date={fromServerDate(card.unloaded.iso)} />
            ) : (
              MISSING_VALUE
            )}
          </ChargeHistoryRow>
        </div>
      </div>

      <div className="charges-calculations">
        <FlowContentHeader theme={theme}>
          <FormattedMessage id="card-popup.charges-calculations" />
        </FlowContentHeader>

        <div className="charges-total">
          <div>
            <h3 className="text-uppercase">
              <FormattedMessage id="card-popup.charges-total" />
            </h3>
            <div className="total-sum text-important">
              <CurrencyFormat
                value={card.totalCharges}
                currency={CURRENCIES.US_DOLLAR}
              />
            </div>
          </div>

          <Button
            className="audit-btn"
            onClick={onAuditOpened}
            ghost={isCardUnloaded && !auditChargesDisabled}
            disabled={auditChargesDisabled}
            type="primary"
            size="large"
            disabledTooltip={
              !archiveVersion && <FormattedMessage id={tooltipMessage} />
            }
            theme={theme}
          >
            <FormattedMessage id="card-popup.charges-audit-btn" />
          </Button>
        </div>

        <div className="expenses-table space-between">
          <ScrollArea wrapClassName="expenses-column">
            <ChargeCalculationsRow
              headerId="card-popup.charges-distance-driven"
              // units={missingDataPlaceholder}
              // unitPrice={missingDataPlaceholder}
              totalSum={card.totalDriveCharge}
              alwaysVisible
            />

            <ChargeCalculationsRow
              headerId="card-popup.charges-excess-weight"
              totalSum={card.totalExcessWeightCharge}
            />

            <ChargeCalculationsRow
              headerId="card-popup.charges-wait"
              // units={missingDataPlaceholder}
              // unitPrice={missingDataPlaceholder}
              totalSum={card.totalWaitCharge}
            />

            <ChargeCalculationsRow
              headerId="card-popup.charges-weekend"
              // units={missingDataPlaceholder}
              // unitPrice={missingDataPlaceholder}
              totalSum={card.totalWeekendCharge}
            />

            <ChargeCalculationsRow
              headerId="card-popup.charges-holiday"
              // units={missingDataPlaceholder}
              // unitPrice={missingDataPlaceholder}
              totalSum={card.totalHolidayCharge}
            />
          </ScrollArea>

          <AdditionalCharges
            charges={card?.agentCharges}
            possibleCharges={data?.getAdditionalCharges}
            onChargeAdded={onChargeAdded}
            theme={theme}
          />
        </div>
      </div>
    </FlowWindow>
  )
}

export default ChargeReview
