import Gauge from './Gauge'
import { SensorPanel } from './SensorPanel'

const HumiditySensor = ({ value, lastReported }) => (
  <SensorPanel headerMessageId="sensors.humidity" lastReported={lastReported}>
    <Gauge
      value={value}
      minValue={0}
      maxValue={100}
      formatValue={val => <div>{val}%</div>}
    />
  </SensorPanel>
)

export default HumiditySensor
