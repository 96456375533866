import classNames from 'classnames'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import SplashScreenBase from './SplashScreenBase'
import { currentVersion } from '../app/config'
import { ThemesEnum } from '../app/enums/Themes'
import { CloseIcon } from '../components/Icons'
import LoadingIndicator from '../components/LoadingIndicators'
import PanelHandle from '../components/PanelHandle'
import { Button } from '../components/form/Buttons'
import ChangelogContent from '../components/version-info/ChangelogContent'
import { useActiveStation } from '../hooks/useActiveStation'
import { useCurrentStation } from '../hooks/useCurrentStation'
import useHydrateCache from '../hooks/useHydrateCache'
import { useLocalDateTime } from '../hooks/useLocalDateTime'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { getStationVendorLabel } from '../utils/common-utils'

export const LoadingBox = ({ onClosed }) => {
  const [lastSeenVersion, setLastSeenVersion] =
    useLocalStorage('qdd-last-version')

  const { stationId } = useCurrentStation()
  const { currentStation } = useActiveStation()
  const [wasClosed, setWasClosed] = useState(false)
  const [isChangelog, setIsChangelog] = useState(
    lastSeenVersion !== currentVersion
  )

  const { isLoading, loadingStarted } = useHydrateCache()

  useEffect(() => {
    if (!isLoading && loadingStarted && !isChangelog) {
      onClosed && onClosed()
      setWasClosed(true)
    }
  }, [isLoading, loadingStarted, isChangelog, onClosed])

  useEffect(() => {
    setWasClosed(false)
  }, [stationId])

  const { getCurrentTime } = useLocalDateTime()

  const onCloseChangelog = () => {
    setIsChangelog(false)

    if (!isLoading) {
      onClosed && onClosed()
    }
  }

  const onAckChangelog = () => {
    setLastSeenVersion(currentVersion)
    onCloseChangelog()
  }

  if ((!isLoading && loadingStarted && !isChangelog) || wasClosed) return null

  const css = classNames({ 'changelog-screen': isChangelog })

  return (
    <SplashScreenBase
      className={css}
      currentTime={getCurrentTime() || moment()}
      hideTimePanel={isChangelog}
      handle={
        isChangelog ? (
          <PanelHandle className="changelog-handle">
            <FormattedMessage id="changelog.handle-text" />
          </PanelHandle>
        ) : null
      }
    >
      <LoadingIndicator
        loading={!isChangelog}
        className="changelog-loader"
        loadingMsg={
          <div>
            {currentStation && (
              <p className="station-code text-important text-uppercase">
                <FormattedMessage id="main-header.station" />:{' '}
                {getStationVendorLabel(currentStation)}
              </p>
            )}
            <FormattedMessage id="app.setting-up" />
          </div>
        }
        theme={ThemesEnum.LIGHT}
      >
        {isChangelog && (
          <>
            <div className="changelog-toolbar">
              <Button ghost onClick={onCloseChangelog}>
                <FormattedMessage id="changelog.read-later" />
              </Button>
              <Button
                type="primary"
                onClick={onAckChangelog}
                icon={<CloseIcon />}
              >
                <FormattedMessage id="confirm.confirm-dismiss" />
              </Button>
            </div>
            <ChangelogContent theme={ThemesEnum.LIGHT} />
          </>
        )}
      </LoadingIndicator>
    </SplashScreenBase>
  )
}
