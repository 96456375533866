import { FormattedMessage } from 'react-intl'

import { formatFlightNumber } from '../../utils/common-utils'
import { fromServerDate } from '../../utils/date-utils'
import { MISSING_VALUE } from '../../utils/string-utils'
import { ShortDateTimeFormat } from '../DateTimeFormats'
import { TextImportant } from '../Typography'

export const FlightInfo = ({ flight, card, rowClass, fixedRowsCount }) => {
  const flightData = flight || {}
  return (
    <>
      <p className={rowClass}>
        <TextImportant>
          <FormattedMessage
            id="card-dispatch.flight-number"
            values={{ flightNumber: formatFlightNumber(flightData, ' ') }}
          />
        </TextImportant>
      </p>
      <p className={rowClass}>
        <FormattedMessage id="card-dispatch.flight-lockout" />{' '}
        {card.lockout ? (
          <ShortDateTimeFormat date={fromServerDate(card.lockout.iso)} />
        ) : (
          MISSING_VALUE
        )}
      </p>
      <p className={rowClass}>
        <FormattedMessage id="card-dispatch.flight-departs" />{' '}
        <span>
          {flightData.departure && (
            <ShortDateTimeFormat
              ignoreOffset
              date={fromServerDate(flightData.departure.iso)}
            />
          )}
        </span>
      </p>
      {fixedRowsCount && <p className={rowClass} />}
    </>
  )
}
