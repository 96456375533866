import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from 'apollo-utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import typePolicies from './typePolicies'
import { clearCurrentUser, getCurrentUser } from '../../hooks/useAuthentication'

const httpLink = new HttpLink({})

const GRAPHQL_ENDPOINT =
  (window.location.protocol === 'https:' ? 'wss://' : 'ws://') +
  window.location.host +
  '/graphql'

export const subcriptionClient = new SubscriptionClient(GRAPHQL_ENDPOINT, {
  lazy: true,
  reconnect: true,
  connectionParams: () => ({ authToken: getCurrentUser()?.accessToken }),
})

const wsLink = new WebSocketLink(subcriptionClient)

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const user = getCurrentUser()

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: user ? `Bearer ${user.accessToken}` : '',
    },
  }
})

const errorLink = onError(error => {
  console.error(error)

  const { networkError } = error
  const { operation } = getMainDefinition(error.operation.query)

  if (
    operation === 'subscription' &&
    networkError?.message === 'Unauthorized'
  ) {
    clearCurrentUser()
  }

  if (networkError && networkError.statusCode === 401) {
    clearCurrentUser()
  }
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const terminatingLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink.concat(httpLink)
)

export const cache = new InMemoryCache({
  typePolicies: typePolicies,
})

const apolloClient = new ApolloClient({
  cache: cache,
  link: ApolloLink.from([errorLink, terminatingLink]),
})

export default apolloClient
