import classNames from 'classnames'
import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  HeaderSizes,
  JobDetailHeader,
  JobHeaderLabel,
} from './ModalsTypography'
import { toIntlType } from '../app/enums/VehicleTypes'
import { formatNullableString } from '../utils/string-utils'

const RequestedVehicleType = ({ card }) => {
  const { formatMessage: f } = useIntl()

  const requestedType = card?.requestedVehicleType
  const translatedTypes = _.map(
    _.split(formatNullableString(requestedType), '/'),
    type => f({ id: toIntlType(type), defaultMessage: type })
  )

  const css = classNames({
    'text-important': !!requestedType,
  })

  return (
    <>
      <JobHeaderLabel>
        <FormattedMessage id="drivers.requested-vehicle" />
      </JobHeaderLabel>
      <JobDetailHeader size={HeaderSizes.Medium} uppercase className={css}>
        {_.join(translatedTypes, '/')}
      </JobDetailHeader>
    </>
  )
}

export default RequestedVehicleType
