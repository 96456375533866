import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { JobHeaderLabel } from '../../ModalsTypography'

export const ValueWithLabel = ({ labelId, children, className }) => (
  <div className={className}>
    <JobHeaderLabel className="additional-information-label">
      <FormattedMessage id={labelId} />
    </JobHeaderLabel>
    {children}
  </div>
)

export const PackageInformationPiece = props => {
  const css = classNames(props.className, 'package-information-col')
  return <ValueWithLabel {...props} className={css} />
}
