import { useCallback, useEffect, useRef, useState } from 'react'

export function usePopover() {
  const [isOpened, setIsOpened] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const ref = useRef(null)
  const popupRef = useRef(null)

  const handleMouseOver = useCallback(
    () => setIsMouseOver(true),
    [setIsMouseOver]
  )
  const handleMouseOut = useCallback(
    () => setIsMouseOver(false),
    [setIsMouseOver]
  )

  const setListeners = useCallback(
    (ref, node) => {
      if (ref.current) {
        ref.current.removeEventListener('mouseover', handleMouseOver)
        ref.current.removeEventListener('mouseout', handleMouseOut)
      }

      ref.current = node

      if (ref.current) {
        ref.current.addEventListener('mouseover', handleMouseOver)
        ref.current.addEventListener('mouseout', handleMouseOut)
      }
    },
    [handleMouseOver, handleMouseOut]
  )

  useEffect(() => {
    let timerID

    if (isMouseOver) {
      setIsOpened(true)
    } else {
      timerID = setInterval(() => {
        setIsOpened(false)
      }, 150)
    }

    return function cleanup() {
      if (timerID) clearInterval(timerID)
    }
  }, [isOpened, setIsOpened, isMouseOver])

  const setAnchorElement = useCallback(
    node => {
      setListeners(ref, node)
    },
    [setListeners]
  )

  const setPopupElement = useCallback(
    node => {
      setListeners(popupRef, node)
    },
    [setListeners]
  )

  const closePopup = useCallback(() => {
    setIsOpened(false)
    setIsMouseOver(false)
  }, [])

  return [isOpened, setAnchorElement, setPopupElement, closePopup]
}
