import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { ETATypes } from '../../app/enums/EtaTypes'
import { themedClass } from '../../utils/style-utils'

const orderEtaToText = order => {
  if (order === 1) return 'order.first-short'
  if (order === 2) return 'order.second-short'
}

export const EtaMarker = ({ eta, etaType, contractor, theme }) => {
  if (!eta) return null
  const { pickupEta, jobEta } = eta
  const etaTime = etaType === ETATypes.PickEta ? pickupEta : jobEta
  if (!etaTime) return null

  const markerClass = themedClass(
    'vehicle-eta-marker',
    theme,
    classNames('vehicle-map-date-panel', {
      'vehicle-eta-marker-ic': contractor,
    })
  )
  if (!eta.etaOrder) {
    return (
      <div className={markerClass}>
        <FormattedMessage
          id="driver.marker-eta"
          values={{ time: etaTime.format('HH:mm') }}
        />
      </div>
    )
  }

  const etaCss = themedClass(
    'vehicle-eta-marker-order',
    theme,
    'vehicle-map-date-panel'
  )
  return (
    <div className="vehicle-eta-marker-multipart">
      <div className={etaCss}>
        <FormattedMessage id={orderEtaToText(eta.etaOrder)} />
      </div>
      <div className={classNames(markerClass, 'vehicle-eta-marker-small')}>
        <div>
          <FormattedMessage
            id="driver.marker-eta"
            values={{ time: etaTime.format('HH:mm') }}
          />
        </div>
      </div>
    </div>
  )
}
