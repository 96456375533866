import classNames from 'classnames'
import moment from 'moment-timezone'
import { FormattedMessage } from 'react-intl'

import useCurrentTime from '../../../hooks/useCurrentTime'
import { themedClass } from '../../../utils/style-utils'
import { DurationTime } from '../../DateTimeFormats'
import Gauge, {
  ClockLabels,
  GaugeAxisAngle,
  ProgressInnerAxis,
  ProgressOuterAxis,
} from '../../sensor-icons/Gauge'

const CountdownValue = ({ duration, disabled }) => {
  if (disabled) return <FormattedMessage id="misc.unavailable" />

  const durationHours = duration.as('hours')
  let value = duration
  if (durationHours <= 0) value = moment.duration(0, 'seconds')
  if (durationHours > 24) value = moment.duration(24, 'hours')

  return (
    <DurationTime secondsClassName="gauge-progress-seconds" duration={value} />
  )
}

const MilestoneCountdown = ({ countdownEnd, disabled, theme }) => {
  const currentTime = useCurrentTime()

  const countdownValue = moment.duration(countdownEnd?.diff(currentTime))
  const remainingHours = countdownValue.as('hours')
  const milestoneCss = themedClass(
    'countdown-milestone',
    theme,
    classNames(
      {
        'countdown-milestone-alarmed': !disabled && remainingHours <= 0,
      },
      {
        'countdown-milestone-inactive': !disabled && remainingHours >= 24,
      },
      {
        'countdown-milestone-archived': disabled,
      }
    )
  )

  return (
    <Gauge
      className={milestoneCss}
      value={remainingHours}
      minValue={0}
      maxValue={24}
      formatValue={() => (
        <CountdownValue duration={countdownValue} disabled={disabled} />
      )}
      outerAxisComponent={ProgressOuterAxis}
      outerAxisProps={{
        tickLength: 15,
        tickWidth: 2.25,
      }}
      innerAxisComponent={ProgressInnerAxis}
      innerAxisProps={{
        shortTickSize: { length: 4, width: 0.7 },
        longTickSize: { length: 7, width: 1.5 },
        outerAxisGap: 20,
      }}
      angleType={GaugeAxisAngle.FULL_CIRCLE}
      valueTickComponent={() => null}
      labelsComponent={ClockLabels}
      size={135}
      exactSize
    />
  )
}

export default MilestoneCountdown
