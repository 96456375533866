import { TopHeaderSection } from './TopHeaderSection'
import useCardsFilter from '../../hooks/useCardsFilter'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import DateTimePicker from '../form/DateTimePicker'

const CardsRangeFilter = () => {
  const [cardsFilter, changeCardFilter] = useCardsFilter()
  const { fromServerValue } = useLocalDateTime()

  const onFromChanged = val => {
    changeCardFilter({ ...cardsFilter, from: val })
  }
  const onToChanged = val => {
    changeCardFilter({ ...cardsFilter, to: val })
  }

  const fromDate = fromServerValue(cardsFilter.from)
  const toDate = fromServerValue(cardsFilter.to)

  return (
    <>
      <TopHeaderSection
        labelMessageId="inputs.start-label"
        className="date-picker-section"
      >
        <DateTimePicker
          onChange={onFromChanged}
          value={fromDate}
          placeholder="date-filter.from-placeholder"
        />
      </TopHeaderSection>
      <TopHeaderSection
        labelMessageId="inputs.end-label"
        className="date-picker-section"
      >
        <DateTimePicker
          onChange={onToChanged}
          value={toDate}
          placeholder="date-filter.to-placeholder"
        />
      </TopHeaderSection>
    </>
  )
}

export default CardsRangeFilter
