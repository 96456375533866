import { DatePicker } from 'antd'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { themedClass } from '../../utils/style-utils'
import { CalendarIcon } from '../Icons'

export const DatePickerInput = ({
  label,
  value,
  onChange,
  format,
  theme,
  size = 'large',
  iconClass,
}) => {
  const [currentMode, setCurrentMode] = useState()

  const onModeChanged = (_val, mode) => {
    if (mode !== 'decade') setCurrentMode(mode)
  }

  return (
    <div className="flex-flex-end" style={{ position: 'relative' }}>
      <div className={iconClass}>{<CalendarIcon />}</div>
      <div className="daterange-datepicker">
        {label && (
          <label className="text-uppercase">
            <FormattedMessage id={label} />
          </label>
        )}
        <DatePicker
          format={format}
          value={value}
          onChange={onChange}
          popupClassName={themedClass('date-range-dropdown', theme)}
          mode={currentMode}
          onPanelChange={onModeChanged}
          inputReadOnly
          size={size}
        />
      </div>
    </div>
  )
}
