import _ from 'lodash'
import { useContext } from 'react'

import { AssignedCardsPopover } from './AssignedCardsPopover'
import DraggableDriverCard from './DraggableDriverCard'
import MultipleDriversPreview from './DraggedDriversPreview'
import { useDragInfoContext } from '../../hooks/dragAndDrop/useDragInfoContext'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { isHoveredDispatchMap } from '../../utils/drag-utils'
import { isLoaded } from '../../utils/driver-utils'
import { DriversContext } from '../DriverAssigmentModule'

const driversContextToCardProps = context => {
  const { onDriverClick, onHistoryClick, onDriverDragStart, onDriverDragEnd } =
    context

  return {
    onClick: onDriverClick,
    onHistoryIconClick: onHistoryClick,
    onDragStart: onDriverDragStart,
    onDragEnd: onDriverDragEnd,
  }
}

export const DriverCardsList = ({ drivers, estimatedArrivals = {}, theme }) => {
  const context = useContext(DriversContext)
  const { selectedDrivers, checkedDrivers, isDragging } = context

  const [itemHovered] = useDragInfoContext()
  const { getCurrentTime } = useLocalDateTime()

  const isSelected = id => _.some(selectedDrivers, d => d.id === id)
  const isChecked = id => _.some(checkedDrivers, d => d.id === id)
  const dragging = isHoveredDispatchMap(itemHovered) && isDragging

  const cardProps = driversContextToCardProps(context)

  return drivers.map(c => {
    const isDriverSelected = isSelected(c.id)
    const isDriverChecked = isChecked(c.id)
    const showPopup = isLoaded(c)
    return (
      <DraggableDriverCard
        key={c.id}
        driver={c}
        eta={estimatedArrivals[c.id]?.eta}
        isSelected={isDriverSelected}
        isChecked={isDriverChecked}
        isDragged={isDriverSelected && dragging}
        DragPreview={MultipleDriversPreview}
        currentDay={getCurrentTime().day() - 1}
        theme={theme}
        IconPopover={showPopup ? AssignedCardsPopover : null}
        {...cardProps}
      />
    )
  })
}
