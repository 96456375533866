export const scrollUp = (el, scrollStep) => {
  el.scrollBy({
    top: -scrollStep,
    left: 0,
    behavior: 'smooth',
  })
}

export const scrollDown = (el, scrollStep) => {
  el.scrollBy({
    top: scrollStep,
    left: 0,
    behavior: 'smooth',
  })
}

export const isUtilityKeyDown = event =>
  isControlKeyDown(event) || isShiftKeyDown(event)

export const isControlKeyDown = event => event.ctrlKey || event.metaKey
export const isShiftKeyDown = event => event.shiftKey
