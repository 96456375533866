import { useMutation } from '@apollo/client'
import { useCallback } from 'react'

import { createResponseNotification } from '../../utils/notifications-utils'
import {
  NotificationsTypeEnum,
  useNotificationsContext,
} from '../useNotificationsContext'

const defaultResponse = {
  success: true,
  message: '',
  errorCode: 0,
  __typename: 'MutationResponse',
}

const defaultSuccessCheck = (data, mutationName) =>
  data && data[mutationName].success
/**
 *
 * @param {*} mutationDoc graphql mutation schema
 * @param {*} mutationName field containg data of mutation response  (for example {data: {moveStack: {...}}} key should be 'moveStack')
 *                         also used as intl key for notifications.
 */
export const useOptimisticMutation = (mutationDoc, mutationName) => {
  const { addNotification } = useNotificationsContext()

  const [mutation] = useMutation(mutationDoc, {
    onCompleted: data => {
      if (data[mutationName].success === false || data[mutationName].message) {
        // we expect field success to exist (according to MutationResponse scheme)
        addNotification(
          createResponseNotification(
            mutationName,
            data[mutationName].message,
            data[mutationName].errorCode,
            data[mutationName].success
              ? NotificationsTypeEnum.WARNING
              : NotificationsTypeEnum.ERROR
          )
        )
      }
    },
    onError: err => {
      addNotification(createResponseNotification(mutationName, err.message))
    },
  })

  const mutationFunc = useCallback(
    ({
      variables,
      cacheUpdate,
      onFinished,
      response = defaultResponse,
      successCheck = defaultSuccessCheck,
    }) => {
      mutation({
        variables: variables,
        optimisticResponse: {
          __typename: 'Mutation',
          [mutationName]: response,
        },
        update: (cache, { data }) => {
          // https://www.apollographql.com/docs/react/api/apollo-client/#apolloclient-functions
          if (successCheck(data, mutationName)) {
            cacheUpdate(cache, data[mutationName])
          }
        },
      }).then(reponse => {
        onFinished && onFinished(reponse)
      })
    },
    [mutation, mutationName]
  )
  return [mutationFunc]
}
