import _ from 'lodash'

import ConfirmAlarmModal from './ConfirmAlarmModal'
import useStacksList from '../../hooks/graphQl/useStacksQuery'
import { useCards } from '../../hooks/useCards'
import { orderStacksForDropdown } from '../../utils/common-utils'
import { isSelectable, stackToSelectOption } from '../../utils/stacks-utils'

const CardAlarmModal = ({
  visible,
  onClosed,
  dateTime,
  card,
  afterConfirmed,
  headerMsg,
}) => {
  const {
    stacksData: listStacks,
    loading: loadingStacks,
    error: stacksError,
  } = useStacksList()

  const { updateReminder } = useCards()

  const stacksToAssign = orderStacksForDropdown(
    listStacks.filter(isSelectable)
  ).map(stackToSelectOption)

  const onConfirmed = (_cardId, stackId) => {
    const selectedStack = _.find(listStacks, s => s.id === stackId)
    updateReminder(card, dateTime, selectedStack)
    afterConfirmed && afterConfirmed()
  }

  const defaultStack = card ? card.stack : null

  if (!visible) return null

  return (
    <ConfirmAlarmModal
      visible={visible}
      onClosed={onClosed}
      dateTime={dateTime}
      card={card}
      allowedStacks={stacksToAssign}
      onConfirm={onConfirmed}
      loading={loadingStacks}
      error={stacksError}
      defaultStack={defaultStack}
      headerMsg={headerMsg}
    />
  )
}

export default CardAlarmModal
