import { Table } from 'antd'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

import { actionToTranslationMessage } from './actionsEnum'
import { useDocumentTypes } from '../../../hooks/graphQl/useDocumentTypes'
import useGetActionLog from '../../../hooks/graphQl/useGetActionLog'
import { useLocalDateTime } from '../../../hooks/useLocalDateTime'
import { useSelectedTheme } from '../../../hooks/useUserSettings'
import { fromServerDate, toServerDate } from '../../../utils/date-utils'
import { HourMinuteFormat, LongWeekDayDate } from '../../DateTimeFormats'
import Drawer, { DrawerOpenDirection } from '../../Drawer'
import * as Icons from '../../Icons'
import LoadingIndicator from '../../LoadingIndicators'
import ScrollArea from '../../ScrollArea'
import { JobNumberPanel } from '../../job-detail/JobNumberPanel'
import { ActionLogArgsColumn } from '../action-log/ActionLogArgsColumn'

const HistoryDrawerControl = () => (
  <div>
    <Icons.HistoryIcon className="text-important" />
    <FormattedMessage id="card-popup.activity-log" />
  </div>
)

const CardHistoryNestedTable = ({ entries = [], documentTypes }) => (
  <Table
    className="nested-tbl"
    rowKey="id"
    columns={[
      {
        key: 1,
        dataIndex: 'datetime.iso',
        width: 60,
        render: (_, record) => (
          <HourMinuteFormat date={fromServerDate(record.datetime?.iso)} />
        ),
      },
      {
        key: 2,
        dataIndex: 'action',
        width: 140,
        render: (_, record) => (
          <FormattedMessage
            id={actionToTranslationMessage(record.action, record.args)}
            defaultMessage={record.action}
          />
        ),
      },
      {
        key: 3,
        dataIndex: 'args',
        className: 'text-ellipsed',
        render: (_, record) => (
          <ActionLogArgsColumn value={record} documentTypes={documentTypes} />
        ),
      },
      {
        key: 4,
        width: 150,
        dataIndex: 'userFullName',
        render: text => (
          <div
            className="text-capitalized text-ellipsed"
            style={{ width: 150 }}
          >
            {_.lowerCase(text)}
          </div>
        ),
      },
    ]}
    pagination={false}
    dataSource={entries}
    rowClassName="log-table"
  />
)

const CardHistory = ({ card }) => {
  const { data: entries, loading } = useGetActionLog(card?.id)
  const { types, loading: typesLoading } = useDocumentTypes()

  const [theme] = useSelectedTheme()
  const { fromServerValue } = useLocalDateTime()
  const subtables = _.groupBy(entries, e =>
    toServerDate(fromServerValue(e.datetime.iso).startOf('day'))
  )
  const dates = _.map(_.sortBy(_.keys(subtables)), key => ({ key }))

  return (
    <Drawer
      ControlComponent={HistoryDrawerControl}
      className="activity-log-drawer flow-content-drawer"
      direction={DrawerOpenDirection.DOWN}
      height={765}
      theme={theme}
      mask
    >
      <JobNumberPanel card={card} className="activity-log-panel" theme={theme}>
        <div className="activity-log-header">
          <FormattedMessage id="card-popup.activity-log" />
        </div>
      </JobNumberPanel>

      <LoadingIndicator loading={loading || typesLoading} />

      {_.some(entries) && (
        <ScrollArea hideHorizontal={true}>
          <Table
            pagination={false}
            dataSource={dates}
            rowClassName="log-table-date text-uppercase"
            expandedRowKeys={_.keys(subtables)}
            bodyStyle={{
              overflow: 'hidden',
              borderRadius: 6,
              boxShadow: '1px 1px 20px #bfcce2',
            }}
            expandedRowRender={row => (
              <CardHistoryNestedTable
                key={row}
                entries={_.sortBy(subtables[row.key], x => x.datetime.iso)}
                documentTypes={types}
              />
            )}
            showExpandColumn={false}
            expandIcon={() => null}
            columns={[
              {
                key: 0,
                dataIndex: 'key',
                render: (_text, record) => (
                  <LongWeekDayDate
                    date={fromServerValue(record.key)}
                    separator=", "
                  />
                ),
              },
            ]}
          />
        </ScrollArea>
      )}
    </Drawer>
  )
}

export default CardHistory
