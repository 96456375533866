import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input } from 'antd'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import { useStacks } from '../../hooks/useStacks'

const CardsColumnHeader = ({
  reorderable,
  editable,
  onRemove,
  label,
  stackId,
  cardsCount,
}) => {
  const { formatMessage: f } = useIntl()
  const [existingName, setExistingName] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [editName, setEditName] = useState(label)
  const editNameRef = useRef(null)

  const { stackExists, renameStack } = useStacks()

  useEffect(() => setEditName(label), [label])

  useEffect(() => {
    if (editMode) {
      editNameRef.current.focus()
    }
  }, [editMode])

  const onLabelClick = () => {
    if (!editable) return
    setEditMode(true)
  }

  const removeIconCss = classNames('fas fa-trash deletable', {
    invisible: !editable,
  })

  const reorderIconCss = classNames('fas fa-grip-vertical reorderable', {
    invisible: !reorderable,
  })

  const labelCss = classNames('cards-column-label', {
    'editable-text': editable,
  })

  const saveValue = e => {
    e.preventDefault()
    if (!existingName) {
      renameStack(stackId, editName)
    } else {
      setEditName(label)
    }

    setEditMode(false)
    setExistingName(false)
  }

  const onLabelChanged = ({ target }) => {
    setExistingName(stackExists(target.value, stackId))
    setEditName(target.value)
  }

  return (
    <div className="cards-column-header">
      <i
        className={removeIconCss}
        title={f({ id: 'card-columns.removable-title' })}
        onClick={onRemove}
      />

      {editMode ? (
        <Form onSubmit={saveValue}>
          <Form.Item validateStatus={existingName ? 'error' : 'success'}>
            <Input
              className={'label-edit-input'}
              ref={editNameRef}
              size="large"
              onChange={onLabelChanged}
              onBlur={saveValue}
              value={editName}
              title={
                existingName ? f({ id: 'card-columns.existing-name' }) : ''
              }
            />
          </Form.Item>
        </Form>
      ) : (
        <p className={labelCss} onClick={onLabelClick}>
          {label} ({cardsCount})
        </p>
      )}
      <i
        className={reorderIconCss}
        title={f({ id: 'card-columns.reordable-title' })}
      />
    </div>
  )
}

export default CardsColumnHeader
