import _ from 'lodash'

import { getTotalCount, hasAlarmFired } from './card-utils'
import { orderStacksForDropdown } from './common-utils'
import { emptyArray } from './perf-utils'
import { ColumnsEnum } from '../app/constants'

export const isSelectable = stack =>
  stack.name !== ColumnsEnum.NEWJOBS && stack.name !== ColumnsEnum.DECIDE

export const isStaticColumn = stack =>
  stack.name === ColumnsEnum.NEWJOBS || stack.name === ColumnsEnum.DECIDE

export const stackToSelectOption = stack => ({
  value: stack.id,
  label: getStackLabel(stack).toLowerCase(),
})

export const findByName = (stacks, name) => _.find(stacks, s => s.name === name)

export const findNewJobsStack = stacks =>
  findByName(stacks, ColumnsEnum.NEWJOBS)

export const findDecideStack = stacks => findByName(stacks, ColumnsEnum.DECIDE)
export const findDockStack = stacks => findByName(stacks, ColumnsEnum.ONDOCK)

export function isStackScrollable(stack) {
  return (
    !_.includes([ColumnsEnum.NEWJOBS, ColumnsEnum.DECIDE], stack.name) &&
    !isAdditionalDockStack(stack)
  )
}

export function sortStacksByOrder(stacks) {
  return _.sortBy(stacks, ['order'])
}

export const countCards = (column, stack) => {
  if (!column) return 0

  const { alarmed = [], nonAlarmed = [], changed = [] } = column
  const allCards = _.filter(
    [...alarmed, ...nonAlarmed, ...changed],
    c => c.stack.id === stack.id
  )
  return getTotalCount(allCards)
}

const getGroupCategory = card => {
  if (_.some(card.trackedChanges, change => !change.acknowledgedBy))
    return 'changed'

  if (hasAlarmFired(card) || _.some(card.groupChildren, hasAlarmFired))
    return 'alarmed'

  return 'nonAlarmed'
}

export const groupByAlarm = cards => _.groupBy(cards, getGroupCategory)

const toGroupedKey = s => s + '-non-grouped'
export const nonGroupedKey = stack => toGroupedKey(stack.id)

export const groupCardsByStack = cards => {
  const stacks = _.groupBy(cards, item => item.stack.id)

  const result = {}
  _.keys(stacks).forEach(s => {
    result[s] = groupByAlarm(stacks[s])
  })
  return result
}

function isAdditionalDockStack(stack) {
  return stack.name !== ColumnsEnum.ONDOCK && !!stack.dropLocation
}

export function filterAdditionalDockStacks(stacks) {
  const result = _.filter(stacks, isAdditionalDockStack)
  if (_.isEmpty(result)) return emptyArray
  return result
}
export function filterNonDockStacks(stacks) {
  return _.filter(stacks, stack => !isAdditionalDockStack(stack))
}

export function buildStacksOrderMap(stacks) {
  const orderedStacks = orderStacksForDropdown(stacks)
  return _.keyBy(
    _.map(orderedStacks, (s, index) => ({ index, id: s.id })),
    'id'
  )
}

export function getStackLabel(stack) {
  if (stack.dropLocation && stack.name === ColumnsEnum.ONDOCK) {
    return `${stack.name} (${stack.dropLocation})`
  }
  return stack.name
}
