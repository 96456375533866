import _ from 'lodash'

import { getCurrencySign } from '../../app/enums/Currencies'
import { padDecimal } from '../../utils/string-utils'

const CurrencyFormat = ({ value, currency }) => {
  const formattedValue = _.isNumber(value) ? padDecimal(value) : value
  return (
    <>
      {getCurrencySign(currency)}
      {formattedValue}
    </>
  )
}

export default CurrencyFormat
