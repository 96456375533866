import classNames from 'classnames'
import * as clipboard from 'clipboard-polyfill/text'
import { FormattedHTMLMessage } from 'react-intl'

import { HintTooltip } from './HintTooltip'
import { CopyIcon } from './Icons'
import {
  NotificationsTypeEnum,
  useNotificationsContext,
} from '../hooks/useNotificationsContext'

const CopyJobNumber = ({ jobNumber, withMargins, theme }) => {
  const { displayTopMessage } = useNotificationsContext()

  const onIconClicked = e => {
    clipboard.writeText(jobNumber)
    e.preventDefault()
    e.stopPropagation()

    displayTopMessage({
      messageId: 'user-actions.clipboard-copied',
      type: NotificationsTypeEnum.SUCCESS,
    })
  }

  const css = classNames('copy-icon', {
    'copy-icon-with-margins': withMargins,
  })

  if (!jobNumber) return null

  return (
    <HintTooltip
      theme={theme}
      size="tiny"
      placement="bottom"
      tooltip={<FormattedHTMLMessage id="actions.copy-job-number" />}
    >
      <span className="copy-icon-wrap">
        <CopyIcon onClick={onIconClicked} className={css} />
      </span>
    </HintTooltip>
  )
}

export default CopyJobNumber
