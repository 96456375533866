import { ApolloProvider } from '@apollo/client'
import enUS from 'antd/es/locale/en_US'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { IntlProvider } from 'react-intl'
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'

import apolloClient from './app/graphQl/apolloClient'
import { messages } from './app/localization'
import Sitemap from './app/sitemap'
import ErrorBoundary from './components/ErrorBoundary'
import ContentRoot from './containers/ContentRoot'
import LoginPage from './containers/LoginPage'
import { DragInfoContextProvider } from './hooks/dragAndDrop/useDragInfoContext'
import { setNextTokenRefresh } from './hooks/useAuthentication'

import './styles/vendors/fontawesome/all.min.css'
import './main.css' // eslint-disable-line import/no-unresolved

setNextTokenRefresh()

const App = () => {
  const currentLocale = enUS.locale
  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <DndProvider backend={HTML5Backend}>
          <DragInfoContextProvider>
            <Router>
              <Switch>
                <Route path={Sitemap.login}>
                  <IntlProvider
                    locale={currentLocale}
                    messages={messages[currentLocale]}
                  >
                    <LoginPage />
                  </IntlProvider>
                </Route>
                <Route path={[Sitemap.monitor, Sitemap.dispatch]}>
                  <ContentRoot />
                </Route>
                <Redirect to={Sitemap.createMonitorLink('')} />
              </Switch>
            </Router>
          </DragInfoContextProvider>
        </DndProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}

export default App
