import classNames from 'classnames'
import { useState } from 'react'

import PanelHandle from './PanelHandle'
import { themedClass } from '../utils/style-utils'

export const DrawerOpenDirection = {
  UP: 0,
  DOWN: 1,
}

const Drawer = ({
  ControlComponent,
  children,
  className,
  direction,
  height,
  mask,
  theme,
}) => {
  const [opened, setOpened] = useState(false)

  const onToggle = () => {
    setOpened(o => !o)
  }

  const style = opened ? { height: height } : { height: 0 }
  const css = classNames(
    className,
    themedClass('vertical-drawer', theme),
    {
      'vertical-drawer-opened': opened,
    },
    {
      'vertical-drawer-topdown': direction === DrawerOpenDirection.DOWN,
    },
    {
      'vertical-drawer-bottomup': direction === DrawerOpenDirection.UP,
    }
  )
  const controlCss = classNames('vertical-drawer-control', {
    'vertical-drawer-control-opened': opened,
  })
  const contentCss = classNames('vertical-drawer-content', {
    'vertical-drawer-content-opened': opened,
  })

  return (
    <>
      {opened && mask && <div className="drawer-mask" onClick={onToggle} />}
      <div className={css}>
        {direction === DrawerOpenDirection.UP && (
          <PanelHandle className={controlCss} onClick={onToggle}>
            {<ControlComponent opened={opened} />}
          </PanelHandle>
        )}
        <div style={style} className={contentCss}>
          {children}
        </div>
        {direction === DrawerOpenDirection.DOWN && (
          <PanelHandle className={controlCss} onClick={onToggle} inverted>
            {<ControlComponent opened={opened} />}
          </PanelHandle>
        )}
      </div>
    </>
  )
}

export default Drawer
