import { Calendar } from 'antd'
import classNames from 'classnames'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { combineDateAndTime } from '../../utils/date-utils'
import { padInteger } from '../../utils/string-utils'
import { themedClass } from '../../utils/style-utils'
import { YearLongMonthFormat } from '../DateTimeFormats'

const CalendarHeader = ({ value, onValueChanged }) => {
  const onNextClicked = () => {
    onValueChanged(moment(value).add(1, 'months'))
  }

  const onPrevClicked = () => {
    onValueChanged(moment(value).add(-1, 'months'))
  }

  return (
    <div className="datetime-calendar-controls">
      <i className="fas fa-angle-left" onClick={onPrevClicked} />
      <span>
        <YearLongMonthFormat date={value} />
      </span>
      <i className="fas fa-angle-right" onClick={onNextClicked} />
    </div>
  )
}

export function CalendarPicker({ onValueChanged, value, theme, className }) {
  const { getCurrentTime, parseStoredValue } = useLocalDateTime()
  const [calendarValue, setCalendarValue] = useState(value || getCurrentTime())

  useEffect(() => {
    setCalendarValue(value || getCurrentTime())
  }, [getCurrentTime, value])

  useEffect(() => {
    setCalendarValue(x => x || moment())
  }, [calendarValue])

  const onPanelChange = value => {
    setCalendarValue(value)
  }

  const onSelect = val => {
    const newVal = value
      ? combineDateAndTime(value, val)
      : parseStoredValue(val).startOf('day')
    onValueChanged(newVal)
    setCalendarValue(newVal)
  }

  const renderCell = val => {
    const cellCss = classNames('ant-fullcalendar-date', {
      'ant-fullcalendar-date-selected': val.isSame(value, 'date'),
    })
    return (
      <div className={cellCss}>
        <div className="ant-fullcalendar-value">{padInteger(val.date())}</div>
      </div>
    )
  }

  const css = themedClass('calendar-picker', theme, className)

  return (
    <Calendar
      className={css}
      fullscreen={false}
      value={calendarValue}
      onSelect={onSelect}
      onPanelChange={onPanelChange}
      dateFullCellRender={renderCell}
      headerRender={props => (
        <CalendarHeader {...props} onValueChanged={setCalendarValue} />
      )}
    />
  )
}
