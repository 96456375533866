import moment from 'moment-timezone'

import { padInteger } from './string-utils'

export function fromServerDate(date) {
  if (!date) return null
  return moment(date)
}
export function parseWithTimeZone(date, timezone) {
  return moment(date).tz(timezone)
}

export function unixToDate(date) {
  return moment.unix(date)
}

export function areDatesEqual(a, b) {
  return a?.isSame(b)
}

export function areDatesEqualDay(a, b) {
  return a?.isSame(b, 'day')
}

export function compareUtcDates(first, second) {
  return first.utc().diff(second.utc())
}

export function toServerDate(date) {
  if (!date) return ''
  return date.toISOString()
}

export function toDateTimeVariable(date, timezone) {
  return { date: toServerDate(date), timezone }
}

export function combineDateAndTime(time, date) {
  return moment(time).date(date.date()).month(date.month()).year(date.year())
}

export function isDateInPast(unixEpoch) {
  return unixEpoch.utc().diff(moment().utc()) < 0
}

export function isInRange(date, range) {
  if (range.from && range.to) return date >= range.from && date <= range.to
  if (range.from) return date >= range.from
  if (range.to) return date <= range.to
  return true
}

export function getStartOfDay(date) {
  if (!date) return null
  return date.startOf('day')
}

export function setHour(date, hour) {
  return moment(date).hour(hour).startOf('hour')
}

export function formatHour24Format(hour) {
  return padInteger(hour % 24)
}

export function toCurrentDayRange(date) {
  return {
    from: date.clone().startOf('day'),
    to: date.clone().endOf('day'),
  }
}
export function toCurrentWeekRange(date) {
  return {
    from: date.clone().isoWeekday(1).startOf('isoWeek'),
    to: date.clone().isoWeekday(1).endOf('isoWeek'),
  }
}

export function toPreviousDayRange(date) {
  return {
    from: date.clone().add(-1, 'days').startOf('day'),
    to: date.clone().add(-1, 'days').endOf('day'),
  }
}

export function toPreviousWeekRange(date) {
  const thisWeek = toCurrentWeekRange(date)
  return {
    from: thisWeek.from.add(-7, 'days'),
    to: thisWeek.to.add(-7, 'days'),
  }
}

export function toCurrentMonthRange(date) {
  return {
    from: date.clone().startOf('month'),
    to: date.clone().endOf('month'),
  }
}

export function toPreviousMonthRange(date) {
  const thisWeek = toCurrentMonthRange(date)
  return {
    from: thisWeek.from.add(-1, 'month'),
    to: thisWeek.to.add(-1, 'month'),
  }
}
