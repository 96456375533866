import { Menu } from 'antd'

import { themedClass } from '../utils/style-utils'

const PopoverMenu = ({ theme, className, items, onClick }) => {
  const popoverMenuCss = themedClass('popover-menu', theme, className)
  return <Menu className={popoverMenuCss} items={items} onClick={onClick} />
}

export default PopoverMenu
