import { useQuery } from '@apollo/client'

import { GET_DRIVER } from '../../app/graphQl/schema/Drivers'

const useGetDriver = driverId => {
  const { data } = useQuery(GET_DRIVER, {
    skip: !driverId,
    variables: { driverId: driverId },
  })

  return data?.getDriver
}

export default useGetDriver
