import classNames from 'classnames'

const BadgeBase = ({ children, className, inverted }) => {
  const css = classNames(className, 'card-detail-badge', {
    'card-detail-badge-inverted': inverted,
  })
  return <div className={css}>{children}</div>
}

export const BadgeSmall = ({ children, className, inverted }) => {
  const css = classNames(className, 'card-detail-badge-sm')
  return (
    <BadgeBase className={css} inverted={inverted}>
      {children}
    </BadgeBase>
  )
}

export const BadgeLarge = ({ children, className, inverted }) => {
  const css = classNames(className, 'card-detail-badge-lg')
  return (
    <BadgeBase className={css} inverted={inverted}>
      {children}
    </BadgeBase>
  )
}
