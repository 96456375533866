import { FormattedMessage } from 'react-intl'

import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { BatterySensor } from '../sensor-icons/BatterySensor'
import HumiditySensor from '../sensor-icons/HumiditySensor'
import LightSensor from '../sensor-icons/LightSensor'
import PressureSensor from '../sensor-icons/PressureSensor'
import TemperatureSensor from '../sensor-icons/TemperatureSensor'
import TiltSensor from '../sensor-icons/TiltSensor'

const SensorReadings = ({ device, deviceIndex }) => {
  const { fromServerValue } = useLocalDateTime()

  const lastReported = device?.lastReported?.iso
    ? fromServerValue(device?.lastReported?.iso)
    : null

  if (!device) return null

  return (
    <div className="sensor-readings">
      <div className="text-uppercase temperature-sensors-header">
        <FormattedMessage id="card-popup.sensor-readings" />:{' '}
        <span className="text-important">
          <FormattedMessage
            id="sensor-device.device-number"
            values={{ number: deviceIndex + 1 }}
          />
        </span>
      </div>
      <div className="sensors-table">
        <BatterySensor
          percentage={device?.readings?.batteryLevel}
          lastReported={lastReported}
        />
        <LightSensor
          lastReported={lastReported}
          value={device?.readings?.lightLevel}
        />
        <TemperatureSensor
          lastReported={lastReported}
          value={device?.readings?.temperature}
        />
        <HumiditySensor
          lastReported={lastReported}
          value={device?.readings?.humidity}
        />

        <TiltSensor
          value={device?.readings?.orientation}
          lastReported={lastReported}
        />
        <PressureSensor
          lastReported={lastReported}
          value={device?.readings?.pressure}
        />
      </div>
    </div>
  )
}

export default SensorReadings
