import Gauge from './Gauge'
import { SensorPanel } from './SensorPanel'
import { PressureUnit } from '../user-units/PressureUnits'

const PressureLabel = ({ value }) => (
  <div>
    {value}
    <small>
      <PressureUnit />
    </small>
  </div>
)
const PressureSensor = ({ lastReported, value }) => (
  <SensorPanel headerMessageId="sensors.pressure" lastReported={lastReported}>
    <Gauge
      value={value}
      minValue={300}
      maxValue={1200}
      formatValue={val => <PressureLabel value={val} />}
    />
  </SensorPanel>
)

export default PressureSensor
