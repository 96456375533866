import { gql } from '@apollo/client'

import { COMPANY_INFO, FLIGHT_DETAIL, MUTATION_RESPONSE } from './Common'
import { DOCUMENT } from './Documents'

//#region fragments
export const UPDATE_CT_NOTES_FRAGMENT = gql`
  fragment updateCTNotes on JobDetail {
    id
    ctNotes {
      text
      createdAt {
        iso
      }
      pending @client
    }
  }
`

export const UPDATE_DOCUMENTS_FRAGMENT = gql`
  fragment updateDocuments on JobDetail {
    id
    documents {
      ...document
    }
  }
  ${DOCUMENT}
`

const JOB_DETAIL = gql`
  fragment jobDetail on JobDetail {
    id
    shipper {
      ...companyInfo
    }
    consignee {
      ...companyInfo
    }
    dimensionalWeight
    requestedVehicle
    billing {
      notes
      customerNumber
      shipmentValue
      insuranceValue
    }
    flightsItinerary {
      ...flightDetail
    }
    packages {
      piecesCount
      commodity
      weight
      dimensionalWeight
      height
      length
      width
    }
    shipperSpecificInfo {
      label
      value
    }
    packageInformation {
      source
      typeDesc
      commodityTemp
      commodityTempDesc
      packoutDate {
        iso
      }
      packoutHours
      coolantType
      coolantSource
      coolantAmount
      coolantInterval
    }
    devices {
      id
      caseId
      brand
      model
      source
      serialNumber
      lastReported {
        iso
      }
      readings {
        batteryLevel
        lightLevel
        temperature
        humidity
        pressure
        orientation
      }
    }
    ctNotes {
      text
      createdAt {
        iso
      }
      pending @client
    }
    documents {
      ...document
    }
    bolPdfUrl
  }
  ${FLIGHT_DETAIL}
  ${COMPANY_INFO}
  ${DOCUMENT}
`
//#endregion

//#region queries
export const GET_JOB_DETAIL = gql`
  query GetJobDetail($jobNumber: ID!) {
    getJobDetail(itemId: $jobNumber) {
      ...jobDetail
    }
  }
  ${JOB_DETAIL}
`

export const GET_JOB_OVERVIEW = gql`
  query GetJobOverview($itemId: ID!) {
    getJobDetail(itemId: $itemId) {
      id
      shipper {
        ...companyInfo
      }
      consignee {
        ...companyInfo
      }
      packages {
        piecesCount
        commodity
        weight
        dimensionalWeight
        height
        length
        width
      }
    }
  }
  ${COMPANY_INFO}
`

export const GET_CONSIGNEE = gql`
  query GetJobConsignee($itemId: ID!) {
    getJobDetail(itemId: $itemId) {
      consignee {
        name
      }
    }
  }
`
//#endregion

//#region mutations
export const ADD_CT_NOTE = gql`
  mutation AddCTNote($job: ID!, $note: String) {
    addCTNote(job: $job, note: $note) {
      ...responseDetail
    }
  }
  ${MUTATION_RESPONSE}
`
//#endregion

//#region subscriptions
export const JOB_SUBSCRIPTION = gql`
  subscription jobSubscription($jobNumber: ID) {
    jobUpdated(jobNumber: $jobNumber) {
      operation
      changeData {
        ...jobDetail
      }
    }
  }
  ${JOB_DETAIL}
`
