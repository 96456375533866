import moment from 'moment'
import { useCallback, useMemo } from 'react'

import { useActiveStation } from './useActiveStation'
import { useLocalDateTime } from './useLocalDateTime'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { fromServerDate } from '../utils/date-utils'
import { convertFilterToQuery } from '../utils/filter-utils'

const FILTER_KEY = 'qdd-cards-filter'

const createDefaultFilter = now => ({
  jobId: null,
  from: null,
  to: moment(now).add(12, 'hour').startOf('hour'),
})

export function parseStoredFilter(filter) {
  return {
    ...filter,
    from: filter.from ? fromServerDate(filter.from) : null,
    to: filter.to ? fromServerDate(filter.to) : null,
  }
}

const useCardsFilter = () => {
  const { getCurrentTime } = useLocalDateTime()
  const [cardsFilter, changeCardFilter] = useLocalStorage(
    FILTER_KEY,
    createDefaultFilter(getCurrentTime())
  )

  const currentFilter = useMemo(
    () => parseStoredFilter(cardsFilter),
    [cardsFilter]
  )

  const resetFilter = useCallback(() => {
    changeCardFilter(createDefaultFilter(getCurrentTime()))
  }, [changeCardFilter, getCurrentTime])

  return [currentFilter, changeCardFilter, resetFilter]
}

export const useCardsFilterQuery = () => {
  const [cardsFilter] = useCardsFilter()
  const { currentStation } = useActiveStation()

  const cardsVariables = useMemo(
    () =>
      currentStation
        ? convertFilterToQuery(cardsFilter, currentStation.id)
        : null,
    [cardsFilter, currentStation]
  )

  return cardsVariables
}

export default useCardsFilter
