import _ from 'lodash'

import { MISSING_VALUE } from './string-utils'
import { CssValues } from '../app/constants'
import { DriverTypesEnum } from '../app/enums/DriverType'
import { MilestonesEnum } from '../app/enums/MilestonesEnum'
import { ThemesEnum } from '../app/enums/Themes'
import { VehiclesCategories } from '../app/enums/VehicleTypes'

export const getDisplayName = driver =>
  driver ? _.toLower(driver.nickname || driver.name) : MISSING_VALUE

export const getLoadedCards = driver =>
  _.filter(
    driver.assignedCards,
    d => d.milestone && d.milestone.code === MilestonesEnum.LOADED
  )

export const countLoadedCards = driver => _.size(getLoadedCards(driver))

export const isLoaded = driver => countLoadedCards(driver) > 0

export const getMapMarkerColor = (driver, theme) => {
  if (!driver) return null

  if (theme === ThemesEnum.DARK) {
    switch (driver.driverType) {
      case DriverTypesEnum.EMPLOYEE:
        return CssValues.markerColorDark
      default:
        return CssValues.markerColorICDark
    }
  }

  switch (driver.driverType) {
    case DriverTypesEnum.EMPLOYEE:
      return CssValues.markerColor
    default:
      return CssValues.markerColorIC
  }
}
export const areSameVehicleGroup = (a, b) =>
  a.isActive === b.isActive &&
  VehiclesCategories[a.vehicle.type] === VehiclesCategories[b.vehicle.type]

export const getAvailability = (driver, day) => {
  if (!driver.availability) return {}
  return driver.availability[day] || {}
}

export const isActive = driver => driver?.isActive

export const isPossibleToOverrideStatus = driver =>
  driver.driverType === DriverTypesEnum.LOGISTIC_SERVICE_PROVIDER
