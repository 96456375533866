import classNames from 'classnames'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import FlowTransition from './FlowTransition'
import { FlowColumn, FlowToolbar } from './common/FlowLayout'
import { ModifyHeader } from './common/ModifyHeader'
import { MapStyles } from '../../app/constants'
import { CardPopupState } from '../../app/enums/CardPopupState'
import { SubJobTypeEnum } from '../../app/enums/SubJobTypeEnum'
import { useCardContext } from '../../hooks/useCardPopupContext'
import { useDriverAssignContext } from '../../hooks/useDriversAssignContext'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import {
  getSubjobNumber,
  hasClosableAlarm,
  isSubJobCard,
} from '../../utils/card-utils'
import { themedClass } from '../../utils/style-utils'
import { AlarmBadge } from '../AlarmBadge'
import { ContentPanel } from '../ContentPanel'
import DriverAssigmentModule from '../DriverAssigmentModule'
import { HeaderSizes, JobDetailHeader } from '../ModalsTypography'
import RequestedVehicleType from '../RequestedVehicleType'
import AssignedDriver from '../drivers/AssignedDriver'
import DriversList from '../drivers/DriversList'
import JobDetailBox from '../job-detail/JobDetailBox'
import { JobNumberPanel } from '../job-detail/JobNumberPanel'

const CardDriverAssign = ({
  card,
  job,
  page = CardPopupState.DriverAssign.Page,
}) => {
  const { setMapStyle, mapStyle } = useDriverAssignContext()
  const { currentPage, addCurrentPageToHistory } = useCardContext()
  const [theme] = useSelectedTheme()

  useEffect(() => {
    if (currentPage !== page) setMapStyle(MapStyles.Default)
  }, [currentPage, page, setMapStyle])

  let topToolbarMsg = null
  if (isSubJobCard(card)) {
    topToolbarMsg =
      card.subjobType === SubJobTypeEnum.REICE
        ? 'card-popup.reice-subjob'
        : 'card-popup.purchase-subjob'
  }

  const toolbarCss = classNames({
    'flow-toolbar-marginless': card.driver,
  })
  const flowCss = classNames('flow-specific-column driver-assign-flow', {
    'driver-assign-flow-maximized': mapStyle === MapStyles.Maximized,
  })
  return (
    <FlowTransition page={page}>
      <FlowColumn className="job-overview-column drivers-list-column">
        <JobNumberPanel card={card} theme={theme} />
        <ContentPanel
          className="drivers-list-panel drivers-list-panel-flow"
          theme={theme}
        >
          <RequestedVehicleType card={card} />
          <DriversList currentCard={card} />
        </ContentPanel>
      </FlowColumn>

      <FlowColumn className={flowCss} extended={isSubJobCard(card)}>
        <DriverAssigmentModule
          cardDetail={card}
          afterConfirmed={addCurrentPageToHistory}
          theme={theme}
        >
          {mapStyle !== MapStyles.Maximized && (
            <>
              <FlowToolbar
                theme={theme}
                className={toolbarCss}
                toolbarPanelMessage={
                  topToolbarMsg && (
                    <FormattedMessage
                      id={topToolbarMsg}
                      values={{ subjobNumber: getSubjobNumber(card.id) }}
                    />
                  )
                }
              >
                <ModifyHeader noButtons messageId="card-popup.driver-assign">
                  {hasClosableAlarm(card) && (
                    <AlarmBadge value={card.reminder.iso} inline />
                  )}
                </ModifyHeader>
              </FlowToolbar>
              {card.driver && (
                <JobDetailHeader size={HeaderSizes.Small}>
                  <AssignedDriver card={card} />
                </JobDetailHeader>
              )}

              <ContentPanel
                className={themedClass('job-overview-horizontal', theme)}
                theme={theme}
              >
                <JobDetailBox
                  cardData={card}
                  jobDetail={job}
                  hidden={mapStyle !== MapStyles.Default}
                />
              </ContentPanel>
            </>
          )}
        </DriverAssigmentModule>
      </FlowColumn>
    </FlowTransition>
  )
}

export default CardDriverAssign
