import classNames from 'classnames'
import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import { CalendarPicker } from './CalendarPicker'
import TimePickerGrid from './TimePickerGrid'
import { themedClass } from '../../utils/style-utils'
import { FullDateLongMonthFormat, HourMinuteFormat } from '../DateTimeFormats'

const CardDateTimePicker = ({
  value,
  onValueChanged,
  collapsed,
  theme,
  dirty,
}) => {
  const onTimeSelected = (hour, minutes) => {
    const val = moment(value).hour(hour).minutes(minutes)
    onValueChanged(val)
  }

  const calendarCss = classNames(
    themedClass('datetime-calendar-panel', theme),
    {
      'datetime-calendar-collapsed': collapsed,
    }
  )

  const valueCss = classNames('datetime-calendar-value', {
    'datetime-calendar-value-transparent': !dirty && !collapsed,
  })

  return (
    <div className="datetime-pickers">
      <div className={calendarCss}>
        <div className="datetime-calendar-header">
          <FormattedMessage id="date" />
        </div>
        <div className="datetime-calendar-body">
          <h1 className={valueCss}>
            <FullDateLongMonthFormat date={value} />
          </h1>
          {!collapsed && (
            <CalendarPicker
              value={value}
              onValueChanged={onValueChanged}
              theme={theme}
            />
          )}
        </div>
      </div>

      <div className={calendarCss}>
        <div className="datetime-calendar-header">
          <FormattedMessage id="time" />
        </div>
        <div className="datetime-calendar-body">
          <h1 className={valueCss}>
            <HourMinuteFormat date={value} />
          </h1>
          {!collapsed && (
            <TimePickerGrid
              value={value}
              onTimeSelected={onTimeSelected}
              theme={theme}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CardDateTimePicker
