import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import {
  HeaderSizes,
  JobDetailHeader,
  JobHeaderLabel,
} from '../ModalsTypography'
import { MilestoneValue } from '../job-cards/MilestoneBadge'

const DispatchStatus = ({ milestone }) => {
  const status = milestone?.code

  const css = classNames({
    'text-important': !!status,
  })

  return (
    <div className="dispatch-status">
      <JobHeaderLabel>
        <FormattedMessage id="drivers.dispatch-status" />
      </JobHeaderLabel>
      <JobDetailHeader size={HeaderSizes.Medium} uppercase className={css}>
        <MilestoneValue milestone={milestone} />
      </JobDetailHeader>
    </div>
  )
}

export default DispatchStatus
