import { FormattedMessage } from 'react-intl'

const MetricTemperatureUnitsEnum = {
  CelsiusDegree: 'units.celsius',
}

export const Temperature = ({ value }) => (
  <div>
    {value} <FormattedMessage id={MetricTemperatureUnitsEnum.CelsiusDegree} />{' '}
  </div>
)
