import classNames from 'classnames'
import _ from 'lodash'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useDrag, useDragLayer } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom'

const throttledDragging = _.throttle(
  (ignoreMouseOffsetY, monitor) => {
    const initialOffsetDifference = monitor.getDifferenceFromInitialOffset()
    const initialClientOffset = monitor.getInitialClientOffset()
    const initialOffset = monitor.getInitialSourceClientOffset()

    if (!initialClientOffset || !initialOffset || !initialOffsetDifference) {
      return null
    }

    return {
      x: initialOffsetDifference.x + initialOffset.x,
      y: ignoreMouseOffsetY
        ? initialOffsetDifference.y + initialClientOffset.y
        : initialOffsetDifference.y + initialOffset.y,
    }
  },
  50,
  {
    leading: true,
    trailing: true,
  }
)

const DraggedPreview = ({ children, ignoreMouseOffsetY }) => {
  const previewLocation = useDragLayer(monitor =>
    throttledDragging(ignoreMouseOffsetY, monitor)
  )

  if (!previewLocation) return null

  const { x, y } = previewLocation
  return ReactDOM.createPortal(
    <div
      className="drag-preview"
      style={{
        transform: `translate(${x}px, ${y}px)`,
      }}
    >
      {children}
    </div>,
    document.body
  )
}

//ignoreMouseOffsetY -> top of dragPreview is aligned with cursor (used e.g when drag preview is shorter that dragged element)
const DraggableComponent = (
  {
    dragItem,
    children,
    className,
    onDragStart,
    onDragEnd,
    CustomPreview,
    ignoreMouseOffsetY,
    canDrag = true,
  },
  ref
) => {
  const isUnmountedRef = useRef(false)
  const [dragging, setDragging] = useState(false)

  const [dragStyle, drag, preview] = useDrag({
    item: dragItem,
    collect: monitor => ({
      class: monitor.isDragging() ? 'dragged' : '',
    }),
    begin: () => {
      setDragging(true)
      onDragStart && onDragStart(dragItem.data)
    },
    end: () => {
      onDragEnd && onDragEnd(dragItem.data)
      // avoid updating state if component was umounted before drag ended
      if (!isUnmountedRef.current) {
        setDragging(false)
      }
    },
    canDrag,
  })

  useEffect(
    () => () => {
      isUnmountedRef.current = true
    },
    []
  )

  const setRefs = useCallback(
    r => {
      const refFunctions = [drag]
      if (ref) refFunctions.push(ref)
      return _.flow(refFunctions)(r)
    },
    [drag, ref]
  )

  useEffect(() => {
    preview(getEmptyImage())
  }, [preview])

  const previewComponent = CustomPreview ? (
    <DraggedPreview ignoreMouseOffsetY={ignoreMouseOffsetY}>
      <CustomPreview>{children}</CustomPreview>
    </DraggedPreview>
  ) : (
    <DraggedPreview ignoreMouseOffsetY={ignoreMouseOffsetY}>
      <div className={className}>{children}</div>
    </DraggedPreview>
  )

  const dragCss = classNames(className, dragStyle.class)
  return (
    <>
      {dragging && previewComponent}
      <div ref={setRefs} className={dragCss}>
        {children}
      </div>
    </>
  )
}

export default forwardRef(DraggableComponent)
