import _ from 'lodash'

import { useOptimisticMutation } from './graphQl/useOptimisticMutation'
import { useCurrentStation } from './useCurrentStation'
import { updateDriversList } from '../app/graphQl/cacheManager'
import { GET_DRIVERS, SET_DRIVERS_STATE } from '../app/graphQl/schema/Drivers'

export function useDrivers() {
  const { stationId } = useCurrentStation()
  const [setDriverStateMutation] = useOptimisticMutation(
    SET_DRIVERS_STATE,
    'setDriversState'
  )

  const createDriversFilter = () => ({
    filter: { station: stationId },
  })

  const getAllCachedDrivers = cache => {
    const { listDrivers } = cache.readQuery({
      query: GET_DRIVERS,
      variables: createDriversFilter(),
    })
    return listDrivers
  }

  const setDriverState = (drivers, isActive) => {
    setDriverStateMutation({
      variables: { driverIds: _.map(drivers, d => d.id), isActive },
      cacheUpdate: cache => {
        const allDrivers = getAllCachedDrivers(cache)
        const updatedDrivers = _.map(drivers, d => ({
          ...d,
          isActive: isActive,
        }))
        updateDriversList(cache, _.unionBy(updatedDrivers, allDrivers, 'id'))
      },
    })
  }

  return {
    setDriverState,
  }
}
