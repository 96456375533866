import { Checkbox } from 'antd'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { useCards } from '../../../hooks/useCards'
import { needAcknowledgeInstructions } from '../../../utils/card-utils'
import { themedClass } from '../../../utils/style-utils'
import ScrollArea from '../../ScrollArea'
import UserTextPreview from '../../UserTextPreview'

const SpecialInstructions = ({ card, className, theme }) => {
  const { acknowledgeInstructions } = useCards()

  const emptyCss = classNames('special-instructions-empty', className)

  const onChecked = () => {
    if (showWarning) {
      acknowledgeInstructions(card)
    }
  }

  if (!card) return <div className={emptyCss} />

  const { specialInstructions: instructions } = card
  const showWarning = needAcknowledgeInstructions(instructions)

  const wrapCss = classNames(className, {
    'special-instructions-checked': !showWarning,
  })

  return (
    <>
      {instructions?.text ? (
        <ScrollArea
          wrapClassName={wrapCss}
          autoHeight
          autoHeightMax={120}
          className="special-instructions-scroll"
        >
          <div className={themedClass('special-instructions-content', theme)}>
            <UserTextPreview text={instructions.text} />
          </div>
        </ScrollArea>
      ) : (
        <div className={emptyCss}>
          <div className="special-instructions-content special-instructions-empty">
            <FormattedMessage id="card-popup.special-instructions-empty" />
          </div>
        </div>
      )}
      {showWarning && (
        <div className="acknowledge-warning text-danger flex-center-end">
          <FormattedMessage id="special-instructions.need-acknowledge" />
          <Checkbox
            className="special-instructions-checkbox special-instructions-checkbox-bottom"
            checked={instructions?.wasAcknowledged}
            onChange={onChecked}
          />
        </div>
      )}
    </>
  )
}

export default SpecialInstructions
