import { FormattedMessage, useIntl } from 'react-intl'

import { MilestonesEnum } from '../../app/enums/MilestonesEnum'
import { MISSING_VALUE } from '../../utils/string-utils'
import CardsStatus from '../CardStatus'
import { DotsLoader } from '../LoadingIndicators'

function getMilestoneStatus(milestone) {
  if (!milestone) return 'default'

  if (milestone.code === MilestonesEnum.UNLOADED) {
    return 'warning'
  }

  return 'default'
}

export const MilestoneValue = ({ milestone }) => {
  if (!milestone || !milestone.code) return MISSING_VALUE

  return (
    <FormattedMessage
      id={'card.milestone-' + milestone.code}
      defaultMessage={milestone.code.toLowerCase()}
    />
  )
}

const MilestoneBadge = ({ milestone, lockData, pendingUpdate }) => {
  const { formatMessage: f } = useIntl()
  const isLocked = lockData && lockData.isLocked
  const css = isLocked
    ? 'card-milestone card-milestone-locked'
    : 'card-milestone'
  const title = isLocked
    ? f({ id: 'card.locked-tooltip' }, { byWhom: lockData.byWhom })
    : ''
  const topLabel = isLocked ? 'card.status-pending' : 'card.status'

  return (
    <CardsStatus
      className={css}
      labelId={topLabel}
      value={
        pendingUpdate ? (
          <DotsLoader />
        ) : (
          <MilestoneValue milestone={milestone} />
        )
      }
      valueStatus={getMilestoneStatus(milestone)}
      title={title}
    />
  )
}

export default MilestoneBadge
