import { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

import DispatchPage from './DispatchPage'
import MonitorPage from './MonitorPage'
import { CssValues } from '../app/constants'
import { SWITCH_PAGE } from '../app/keymaps'
import Sitemap from '../app/sitemap'
import NewTestCardButton from '../components/NewTestCardButton'
import ArchivedCardDetail from '../components/card-popup/ArchivedCard'
import CardDetailPopup from '../components/card-popup/CardDetailPopup'
import { useCardsCleanup } from '../hooks/useCardsCleanup'
import { useCurrentStation } from '../hooks/useCurrentStation'
import { DriversAssignContextProvider } from '../hooks/useDriversAssignContext'
import useHydrateCache from '../hooks/useHydrateCache'
import { MonitorContextProvider } from '../hooks/useMonitorContext'
import { NotificationsProvider } from '../hooks/useNotificationsContext'
import { useSubscriptionsSetup } from '../hooks/useSubscriptionsSetup'
import { isArchived } from '../utils/card-utils'

const useNavigationShortcuts = currentPage => {
  const { stationId } = useCurrentStation()
  let history = useHistory()

  useHotkeys(
    SWITCH_PAGE.keymap,
    e => {
      const nextPage =
        currentPage === Sitemap.monitor
          ? Sitemap.createDispatchLink(stationId)
          : Sitemap.createMonitorLink(stationId)
      history.push(nextPage)
      e.preventDefault()
    },
    {},
    [stationId, currentPage]
  )
}

const PrivateSection = () => {
  useSubscriptionsSetup()
  useCardsCleanup()

  const { isLoading, hasError } = useHydrateCache()

  const monitorMatch = useRouteMatch(Sitemap.monitor)
  const dispatchMatch = useRouteMatch(Sitemap.dispatch)
  useNavigationShortcuts(monitorMatch ? Sitemap.monitor : Sitemap.dispatch)

  const [popupCard, setPopupCard] = useState(null)

  const animDuration = CssValues.pageTransitionDuration

  return (
    <NotificationsProvider openPopupCard={setPopupCard}>
      <CardDetailPopup
        onClose={setPopupCard}
        cardData={isArchived(popupCard) ? null : popupCard}
      />

      <ArchivedCardDetail
        onClose={setPopupCard}
        cardData={!isArchived(popupCard) ? null : popupCard}
      />

      <CSSTransition
        in={monitorMatch !== null}
        timeout={animDuration}
        classNames={'page-transition-from-left'}
        mountOnEnter={true}
        appear={true}
      >
        <div className="page-wrapper page-wrapper-anchored-left">
          <MonitorContextProvider>
            <MonitorPage
              openPopupCard={setPopupCard}
              isLoading={isLoading}
              hasError={hasError}
            />
          </MonitorContextProvider>
        </div>
      </CSSTransition>

      <CSSTransition
        in={dispatchMatch !== null}
        timeout={animDuration}
        classNames={'page-transition-from-right'}
        mountOnEnter={true}
        appear={true}
      >
        <div className="page-wrapper">
          <DriversAssignContextProvider>
            <DispatchPage
              openPopupCard={setPopupCard}
              isLoading={isLoading}
              hasError={hasError}
            />
          </DriversAssignContextProvider>
        </div>
      </CSSTransition>
      <NewTestCardButton />
    </NotificationsProvider>
  )
}

export default PrivateSection
