import classNames from 'classnames'
import { Fragment } from 'react'

import ScrollArea from './ScrollArea'
import { themedClass } from '../utils/style-utils'

const VerticalList = ({
  items,
  itemCss,
  isActive,
  onItemClick,
  isDisabled,
  scrollableContent,
  className,
  getItemCss,
  ItemComponent,
  theme,
}) => {
  const onItemClicked = (item, index) => {
    if (!isDisabled || !isDisabled(item)) {
      onItemClick && onItemClick(item, index)
    }
  }
  const cssClass = themedClass('vertical-list', theme, className)
  const WrapComponent = scrollableContent ? ScrollArea : Fragment

  const getItemClass = (item, isActive) =>
    themedClass(
      'vertical-list-item',
      theme,
      classNames(
        itemCss,
        getItemCss ? getItemCss(item) : '',
        { active: isActive },
        { disabled: isDisabled && isDisabled(item) }
      )
    )

  return (
    <div className={cssClass}>
      <WrapComponent>
        {items.map((item, index) => {
          const active = isActive && isActive(item)
          const css = getItemClass(item, active)
          return (
            <div
              onClick={e => onItemClicked(item, e)}
              key={index}
              className={css}
            >
              <ItemComponent item={item} active={active} />
            </div>
          )
        })}
      </WrapComponent>
    </div>
  )
}

export default VerticalList
