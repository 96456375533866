import _ from 'lodash'
import { useState } from 'react'

import useDropWithContext from './dragAndDrop/useDropWithContext'
import { useCards } from './useCards'
import { CardDragType } from '../components/job-cards/Card'
import { canBeCardDropped } from '../utils/common-utils'

const useCardStackChange = (stack, selectedCards, onConfirm) => {
  const [lastDropped, setLastDropped] = useState(null)
  const [droppedUncofirmed, setDroppedUnconfirmed] = useState(null)
  const { changeStack } = useCards()

  const onConfirmed = pickupEta => {
    changeStack(droppedUncofirmed, stack, pickupEta)
    setDroppedUnconfirmed(null)
  }

  const [{ isHovered }, cardDrop] = useDropWithContext(stack, {
    accept: CardDragType,
    drop: item => {
      const cardsToMove = _.unionBy(selectedCards, [item.data], 'id')
      if (!onConfirm) {
        changeStack(cardsToMove, stack)
        setLastDropped(item.data)
      } else {
        setDroppedUnconfirmed(cardsToMove)
        onConfirm(cardsToMove)
      }
    },
    collect: monitor => ({
      isHovered: monitor.isOver() && monitor.canDrop(),
    }),
    canDrop: item => canBeCardDropped(item, stack ? stack.name : null),
  })

  return [isHovered, cardDrop, lastDropped, onConfirmed]
}

export default useCardStackChange
