import _ from 'lodash'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

const ScrollDirection = {
  Right: 1,
  Left: -1,
}

const ScrollSpeed = 30

const View = forwardRef((props, ref) => {
  const { scrollOnEdge, onEdgeEnter, onEdgeLeave, ...rest } = props
  const [isDragging, setIsDragging] = useState(false)

  return (
    <>
      {isDragging && (
        <div
          className="scrollable-edge-left"
          onDragLeave={onEdgeLeave}
          onDragEnter={() => {
            onEdgeEnter && onEdgeEnter(ScrollDirection.Left)
          }}
        />
      )}
      <div
        {...rest}
        ref={ref}
        onDragStart={() => {
          if (scrollOnEdge) {
            setIsDragging(true)
          }
        }}
        onDragEnd={() => {
          setIsDragging(false)
        }}
        onDrop={() => {
          setIsDragging(false)
        }}
      />
      {isDragging && (
        <div
          className="scrollable-edge-right"
          onDragLeave={onEdgeLeave}
          onDragEnter={() => {
            onEdgeEnter && onEdgeEnter(ScrollDirection.Right)
          }}
        />
      )}
    </>
  )
})

const ScrollArea = forwardRef(
  (
    {
      children,
      wrapClassName,
      className,
      hideHorizontal,
      hideVertical,
      onUpdate,
      scrollOnEdge,
      noZeroWidth,
      style,
      ...rest
    },
    ref
  ) => {
    const [currentValues, setCurrentValues] = useState()
    const [autoScrollDirection, setAutoScrollDirection] = useState(0)

    const hideScrollBar = () => <div style={{ visibility: 'hidden' }} />

    useEffect(() => {
      if (autoScrollDirection && currentValues) {
        const timerID = setInterval(() => {
          ref.current.scrollLeft(currentValues.scrollLeft + autoScrollDirection)
        }, 10)
        return () => clearInterval(timerID)
      }
    }, [currentValues, autoScrollDirection, ref])

    const onScrollUpdate = useCallback(
      e => {
        if (scrollOnEdge) setCurrentValues(x => (_.isEqual(x, e) ? x : e))
        onUpdate && onUpdate(e)
      },
      [onUpdate, scrollOnEdge]
    )

    const onEdgeEnter = direction => {
      setAutoScrollDirection(direction * ScrollSpeed)
    }

    const onEdgeLeave = () => {
      setAutoScrollDirection(0)
    }

    return (
      <Scrollbars
        ref={ref}
        className={wrapClassName}
        style={{
          flexGrow: 1,
          height: 'auto',
          transform: 'translate3d(0, 0, 0)', // fix for safari
          ...style,
        }}
        hideTracksWhenNotNeeded={true}
        renderView={props => (
          <View
            {...props}
            className={className}
            scrollOnEdge={scrollOnEdge}
            onEdgeEnter={onEdgeEnter}
            onEdgeLeave={onEdgeLeave}
          />
        )}
        renderThumbVertical={props => (
          <div {...props} className="scrollbar-thumb" />
        )}
        renderThumbHorizontal={props => (
          <div {...props} className="scrollbar-thumb" />
        )}
        renderTrackVertical={props =>
          hideVertical ? (
            hideScrollBar()
          ) : (
            <div {...props} className="scrollbar-track track-vertical" />
          )
        }
        renderTrackHorizontal={props =>
          hideHorizontal ? (
            hideScrollBar()
          ) : (
            <div {...props} className="scrollbar-track track-horizontal" />
          )
        }
        onUpdate={onScrollUpdate}
        {...rest}
      >
        {children}
      </Scrollbars>
    )
  }
)

export default ScrollArea
