import classNames from 'classnames'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { DispatchPageConstants } from '../app/constants'
import ColumnDropdown, {
  DropdownButtonStyle,
} from '../components/ColumnDropdown'
import JobCardsColumnContainer from '../components/cards-column/JobCardsColumnContainer'
import { DispatchCardPreview } from '../components/job-cards/DispatchCardPreview'
import { JobNumberPanel } from '../components/job-detail/JobNumberPanel'
import { useFilterDispatchPageCards } from '../hooks/dispatch-page/useFilterDispatchPageCards'
import { useActiveCardsList } from '../hooks/graphQl/useActiveCardsList'
import useStacksList from '../hooks/graphQl/useStacksQuery'
import { useCardsHighlight } from '../hooks/useCardsHighlight'
import { createToggleFunc } from '../utils/array-utils'
import { isDeclined } from '../utils/card-utils'
import { orderStacksForDropdown } from '../utils/common-utils'
import { emptyArray, emptyObj } from '../utils/perf-utils'
import {
  filterAdditionalDockStacks,
  filterNonDockStacks,
  findDockStack,
  findNewJobsStack,
} from '../utils/stacks-utils'
import { themedClass } from '../utils/style-utils'

const getMenuItems = stacks => {
  if (!stacks) return
  return [
    {
      id: DispatchPageConstants.viewGroupId,
      name: 'card-column-menu.view',
      items: orderStacksForDropdown(stacks).map(s => ({
        id: s.id,
        label: (s.name || '').toLowerCase(),
      })),
    },
    {
      id: DispatchPageConstants.statusGroupId,
      name: 'card-column-menu.status',
      items: [
        {
          id: DispatchPageConstants.unassignedItemId,
          label: 'card-column-menu.unassigned',
        },
      ],
    },
  ]
}
function needSelectCard(cardsSelection, filteredCards) {
  const selectedCard = _.head(cardsSelection.selectedCards)
  const selectedParent = cardsSelection.selectedParent

  if (!selectedCard) return _.some(filteredCards)

  return selectedParent
    ? !_.some(filteredCards, c => c.id === selectedParent.id)
    : !_.some(
        filteredCards,
        c => c.id === selectedCard.id || c.id === selectedCard.groupParent
      )
}

const getTopCard = filtered => {
  if (!filtered) return null
  return (
    _.find(filtered.nonAlarmed, c => !isDeclined(c)) ||
    _.find(filtered.alarmed, c => !isDeclined(c))
  )
}

export function JobCardsDispatchColumn({
  cardDetail,
  cardsSelection,
  selectCard,
  selectedHours,
  hidden,
  theme,
}) {
  const [topStack, setTopStack] = useState(emptyObj)
  const [selectedStatuses, setSelectedStatuses] = useState([])
  const [highlightInfo, highlightSubjobs, highlightLegs] = useCardsHighlight()
  const { stacksData: stacks } = useStacksList()

  const { cardsData: cards } = useActiveCardsList()

  const menuItems = getMenuItems(filterNonDockStacks(stacks))
  const onDockStack = findDockStack(stacks)

  useEffect(() => {
    if (_.some(stacks, x => x.id === topStack.id)) return
    if (stacks && stacks.length) {
      const newJobsStack = findNewJobsStack(stacks)
      if (newJobsStack) setTopStack(newJobsStack)
    }
  }, [stacks, topStack])

  const filteredCards = useFilterDispatchPageCards(
    selectedHours,
    selectedStatuses,
    topStack
  )

  useEffect(() => {
    const allCards = _.flatMap(_.values(filteredCards[topStack?.id]))
    if (needSelectCard(cardsSelection, allCards)) {
      selectCard(getTopCard(filteredCards[topStack?.id]))
    }
  }, [cardsSelection, filteredCards, selectCard, topStack])

  const onCardSelected = useCallback(
    (card, _event, parent) => {
      selectCard(card, parent)
    },
    [selectCard]
  )

  const toggleSelectedStatus = createToggleFunc(
    selectedStatuses,
    setSelectedStatuses
  )

  const onMenuItemClicked = (item, group) => {
    if (group === DispatchPageConstants.viewGroupId) {
      const clickedStack = stacks.find(s => s.id === item)
      if (clickedStack) setTopStack(clickedStack)
    }

    if (group === DispatchPageConstants.statusGroupId) {
      toggleSelectedStatus(item)
    }
  }

  const onCardDragStart = useCallback(
    card => {
      selectCard(card)
    },
    [selectCard]
  )

  const wrapCss = classNames('flexbox-multiline dispatch-layout-column', {
    'column-hidden': hidden,
  })

  return (
    <div className={wrapCss}>
      <JobNumberPanel darkShadow card={cardDetail} theme={theme} />

      <div className={themedClass('cards-column-menu-wrapper', theme)}>
        <div className="cards-column-menu">
          <ColumnDropdown
            theme={theme}
            items={menuItems}
            buttonText="."
            onItemClick={onMenuItemClicked}
            iconStyle={DropdownButtonStyle.LARGE}
            selectedValues={{
              [DispatchPageConstants.viewGroupId]: [topStack.id],
              [DispatchPageConstants.statusGroupId]: selectedStatuses,
            }}
            isItemEnabled={(item, groupId) =>
              groupId === DispatchPageConstants.viewGroupId
                ? _.some(cards, c => c.stack?.id === item.id)
                : true
            }
          />
        </div>

        <JobCardsColumnContainer
          cardsSelection={cardsSelection}
          cardClicked={onCardSelected}
          topLabel={topStack.name}
          cards={filteredCards}
          stack={topStack}
          onCardDragStart={onCardDragStart}
          DragPreview={DispatchCardPreview}
          onGroupCreated={selectCard}
          groupAlarmedByStack
          highlightInfo={highlightInfo}
          onSubJobsHighlight={highlightSubjobs}
          onLegsHighlight={highlightLegs}
          additionalStacks={
            topStack?.id === onDockStack?.id
              ? filterAdditionalDockStacks(stacks)
              : emptyArray
          }
        />
      </div>
    </div>
  )
}
