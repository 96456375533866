import { FormattedMessage } from 'react-intl'

import { CardPopupState } from '../../../app/enums/CardPopupState'
import { ArrowCircleRight } from '../../Icons'
import VerticalList from '../../VerticalList'

const MenuItem = ({ item, active }) => {
  if (item === CardPopupState.SubJob) {
    return (
      <>
        <i className={`fas ${item.Icon}`} />
        <span className="navigation-item-label text-uppercase">
          <FormattedMessage id={item.Label} />
        </span>
      </>
    )
  }

  if (item.card) {
    return (
      <>
        <span className="navigation-item-label text-capitalized">
          <i className={`navigation-icon fas ${item.Icon}`} />
          <FormattedMessage id={item.Label} defaultMessage={item.Label} />
        </span>
        {active && (
          <ArrowCircleRight className="text-important"></ArrowCircleRight>
        )}
      </>
    )
  }
  return (
    <>
      <span className="navigation-item-label text-capitalized">
        <FormattedMessage id={item.Label} defaultMessage={item.Label} />
      </span>
      <i className={`fas ${item.Icon}`} />
    </>
  )
}

const VerticalMenu = ({
  items,
  onItemClick,
  isDisabled,
  isActive,
  getItemCss,
  theme,
}) => (
  <VerticalList
    items={items}
    itemCss="navigation-menu-item"
    ItemComponent={MenuItem}
    onItemClick={onItemClick}
    isDisabled={isDisabled}
    isActive={isActive}
    className="navigation-menu"
    getItemCss={getItemCss}
    theme={theme}
  />
)

export default VerticalMenu
