import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { CardNotificationDuration, CssValues } from '../../app/constants'
import { getCardIdLabel } from '../../utils/card-utils'
import { CloseIconAlt } from '../Icons'
import Transition, { TransitionTypes } from '../Transition'

const ClosedCard = ({ data, onExited }) => {
  const [createTime] = useState(new Date())
  const [visible, setVisible] = useState(true)

  const onCloseClicked = () => {
    setVisible(false)
  }

  useEffect(() => {
    const timerID = setTimeout(() => {
      setVisible(false)
    }, CardNotificationDuration - (new Date() - createTime))

    return function cleanup() {
      clearInterval(timerID)
    }
  }, [createTime])

  return (
    <Transition
      opened={visible}
      type={TransitionTypes.ScaleUp}
      duration={CssValues.cardRemovalDuration}
      className="card-wrap"
      onExited={onExited}
    >
      <div className="removed-card-notification">
        <i className="fas fa-archive" />
        <div style={{ flexGrow: 1 }}>
          {getCardIdLabel(data)} <FormattedMessage id="card.archived" />
        </div>
        <CloseIconAlt onClick={onCloseClicked} />
      </div>
    </Transition>
  )
}

export default ClosedCard
