import _ from 'lodash'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import useDriversList from './graphQl/useDriversList'
import useMultipleSelection from './useMultipleSelection'
import { MapStyles } from '../app/constants'
import { objectToArray } from '../utils/array-utils'
import { areSameVehicleGroup, isActive } from '../utils/driver-utils'

const updateEtaOrders = etaTable => {
  const orderedEta = _.sortBy(objectToArray(etaTable), e => e.eta.pickupEta)

  for (let i = 0; i < _.size(orderedEta); i++) {
    etaTable[orderedEta[i].key] = {
      ...etaTable[orderedEta[i].key],
      eta: {
        ...etaTable[orderedEta[i].key].eta,
        etaOrder: i < 2 ? i + 1 : null,
      },
    }
  }
  return etaTable
}

export const DriversAssignContext = createContext()

export const DriversAssignContextProvider = ({ children }) => {
  const [mapStyle, setMapStyle] = useState(MapStyles.Default)
  const [estimatedArrivals, setEstimatedArrivals] = useState({})

  const { drivers: driversData } = useDriversList()
  const driversSelection = useMultipleSelection(
    driversData,
    areSameVehicleGroup,
    true
  )

  const driversChecking = useMultipleSelection(driversData)

  useEffect(() => {
    const { actions, selectedItems } = driversChecking
    const itemsToDeselect = _.filter(selectedItems, driver => !isActive(driver))

    if (_.some(itemsToDeselect)) actions.deselectItems(itemsToDeselect)
  }, [driversChecking, driversData])

  const onMapStyleChanged = useCallback(type => {
    if (type === MapStyles.Expanded) {
      setMapStyle(current =>
        current === MapStyles.Expanded ? MapStyles.Default : MapStyles.Expanded
      )
      return
    }
    setMapStyle(type)
  }, [])

  const onEtasChanged = useCallback(
    callback =>
      setEstimatedArrivals(current => updateEtaOrders(callback(current))),
    []
  )

  console.log('DriversAssignContext render')

  const value = useMemo(
    () => ({
      mapStyle,
      setMapStyle: onMapStyleChanged,
      estimatedArrivals,
      setEstimatedArrivals: onEtasChanged,
      driversSelection,
      driversChecking,
    }),
    [
      mapStyle,
      onMapStyleChanged,
      estimatedArrivals,
      onEtasChanged,
      driversSelection,
      driversChecking,
    ]
  )
  return (
    <DriversAssignContext.Provider value={value}>
      {children}
    </DriversAssignContext.Provider>
  )
}

export const useDriverAssignContext = () => useContext(DriversAssignContext)
