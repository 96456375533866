import _ from 'lodash'

import cargoImg from '../../styles/img/vehicles/CargoVanBlue.png'
import cargoImgDark from '../../styles/img/vehicles/CargoVanBlueGreen.png'
import cargoICImg from '../../styles/img/vehicles/CargoVanBrown.png'
import cargoICImgDark from '../../styles/img/vehicles/CargoVanBrownDark.png'
import cargoLSP from '../../styles/img/vehicles/CargoVanLSP.png'
import suvImg from '../../styles/img/vehicles/SUVBlue.png'
import suvImgDark from '../../styles/img/vehicles/SUVBlueGreen.png'
import suvICImg from '../../styles/img/vehicles/SUVBrown.png'
import suvICImgDark from '../../styles/img/vehicles/SUVBrownDark.png'
import suvLSP from '../../styles/img/vehicles/SUVGrayLSP.png'
import sprinterImg from '../../styles/img/vehicles/Sprinter.svg'
import sprinterImgDark from '../../styles/img/vehicles/SprinterBlueGreen.svg'
import sprinterICImg from '../../styles/img/vehicles/SprinterBrown.svg'
import sprinterICImgDark from '../../styles/img/vehicles/SprinterBrownDark.svg'
import sprinterLSP from '../../styles/img/vehicles/SprinterGrayLSP.svg'
import truckImg from '../../styles/img/vehicles/TruckBlue.png'
import truckImgDark from '../../styles/img/vehicles/TruckBlueGreen.png'
import truckICImg from '../../styles/img/vehicles/TruckBrown.png'
import truckICImgDark from '../../styles/img/vehicles/TruckBrownDark.png'
import truckLSP from '../../styles/img/vehicles/TruckGrayLSP.png'

export const VehicleTypesEnum = {
  Car: {
    id: 'CAR',
    icon: 'fa-car',
    label: 'vehicle-type.car',
    unicodeChar: '\uf1b9',
  },
  Suv: {
    id: 'SUV',
    icon: 'svg-suv',
    label: 'vehicle-type.suv',
    img: { contractor: suvImg, default: suvICImg, lsp: suvLSP },
    imgDarkTheme: {
      contractor: suvImgDark,
      default: suvICImgDark,
      lsp: suvLSP,
    },
  },
  Minivan: {
    id: 'MINIVAN',
    icon: 'fa-shuttle-van',
    label: 'vehicle-type.minivan',
    unicodeChar: '\uf5b6',
  },
  Pickup: {
    id: 'PICKUP',
    icon: 'fa-truck-pickup',
    label: 'vehicle-type.pickup',
    unicodeChar: '\uf63c',
  },
  Cargo: {
    id: 'CARGO',
    icon: 'svg-cargo',
    label: 'vehicle-type.cargo-van',
    img: { contractor: cargoImg, default: cargoICImg, lsp: cargoLSP },
    imgDarkTheme: {
      contractor: cargoImgDark,
      default: cargoICImgDark,
      lsp: cargoLSP,
    },
  },
  Sprinter: {
    id: 'SPRINTER',
    icon: 'svg-sprinter',
    label: 'vehicle-type.sprinter',
    img: { contractor: sprinterImg, default: sprinterICImg, lsp: sprinterLSP },
    imgDarkTheme: {
      contractor: sprinterImgDark,
      default: sprinterICImgDark,
      lsp: sprinterLSP,
    },
  },
  LargeCargo: {
    id: 'TRUCK',
    icon: 'svg-truck',
    label: 'vehicle-type.truck',
    img: { contractor: truckImg, default: truckICImg, lsp: truckLSP },
    imgDarkTheme: {
      contractor: truckImgDark,
      default: truckICImgDark,
      lsp: truckLSP,
    },
  },
}

export const VehiclesCategories = {
  [VehicleTypesEnum.Car.id]: '0',
  [VehicleTypesEnum.Suv.id]: '0',
  [VehicleTypesEnum.Minivan.id]: '0',
  [VehicleTypesEnum.Pickup.id]: '0',

  [VehicleTypesEnum.Cargo.id]: '1',
  [VehicleTypesEnum.Sprinter.id]: '1',
  [VehicleTypesEnum.LargeCargo.id]: '1',
}

export function toIntlType(type) {
  return `vehicle-type.${_.toLower(type)}`
}
