import { FormattedMessage } from 'react-intl'

import ConfirmModalBase from './ConfirmModalBase'
import { WarningIcon } from '../Icons'

const AcknowledgeInstructions = ({ containerRef, onClosed }) => {
  console.log('AcknowledgeInstructions displayed')
  return (
    <ConfirmModalBase
      getContainer={containerRef}
      className="ack-instructions-popup"
      width={360}
      okButtonProps={{ style: { display: 'none' } }}
      title={<WarningIcon className="opaque-icon text-warning" />}
      onCancel={onClosed}
    >
      <h1>
        <FormattedMessage id="confirm.attention" />
      </h1>
      <h2>
        <FormattedMessage id="special-instructions.need-acknowledge-long" />
      </h2>
    </ConfirmModalBase>
  )
}

export default AcknowledgeInstructions
