import classNames from 'classnames'

const CANDY_STRIP_SMALL = 'candy-strip-segment-small'
const CANDY_STRIP = 'candy-strip-segment'
const CANDY_STRIP_BORDER = 'candy-strip-border'

const CANDY_STRIP_SMALL_MASK = CANDY_STRIP_SMALL + '_mask'
const CANDY_STRIP_MASK = CANDY_STRIP + '_mask'
const CANDY_STRIP_BORDER_MASK = CANDY_STRIP_BORDER + '_mask'

const candyStripWidth = 23

const PathPattern = props => {
  const { id, width, path, ...pathProps } = props
  return (
    <pattern id={id} patternUnits="userSpaceOnUse" width={width} height="100%">
      <path d={path} {...pathProps} />
    </pattern>
  )
}

const SvgMask = ({ maskId, patternId }) => (
  <mask id={maskId}>
    <rect fill={`url(#${patternId})`} height="100%" width="100%" />
  </mask>
)

const RectWithMask = ({ maskId, className }) => (
  <rect
    mask={`url(#${maskId})`}
    height="100%"
    width="100%"
    className={className}
  />
)

// need to be included once in root of App
export const SVGPatterns = () => (
  <svg style={{ display: 'block', position: 'absolute' }}>
    <defs>
      <PathPattern
        id={CANDY_STRIP_SMALL}
        path="M 8 0 L 0 6 L 14.5 6 L 23 0 L 8 0"
        width={candyStripWidth}
        fill="white"
      />
      <PathPattern
        id={CANDY_STRIP}
        path="M 7 1 L 2 7 L 15 7 L 20 1 L 7 1"
        width={candyStripWidth}
        fill="white"
      />
      <PathPattern
        id={CANDY_STRIP_BORDER}
        path="M 7 1 L 2 7 L 15 7 L 20 1 L 7 1"
        width={candyStripWidth}
        strokeWidth="2"
        stroke="white"
      />
    </defs>
    <SvgMask maskId={CANDY_STRIP_SMALL_MASK} patternId={CANDY_STRIP_SMALL} />
    <SvgMask maskId={CANDY_STRIP_MASK} patternId={CANDY_STRIP} />
    <SvgMask maskId={CANDY_STRIP_BORDER_MASK} patternId={CANDY_STRIP_BORDER} />
  </svg>
)

export const CandyStripSmall = () => (
  <div className="candy-strip">
    <svg>
      <RectWithMask
        maskId={CANDY_STRIP_SMALL_MASK}
        className="candy-strip-border"
      />
    </svg>
  </div>
)

export const CandyStripBordered = ({ className }) => {
  const css = classNames('candy-strip', className)
  return (
    <div className={css}>
      <svg>
        <g>
          <RectWithMask
            maskId={CANDY_STRIP_BORDER_MASK}
            className="candy-strip-border"
          />
          <RectWithMask
            maskId={CANDY_STRIP_MASK}
            className="candy-strip-fill"
          />
        </g>
      </svg>
    </div>
  )
}
