import { config } from '../app/config'

export const isFeatureAllowed = key => !!config[key]

const SwitchableFeature = ({ configKey, children }) => {
  if (!isFeatureAllowed(configKey)) return null
  return children
}

export default SwitchableFeature
