import { useEffect, useState } from 'react'

import {
  WindowSizesEnum,
  windowWidthToSizeEnum,
} from '../app/enums/WindowSizesEnum'

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(WindowSizesEnum.Small)
  const [windowSizeExact, setWindowSizeExact] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(windowWidthToSizeEnum(window.innerWidth))
      setWindowSizeExact({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [setWindowSize])

  return [windowSize, windowSizeExact]
}

export default useWindowSize
