import classNames from 'classnames'
import _ from 'lodash'
import { forwardRef, memo, useState } from 'react'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import CardBadge from './CardBadge'
import CardDestinations from './CardDestinations'
import CardRemovalTransition from './CardRemovalTransition'
import ClosedCard from './ClosedCard'
import DistanceBadge from './DistanceBadge'
import MilestoneBadge from './MilestoneBadge'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import {
  getCardIdLabel,
  getDriverLabel,
  hasAlarmFired,
  isArchived,
  isCardFinished,
  isDeclined,
} from '../../utils/card-utils'
import {
  formatNullableString,
  missingDataPlaceholder,
} from '../../utils/string-utils'
import { themedClass } from '../../utils/style-utils'
import CopyJobNumber from '../CopyJobNumber'
import { CandyStripSmall } from '../SVGGraphics'

export const CardDragType = 'CARD'

const DangerousGoodsIndicator = ({ card, header }) =>
  !header && card.isDangerousGoods ? (
    <div className="dangerous-indicator">
      <CandyStripSmall />
    </div>
  ) : null

const Card = forwardRef(
  (
    {
      data,
      onClick,
      selected,
      header,
      countBadge,
      className,
      highlighted,
      theme,
      roundedCorners = true,
      distanceUnit,
    },
    ref
  ) => {
    const { getCurrentTime, fromServerValue } = useLocalDateTime()
    const [showClosed, setShowClosed] = useState(false)
    const [showCopyIcon, setShowCopyIcon] = useState(false)
    const [, setFiredAlarm] = useState(false)

    const onCardClick = e => {
      onClick && onClick(data, e)
      e.preventDefault()
      e.stopPropagation()
    }

    const removeAnimFinished = () => {
      setShowClosed(true)
    }

    useEffect(() => {
      setFiredAlarm(false)
    }, [data])

    useEffect(() => {
      if (!data.reminder || hasAlarmFired(data)) return

      const parsedReminder = fromServerValue(data.reminder?.iso)
      const now = getCurrentTime()
      const timeToFire = parsedReminder.diff(now, 'miliseconds')

      const timeoutHandle = setTimeout(() => {
        if (hasAlarmFired(data)) {
          setFiredAlarm(true)
        }
      }, timeToFire)

      return () => clearInterval(timeoutHandle)
    }, [fromServerValue, getCurrentTime, data])

    const cardCss = themedClass(
      'card',
      theme,
      classNames(
        'job-card',
        className,
        { 'card-selected': selected },
        { 'subjob-highlight': highlighted },
        { 'card-dangerous': data.isDangerousGoods },
        { 'declined-card': isDeclined(data) },
        { 'card-normal-corners': !roundedCorners }
      )
    )
    const wrapCss = themedClass(
      'card-wrap',
      theme,
      classNames(
        { 'cards-group': !!countBadge },
        { 'card-with-header': !!header }
      )
    )

    const driverText = getDriverLabel(data)

    if (isCardFinished(data) && showClosed) {
      return <ClosedCard data={data} />
    }

    return (
      <CardRemovalTransition
        className={wrapCss}
        visible={!isCardFinished(data)}
        card={data}
        onExited={removeAnimFinished}
      >
        <div ref={ref} className={cardCss} data-testid={`card-${data.id}`}>
          {header}
          <DangerousGoodsIndicator card={data} header={header} />
          <div className="card-body" onClick={onCardClick}>
            <div className="card-details">
              <p
                className="card-number text-ellipsed"
                title={data.id}
                onMouseEnter={() => setShowCopyIcon(true)}
                onMouseLeave={() => setShowCopyIcon(false)}
              >
                {getCardIdLabel(data)}{' '}
                {showCopyIcon && (
                  <CopyJobNumber jobNumber={data.jobNumber} theme={theme} />
                )}{' '}
                | {data.service}
              </p>
              <CardDestinations card={data} />
              <p className="card-commodity text-ellipsed">
                {driverText} |{' '}
                <span className="text-capitalized" title={data.commodity}>
                  {formatNullableString(data.commodity).toLowerCase()}
                </span>
              </p>

              <div className="card-load-info">
                <div>
                  <p className="card-dimension-label">
                    <FormattedMessage id="card.pieces" />
                  </p>
                  <p className="card-dimension">
                    {formatNullableString(data.pieces)}
                  </p>
                </div>
                <div>
                  <p className="card-dimension-label">
                    <FormattedMessage id="card.weight" />
                  </p>
                  <p className="card-dimension">
                    {formatNullableString(data.weight)}
                  </p>
                </div>
                <div>
                  <p className="card-dimension-label">
                    <FormattedMessage id="card.dimensions" />
                  </p>
                  <p className="card-dimension">
                    {formatNullableString(data.dimensions)}
                  </p>
                </div>
              </div>
            </div>
            <div className="card-badges flexbox-multiline">
              {isArchived(data) ? (
                <DistanceBadge
                  unit={distanceUnit}
                  distance={missingDataPlaceholder}
                />
              ) : (
                <CardBadge card={data} />
              )}
              <MilestoneBadge
                milestone={data.milestone}
                lockData={data.lockData}
                pendingUpdate={data.pendingUpdate}
              />
            </div>
          </div>
          <DangerousGoodsIndicator card={data} header={header} />
          {!!countBadge && (
            <div className="cards-count-badge">{countBadge}</div>
          )}
        </div>
      </CardRemovalTransition>
    )
  }
)

export default memo(Card, (prevProps, nextProps) =>
  _.isEqual(prevProps, nextProps)
)
