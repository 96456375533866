import _ from 'lodash'

import { GET_CARDS } from './schema/Cards'
import { parseStoredFilter } from '../../hooks/useCardsFilter'
import { getCardFilterDate, hasAlarmFired } from '../../utils/card-utils'
import { getCardDate } from '../../utils/common-utils'
import { fromServerDate, isInRange } from '../../utils/date-utils'
import { DriverTypesEnum } from '../enums/DriverType'

const typePolicies = {
  Query: {
    fields: {
      getDriver: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Driver',
            id: args.driverId,
          })
        },
      },
      getCard: {
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Card',
            id: args.itemId,
          })
        },
      },
      listCards: {
        merge: false,
      },
      listStacks: {
        merge: false,
      },
      getCardsForBoard: {
        read(__, { args, cache }) {
          console.log('getCardsForBoard called')
          const allcards = cache.readQuery(
            { query: GET_CARDS, variables: args },
            true
          )
          const parsedFilter = parseStoredFilter(args.filter)
          return _.sortBy(
            _.filter(
              allcards?.listCards,
              c =>
                c.stack &&
                !c.groupParent &&
                isInRange(fromServerDate(getCardFilterDate(c)), parsedFilter)
            ),
            c => (hasAlarmFired(c) ? -1 : 1),
            getCardDate
          )
        },
      },
    },
  },
  Milestone: {
    keyFields: 'code',
  },
  Card: {
    keyFields: 'id',
    fields: {
      pendingUpdate: {
        read(value) {
          return !!value
        },
      },
      skipNextUpdate: {
        read(value) {
          return !!value
        },
      },
      agentCharges: {
        merge: false,
        read(value) {
          return value || []
        },
      },
      parent: {
        merge: false,
      },
      subjobs: {
        merge: false,
      },
      groupChildren: {
        merge: false,
      },
      dispatcherNotes: {
        merge: false,
      },
    },
  },
  DispatcherNote: {
    keyFields: false,
  },
  CTNote: {
    keyFields: false,
  },
  AgentCharge: {
    keyFields: false,
    fields: {
      loading(loading) {
        return !!loading
      },
    },
  },
  JobDetail: {
    keyFields: 'id',
    fields: {
      ctNotes: {
        merge: false,
      },
    },
  },
  Driver: {
    keyFields: 'id',
    fields: {
      contractor: {
        read(_, ctx) {
          return ctx.readField('driverType') !== DriverTypesEnum.EMPLOYEE
        },
      },
    },
  },
}

export default typePolicies
