import { DatePickerInput } from './DatePickerInput'
import TimePickerInput from './TimePickerInput'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { combineDateAndTime, getStartOfDay } from '../../utils/date-utils'
import { ShortDateFormats } from '../DateTimeFormats'

const DateTimeInput = ({ value, onChange }) => {
  const { getCurrentTime } = useLocalDateTime()

  const getDefaultDate = () => getStartOfDay(getCurrentTime())

  const onTimeChange = time => {
    let newVal
    if (!value) {
      newVal = combineDateAndTime(time, getDefaultDate())
    } else {
      newVal = combineDateAndTime(time, value)
    }
    onChange(newVal)
  }

  const onDateChange = date => {
    let newVal
    if (!value) {
      newVal = combineDateAndTime(getDefaultDate(), date)
    } else {
      newVal = combineDateAndTime(value, date)
    }
    onChange(newVal)
  }

  return (
    <div className="date-time-input space-between">
      <DatePickerInput
        onChange={onDateChange}
        iconClass="icon-absolute"
        value={value}
        format={ShortDateFormats.MDY}
      />
      <TimePickerInput
        onChange={onTimeChange}
        format="HH:mm"
        iconClass="icon-absolute"
        size="large"
        value={value}
      />
    </div>
  )
}

export default DateTimeInput
