import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'

import ConfirmModalBase from './ConfirmModalBase'
import { useCardChangesTracking } from '../../hooks/graphQl/useCardChangesTracking'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { fromServerDate } from '../../utils/date-utils'
import { themedClass } from '../../utils/style-utils'
import { BorderOutsetSeparator } from '../BorderOutset'
import { ShortDateTimeFormat } from '../DateTimeFormats'
import { CloseIconAlt, WarningIcon } from '../Icons'
import ScrollArea from '../ScrollArea'
import UserTextPreview from '../UserTextPreview'
import { Button } from '../form/Buttons'

export const CardUpdateNotificationSmall = ({
  card,
  onClose,
  openPopupCard,
  formatMessage,
}) => {
  const onCardNumberClick = () => {
    openPopupCard(card)
    onClose()
  }

  return (
    <div className="card-update-notification">
      <p className="notification-title">
        <strong>{formatMessage({ id: 'card.ct-update' })}</strong>
      </p>
      <p>
        {formatMessage({ id: 'job.job' })}:{' '}
        <Button
          className="text-important"
          type="link"
          onClick={onCardNumberClick}
        >
          #{card.function}
          {card.jobNumber}
        </Button>
      </p>

      <div className="notification-close">
        <CloseIconAlt onClick={onClose} className="text-clickable" />
      </div>
    </div>
  )
}

const ChangeValue = ({ path, value }) => {
  if (_.endsWith(path, 'iso')) {
    return <ShortDateTimeFormat date={fromServerDate(value)} separator=" " />
  }
  return <UserTextPreview text={value} />
}

export const CardUpdateNotification = ({ onIgnore, card }) => {
  const { formatMessage: f } = useIntl()
  const { acknowledgeChange } = useCardChangesTracking()
  const [theme] = useSelectedTheme()

  const ackClicked = () => {
    acknowledgeChange(card, card?.trackedChanges)
  }

  const allChanges = _.flatten(_.map(card?.trackedChanges, ch => ch.changes))

  return (
    <ConfirmModalBase
      width={380}
      okText={f({ id: 'changelog.acknowledge' })}
      onOk={ackClicked}
      cancelText={f({ id: 'confirm.exit' })}
      onCancel={onIgnore}
      className={themedClass('card-updates-modal', theme)}
      title={<WarningIcon className="opaque-icon text-warning" />}
    >
      <div>
        <h1>
          <FormattedMessage id="confirm.attention" />
        </h1>
        <h2 className="text-warning">
          <FormattedMessage id="card.ct-update-long" />
        </h2>
        <ScrollArea autoHeight autoHeightMin={100} autoHeightMax={310}>
          {_.map(allChanges, (change, index) => (
            <div key={index} className="card-update-row">
              <div className="card-updates-list">
                <div className="subheader text-uppercase">
                  <FormattedMessage id={'card.ct-update-' + change.path} />
                </div>
                <div>
                  <span className="text-important card-change-label text-uppercase">
                    <FormattedMessage id="card.ct-update-previous" />
                  </span>
                  <span className="card-change-value">
                    <ChangeValue
                      path={change.path}
                      value={change.previousValue}
                    />
                  </span>
                </div>
                <div>
                  <span className="text-important card-change-label text-uppercase">
                    <FormattedMessage id="card.ct-update-current" />
                  </span>
                  <span className="card-change-value">
                    <ChangeValue
                      path={change.path}
                      value={change.currentValue}
                    />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </ScrollArea>
        <BorderOutsetSeparator theme={theme} />
      </div>
    </ConfirmModalBase>
  )
}
