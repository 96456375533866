import { useCallback } from 'react'

import { useLocalStorage } from './useLocalStorage'
import { ThemesEnum } from '../app/enums/Themes'

const userSettingsKey = 'qdd-user-settings'

export function useUserSettings() {
  const [currentUserSettings, saveUserSettings] = useLocalStorage(
    userSettingsKey,
    null
  )
  return [currentUserSettings, saveUserSettings]
}

export function useSelectedTheme() {
  const [settings, setSettings] = useUserSettings()

  const setTheme = useCallback(
    theme => {
      setSettings({
        ...settings,
        theme,
      })
    },
    [setSettings, settings]
  )

  const selectedTheme = settings?.theme || ThemesEnum.LIGHT
  return [selectedTheme, setTheme]
}
