import { message as showMessage, notification as showNotification } from 'antd'
import { createContext, useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { CheckIcon, InfoCircle, WarningIcon } from '../components/Icons'

export const NotificationsTypeEnum = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
}

const notificationTypeToIcon = type => {
  switch (type) {
    case NotificationsTypeEnum.SUCCESS:
      return <CheckIcon />
    case NotificationsTypeEnum.ERROR:
      return <i className="fas fa-exclamation-circle text-danger" />
    case NotificationsTypeEnum.WARNING:
      return <WarningIcon className="text-warning" />
    case NotificationsTypeEnum.INFO:
      return <InfoCircle className="text-important" />
    default:
      return <></>
  }
}

export const NotificationsContext = createContext({
  addNotification: n => {
    console.log(n)
  },
  addMessage: n => {
    console.log(n)
  },
})

export const NotificationsProvider = ({ children, openPopupCard }) => {
  const { formatMessage: f } = useIntl()

  const addNotification = useCallback(
    notification => {
      console.log(notification)

      const { messageId, message, titleId, type } = notification
      const notifyFunc = showNotification[type] || showNotification.open

      const htmlMessage = messageId
        ? f({
            id: messageId,
            defaultMessage: message,
          })
        : message

      notifyFunc({
        duration: 5,
        top: 0,
        icon: notificationTypeToIcon(type),
        message: f({
          id: titleId,
        }),
        description: <span dangerouslySetInnerHTML={{ __html: htmlMessage }} />,
        placement: 'topRight',
      })
    },
    [f]
  )

  const displayTopMessage = useCallback(
    notification => {
      const { messageId, messageParams, type, Component, componentProps } =
        notification

      const closeNotification = () => {
        closeMessage && closeMessage()
      }

      const closeMessage = showMessage.open({
        type: type,
        icon: notificationTypeToIcon(type),
        content: Component ? (
          <Component
            {...componentProps}
            openPopupCard={openPopupCard}
            onClose={closeNotification}
            formatMessage={f}
          />
        ) : (
          f(
            {
              id: messageId,
            },
            messageParams
          )
        ),
      })
    },
    [f, openPopupCard]
  )

  const value = useMemo(
    () => ({
      addNotification,
      displayTopMessage,
    }),
    [addNotification, displayTopMessage]
  )

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  )
}
export const useNotificationsContext = () => useContext(NotificationsContext)
