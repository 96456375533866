import { DistanceUnits } from './DistanceUnits'

export const withUserUnits = Component => (props, ref) =>
  (
    <Component
      {...props}
      ref={ref}
      userUnits={{ distance: DistanceUnits.Miles }}
    />
  )
