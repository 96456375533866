import { useQuery } from '@apollo/client'

import { GET_CARD } from '../../app/graphQl/schema/Cards'

export function useGetCard(cardId, query = GET_CARD) {
  const { loading, data, error } = useQuery(query, {
    partialRefetch: true,
    skip: !cardId,
    variables: { itemId: cardId },
  })

  return {
    card: data?.getCard,
    loading,
    error,
  }
}
