import { FormattedMessage } from 'react-intl'

import { useCards } from '../../hooks/useCards'
import { hasClosableAlarm } from '../../utils/card-utils'
import { StopwatchIcon } from '../Icons'
import { Button } from '../form/Buttons'

const DismissAlarmButton = ({ card }) => {
  const { closeAlarm } = useCards()

  const onClick = () => {
    closeAlarm(card)
  }

  if (hasClosableAlarm(card))
    return (
      <Button
        onClick={onClick}
        type="danger"
        ghost
        size="large"
        block
        icon={<StopwatchIcon />}
      >
        <FormattedMessage id="card-popup.close-alarm" />
      </Button>
    )

  return null
}

export default DismissAlarmButton
