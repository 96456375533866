import { Polyline } from '@react-google-maps/api'

const lineSymbol = {
  path: 'M 0,0 0,1',
  strokeOpacity: 1,
  scale: 2,
}

export const DriverRoute = ({ points, lineColor }) => (
  <Polyline
    path={points}
    options={{
      strokeOpacity: 0,
      strokeColor: lineColor,
      icons: [
        {
          icon: lineSymbol,
          offset: '0',
          repeat: '10px',
        },
      ],
    }}
  />
)
