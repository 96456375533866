import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import ConfirmModalBase from './ConfirmModalBase'
import { CardDestinationsEnum } from '../../app/enums/CardDestinationsEnum'
import { SubJobTypeEnum } from '../../app/enums/SubJobTypeEnum'
import { GET_CONSIGNEE } from '../../app/graphQl/schema/Jobs'
import { useGetCard } from '../../hooks/graphQl/useGetCard'
import useStacksList from '../../hooks/graphQl/useStacksQuery'
import { useActiveStation } from '../../hooks/useActiveStation'
import { useCards } from '../../hooks/useCards'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { isSubJobCard } from '../../utils/card-utils'
import {
  formatFlightNumber,
  getStationVendorLabel,
  orderStacksForDropdown,
} from '../../utils/common-utils'
import { isSelectable, stackToSelectOption } from '../../utils/stacks-utils'
import { formatNullableString } from '../../utils/string-utils'
import { ContentPanel } from '../ContentPanel'
import { WarningIcon } from '../Icons'
import LoadingIndicator from '../LoadingIndicators'
import { HeaderSizes, JobDetailHeader } from '../ModalsTypography'
import AssignedDriver from '../drivers/AssignedDriver'
import SelectPopover from '../form/SelectPopover'

const AIR_DESTINATION = 'A'

// if scenarion is AIR TO OFFICE, cards function needs to be one of these to show destinations dropbox
const airOfficeDestinationFuncs = ['T', 'D']

function checkGroupScenarios(card) {
  if (!card.groupChildren) return true

  const pickValues = _.every(
    card.groupChildren,
    c => c.pickup.type === card.pickup.type
  )
  const dropValues = _.every(
    card.groupChildren,
    c => c.drop.type === card.drop.type
  )
  return !pickValues || !dropValues
}

function getDestinationSelect(card, currentStation, jobDetail, formatMessage) {
  function createResult(airLabel) {
    return [
      {
        value: AIR_DESTINATION,
        label: airLabel,
      },
      ...currentStationItems,
    ]
  }

  if (!currentStation) return null

  const { flights, airOrigin } = card
  const officeLabel = _.upperCase(
    formatMessage({
      id: 'card.legs.OFFICE',
    })
  )

  let currentStationItems = []
  if (_.some(currentStation.dropLocations)) {
    currentStationItems.push(
      ..._.map(currentStation.dropLocations, loc => ({
        value: currentStation.id,
        label: loc.name,
        location: loc.id,
      }))
    )
  } else {
    currentStationItems.push({
      value: currentStation.id,
      label: `${officeLabel} | ${getStationVendorLabel(currentStation)}`,
    })
  }

  if (
    card.pickup.type === CardDestinationsEnum.Air &&
    card.drop.type === CardDestinationsEnum.Office &&
    _.includes(airOfficeDestinationFuncs, card.function)
  ) {
    return createResult(
      `${formatNullableString(jobDetail?.consignee?.name).toLowerCase()}`
    )
  }

  if (card.function === 'L') {
    return createResult(
      `${formatNullableString(jobDetail?.consignee?.name).toLowerCase()}`
    )
  }

  if (
    (card.pickup.type === CardDestinationsEnum.Shipper ||
      card.pickup.type === CardDestinationsEnum.Air) &&
    card.drop.type === CardDestinationsEnum.Air
  ) {
    return createResult(
      `${formatFlightNumber(_.last(flights))} | ${airOrigin} `
    )
  }

  if (
    card.pickup.type === CardDestinationsEnum.Air &&
    card.drop.type === CardDestinationsEnum.Consignee
  ) {
    return createResult(`${formatNullableString(card.drop.name).toLowerCase()}`)
  }

  if (
    card.pickup.type === CardDestinationsEnum.Shipper &&
    card.drop.type === CardDestinationsEnum.Office
  ) {
    return createResult(
      `${formatFlightNumber(_.head(flights))} | ${airOrigin} `
    )
  }

  return null
}

const AssignDriverConfirm = ({
  driver = {},
  card,
  currentCardStack,
  onClosed,
  smallOverlay,
}) => {
  const { formatMessage: f } = useIntl()
  const [theme] = useSelectedTheme()
  const { currentStation } = useActiveStation()

  const [selectedStack, setSelectedStack] = useState()
  const [selectedDestination, setSelectedDestination] = useState(null)
  const { assignDriver } = useCards()
  const [confirmed, setConfirmed] = useState(false)
  const firstRender = useRef(true)
  const { data, loading, error } = useQuery(GET_CONSIGNEE, {
    variables: { itemId: card ? card.jobNumber : '' },
  })

  useEffect(() => {
    firstRender.current = false
  }, [])

  const { card: parentCardData } = useGetCard(card.groupParent)
  const parentCard = parentCardData || card

  const {
    stacksData: listStacks,
    loading: loadingStacks,
    error: stacksError,
  } = useStacksList()

  useEffect(() => {
    if (!currentCardStack) return

    if (isSelectable(currentCardStack))
      setSelectedStack(stackToSelectOption(currentCardStack))
  }, [currentCardStack])

  const stacksToAssign = orderStacksForDropdown(
    listStacks.filter(isSelectable)
  ).map(stackToSelectOption)

  const onStackChanged = s => {
    setSelectedStack(s)
  }

  const onConfirm = () => {
    if (!selectedStack) return
    assignDriver(
      card,
      driver.id,
      selectedStack.value,
      selectedDestination?.value,
      selectedDestination?.location
    )
    setConfirmed(true)
  }

  const afterClosed = useCallback(() => {
    onClosed(confirmed)
  }, [confirmed, onClosed])

  const destinations = getDestinationSelect(
    card,
    currentStation,
    data?.getJobDetail,
    f
  )

  let disabledConfirm = !selectedStack
  if (_.some(destinations)) {
    if (!selectedDestination) disabledConfirm = true
  }

  const needWarning = checkGroupScenarios(parentCard)
  return (
    <ConfirmModalBase
      className="assign-driver-popup"
      width={480}
      okText={f({ id: 'confirm.confirm' })}
      onOk={onConfirm}
      afterClose={afterClosed}
      canConfirm={!disabledConfirm}
      title={<i className="fas fa-check-circle opaque-icon text-warning" />}
      data-testid="confirm-driver-modal"
    >
      <LoadingIndicator
        loading={loading}
        error={error && 'errors.loading-fail'}
      >
        <h1>
          <FormattedMessage id={getHeaderMsg(card)} />
        </h1>

        <ContentPanel className="assign-driver-current">
          <JobDetailHeader size={HeaderSizes.Medium} uppercase>
            <FormattedMessage id="drivers.driver" />
            {' : '}
          </JobDetailHeader>
          <AssignedDriver card={{ driver }} className="text-important" />
        </ContentPanel>

        <div className="assign-driver-select">
          <div data-testid="assign-driver-dest">
            {destinations && (
              <SelectPopover
                placeholder={f({ id: 'form.destination-select' })}
                options={destinations}
                selectedItem={selectedDestination}
                onChange={setSelectedDestination}
                className="text-capitalized"
                scrollableContent={false}
                theme={theme}
              />
            )}
          </div>
          <div data-testid="assign-driver-stack">
            <SelectPopover
              loading={loadingStacks}
              error={stacksError}
              placeholder={f({ id: 'form.assign-stack' })}
              options={stacksToAssign}
              selectedItem={selectedStack}
              onChange={onStackChanged}
              className="text-capitalized"
              smallOverlay={smallOverlay}
              theme={theme}
            />
          </div>
          {needWarning && (
            <div className="warning">
              <WarningIcon />
              Group contains cards in different scenarios
            </div>
          )}
        </div>
      </LoadingIndicator>
    </ConfirmModalBase>
  )
}

const getHeaderMsg = card => {
  if (!isSubJobCard(card)) {
    return card.driver ? 'driver-assign.update' : 'driver-assign.confirm'
  }
  const subjobType =
    card.subjobType === SubJobTypeEnum.REICE ? 'reice' : 'purchase'
  return card.driver
    ? `driver-assign.update-${subjobType}`
    : `driver-assign.confirm-${subjobType}`
}

export default AssignDriverConfirm
