import classNames from 'classnames'
import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'

import { CloseIconAlt, HighlightIcon } from '../Icons'
import { CandyStripBordered } from '../SVGGraphics'

const getHeaderStyle = (alarmed, expanded, additionalCss) =>
  classNames(
    'card-header',
    {
      'card-header-alarmed': alarmed && !expanded,
    },
    additionalCss
  )

const HeaderBase = ({
  className,
  onClick,
  label,
  labelRight,
  expandable,
  expanded,
  icon,
  dangerousGoods,
}) => {
  const caretCss = expanded ? 'fas fa-caret-down' : 'fas fa-caret-right'
  return (
    <div className={className}>
      <span className="cards-group-header" onClick={onClick}>
        {expandable && <i className={caretCss} />}
        {label}
      </span>
      {dangerousGoods && <CandyStripBordered />}
      {labelRight}
      {icon}
    </div>
  )
}

export const GroupHeader = ({
  onToggleClick,
  onCloseClick,
  expanded,
  alarmed,
  dangerousGoods,
}) => (
  <HeaderBase
    className={getHeaderStyle(alarmed, expanded)}
    onClick={onToggleClick}
    icon={<CloseIconAlt onClick={onCloseClick} />}
    expandable
    expanded={expanded}
    label={<FormattedMessage id={'card.cards-group'} />}
    dangerousGoods={dangerousGoods}
    alarmed={alarmed}
  />
)

export const ManifestHeader = ({
  onToggleClick,
  onCloseClick,
  expanded,
  alarmed,
  dangerousGoods,
  flightManifest,
  cardsCount,
}) => (
  <HeaderBase
    className={getHeaderStyle(alarmed, expanded)}
    onClick={onToggleClick}
    icon={<CloseIconAlt onClick={onCloseClick} />}
    expandable
    expanded={expanded}
    label={
      <FormattedMessage id="card.cards-manifest" values={{ cardsCount }} />
    }
    labelRight={<span className="flight-manifest">{flightManifest}</span>}
    dangerousGoods={dangerousGoods}
    alarmed={alarmed}
  />
)

export const ParentHeader = ({
  onToggleClick,
  expanded,
  onSubJobsHighlight,
  alarmed,
  dangerousGoods,
}) => (
  <HeaderBase
    className={getHeaderStyle(alarmed, expanded)}
    onClick={onToggleClick}
    icon={<HighlightIcon onClick={onSubJobsHighlight} />}
    expandable
    expanded={expanded}
    label={<FormattedMessage id="card.cards-parent" />}
    alarmed={alarmed}
    dangerousGoods={dangerousGoods}
  />
)

export const SubJobHeader = ({ order, onSubJobsHighlight }) => (
  <HeaderBase
    className={getHeaderStyle()}
    icon={<HighlightIcon onClick={onSubJobsHighlight} />}
    label={<FormattedMessage id="card.subjob-header" values={{ order }} />}
  />
)

export const ChangeColumnHeader = ({ alarmed }) => (
  <HeaderBase
    className={getHeaderStyle(alarmed)}
    label={<FormattedMessage id="card.change-column" />}
  />
)

export const LegsHeader = ({ alarmed, cardData, onLegsHighlight }) => {
  const { formatMessage: f } = useIntl()
  const currentLeg = _.last(_.split(cardData.id, ':'))
  return (
    <HeaderBase
      className={getHeaderStyle(alarmed)}
      icon={<HighlightIcon onClick={onLegsHighlight} />}
      alarmed={alarmed}
      dangerousGoods={cardData.isDangerousGoods}
      label={
        <FormattedMessage
          id="card.legs.header"
          values={{
            currentLeg,
            pick: f({ id: `card.legs.${cardData.pickup.type}` }),
            drop: f({ id: `card.legs.${cardData.drop.type}` }),
          }}
        />
      }
    />
  )
}
