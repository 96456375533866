import { themedClass } from '../../utils/style-utils'

const PhonePopover = ({
  phone,
  className,
  onHoverStart,
  onHoverEnd,
  theme,
  children,
}) => {
  const phoneClick = e => {
    e.stopPropagation()
  }

  const css = themedClass('phone-popover', theme, className)

  return (
    <div className={css} onMouseEnter={onHoverStart} onMouseLeave={onHoverEnd}>
      <a href={`tel:${phone}`} onClick={phoneClick}>
        <i className="fas fa-phone-square" />
        &nbsp;&nbsp;{phone}
      </a>
      {children}
    </div>
  )
}

export default PhonePopover
