import { useApolloClient } from '@apollo/client'

import { useOptimisticMutation } from './useOptimisticMutation'
import {
  ADD_CT_NOTE,
  UPDATE_CT_NOTES_FRAGMENT,
  UPDATE_DOCUMENTS_FRAGMENT,
} from '../../app/graphQl/schema/Jobs'
import { toServerDate } from '../../utils/date-utils'

const useJobs = () => {
  const [addCTNoteMutation] = useOptimisticMutation(ADD_CT_NOTE, 'addCTNote')
  const client = useApolloClient()

  const updateDocumentsList = (job, documents) => {
    client.writeFragment({
      id: `${job.__typename}:${job.id}`,
      fragment: UPDATE_DOCUMENTS_FRAGMENT,
      fragmentName: 'updateDocuments',
      data: {
        __typename: job.__typename,
        id: job.id,
        documents: documents,
      },
    })
  }

  const addCTNote = (job, note) => {
    const newNote = {
      createdAt: { iso: toServerDate(new Date()) },
      text: note,
    }
    addCTNoteMutation({
      variables: {
        job: job.id,
        note,
      },
      cacheUpdate: (cache, data) => {
        cache.writeFragment({
          id: `JobDetail:${job.id}`,
          fragment: UPDATE_CT_NOTES_FRAGMENT,
          fragmenName: '',
          data: {
            id: job.id,
            ctNotes: [newNote, ...job.ctNotes],
          },
        })
      },
    })
  }

  return {
    addCTNote,
    updateDocumentsList,
  }
}

export default useJobs
