const Sitemap = {
  get dispatch() {
    return '/dispatch/:station?'
  },

  get monitor() {
    return '/monitor/:station?'
  },

  get login() {
    return '/login'
  },

  createMonitorLink: station => `/monitor/${station}`,

  createDispatchLink: station => `/dispatch/${station}`,

  createDocumentFileLink: (jobNumber, documentId, queryParams) => {
    const result = new URL(
      `/api/job/${jobNumber}/document/${documentId}`,
      window.location.origin
    )
    result.search = new URLSearchParams(queryParams).toString()
    return result
  },
}

export default Sitemap
