import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { AddressArray } from './CommonAddress'
import { getOfficeLocation } from '../../utils/card-utils'
import { TextImportant } from '../Typography'

const OfficeInfo = ({ rowClass, card, location, address, fixedRowsCount }) => {
  const css = classNames(rowClass, 'text-ellipsed')

  return (
    <>
      <p className={css}>
        <TextImportant>
          <FormattedMessage
            id="card.office-destination"
            values={{ stationCode: getOfficeLocation(card, location) }}
          />
        </TextImportant>
      </p>
      <AddressArray
        rowCss={css}
        address={address}
        fixedRowsCount={fixedRowsCount}
      />
    </>
  )
}

export default OfficeInfo
