import classNames from 'classnames'
import { useState } from 'react'
import { useEffect } from 'react'

import ThemableModal from './ThemableModal'
import { useBackgroundBlur } from '../../hooks/useBackgroundBlur'
import { useModalContainerContext } from '../../hooks/useModalContainerContext'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { themedClass } from '../../utils/style-utils'

const ConfirmModalBase = props => {
  const {
    icon,
    children,
    className,
    onOk,
    onCancel,
    canConfirm = true,
    noFooter,
    closeAfterConfirm = true,
    forceClose,
    ...rest
  } = props
  const { container } = useModalContainerContext()
  const [theme] = useSelectedTheme()
  const css = classNames(themedClass('confirm-dialog', theme), className, {
    'confirm-dialog-footerless': noFooter,
  })
  const [isVisible, setIsVisible] = useState(true)

  useBackgroundBlur(isVisible, rest.getContainer)

  useEffect(() => {
    if (forceClose) closeWindow()
  }, [forceClose])

  const onOkClicked = () => {
    onOk && onOk()
    closeAfterConfirm && setIsVisible(false)
  }

  const closeWindow = () => {
    setIsVisible(false)
  }

  return (
    <ThemableModal
      open={isVisible}
      className={css}
      closable={false}
      onOk={onOkClicked}
      onCancel={closeWindow}
      afterClose={onCancel}
      cancelButtonProps={{ className: 'btn-cancel', size: 'large' }}
      okButtonProps={{ disabled: !canConfirm, size: 'large' }}
      theme={theme}
      getContainer={container}
      {...rest}
    >
      {children}
    </ThemableModal>
  )
}

export default ConfirmModalBase
