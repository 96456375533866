import _ from 'lodash'
import { useEffect, useState } from 'react'

import { useActiveCardsList } from './graphQl/useActiveCardsList'
import { useCards } from './useCards'
import { CardNotificationDuration, CssValues } from '../app/constants'
import { isCardFinished } from '../utils/card-utils'

export const useCardsCleanup = () => {
  const { removeFromCache } = useCards()
  const [cardsToRemove, setCardsToRemove] = useState([])
  const { cardsData: cards } = useActiveCardsList()

  useEffect(() => {
    const flatCardsList = _.flatten(_.map(cards, c => c.groupChildren))
    const finishedCards = _.filter(
      [...cards, ...flatCardsList],
      c => isCardFinished(c) && !c.skipNextUpdate // if skipNextUpdate is true, we are waiting for incoming subscription to confirm removal
    )
    if (_.some(finishedCards)) {
      const newFinished = _.map(finishedCards, card => ({
        id: card.id,
        card,
        markedTime: new Date(),
      }))
      setCardsToRemove(newFinished)
    }
  }, [cards])

  useEffect(() => {
    const timerId = setInterval(() => {
      if (!_.some(cardsToRemove)) return

      const cleanInterval =
        CardNotificationDuration + CssValues.cardRemovalDuration * 2

      const nowDate = new Date()
      const toRemove = _.filter(
        cardsToRemove,
        card => nowDate - card.markedTime > cleanInterval // wait enough time to pass to show full animation
      )
      removeFromCache(_.map(toRemove, x => x.card))
      setCardsToRemove(_.differenceBy(cardsToRemove, toRemove, 'id'))
    }, CssValues.cardRemovalDuration)

    return () => {
      if (timerId) clearInterval(timerId)
    }
  }, [cardsToRemove, removeFromCache])
}
