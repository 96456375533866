import { Popover } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'

import PhonePopover from './PhonePopover'
import VehicleIcon from './VehicleIcon'
import { getAvailability, getDisplayName } from '../../utils/driver-utils'
import { themedClass } from '../../utils/style-utils'
import { HistoryIcon } from '../Icons'
import TimeRange from '../TimeRange'

export const DriverDragType = 'DRIVER'

const DriverCard = ({
  driver,
  onClick,
  onHistoryIconClick,
  isChecked,
  isSelected,
  eta,
  countBadge,
  currentDay,
  theme,
  IconPopover,
}) => {
  const onCardSelected = event => {
    onClick && onClick(driver, event)
  }

  const onHistoryClick = event => {
    event.stopPropagation()
    event.preventDefault()
    onHistoryIconClick && onHistoryIconClick(driver, event)
  }

  const driverEta = eta || {}
  const currentAvailability = getAvailability(driver, currentDay)
  const css = themedClass(
    'card',
    theme,
    classNames('driver-card', {
      'card-selected': isSelected,
    })
  )
  const wrapCss = classNames(themedClass('driver-card-wrap', theme), {
    'drivers-group': !!countBadge,
  })
  return (
    <div className={wrapCss}>
      <div onClick={onCardSelected} className={css}>
        <div className="driver-card-details">
          <p className="driver-code">
            {driver.code}
            <Popover
              overlayClassName={themedClass('phone-popover-wrap', theme)}
              content={<PhonePopover phone={driver.phone} theme={theme} />}
            >
              <i className="fas fa-phone-square-alt" />
            </Popover>
            <HistoryIcon onClick={onHistoryClick} />
          </p>

          <p className="driver-name text-capitalized card-text-highlight">
            {getDisplayName(driver)}
          </p>

          <p className="driver-availability">
            <FormattedMessage id="card.availability" />{' '}
            <TimeRange
              from={currentAvailability.from}
              to={currentAvailability.to}
            />
          </p>
          <p className="driver-dimensions">
            <FormattedMessage id="card.dimensions" />{' '}
            {driver.vehicle.dimensions}
          </p>
        </div>

        {driverEta.etaOrder && (
          <div className={themedClass('driver-eta-badge', theme)}>
            <FormattedMessage
              id="drivers-card.eta"
              values={{ etaOrder: driverEta.etaOrder }}
            />
          </div>
        )}

        <VehicleIcon
          theme={theme}
          driver={driver}
          PopoverContent={IconPopover}
        />

        {isChecked && (
          <div className="driver-checkbox">
            <i className="checkbox-icon fas fa-check" />
            <svg height="35" width="35">
              <polygon points="0,0 35,0 35,35" />
            </svg>
          </div>
        )}

        {!!countBadge && <div className="cards-count-badge">{countBadge}</div>}
      </div>
    </div>
  )
}

export default memo(DriverCard, (prev, next) => _.isEqual(prev, next))
