import classNames from 'classnames'
import { useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import DispatchStatus from './DispatchStatus'
import { DriverCardsList } from './DriverCardsList'
import { MilestonesEnum } from '../../app/enums/MilestonesEnum'
import { useCards } from '../../hooks/useCards'
import {
  canBeLoadedToDock,
  canBeUnloadedToDock,
  isArchived,
} from '../../utils/card-utils'
import { themedClass } from '../../utils/style-utils'
import BorderOutset from '../BorderOutset'
import { DriversContext } from '../DriverAssigmentModule'
import { CheckIcon, CheckIconUnchecked, ThumbsUp, UserSlash } from '../Icons'
import { Button } from '../form/Buttons'

const canUnassign = status =>
  status === MilestonesEnum.ACCEPTED || status === MilestonesEnum.OFFERED

const canAccept = status => status === MilestonesEnum.OFFERED

const isAccepted = status =>
  [
    MilestonesEnum.ACCEPTED,
    MilestonesEnum.LOADED,
    MilestonesEnum.UNLOADED,
  ].includes(status)

const ToolbarButton = ({ onClick, messageId, disabled, IconComponent }) => (
  <Button
    type="link"
    size="small"
    className="text-uppercase"
    disabled={disabled}
    icon={<IconComponent />}
    onClick={onClick}
  >
    <span>
      <FormattedMessage id={messageId} />
    </span>
  </Button>
)

const AssignedDriverPanel = ({ theme, assignedDriver, card }) => {
  const { onDropToDock, onPickFromDock } = useContext(DriversContext)
  const { unassignDriver, acceptDriver } = useCards()

  if (!assignedDriver) return null

  const onUnassignClick = () => {
    unassignDriver(card)
  }
  const onAcceptClick = () => {
    canAccept(card?.milestone.code) && acceptDriver(card)
  }
  const onConfirmUnloadedClick = () => {
    onPickFromDock(card)
  }
  const onConfirmLoadedClick = () => {
    onDropToDock(card)
  }

  const css = themedClass(
    'cards-column',
    theme,
    'cards-column-headless cards-column-no-shadow'
  )

  const dispatchStatus = card?.milestone.code
  const showUnloadBtn = canBeUnloadedToDock(card)
  const showLoadBtn = canBeLoadedToDock(card)

  const classname = classNames('drivers-list-toolbar', {
    'driver-list-toolbar-multiline': showLoadBtn || showUnloadBtn,
  })

  return (
    <div className={css}>
      <DispatchStatus milestone={card?.milestone} />

      <BorderOutset className={classname} theme={theme}>
        <div className="drivers-list-toolbar-items space-between">
          <ToolbarButton
            messageId="drivers.driver-accepted"
            onClick={onAcceptClick}
            disabled={!canAccept(dispatchStatus) || isArchived(card)}
            IconComponent={
              isAccepted(dispatchStatus) ? CheckIcon : CheckIconUnchecked
            }
          />

          <ToolbarButton
            messageId="drivers.unassign-driver"
            disabled={!canUnassign(dispatchStatus) || isArchived(card)}
            IconComponent={UserSlash}
            onClick={onUnassignClick}
          />
        </div>
        {(showUnloadBtn || showLoadBtn) && (
          <div className="drivers-list-toolbar-items flex-center">
            {showUnloadBtn && (
              <ToolbarButton
                messageId="drivers.confirm-dock-unloaded"
                IconComponent={ThumbsUp}
                onClick={onConfirmUnloadedClick}
              />
            )}
            {showLoadBtn && (
              <ToolbarButton
                messageId="drivers.confirm-dock-loaded"
                IconComponent={ThumbsUp}
                onClick={onConfirmLoadedClick}
              />
            )}
          </div>
        )}
      </BorderOutset>

      <DriverCardsList drivers={[assignedDriver]} theme={theme} />
    </div>
  )
}

export default AssignedDriverPanel
