import { useEffect, useState } from 'react'

import { useLocalDateTime } from './useLocalDateTime'

export default function useCurrentTime() {
  const { getCurrentTime } = useLocalDateTime()
  const [currentTime, setCurrentTime] = useState(getCurrentTime())

  useEffect(() => {
    const timerID = setInterval(() => setCurrentTime(new Date()), 500)

    return function cleanup() {
      clearInterval(timerID)
    }
  }, [])

  return currentTime
}
