import { MISSING_VALUE, padInteger } from '../utils/string-utils'

function restrictHours(hours) {
  if (hours > 23) {
    return hours - 24
  } else return hours
}

const TimeRange = ({ from, to }) => {
  if (!from || !to) return MISSING_VALUE

  return (
    <span>
      {padInteger(restrictHours(from.hours))}:{padInteger(from.minutes)} -{' '}
      {padInteger(restrictHours(to.hours))}:{padInteger(to.minutes)}
    </span>
  )
}

export default TimeRange
