import { AutoComplete } from 'antd'
import _ from 'lodash'
import { forwardRef, useRef, useState } from 'react'

import { TextInputMultiline } from './form/TextInput'
import ScrollArea from '../components/ScrollArea'
import { useLocations } from '../hooks/useLocations'
import { themedClass } from '../utils/style-utils'

function formatLocation(location) {
  if (!location) return ''
  const { name, address = {} } = location
  const { street, city, state, zip } = address || {}
  return `${name} | ${_.compact(street).join(', ')}, ${city}, ${state} ${zip}`
}

const AutocompleteFormattedInput = forwardRef((props, ref) => {
  const formattedValue =
    typeof props.value === 'string' ? props.value.replace('| ', '\n') : ''
  return <TextInputMultiline {...props} ref={ref} value={formattedValue} />
})

const ReadOnlyLocation = ({ location, theme }) => {
  const css = themedClass('autocomplete-readonly', theme)
  if (!location) return <div className={css} />

  const { name, address } = location
  const { street, city, state, zip } = address || {}
  return (
    <div className={css}>
      {name}
      <br />
      {_.compact(street).join(', ')}, {city}, {state} {zip}
    </div>
  )
}
const LocationsAutocomplete = (
  { placeholder, value, onSelected, readOnly, dataCy, theme },
  _ref
) => {
  const [menuWidth, setMenuWidth] = useState(null)
  const [menuHeight, setMenuHeight] = useState(null)
  const [textValue, setTextValue] = useState('')
  const scrollbarRef = useRef(null)
  const {
    filtering: { filterLocations, data },
  } = useLocations()

  const onSearch = searchText => {
    filterLocations({
      variables: {
        filter: { search: searchText, onlyActive: true },
      },
    })
  }

  const onSelect = (_data, opt) => {
    onSelected && onSelected(opt)
  }

  const onScrollAreaUpdate = () => {
    setMenuHeight(scrollbarRef.current.clientHeight)
    setMenuWidth(scrollbarRef.current.clientWidth)
  }

  const onChanged = val => {
    setTextValue(val)
    if (value) onSelected(null)
  }

  const renderDropdown = menu => (
    <ScrollArea
      className="autocomplete-scrollarea"
      onUpdate={onScrollAreaUpdate}
      style={{
        maxHeight: 100,
        maxWidth: 420,
        height: menuHeight,
        width: menuWidth,
      }}
    >
      <div ref={scrollbarRef}>{menu}</div>
    </ScrollArea>
  )

  let dataSource = data.listLocations || []
  if (value && value.id && !_.some(dataSource, d => d.id === value.id)) {
    dataSource = [...dataSource, value]
  }

  if (readOnly) {
    return <ReadOnlyLocation location={value} theme={theme} />
  }

  const autocompleteItems = _.map(dataSource, opt => ({
    label: formatLocation(opt),
    value: opt.id,
  }))

  const autocompleteValue =
    _.find(autocompleteItems, item => item.value === value?.id) || value

  return (
    <AutoComplete
      options={autocompleteItems}
      onSelect={onSelect}
      onSearch={onSearch}
      onChange={onChanged}
      defaultActiveFirstOption
      dropdownMatchSelectWidth={false}
      popupClassName={themedClass('autocomplete-dropdown', theme)}
      dropdownRender={renderDropdown}
      value={autocompleteValue?.label || textValue}
      data-cy={dataCy}
    >
      <AutocompleteFormattedInput
        placeholder={placeholder}
        className="autocomplete-input"
        theme={theme}
      />
    </AutoComplete>
  )
}

export default forwardRef(LocationsAutocomplete)
