import { useSubscription } from '@apollo/client'
import _ from 'lodash'

import { useCards } from './useCards'
import { useCardsFilterQuery } from './useCardsFilter'
import { useCurrentStation } from './useCurrentStation'
import {
  NotificationsTypeEnum,
  useNotificationsContext,
} from './useNotificationsContext'
import { OperationTypeEnum } from '../app/constants'
import {
  updateCardsList,
  updateDriversList,
  updateStacksList,
} from '../app/graphQl/cacheManager'
import { CARD_SUBSCRIPTION, GET_CARDS } from '../app/graphQl/schema/Cards'
import { DRIVER_SUBSCRIPTION, GET_DRIVERS } from '../app/graphQl/schema/Drivers'
import { LOCATION_SUBSCRIPTION } from '../app/graphQl/schema/Locations'
import { GET_STACKS, STACK_SUBSCRIPTION } from '../app/graphQl/schema/Stacks'
import { TRACKED_CHANGE_SUBSCRIPTION } from '../app/graphQl/schema/TrackedChanges'
import { CardUpdateNotificationSmall } from '../components/modals/CardUpdateNotification'
import { mergeArrays, stableUnion } from '../utils/array-utils'
import { compareCharges } from '../utils/card-utils'
import { updateCardsSubjobs } from '../utils/common-utils'
import { getDisplayName } from '../utils/driver-utils'

const getLoadingCharges = charges => _.filter(charges, ({ loading }) => loading)

function pickChangedData(listCards, changeData) {
  const storedData = _.intersectionBy(listCards, changeData, 'id')
  const storedDataDict = _.keyBy(storedData, 'id')
  return _.map(changeData, d => {
    const storedData = storedDataDict[d.id]
    if (!storedData) return d

    const loadingCharges = getLoadingCharges(storedData.agentCharges)
    const skipUpdate = storedData.skipNextUpdate

    return _.merge({}, d, {
      agentCharges: mergeArrays(d.agentCharges, loadingCharges, compareCharges),
      skipNextUpdate: false,
      milestone: skipUpdate ? storedData.milestone : d.milestone,
      pendingUpdate: skipUpdate ? storedData.pendingUpdate : false,
    })
  })
}

function getLegsToUpdate(allCards, changeData, operation) {
  if (operation === OperationTypeEnum.UPDATE) return []

  const countDelta = operation === OperationTypeEnum.CREATE ? 1 : -1
  const legsToUpdate = _.map(changeData, 'jobNumber')
  return _.map(
    _.filter(allCards, c => _.includes(legsToUpdate, c.jobNumber)),
    x => ({ ...x, legsCount: x.legsCount + countDelta })
  )
}

function getDriversNotifications(currentData, newData) {
  const withChangedState = _.filter(newData, driver => {
    const prevData = currentData.find(d => d.code === driver.code)
    return prevData && prevData.isActive !== driver.isActive
  })

  return _.map(withChangedState, driver => ({
    type: NotificationsTypeEnum.INFO,
    messageId: driver.isActive
      ? 'drivers.driver-to-active'
      : 'drivers.driver-to-inactive',
    messageParams: {
      code: driver.code,
      name: _.toUpper(getDisplayName(driver)),
    },
  }))
}

function getCardUpdateNotifications(cards) {
  const updatedCards = _.filter(cards, card =>
    _.some(card.trackedChanges, x => !x.acknowledged)
  )
  return _.map(updatedCards, card => ({
    type: NotificationsTypeEnum.WARNING,
    Component: CardUpdateNotificationSmall,
    componentProps: { card },
  }))
}

//using fetchPolicy: 'no-cache' because we are updating cache manually
/*
  TODO replace try / catch blocks with better implementation ()
  https://www.apollographql.com/docs/react/caching/cache-interaction/#readquery
  If your cache doesn't contain all of the data necessary to fulfill a specified query, readQuery throws an error. It never attempts to fetch data from a remote server.
*/
export function useSubscriptionsSetup() {
  const { stationId } = useCurrentStation()
  const cardsVariables = useCardsFilterQuery()
  const { displayTopMessage } = useNotificationsContext()

  const { removeFromCache } = useCards()

  useSubscription(TRACKED_CHANGE_SUBSCRIPTION)

  // we dont need to react to DELETE
  useSubscription(LOCATION_SUBSCRIPTION, {
    skip: !stationId,
  })

  useSubscription(STACK_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    variables: { station: stationId },
    skip: !stationId,
    onData: ({ client, data: { data } }) => {
      if (!data || !data.stackUpdated) return
      const { operation, changeData } = data.stackUpdated
      try {
        const { listStacks } = client.readQuery({
          query: GET_STACKS,
          variables: { stationId },
        })
        let stacksToCache = null
        if (
          operation === OperationTypeEnum.UPDATE ||
          operation === OperationTypeEnum.CREATE
        ) {
          stacksToCache = _.unionBy(changeData, listStacks, 'id')
        }
        if (operation === OperationTypeEnum.DELETE) {
          stacksToCache = _.differenceBy(listStacks, changeData, 'id')
        }
        if (stacksToCache) updateStacksList(client, stacksToCache, stationId)
      } catch (e) {
        console.log(e)
        return
      }
    },
  })

  const driversVariables = { filter: { station: stationId } }
  useSubscription(DRIVER_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    variables: driversVariables,
    skip: !stationId,
    onData: ({ client, data: { data } }) => {
      if (!data || !data.driverUpdated) return
      const { operation, changeData } = data.driverUpdated
      try {
        const { listDrivers } = client.readQuery({
          query: GET_DRIVERS,
          variables: driversVariables,
        })
        let driversToCache = null
        let updateNotifications = []
        if (operation === OperationTypeEnum.UPDATE) {
          driversToCache = stableUnion(listDrivers, changeData)

          updateNotifications = getDriversNotifications(listDrivers, changeData)
        }

        if (operation === OperationTypeEnum.CREATE) {
          driversToCache = _.unionBy(changeData, listDrivers, 'id')
        }

        if (operation === OperationTypeEnum.DELETE) {
          driversToCache = _.differenceBy(listDrivers, changeData, 'id')
        }

        if (driversToCache) updateDriversList(client, driversToCache, stationId)

        _.forEach(updateNotifications, displayTopMessage)
      } catch (e) {
        console.log(e)
        return
      }
    },
  })

  useSubscription(CARD_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    variables: cardsVariables,
    skip: !stationId,
    onData: ({ client, data: { data } }) => {
      if (!data || !data.cardUpdated) return
      const { operation, changeData } = data.cardUpdated
      try {
        const { listCards } = client.readQuery({
          query: GET_CARDS,
          variables: cardsVariables,
        })
        let cardsToCache = null
        if (
          operation === OperationTypeEnum.UPDATE ||
          operation === OperationTypeEnum.CREATE
        ) {
          changeData.push(...getLegsToUpdate(listCards, changeData, operation))
          const dataToUpdate = pickChangedData(listCards, changeData)

          const flatList = _.flatten(
            _.map(listCards, card => card.groupChildren)
          )
          cardsToCache = _.unionBy(
            updateCardsSubjobs([...listCards, ...flatList], dataToUpdate),
            dataToUpdate,
            listCards,
            'id'
          )
        }

        if (operation === OperationTypeEnum.DELETE) {
          removeFromCache(changeData)
          cardsToCache = _.differenceBy(listCards, changeData, 'id')
          cardsToCache = _.unionBy(
            getLegsToUpdate(cardsToCache, changeData, operation),
            cardsToCache,
            'id'
          )
        }

        if (cardsToCache !== null) {
          updateCardsList(client, cardsToCache, cardsVariables)
        }

        const notifications = getCardUpdateNotifications(changeData)
        _.forEach(notifications, displayTopMessage)
      } catch (e) {
        console.log(e)
        return
      }
    },
  })
}
