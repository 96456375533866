import _ from 'lodash'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { PackageInformation } from './PackageInformation'
import SensorDevices from './SensorDevices'
import SensorReadings from './SensorReadings'
import { TemperatureAndSensors } from './TemperatureAndSensors'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { BorderOutsetSeparator } from '../BorderOutset'
import Drawer, { DrawerOpenDirection } from '../Drawer'
import * as Icons from '../Icons'
import { FlowContentHeaderSmall } from '../ModalsTypography'
import ScrollArea from '../ScrollArea'
import { PackagesSwitch } from '../shipment-information_v2/PackagesSwitch'

const DrawerControl = ({ opened }) => (
  <div>
    {opened ? (
      <Icons.ArrowCircleDownIcon className="text-important" />
    ) : (
      <Icons.ArrowCircleUpIcon className="text-important" />
    )}{' '}
    <FormattedMessage id="card-popup.additional-shipment-info" />
  </div>
)

const AdditionalShipmentInfo = ({ job }) => {
  const [packageIndex, setPackageIndex] = useState(0)
  const [selectedDeviceIndex, setSelectedDeviceIndex] = useState()
  const [theme] = useSelectedTheme()

  useEffect(() => {
    if (!selectedDeviceIndex && _.some(job?.devices)) {
      setSelectedDeviceIndex(0)
    }
  }, [job, selectedDeviceIndex])

  const packageInfo =
    job?.packageInformation && job?.packageInformation[packageIndex]

  const selectedDevice = _.nth(job?.devices, selectedDeviceIndex)
  return (
    <Drawer
      ControlComponent={DrawerControl}
      className="additional-info-drawer flow-content-drawer"
      direction={DrawerOpenDirection.UP}
      height={675}
      theme={theme}
    >
      <ScrollArea className="additional-info-content-wrapper">
        <BorderOutsetSeparator theme={theme} />

        <div className="additional-info-content">
          <FlowContentHeaderSmall
            className="package-info-header space-between"
            theme={theme}
          >
            <FormattedMessage id="card-popup.package-information" />
            <PackagesSwitch
              packages={job?.packageInformation}
              selectedIndex={packageIndex}
              onPieceSelected={setPackageIndex}
            />
          </FlowContentHeaderSmall>
          <PackageInformation packageInfo={packageInfo} />
          <TemperatureAndSensors packageInfo={packageInfo} theme={theme} />
          {_.some(job?.devices) && (
            <SensorDevices
              devices={job?.devices}
              selectedDevice={selectedDevice}
              onDeviceClick={setSelectedDeviceIndex}
            />
          )}
          <SensorReadings
            device={selectedDevice}
            deviceIndex={selectedDeviceIndex}
          />
        </div>
      </ScrollArea>
    </Drawer>
  )
}

export default AdditionalShipmentInfo
