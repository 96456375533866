import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import ConfirmModalBase from './ConfirmModalBase'
import { useCards } from '../../hooks/useCards'
import { WarningIcon } from '../Icons'
import DateTimeInput from '../form/DateTimeInput'

const ConfirmDateModal = ({ textMessage, onOk, ...rest }) => {
  const { formatMessage: f } = useIntl()
  const [date, setDate] = useState(null)

  const onConfirmed = () => {
    onOk(date)
  }

  return (
    <ConfirmModalBase
      width={360}
      className="confirm-date-modal"
      okText={f({ id: 'confirm.set' })}
      title={<WarningIcon className="opaque-icon text-warning" />}
      onOk={onConfirmed}
      {...rest}
    >
      <h1>
        <FormattedMessage id="confirm.attention" />
      </h1>
      <h2 className="text-warning">
        <FormattedMessage id={textMessage} />
      </h2>

      <DateTimeInput onChange={setDate} value={date} />
    </ConfirmModalBase>
  )
}

export const ConfirmLoadedToDockModal = ({ card, onClosed, ...props }) => {
  const { pickFromDock } = useCards()

  const onConfirm = date => {
    pickFromDock(card, date)
  }

  return (
    <ConfirmDateModal
      onOk={onConfirm}
      afterClose={onClosed}
      textMessage="drivers.enter-dock-loaded"
      {...props}
    />
  )
}

export const ConfirmUnloadedFromDockModal = ({ card, onClosed, ...props }) => {
  const { dropToDock } = useCards()

  const onConfirm = date => {
    dropToDock(card, date)
  }

  return (
    <ConfirmDateModal
      onOk={onConfirm}
      afterClose={onClosed}
      textMessage="drivers.enter-dock-unloaded"
      {...props}
    />
  )
}
