import classNames from 'classnames'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import AdditionalShipmentInfo from './AdditionalShipmentInfo'
import DocumentCenter from './DocumentCenter'
import FlightsTable from './FlightsTable'
import ShipperSpecificInfo from './ShipperSpecificInfo'
import FlowWindow from './common/FlowWindow'
import MilestoneCountdown from './common/MilestoneCountdown'
import { LayoutColumn, LayoutGrid, LayoutRow } from './common/MultiColumnLayout'
import { CardPopupState } from '../../app/enums/CardPopupState'
import { MilestonesEnum } from '../../app/enums/MilestonesEnum'
import useGetDriver from '../../hooks/graphQl/useGetDriver'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { getConsignee, isArchived } from '../../utils/card-utils'
import { getCardDate } from '../../utils/common-utils'
import { getMapMarkerColor } from '../../utils/driver-utils'
import { ShortDateFormat, TimeWithTimeZone } from '../DateTimeFormats'
import { ArrowCircleUp } from '../Icons'
import {
  FlowContentHeaderSmall,
  HeaderSizes,
  JobDetailHeader,
  JobHeaderLabel,
} from '../ModalsTypography'
import AssignedDriver from '../drivers/AssignedDriver'
import { Button } from '../form/Buttons'
import FullRouteMap from '../maps/FullRouteMap'
import UploadDocumentsModal from '../modals/UploadDocumentsModal'
import { LocationInfo } from '../shipment-information_v2/LocationInfo'

const JobHeader = ({ card }) => {
  const { formatMessage: f } = useIntl()
  return (
    <>
      <div className="job-number-panel-section">
        <JobDetailHeader size={HeaderSizes.Large}>
          <FormattedMessage id="card-popup.overview" />
        </JobDetailHeader>
      </div>
      <div className="job-number-panel-section">
        <JobHeaderLabel>
          <FormattedMessage id="card-dispatch.status" />
        </JobHeaderLabel>
        <JobDetailHeader
          size={HeaderSizes.Medium}
          className="text-important"
          uppercase
        >
          {card.milestone?.code}
        </JobDetailHeader>
      </div>
      <div className="job-number-panel-section">
        <JobHeaderLabel>
          <FormattedMessage id="job.type" />
        </JobHeaderLabel>
        <JobDetailHeader size={HeaderSizes.Medium} uppercase>
          <FormattedMessage
            id="job.type-value"
            values={{
              pick: f({ id: `card.legs.${card.pickup.type}` }),
              drop: f({ id: `card.legs.${card.drop.type}` }),
            }}
          />
        </JobDetailHeader>
      </div>
    </>
  )
}

const SectionHeader = ({ children, messageId, theme }) => (
  <FlowContentHeaderSmall theme={theme}>
    <FormattedMessage id={messageId} />
    {children}
  </FlowContentHeaderSmall>
)

function CountDownBadge({ date, label, placement }) {
  const css = classNames('job-detail-countdown-badge', {
    'job-detail-countdown-badge-bottom': placement === 'bottom',
    'job-detail-countdown-badge-top': placement === 'top',
  })
  return (
    <div className={css}>
      <div className="job-detail-countdown-badge-label">{label}</div>
      <div className="job-detail-countdown-badge-iso">
        <ShortDateFormat date={date}></ShortDateFormat>
        <br />
        <TimeWithTimeZone date={date} />
      </div>
    </div>
  )
}

function CountDownSection({ card, job, theme }) {
  const { fromServerValue } = useLocalDateTime()
  const badge1Date = fromServerValue(getCardDate(card))
  const ddlDate = fromServerValue(card?.deadline?.iso)
  const disabledMilestone =
    card.milestone.code === MilestonesEnum.UNLOADED || isArchived(card)

  return (
    <div className="overview-milestone-column">
      <SectionHeader messageId="card-popup.milestone-countdown" theme={theme} />
      <div className="countdown-milestone-section">
        <MilestoneCountdown
          countdownEnd={badge1Date}
          disabled={disabledMilestone}
          theme={theme}
        />
        {!disabledMilestone && (
          <>
            <CountDownBadge
              placement="top"
              date={badge1Date}
              label={
                card?.reminder ? (
                  <span className="text-uppercase">
                    <FormattedMessage id="card.badge-action" />
                  </span>
                ) : (
                  card?.badge1.label
                )
              }
            />
            <CountDownBadge
              date={ddlDate}
              placement="bottom"
              label={<FormattedMessage id="card-popup.ddl-badge" />}
            />
          </>
        )}
      </div>
      <SectionHeader messageId="card-popup.consignee" theme={theme} />
      <div className="overview-consignee">
        <LocationInfo
          company={getConsignee(card, job)}
          rowClass="overview-consignee-row"
          highlightTopRow={false}
        />
      </div>
    </div>
  )
}

const JobDetailScreen = ({ card, job }) => {
  const driver = useGetDriver(card.driver?.id)
  const [showUploadForm, setShowUploadForm] = useState()
  const [theme] = useSelectedTheme()

  const pickupCoords = card?.pickup?.position
  const dropCoords = card?.drop?.position

  return (
    <FlowWindow
      card={card}
      page={CardPopupState.Overview.Page}
      className="flow-content-job-detail"
      header={<JobHeader card={card} />}
    >
      <div className="job-detail-inner">
        <div className="space-between">
          <div className="overview-map-column">
            <SectionHeader messageId="card-popup.agent" theme={theme}>
              <AssignedDriver card={card} />
            </SectionHeader>

            <div className="overview-map">
              <FullRouteMap
                center={pickupCoords}
                pickupPoint={pickupCoords}
                dropPoint={dropCoords}
                lineColor={getMapMarkerColor(card.driver)}
                driver={driver}
                theme={theme}
              />
            </div>
          </div>
          <CountDownSection card={card} job={job} theme={theme} />
        </div>

        <FlightsTable flights={job?.flightsItinerary} theme={theme} />

        <LayoutGrid className="job-detail-section">
          <LayoutRow>
            <LayoutColumn>
              <LayoutRow>
                <FlowContentHeaderSmall theme={theme}>
                  <FormattedMessage id="card-popup.shipper-specific" />
                </FlowContentHeaderSmall>
              </LayoutRow>

              <LayoutRow>
                <ShipperSpecificInfo job={job} />
              </LayoutRow>
            </LayoutColumn>

            <LayoutColumn className="documents-layout-column" bordered>
              <LayoutRow>
                <FlowContentHeaderSmall className="space-between" theme={theme}>
                  <FormattedMessage id="card-popup.documents" />
                  <Button
                    type="primary"
                    size="small"
                    className="upload-document-btn"
                    icon={<ArrowCircleUp />}
                    onClick={() => setShowUploadForm(true)}
                  >
                    <FormattedMessage id="form.upload" />
                  </Button>
                </FlowContentHeaderSmall>
              </LayoutRow>

              <LayoutRow className="documents-bol-link-row">
                <Button type="link" href={job?.bolPdfUrl} target="blank">
                  <FormattedMessage id="documents-center.bol-pdf-link" />
                </Button>
              </LayoutRow>

              <LayoutRow bordered>
                <DocumentCenter
                  documents={job?.documents}
                  jobNumber={job?.id}
                  theme={theme}
                />
              </LayoutRow>
            </LayoutColumn>
          </LayoutRow>
        </LayoutGrid>
      </div>

      <AdditionalShipmentInfo job={job} />

      {showUploadForm && (
        <UploadDocumentsModal
          onClosed={() => setShowUploadForm(false)}
          job={job}
        />
      )}
    </FlowWindow>
  )
}

export default JobDetailScreen
