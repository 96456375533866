import { PackageInformationPiece } from './common/ValueWithLabel'
import { formatNullableString } from '../../utils/string-utils'

export const PackageInformation = ({ packageInfo }) => (
  <div className="package-information">
    <div className="space-between">
      <PackageInformationPiece labelId="card-popup.package-source">
        {formatNullableString(packageInfo?.source)}
      </PackageInformationPiece>
      <PackageInformationPiece
        labelId="card-popup.packaging-type"
        className="text-ellipsed"
      >
        {formatNullableString(packageInfo?.typeDesc)}
      </PackageInformationPiece>
      <PackageInformationPiece labelId="card-popup.commodity-temperature">
        {formatNullableString(packageInfo?.commodityTemp)} (
        {formatNullableString(packageInfo?.commodityTempDesc)} )
      </PackageInformationPiece>
    </div>
  </div>
)
