import classNames from 'classnames'

export const LayoutColumn = ({ children, className, bordered }) => {
  const css = classNames('job-detail-col', className, {
    'job-detail-col-bordered': bordered,
  })
  return <div className={css}>{children}</div>
}

export const LayoutRow = ({ children, className }) => {
  const css = classNames('job-detail-row', className)
  return <div className={css}>{children}</div>
}

export const LayoutGrid = ({ children, className }) => {
  const css = classNames('job-detail-grid', className)
  return <div className={css}>{children}</div>
}
