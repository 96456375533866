import _ from 'lodash'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { NoteTypesEnum } from '../../../app/enums/NoteTypesEnum'
import { useLocalDateTime } from '../../../hooks/useLocalDateTime'
import { themedClass } from '../../../utils/style-utils'
import { NarrowDateTimeFormat } from '../../DateTimeFormats'
import { TimesIcon } from '../../Icons'
import { DotsLoader } from '../../LoadingIndicators'
import UserTextPreview from '../../UserTextPreview'
import { Button } from '../../form/Buttons'
import { TextInputMultiline } from '../../form/TextInput'

const CloseIcon = ({ note, onClick }) => {
  const onClicked = () => {
    onClick && onClick(note.id)
  }
  return note.id ? (
    <TimesIcon className="note-close" onClick={onClicked} />
  ) : (
    <NoteLoader />
  )
}

const NoteLoader = () => (
  <div className="note-loader">
    <DotsLoader />
  </div>
)

export const DispatcherNote = ({ note, onClosed, theme }) => (
  <div className={themedClass('note-wrapper', theme)} key={note.id}>
    <div className="dispatcher-note">
      <UserTextPreview text={note.text} />
    </div>
    <CloseIcon note={note} onClick={onClosed} />
  </div>
)

export const CTNote = ({ note, theme }) => {
  const { fromServerValue } = useLocalDateTime()
  return (
    <div className={themedClass('note-wrapper', theme)}>
      <div className="ct-note">
        {note.pending && <NoteLoader />}

        <div className="text-important text-uppercase ct-note-date">
          <NarrowDateTimeFormat date={fromServerValue(note.createdAt?.iso)} />
        </div>
        <div className="ct-note-text">
          <UserTextPreview text={note.text} />
        </div>
      </div>
    </div>
  )
}

export const DispatcherNoteEditor = ({
  maxLength,
  onClosed,
  onSave,
  type,
  theme,
}) => {
  const { formatMessage: f } = useIntl()
  const { getCurrentTime } = useLocalDateTime()
  const [value, setValue] = useState('')

  const onNoteChanged = ({ target }) => {
    setValue(target.value)
  }

  const onNoteKeyDown = event => {
    // new line on enter + shift, otherwise save note
    if (event.key === 'Enter' && !event.shiftKey) {
      onSave(value)
    }
  }

  const onSaveClick = () => {
    onSave(value)
  }

  const onBlur = () => {
    onSave(value)
  }

  return (
    <div className={themedClass('note-wrapper', theme)}>
      {type === NoteTypesEnum.CT && (
        <div className="text-important text-uppercase ct-note-date">
          <NarrowDateTimeFormat date={getCurrentTime()} />
        </div>
      )}

      <TextInputMultiline
        maxLength={maxLength}
        placeholder={f({
          id: 'card-popup.dispatcher-notes-placeholder',
        })}
        value={value}
        onChange={onNoteChanged}
        onBlur={onBlur}
        onKeyDown={onNoteKeyDown}
        theme={theme}
      />
      {value && type === NoteTypesEnum.CT ? (
        <Button className="post-ct-note-btn" size="small" onClick={onSaveClick}>
          <FormattedMessage id="card-popup.post-note" />
        </Button>
      ) : (
        <TimesIcon className="note-close" onClick={onClosed} />
      )}
    </div>
  )
}

const DispatcherNotesList = ({ notes, onRemoved, NoteComponent, theme }) =>
  _.map(notes, (n, i) => (
    <NoteComponent note={n} onClosed={onRemoved} key={i} theme={theme} />
  ))

export default DispatcherNotesList
