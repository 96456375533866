import _ from 'lodash'
import { useDrop } from 'react-dnd'

import { CardDragType } from '../components/job-cards/Card'

export function useCardGroupDrop(data, onDrop) {
  const [{ isHovered }, cardDrop] = useDrop({
    accept: CardDragType,
    drop: _item => {
      onDrop(data)
    },
    collect: monitor => ({
      isHovered: monitor.isOver() && monitor.canDrop(),
    }),
    canDrop: item =>
      !_.some(item.data.groupChildren) &&
      item.data.id !== data.id &&
      item.data.stack.id === data.stack.id,
  })

  return [isHovered, cardDrop]
}
