import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { TextInputMultiline } from '../form/TextInput'
import ConfirmModalBase from '../modals/ConfirmModalBase'

const AuditChargesBox = ({ onSubmit, onCancel }) => {
  const { formatMessage: f } = useIntl()
  const [comment, setComment] = useState('')

  const onChanged = e => {
    setComment(e.target.value)
  }

  const onOkClicked = () => {
    onSubmit(comment)
  }

  return (
    <ConfirmModalBase
      className="audit-charges-popover"
      width={360}
      okText={f({ id: 'card-popup.charges-audit-confirm' })}
      onOk={onOkClicked}
      onCancel={onCancel}
      title={<i className="fas fa-comment-dots opaque-icon text-warning" />}
    >
      <h1>
        <FormattedMessage id="card-popup.audit-comment" />
      </h1>
      <h2 className="text-warning">
        <FormattedMessage id="card-popup.charge-issues-identify" />
      </h2>
      <TextInputMultiline
        placeholder={f({ id: 'form.enter' })}
        value={comment}
        onChange={onChanged}
      />
    </ConfirmModalBase>
  )
}

export default AuditChargesBox
