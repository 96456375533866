import _ from 'lodash'
import { cloneElement } from 'react'

import { ChangeColumnHeader, GroupHeader } from './CardHeaders'
import { useDragInfoContext } from '../../hooks/dragAndDrop/useDragInfoContext'
import { useMonitorContext } from '../../hooks/useMonitorContext'
import { areMultipleDragged } from '../../utils/card-utils'

export const MultiSelectCardPreview = ({ children }) => {
  const { selectedCards } = useMonitorContext()

  const [itemHovered, , currentDraggedItem] = useDragInfoContext()

  const newProps = {
    forceExpand: false,
  }

  if (areMultipleDragged(selectedCards)) {
    const groupedCards = _.concat(
      selectedCards,
      _.flatten(_.map(selectedCards, c => c.groupChildren || []))
    )

    let header = <ChangeColumnHeader />
    if (
      itemHovered &&
      itemHovered.id === _.get(currentDraggedItem, 'currentStack.id')
    ) {
      header = <GroupHeader />
    }

    newProps.header = header
    newProps.countBadge = groupedCards.length
  }

  return cloneElement(children, newProps)
}
