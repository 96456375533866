import { Input } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { forwardRef, useEffect, useRef, useState } from 'react'

import { CloseIcon, CloseIconAlt, SearchIcon } from '../Icons'

const SearchBox = (
  {
    value,
    className,
    placeholder,
    onBlur,
    onChange,
    closable,
    startOpened,
    onStateChanged,
    onKeyDown,
  },
  ref
) => {
  const [searchValue, setSearchValue] = useState('')
  const [opened, setOpened] = useState(startOpened)
  const inputRef = useRef()

  useEffect(() => setSearchValue(value), [value, setSearchValue])

  useEffect(() => {
    if (opened && inputRef.current) inputRef.current.focus()
  }, [opened])

  const setRefs = r => {
    inputRef.current = r
    if (ref) {
      if (_.isFunction(ref)) ref(r)
      else ref.current = r
    }
  }

  const onSubmit = () => {
    onBlur && onBlur(searchValue)
  }

  const onChanged = e => {
    const { target } = e
    setSearchValue(target.value)
    onChange && onChange(e)
  }

  const onClearClick = () => {
    onBlur && onBlur()
  }

  const onOpenClick = () => {
    setOpened(true)
    onStateChanged && onStateChanged(true)
  }

  const onCloseClick = e => {
    setOpened(false)
    setSearchValue('')
    onClearClick()
    onStateChanged && onStateChanged(false)
  }

  const suffix = closable ? (
    <CloseIconAlt onClick={onCloseClick} />
  ) : (
    <CloseIcon className="ant-input-clear-icon" onClick={onClearClick} />
  ) // using suffix because standard ant clear icon cannot be changed

  const getInput = () =>
    opened || !closable ? (
      <Input
        ref={setRefs}
        placeholder={placeholder}
        value={searchValue}
        onChange={onChanged}
        onBlur={onSubmit}
        prefix={<SearchIcon />}
        suffix={suffix}
        onPressEnter={onSubmit}
        onKeyDown={onKeyDown}
      />
    ) : (
      <SearchIcon onClick={onOpenClick} />
    )

  const css = classNames(className, 'search-input')
  return <span className={css}>{getInput()}</span>
}

export default forwardRef(SearchBox)
