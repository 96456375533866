import { gql } from '@apollo/client'

export const GET_ADDITIONAL_CHARGES = gql`
  query GetAdditionalCharges($station: ID!) {
    getAdditionalCharges(station: $station) {
      id
      description
      amount
      amountSource
      chargeType
    }
  }
`
