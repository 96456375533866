import { createContext, useContext } from 'react'

export const ModalContainerContext = createContext({ container: document.body })

export const ModalContainerContextProvider = ({ children, container }) => {
  const value = { container: container }
  return (
    <ModalContainerContext.Provider value={value}>
      {children}
    </ModalContainerContext.Provider>
  )
}

export const useModalContainerContext = () => useContext(ModalContainerContext)
