import { useCallback, useEffect } from 'react'
import { useDrop } from 'react-dnd'

import { useDragInfoContext } from './useDragInfoContext'

const useDropWithContext = (dropItem, options) => {
  const [, onItemHovered, , onDraggedItem] = useDragInfoContext()

  const hover = useCallback(
    item => {
      onItemHovered(dropItem)
      onDraggedItem(item)
    },
    [dropItem, onDraggedItem, onItemHovered]
  )

  const dropResult = useDrop({
    ...options,
    hover: hover,
    collect: monitor => {
      let inMonitor = {}
      if (options.collect) {
        inMonitor = options.collect(monitor)
      }
      return {
        ...inMonitor,
        isOverDropPoint: monitor.isOver(),
      }
    },
  })

  const [{ isOverDropPoint }] = dropResult
  useEffect(() => {
    if (!isOverDropPoint) {
      onItemHovered(null)
    }
  }, [isOverDropPoint, onItemHovered])

  return dropResult
}

export default useDropWithContext
