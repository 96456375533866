import { forwardRef } from 'react'

import ScrollArea from './ScrollArea'
import { themedClass } from '../utils/style-utils'

const CardsColumnInner = ({ scrollable, theme, children }, ref) => {
  const css = themedClass('cards-column-inner', theme)

  if (scrollable) {
    return (
      <ScrollArea hideHorizontal={true} ref={ref} className={css}>
        {children}
      </ScrollArea>
    )
  }

  return (
    <div className={css} style={{ overflow: 'hidden' }}>
      {children}
    </div>
  )
}

export default forwardRef(CardsColumnInner)
