import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import { JobActionDate } from './JobActionDate'
import { JobOverviewSectionHeader } from './JobOverviewSectionHeader'
import { JobHeaderLabel } from '../ModalsTypography'
import SpecialInstructions from '../card-popup/common/SpecialInstructions'
import { CommodityInfo } from '../shipment-information_v2/CommodityInfo'
import DropInfo from '../shipment-information_v2/DropInfo'
import PickUpInfo from '../shipment-information_v2/PickUpInfo'

const DangerousGoodsCol = ({ isDangerousGoods, unNumber }) => {
  const css = classNames('job-overview-horizontal-col job-overview-dg', {
    'is-dg': isDangerousGoods,
  })
  const infoCss = classNames(
    'text-uppercase',
    { 'text-important': !isDangerousGoods },
    { 'text-danger': isDangerousGoods }
  )
  return (
    <div className={css}>
      <JobHeaderLabel>
        <FormattedMessage id="card-dispatch.dangerous-goods" />
      </JobHeaderLabel>
      <strong className={infoCss}>
        <FormattedMessage id={`bool.${!!isDangerousGoods}`} />{' '}
        {isDangerousGoods && (
          <span className="text-underlined un-number">
            <FormattedMessage
              id="card-dispatch.dangerous-goods-un"
              values={{ unNumber: unNumber }}
            />
          </span>
        )}
      </strong>
    </div>
  )
}

const JobDetailBox = ({
  cardData,
  jobDetail,
  hidden,
  specialInstructions,
  theme,
}) => {
  const detailCss = classNames('space-between', 'job-overview-hideable', {
    'detail-hidden': hidden,
  })
  const rowClass = 'shipment-info-cell'

  return (
    <>
      <div className="space-between">
        <div className="job-overview-horizontal-col">
          {cardData && (
            <JobActionDate
              badge={cardData.jobBadge1}
              borderless
              theme={theme}
            />
          )}
        </div>
        <DangerousGoodsCol
          isDangerousGoods={cardData?.isDangerousGoods}
          unNumber={cardData?.unNumber}
        />
        <div className="job-overview-horizontal-col job-overview-last-col">
          {cardData && <JobActionDate badge={cardData.jobBadge2} borderless />}
        </div>
      </div>

      <div className={detailCss}>
        <div className="job-overview-horizontal-col">
          <JobOverviewSectionHeader
            mainMessage="shipment-info.from"
            secondaryMessage="shipment-info.pickup"
          />
          <PickUpInfo card={cardData} job={jobDetail} rowClass={rowClass} />
        </div>

        <div className="job-overview-horizontal-col">
          <JobOverviewSectionHeader mainMessage="card-dispatch.shipment-info" />
          <CommodityInfo card={cardData} job={jobDetail} rowClass={rowClass} />
        </div>

        <div className="job-overview-horizontal-col">
          <JobOverviewSectionHeader
            mainMessage="shipment-info.to"
            secondaryMessage="shipment-info.deliver"
          />
          <DropInfo card={cardData} job={jobDetail} rowClass={rowClass} />
        </div>

        {specialInstructions && (
          <div className="job-overview-horizontal-col instructions-column no-margin">
            <JobOverviewSectionHeader
              mainMessage="card.special-instructions"
              className="text-uppercase"
            />

            <SpecialInstructions
              card={cardData}
              className={`special-instructions-cell ${rowClass}`}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default JobDetailBox
