import classNames from 'classnames'

import { ContentPanel } from '../../ContentPanel'

export const FlowColumn = ({ className, children, extended }) => {
  const contentCss = classNames(
    'flow-grid-column',
    {
      'flow-column-extended': extended,
    },
    className
  )

  return <div className={contentCss}>{children}</div>
}

export const FlowToolbar = ({
  className,
  children,
  toolbarPanelMessage,
  theme,
}) => {
  const contentCss = classNames(
    'flow-toolbar',
    {
      'flow-toolbar-extended': !!toolbarPanelMessage,
    },
    className
  )
  return (
    <ContentPanel className={contentCss} theme={theme}>
      {toolbarPanelMessage && (
        <div className="toolbar-top-panel text-uppercase">
          {toolbarPanelMessage}
        </div>
      )}
      {children}
    </ContentPanel>
  )
}
