import classNames from 'classnames'
import _ from 'lodash'

const MAX_DISPLAYED_PACKAGES = 4

export const PackagesSwitch = ({
  packages,
  selectedIndex,
  onPieceSelected,
}) => {
  const getPackageCss = index =>
    classNames(
      { 'packages-switch-index-active': selectedIndex === index },
      'packages-switch-index'
    )

  const onIndexClicked = index => {
    onPieceSelected(index)
  }

  const onNextClicked = () => {
    if (_.size(packages) > selectedIndex + 1) {
      onPieceSelected(selectedIndex + 1)
    }
  }

  const onPrevSelected = () => {
    if (selectedIndex > 0) {
      onPieceSelected(selectedIndex - 1)
    }
  }

  const showArrows = _.size(packages) > 1
  const firstIndex = Math.max(selectedIndex - MAX_DISPLAYED_PACKAGES + 1, 0)
  const displayedIndices = Math.min(
    firstIndex + MAX_DISPLAYED_PACKAGES,
    _.size(packages)
  )

  return (
    <div className="packages-switch">
      {showArrows && (
        <i
          className="packages-switch-arrow fas fa-caret-square-left"
          onClick={onPrevSelected}
        />
      )}
      {_.map(_.range(firstIndex, displayedIndices), index => (
        <div
          key={index}
          className={getPackageCss(index)}
          onClick={() => onIndexClicked(index)}
        >
          {index + 1}
        </div>
      ))}
      {showArrows && (
        <i
          className="packages-switch-arrow fas fa-caret-square-right"
          onClick={onNextClicked}
        />
      )}
    </div>
  )
}
