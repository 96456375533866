import { useSubscription } from '@apollo/client'
import PromisePool from '@supercharge/promise-pool'
import _ from 'lodash'
import { useIntl } from 'react-intl'

import useJobs from './useJobs'
import { DOCUMENT_ACTION_SUBSCRIPTION } from '../../app/graphQl/schema/Documents'
import Sitemap from '../../app/sitemap'
import { doAuthorizedRequest } from '../../utils/rest-utils'
import { useAuthentication } from '../useAuthentication'

const MAX_CONCURRENT_FILE_UPLOADS = 2
const UploadStatusEnum = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

export function useDocumentsUploadSubscription(
  job,
  documentsToUpload,
  onDocumentChanged
) {
  const [currentUser] = useAuthentication()
  const { formatMessage: f } = useIntl()
  const { updateDocumentsList } = useJobs()
  const jobNumber = job?.id

  useSubscription(DOCUMENT_ACTION_SUBSCRIPTION, {
    variables: { jobNumber },
    skip: !jobNumber,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      _.forEach(data.documentActionUpdated.changeData, action => {
        const document = _.find(
          documentsToUpload,
          doc => doc.actionId === action.id
        )
        if (!document) return

        onDocumentChanged(document, {
          status: action.result || document.result,
          error: action.error || f({ id: 'errors.unknownError' }),
        })

        if (action.documentId) {
          updateDocumentsList(job, [
            ...job.documents,
            {
              id: action.documentId,
              documentType: document.type.id,
              documentTypeDesc: document.type.displayName,
              __typename: 'Document',
            },
          ])
        }
      })
    },
  })

  const uploadFile = async document => {
    if (!document.type?.id) return
    const reqBody = new FormData()
    reqBody.append('docType', document.type?.id)
    reqBody.append('file', document.file.originFileObj)
    onDocumentChanged(document, { status: UploadStatusEnum.PENDING })

    const res = await doAuthorizedRequest(
      currentUser,
      Sitemap.createDocumentFileLink(jobNumber, ''),
      {
        method: 'POST',
        body: reqBody,
      }
    ).catch(() => {
      onDocumentChanged(document, {
        status: UploadStatusEnum.ERROR,
        error: f({ id: 'errors.unknownError' }),
      })
    })

    const json = await res.json()
    if (json.success !== false) {
      onDocumentChanged(document, {
        status: json.result,
        actionId: json.id,
      })
    } else {
      onDocumentChanged(document, {
        status: UploadStatusEnum.ERROR,
        error: json.message,
      })
    }
    return json
  }

  const uploadFiles = async () => {
    const toUpload = documentsToUpload.filter(
      file => !file.status || file.status === UploadStatusEnum.ERROR
    )
    await PromisePool.for(toUpload)
      .withConcurrency(MAX_CONCURRENT_FILE_UPLOADS)
      .process(uploadFile)
  }

  return [uploadFiles]
}
