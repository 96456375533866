import { Button as AntButton } from 'antd'

import { themedClass } from '../../utils/style-utils'
import { HintTooltip } from '../HintTooltip'

export const Button = props => {
  const { disabledTooltip, children, icon, theme, className, ...buttonProps } =
    props
  const css = themedClass('btn-theme', theme, className)
  const button = (
    <AntButton {...buttonProps} className={css}>
      {icon}
      {children}
    </AntButton>
  )

  if (buttonProps.disabled && disabledTooltip) {
    return (
      <HintTooltip tooltip={disabledTooltip} arrow size={'small'}>
        {button}
      </HintTooltip>
    )
  }

  return button
}
