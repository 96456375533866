import { NotificationsTypeEnum } from '../hooks/useNotificationsContext'

export const createResponseNotification = (
  operationName,
  serverMessage,
  errCode,
  type = NotificationsTypeEnum.ERROR
) => ({
  titleId: `errors.${operationName}`,
  messageId: errCode ? `errors.code-${errCode}` : null,
  message: serverMessage,
  type: type,
  global: true,
})
