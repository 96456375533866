export const CURRENCIES = {
  US_DOLLAR: 'USD',
}

export const getCurrencySign = currency => {
  switch (currency) {
    case CURRENCIES.US_DOLLAR:
      return '$'
    default:
      return ''
  }
}
