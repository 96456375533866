import classNames from 'classnames'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useCards } from '../../hooks/useCards'
import { canBeDeclined } from '../../utils/card-utils'
import { NotAllowedIcon } from '../Icons'
import { Button } from '../form/Buttons'
import DeclineJobConfirm from '../modals/DeclineJobConfirm'

const DeclineCardButton = ({ card, afterDeclined, className }) => {
  const { declineCard } = useCards()
  const [modalOpened, setModalOpened] = useState(false)

  const onDeclineClicked = () => {
    setModalOpened(true)
  }

  const onDeclined = comment => {
    declineCard(card, comment)
    afterDeclined && afterDeclined(card)
  }

  const onCanceled = () => {
    setModalOpened(false)
  }

  if (!canBeDeclined(card)) return null

  const btnCss = classNames(className, 'decline-btn')
  return (
    <>
      {modalOpened && (
        <DeclineJobConfirm onDeclined={onDeclined} onCancel={onCanceled} />
      )}
      <Button
        type="primary"
        block
        size="large"
        className={btnCss}
        onClick={onDeclineClicked}
      >
        <NotAllowedIcon />
        <FormattedMessage id="card.decline" />
      </Button>
    </>
  )
}

export default DeclineCardButton
