import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import BlockHeader from '../layout/BlockHeader'

export const JobOverviewSectionHeader = ({
  mainMessage,
  secondaryMessage,
  className,
}) => {
  const css = classNames(className, 'shipment-info-section-header')
  return (
    <BlockHeader className={css}>
      <FormattedMessage id={mainMessage} />
      {secondaryMessage && (
        <small className="text-capitalized">
          &nbsp;(
          <FormattedMessage id={secondaryMessage} />)
        </small>
      )}
    </BlockHeader>
  )
}
