import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

import { CardDestinationsEnum } from '../../app/enums/CardDestinationsEnum'
import { getOfficeLocation, isSubJobCard } from '../../utils/card-utils'
import { formatFlightNumber } from '../../utils/common-utils'
import { formatNullableString } from '../../utils/string-utils'

const getPickupText = cardData => {
  if (isSubJobCard(cardData))
    return formatNullableString(cardData.from?.name).toLowerCase()

  if (!cardData.pickup) return '-'

  switch (cardData.pickup.type) {
    case CardDestinationsEnum.Air:
      return `${cardData.airDestination} (${formatFlightNumber(
        _.head(cardData.flights)
      )})`
    case CardDestinationsEnum.Shipper:
      return formatNullableString(cardData.pickup.name).toLowerCase()
    case CardDestinationsEnum.Office:
      return (
        <FormattedMessage
          id="card.office-destination"
          values={{ stationCode: getOfficeLocation(cardData, cardData.pickup) }}
        />
      )
    default:
      throw new Error('Unknown pickup type!')
  }
}

const getDropText = cardData => {
  if (isSubJobCard(cardData))
    return formatNullableString(cardData.to?.name).toLowerCase()

  if (!cardData.drop) return '-'

  switch (cardData.drop.type) {
    case CardDestinationsEnum.Air:
      return `${cardData.airOrigin} (${formatFlightNumber(
        _.last(cardData.flights)
      )})`
    case CardDestinationsEnum.Consignee:
      return formatNullableString(cardData.drop.name).toLowerCase()
    case CardDestinationsEnum.Office: {
      return (
        <FormattedMessage
          id="card.office-destination"
          values={{ stationCode: getOfficeLocation(cardData, cardData.drop) }}
        />
      )
    }
    default:
      throw new Error('Unknown drop type!')
  }
}

const CardDestinations = ({ card }) => {
  const pickupText = getPickupText(card)
  const dropText = getDropText(card)

  return (
    <p className="card-destination text-capitalized text-ellipsed card-text-highlight">
      <span className="card-pickup" title={_.toUpper(pickupText)}>
        <span className="text-ellipsed">{pickupText}</span>
      </span>
      <span className="text-ellipsed" title={_.toUpper(dropText)}>
        {dropText}
      </span>
    </p>
  )
}

export default CardDestinations
