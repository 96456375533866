import { Modal } from 'antd'
import _ from 'lodash'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useArchivedCards } from '../../hooks/graphQl/useArchivedCards'
import useDriverCards from '../../hooks/graphQl/useDriverCards'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { getCardDate } from '../../utils/common-utils'
import {
  fromServerDate,
  isInRange,
  toCurrentDayRange,
  toCurrentMonthRange,
  toCurrentWeekRange,
  toPreviousDayRange,
  toPreviousMonthRange,
  toPreviousWeekRange,
  toServerDate,
} from '../../utils/date-utils'
import { getDisplayName } from '../../utils/driver-utils'
import { themedClass } from '../../utils/style-utils'
import { ContentPanel } from '../ContentPanel'
import { DateFormats, ShortDateFormat } from '../DateTimeFormats'
import { AngleDownIcon, CalendarIcon, CloseIcon, HistoryIcon } from '../Icons'
import LoadingIndicator from '../LoadingIndicators'
import CardsColumn from '../cards-column/CardsColumn'
import { Button } from '../form/Buttons'
import DateRangePicker from '../form/DateRangePicker'
import BlockHeader from '../layout/BlockHeader'

const dateRangePresets = [
  {
    label: 'date-range.today',
    createRange: toCurrentDayRange,
  },
  {
    label: 'date-range.yesterday',
    createRange: toPreviousDayRange,
  },
  {
    label: 'date-range.curent-week',
    createRange: toCurrentWeekRange,
  },
  {
    label: 'date-range.previous-week',
    createRange: toPreviousWeekRange,
  },

  {
    label: 'date-range.current-month',
    createRange: toCurrentMonthRange,
  },

  {
    label: 'date-range.previous-month',
    createRange: toPreviousMonthRange,
  },
]

const DriverLogFilter = ({ currentRange, onRangeChanged, visible, theme }) => {
  const { getCurrentTime } = useLocalDateTime()
  const [selectedPreset, setSelectedPreset] = useState(0)

  const onButtonClicked = (createRange, index) => {
    const newRange = createRange(getCurrentTime())
    onRangeChanged(newRange)
    setSelectedPreset(index)
  }

  const onRangeChange = range => {
    onRangeChanged(range)
    setSelectedPreset(null)
  }

  if (!visible) return null

  const css = themedClass('driver-log-cards-filter', theme)
  return (
    <div className={css}>
      <DateRangePicker
        range={currentRange}
        onRangeChanged={onRangeChange}
        theme={theme}
      />

      <div>
        {_.map(dateRangePresets, (button, index) => (
          <Button
            onClick={() => onButtonClicked(button.createRange, index)}
            block
            key={button.label}
            size="small"
            type={selectedPreset === index ? 'primary' : 'default'}
          >
            <FormattedMessage id={button.label} />
          </Button>
        ))}
      </div>
    </div>
  )
}

const DriverLog = ({
  driver,
  onClosed,
  wrapperRef,
  onDriverJobClick,
  theme,
}) => {
  const [isVisible, setIsVisible] = useState(true)
  const { getCurrentTime } = useLocalDateTime()

  const [displayFilter, setDisplayFilter] = useState(false)
  const [currentRange, setCurrentRange] = useState(
    toCurrentDayRange(getCurrentTime())
  )

  const { cards, loading: cardsLoading } = useDriverCards(driver, true)
  const { cards: archivedCards, loading: archivedLoading } = useArchivedCards({
    filter: {
      driver: driver?.code,
      from: toServerDate(currentRange.from),
      to: toServerDate(currentRange.to),
    },
    skip: !driver?.code,
  })

  if (!driver) return null

  const onHeaderClick = () => {
    setDisplayFilter(filterVisible => !filterVisible)
  }

  const onCancel = () => {
    setIsVisible(false)
  }

  const onWindowClosed = () => {
    onClosed()
  }

  const filteredCards = _.filter(cards, card =>
    isInRange(fromServerDate(getCardDate(card)), currentRange)
  )

  const onCardsRangeChanged = range => {
    setCurrentRange(range)
    setDisplayFilter(false)
  }

  const allCards = _.compact(_.concat(filteredCards, archivedCards))
  return (
    <Modal
      open={isVisible}
      getContainer={wrapperRef}
      className="driver-log-modal"
      wrapClassName="driver-log-modal-wrap"
      width="100%"
      footer={null}
      onCancel={onCancel}
      afterClose={onWindowClosed}
      closable={false}
    >
      <LoadingIndicator
        className={themedClass('driver-log-panel', theme)}
        loading={cardsLoading || archivedLoading}
      >
        <ContentPanel className="driver-log-handle" theme={theme}>
          <HistoryIcon />{' '}
          <span>
            <FormattedMessage id="drivers.log" />
          </span>
          <CloseIcon
            onClick={onCancel}
            className="driver-log-close-icon text-important clickable-text"
          />
        </ContentPanel>
        <ContentPanel className="driver-log-content" theme={theme}>
          <div className="space-between driver-id">
            <span className="text-capitalized">{getDisplayName(driver)}</span>
            <span>{driver.code}</span>
          </div>
          <div style={{ position: 'relative' }}>
            <BlockHeader
              className="date-time-column-header space-between"
              onClick={onHeaderClick}
            >
              <CalendarIcon />
              <div>
                <ShortDateFormat
                  date={currentRange.from}
                  dateFormat={DateFormats.MDY}
                />
                <span className="text-uppercase">
                  {' '}
                  <FormattedMessage id="misc.to" />{' '}
                </span>
                <ShortDateFormat
                  date={currentRange.to}
                  dateFormat={DateFormats.MDY}
                />
              </div>
              <AngleDownIcon />
            </BlockHeader>

            <DriverLogFilter
              currentRange={currentRange}
              onRangeChanged={onCardsRangeChanged}
              visible={displayFilter}
              theme={theme}
            />
          </div>
          <CardsColumn
            cards={{ nonAlarmed: allCards }}
            renderDateDividers
            cardClicked={onDriverJobClick}
            reverseOrder
            theme={theme}
          />
        </ContentPanel>
      </LoadingIndicator>
    </Modal>
  )
}

export default DriverLog
