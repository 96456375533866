import classNames from 'classnames'
import { createContext, useCallback, useEffect, useRef, useState } from 'react'

import { DriverDragType } from './drivers/DriverCard'
import DispatchMap, { MapViewTypesEnum } from './maps/DispatchMap'
import AcknowledgeInstructions from './modals/AcknowledgeInstructions'
import AssignDriverConfirm from './modals/AssignDriverConfirm'
import { MapStyles } from '../app/constants'
import useDropWithContext from '../hooks/dragAndDrop/useDropWithContext'
import useGetDriver from '../hooks/graphQl/useGetDriver'
import { useActiveStation } from '../hooks/useActiveStation'
import { useDriverAssignContext } from '../hooks/useDriversAssignContext'
import useWindowSize from '../hooks/useWindowSize'
import { needAcknowledgeInstructions } from '../utils/card-utils'
import { MapHover } from '../utils/drag-utils'
import { themedClass } from '../utils/style-utils'

const needSmallOverlay = (windowHeight, mapStyle) => {
  if (mapStyle === MapStyles.Maximized) return false
  if (mapStyle === MapStyles.Expanded) return true
  return windowHeight < 940 ? false : true
}

export const DriversContext = createContext()

export const DriversContextProvider = ({ children, value }) => (
  <DriversContext.Provider value={value}>{children}</DriversContext.Provider>
)

const useDropToMap = (onDriverDropped, canDrop) =>
  useDropWithContext(MapHover, {
    accept: DriverDragType,
    drop: item => {
      onDriverDropped(item.data)
    },
    collect: monitor => {
      const item = monitor.getItem()
      return {
        draggedDriver: monitor.isOver() && monitor.canDrop() && item?.data,
      }
    },
    canDrop: canDrop,
  })

const DriverAssigmentModule = ({
  cardDetail,
  afterConfirmed,
  children,
  theme,
}) => {
  const { currentStation } = useActiveStation()
  const [droppedDriver, setDroppedDriver] = useState()
  const mapContainerRef = useRef(null)
  const { mapStyle, setMapStyle, estimatedArrivals, driversChecking } =
    useDriverAssignContext()
  const [, windowSize] = useWindowSize()

  const cardDriver = useGetDriver(cardDetail?.driver?.id)

  const { selectedItems: checkedDrivers, actions: checkActions } =
    driversChecking

  const [{ draggedDriver }, drop] = useDropToMap(
    setDroppedDriver,
    () => !!cardDetail
  )

  useEffect(() => {
    if (!cardDetail) {
      setDroppedDriver(null)
    }
  }, [cardDetail])

  const onAssignModalClosed = useCallback(
    assigned => {
      setDroppedDriver(null)
      if (assigned) {
        checkActions.deselectAll()
        afterConfirmed && afterConfirmed()
      }
    },
    [afterConfirmed, checkActions]
  )

  const onWarningClosed = () => {
    setDroppedDriver(null)
  }

  const getMapView = () => {
    if (draggedDriver || droppedDriver)
      return {
        mapView: MapViewTypesEnum.FULL_ROUTE,
        currentDriver: draggedDriver || droppedDriver,
      }

    if (cardDetail && cardDetail.driver && !checkedDrivers.length) {
      if (cardDriver) {
        return {
          mapView: MapViewTypesEnum.FULL_ROUTE,
          currentDriver: cardDriver,
        }
      }
    }
    return { mapView: MapViewTypesEnum.MULTIPLE_DRIVERS }
  }

  const currentCoords = currentStation ? currentStation.gpsCoordinates : null
  const pickupCoords = cardDetail?.pickup?.position || null
  const dropCoords = cardDetail?.drop?.position || null

  const { mapView, currentDriver } = getMapView()
  const mapPanelCss = classNames('multi-row-panel', {
    maximized: mapStyle === MapStyles.Maximized,
  })

  const setMapRefs = useCallback(
    e => {
      drop(e)
      mapContainerRef.current = e
    },
    [drop]
  )

  const needAcknowledge = needAcknowledgeInstructions(
    cardDetail?.specialInstructions
  )

  const mapCss = themedClass('dispatch-map-container', theme)

  return (
    <div className="dispatch-driver-assigment">
      {cardDetail && droppedDriver && !needAcknowledge && (
        <AssignDriverConfirm
          smallOverlay={needSmallOverlay(windowSize.height, mapStyle)}
          card={cardDetail}
          currentCardStack={cardDetail.stack}
          driver={droppedDriver}
          onClosed={onAssignModalClosed}
        />
      )}
      {cardDetail && droppedDriver && needAcknowledge && (
        <AcknowledgeInstructions
          containerRef={mapContainerRef.current}
          onClosed={onWarningClosed}
        />
      )}
      <div className={mapPanelCss}>
        {children}

        <div className={mapCss} ref={setMapRefs}>
          <DispatchMap
            mapStyle={mapStyle}
            onMapStyleChanged={setMapStyle}
            estimatedArrivals={estimatedArrivals}
            defaultCenter={currentCoords}
            pickDestination={pickupCoords}
            dropDestination={dropCoords}
            vehicles={driversChecking.selectedItems}
            currentDriver={currentDriver}
            mapView={mapView}
          />
        </div>
      </div>
    </div>
  )
}

export default DriverAssigmentModule
