import { useQuery } from '@apollo/client'
import { AutoComplete } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { useState } from 'react'
import { useIntl } from 'react-intl'

import { GET_CARDS } from '../../app/graphQl/schema/Cards'
import { useArchivedCards } from '../../hooks/graphQl/useArchivedCards'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { getStationVendorLabel } from '../../utils/common-utils'
import { themedClass } from '../../utils/style-utils'
import LoadingIndicator from '../LoadingIndicators'
import ScrollArea from '../ScrollArea'
import SearchBox from '../form/SearchBox'

const MIN_SEARCH_LENGTH = 3

const debouncedFunc = _.debounce((val, updateFunc) => updateFunc(val), 300)

const CardsSearch = ({ onCardOpen }) => {
  const { formatMessage: f } = useIntl()
  const [cardsFilter, changeCardFilter] = useState('')
  const [theme] = useSelectedTheme()

  const { data: cardsData, loading } = useQuery(GET_CARDS, {
    skip: _.size(cardsFilter) < MIN_SEARCH_LENGTH,
    variables: {
      filter: { jobId: cardsFilter },
    },
  })
  const { cards: archivedCards, loading: archivedLoading } = useArchivedCards({
    filter: {
      cardId: cardsFilter + ']',
    },
    skip: _.size(cardsFilter) < MIN_SEARCH_LENGTH,
  })

  const filteredCards = _.uniqBy(
    _.concat(archivedCards, cardsData?.listCards || []),
    'id'
  )

  const onSelected = val => {
    onCardOpen(_.find(filteredCards, c => c.id === val))
    debouncedFunc('', changeCardFilter)
  }

  const onJobFilterChanged = val => {
    debouncedFunc(val, changeCardFilter)
  }

  const renderDropdown = menu => {
    const isloading = archivedLoading || loading
    return (
      <LoadingIndicator
        loading={isloading}
        className={themedClass('main-header-popover', theme)}
      >
        <div className="autocomplete-dropdown">
          <ScrollArea autoHeight autoHeightMax={175} style={{ width: 200 }}>
            {menu}
          </ScrollArea>
        </div>
      </LoadingIndicator>
    )
  }

  return (
    <AutoComplete
      options={_.map(_.sortBy(filteredCards, 'id'), opt => ({
        label: (
          <div
            className={classNames(
              'autocomplete-item-bordered',
              'space-between'
            )}
          >
            <span>
              {opt.function}-{opt?.jobNumber}
            </span>
            <span>{getStationVendorLabel(opt?.station)}</span>
          </div>
        ),
        value: opt.id,
      }))}
      onSelect={onSelected}
      onChange={onJobFilterChanged}
      defaultActiveFirstOption
      dropdownMatchSelectWidth={false}
      popupClassName="cards-autocomplete"
      dropdownRender={renderDropdown}
      value={cardsFilter}
    >
      <SearchBox
        placeholder={f({ id: 'inputs.search-placeholder' })}
        value={cardsFilter}
      />
    </AutoComplete>
  )
}

export default CardsSearch
