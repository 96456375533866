import { useEffect, useState } from 'react'

function useEqualState(defaultValue, equalityFunction) {
  const [currentValue, setCurrentValue] = useState(defaultValue)

  useEffect(() => {
    if (!equalityFunction(currentValue, defaultValue)) {
      setCurrentValue(defaultValue)
    }
  }, [currentValue, defaultValue, equalityFunction])

  return currentValue
}

export default useEqualState
