import classNames from 'classnames'

import { missingDataPlaceholder } from '../utils/string-utils'

export const TextImportant = ({ children, className }) => {
  const css = classNames('text-important', className)
  return <strong className={css}>{children}</strong>
}

export const TextDangerous = ({ children, className }) => {
  const css = classNames('text-danger', className)
  return <strong className={css}>{children}</strong>
}

export const NotImplementedField = () => missingDataPlaceholder
