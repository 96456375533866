import classNames from 'classnames'

import { useCardContext } from '../../hooks/useCardPopupContext'
import Transition, { TransitionTypes } from '../Transition'

const getAnimationType = (currentPage, prevPage) =>
  currentPage > prevPage
    ? TransitionTypes.SlideFromRight
    : TransitionTypes.SlideFromLeft

const FlowTransition = ({ page, children, className }) => {
  const { currentPage, prevPage } = useCardContext()
  const animation = getAnimationType(
    page,
    prevPage === page ? currentPage : prevPage
  )

  const css = classNames('flow-grid', className)
  return (
    <Transition opened={currentPage === page} type={animation} className={css}>
      {children}
    </Transition>
  )
}

export default FlowTransition
