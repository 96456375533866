import { ConfigProvider } from 'antd'
import enUS from 'antd/es/locale/en_US'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { IntlProvider } from 'react-intl'
import { Redirect } from 'react-router-dom'

import { LoadingBox } from './LoadingBox'
import PrivateSection from './PrivateSection'
import { PAGE_TITLE } from '../app/constants'
import { messages } from '../app/localization'
import Sitemap from '../app/sitemap'
import { SVGPatterns } from '../components/SVGGraphics'
import ChangelogModal from '../components/version-info/ChangelogModal'
import { useActiveStation } from '../hooks/useActiveStation'
import { useAuthentication } from '../hooks/useAuthentication'
import { useCurrentStation } from '../hooks/useCurrentStation'
import { useSelectedTheme } from '../hooks/useUserSettings'
import { getStationVendorLabel } from '../utils/common-utils'
import { themedClass } from '../utils/style-utils'

const currentLocale = enUS.locale

const ContentRoot = () => {
  const [theme] = useSelectedTheme()
  const { stationId } = useCurrentStation()
  const { currentStation, changeStation } = useActiveStation()
  const [currentUser] = useAuthentication()
  const [loadingClosed, setLoadingClosed] = useState(false)

  useEffect(() => {
    if (currentUser && stationId) {
      changeStation(stationId)
    }
  }, [currentUser, stationId, changeStation])

  useEffect(() => {
    moment.tz.setDefault(currentStation?.timeZone)
  }, [currentStation])

  if (!currentUser) return <Redirect to={Sitemap.login} />

  const pageTitle = currentStation?.defaultVendor
    ? `${getStationVendorLabel(currentStation)} - ${PAGE_TITLE}`
    : PAGE_TITLE

  const bodyCss = themedClass('main-body', theme)
  const footerCss = themedClass('footer', theme)

  return (
    <IntlProvider
      locale={currentLocale}
      messages={messages[currentLocale]}
      timeZone={currentStation?.timeZone}
    >
      <LoadingBox onClosed={() => setLoadingClosed(true)} />

      <ConfigProvider locale={enUS}>
        <SVGPatterns />
        {currentStation && (
          <>
            <Helmet defer={false}>
              <title>{pageTitle}</title>
            </Helmet>
            <div className={bodyCss}>{loadingClosed && <PrivateSection />}</div>
          </>
        )}
      </ConfigProvider>
      <div className={footerCss}>
        <ChangelogModal />
      </div>
    </IntlProvider>
  )
}

export default ContentRoot
