import { useQuery } from '@apollo/client'
import _ from 'lodash'

import { useWatchedQuery } from './useWatchedQuery'
import { MilestonesEnum } from '../../app/enums/MilestonesEnum'
import { GET_CARDS, GET_DRIVER_CARDS } from '../../app/graphQl/schema/Cards'
import { useCardsFilterQuery } from '../useCardsFilter'

const useDriverCards = (driver, allCards = false) => {
  const { data, loading, error, refetch } = useQuery(GET_DRIVER_CARDS, {
    partialRefetch: true,
    skip: !driver,
    variables: { driverId: driver?.id },
    fetchPolicy: 'no-cache',
  })

  const cardsVariables = useCardsFilterQuery()
  useWatchedQuery(GET_CARDS, cardsVariables, refetch)

  let cards = data?.getDriverCards

  if (!allCards)
    cards = _.filter(
      data?.getDriverCards,
      card => card.milestone.code === MilestonesEnum.LOADED
    )

  return { cards, loading, error }
}

export default useDriverCards
