import { JobActionDate } from './JobActionDate'
import { JobNumberPanel } from './JobNumberPanel'
import { JobOverviewSectionHeader } from './JobOverviewSectionHeader'
import WaypointLine from './WaypointLine'
import { useCardContext } from '../../hooks/useCardPopupContext'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { extractTopParent } from '../../utils/card-utils'
import { themedClass } from '../../utils/style-utils'
import { ContentPanel } from '../ContentPanel'
import RequestedVehicleType from '../RequestedVehicleType'
import ScrollArea from '../ScrollArea'
import { CommodityInfo } from '../shipment-information_v2/CommodityInfo'
import DropInfo from '../shipment-information_v2/DropInfo'
import PickUpInfo from '../shipment-information_v2/PickUpInfo'

export const JobOverview = () => {
  const { currentJob: job, currentCard } = useCardContext()
  const [theme] = useSelectedTheme()

  const jobDetail = job
  const rowClass = 'shipment-info-row'
  const card = extractTopParent(currentCard)
  return (
    <div className="flow-grid-column job-overview-column">
      <JobNumberPanel card={card} theme={theme} />

      <ContentPanel className="shipment-info-content-panel" theme={theme}>
        <ScrollArea>
          <div className={themedClass('shipment-info-vertical', theme)}>
            <div className="shipment-info-requested-vehicle">
              <RequestedVehicleType card={card} />
            </div>
            {/* {pickup info} */}
            <div style={{ position: 'relative' }}>
              <WaypointLine card={currentCard} theme={theme} />
              <JobOverviewSectionHeader
                mainMessage="shipment-info.from"
                secondaryMessage="shipment-info.pickup"
                className="shipment-info-section-header-first"
              />
              <JobActionDate badge={card?.jobBadge1} theme={theme} />
              <PickUpInfo
                card={card}
                job={jobDetail}
                rowClass={rowClass}
                fixedRowsCount={false}
              />
            </div>

            {/* {deliver info} */}
            <JobOverviewSectionHeader
              mainMessage="shipment-info.to"
              secondaryMessage="shipment-info.deliver"
            />
            <JobActionDate badge={card?.jobBadge2} theme={theme} />
            <DropInfo
              card={card}
              job={jobDetail}
              rowClass={rowClass}
              fixedRowsCount={false}
            />

            {/* {shipment info} */}
            <JobOverviewSectionHeader mainMessage="shipment-info.information" />
            <CommodityInfo
              card={card}
              job={jobDetail}
              rowClass={rowClass}
              addDangerousGoodsInfo
            />
          </div>
        </ScrollArea>
      </ContentPanel>
    </div>
  )
}

export default JobOverview
