import _ from 'lodash'
import { generatePath } from 'react-router'
import { NavLink, useRouteMatch } from 'react-router-dom'

import { ThemesEnum } from '../../app/enums/Themes'
import { useActiveStation } from '../../hooks/useActiveStation'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { getStationVendorLabel } from '../../utils/common-utils'
import { themedClass } from '../../utils/style-utils'
import VerticalList from '../VerticalList'
import MainHeaderPopover from '../main-header/MainHeaderPopover'

function getStationLabel(station) {
  if (!station) return ''
  return getStationVendorLabel(station)
}

const StationItem = ({ item }) => {
  const { path } = useRouteMatch()
  return (
    <NavLink
      activeClassName=""
      className="text-capitalized"
      key={item.id}
      to={generatePath(path, {
        station: item.id,
      })}
    >
      {getStationLabel(item)}
    </NavLink>
  )
}

const StationsSelection = ({ stations, activeStation }) => (
  <VerticalList
    items={stations}
    ItemComponent={StationItem}
    isActive={i => activeStation === i.id}
    className="vertical-list-transparent"
    theme={ThemesEnum.DARK}
  />
)

export const StationSelect = ({ stations }) => {
  const { currentStation } = useActiveStation()
  const [theme] = useSelectedTheme()

  return (
    <MainHeaderPopover
      content={
        <StationsSelection
          stations={_.sortBy(stations, s => s.defaultVendor)}
          activeStation={currentStation?.id}
          theme={theme}
        />
      }
      placement="bottomLeft"
      overlayClassName={themedClass('stations-menu', theme)}
      trigger="click"
      className="clickable-text"
    >
      <i className="fas fa-globe main-header-icon" />
      <span className="text-capitalized">
        {getStationLabel(currentStation)}
      </span>
    </MainHeaderPopover>
  )
}
