import {
  geIntValue,
  getStringValue,
  getTransitionDuration,
} from '../utils/style-utils'

export const CardNotificationDuration = 5000

export const PAGE_TITLE = 'Quick Driver Dispatch'

export const CssValues = {
  get markerColor() {
    return getStringValue('--driver-icon-default')
  },

  get markerColorIC() {
    return getStringValue('--driver-icon-contractor')
  },

  get markerColorDark() {
    return getStringValue('--driver-icon-default-dark')
  },

  get markerColorICDark() {
    return getStringValue('--driver-icon-contractor-dark')
  },

  get transitionDuration() {
    return getTransitionDuration('--transition-duration')
  },

  get pageTransitionDuration() {
    return getTransitionDuration('--page-transition-duration')
  },

  get cardRemovalDuration() {
    return getTransitionDuration('--card-removal-anim-duration')
  },

  get cardSelectedBorderWidth() {
    return geIntValue('--card-selected-border-width')
  },

  get modalMaskDarkTheme() {
    return getStringValue('--modal-mask-dark-theme')
  },
}

export const ColumnsEnum = {
  NEWJOBS: 'NEW JOBS',
  DECIDE: 'DECIDE',
  PICK: 'PICK UP',
  DELIVERY: 'DELIVERY',
  TRANSFER: 'TRANSFER',
  ONDOCK: 'ON DOCK',
}

export const DriverStatusesEnum = {
  NONE: 'NONE',
  PENDING: 'PENDING',
  LOADED: 'LOADED',
}

export const CARD_MILESTONES_ENUM = {
  LOADED: 'LOADED',
}

export const MapStyles = {
  Default: 0,
  Expanded: 1,
  Maximized: 2,
}

// user presets values
export const LanguagesEnum = {
  ENGLISH: {
    label: 'EN',
    name: 'English (EN)',
    locale: 'enUS',
  },
  SPANISH: {
    label: 'ES',
    name: 'Spanish (ES)',
    locale: 'enUS',
  },
  FRENCH: {
    label: 'FR',
    name: 'French (FR)',
    locale: 'enUS',
  },
  GERMAN: {
    label: 'DE',
    name: 'German (DE)',
    locale: 'enUS',
  },
}

export const UnitsSystemEnum = {
  IMPERIAL: {
    label: 'IMP',
    name: 'Imperial (IMP)',
  },
  METRIC: {
    label: 'MTC',
    name: 'Metric (MTC)',
  },
}

export const TemperatureScaleEnum = {
  FAHRENHEIT: { label: 'F', name: 'Fahrenheit (F)' },
  CELSIUS: { label: 'C', name: 'Celsius (C)' },
}

export const UserDateFormatsEnum = {
  MDY: { label: 'MDY', name: 'MM/DD/YYYY (MDY)' },
  DMY: { label: 'DMY', name: 'DD/MM/YYYY (DMY)' },
}

export const OperationTypeEnum = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}

export const DispatchPageConstants = {
  viewGroupId: 'view',
  statusGroupId: 'status',
  unassignedItemId: 'unassigned',
}
