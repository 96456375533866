import { InfoWindow } from '@react-google-maps/api'
import { Button as AntButton } from 'antd'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'

import GoogleMapsComponent from './GoogleMapsComponent'
import { FullRouteView, MultipleDriversMapView } from './MapViews'
import { MapStyles } from '../../app/constants'
import { ETATypes } from '../../app/enums/EtaTypes'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { AssignedCardsMapPopover } from '../drivers/AssignedCardsPopover'
import { Button } from '../form/Buttons'
import { SwitchControl } from '../form/SwitchControl'

const ButtonGroup = AntButton.Group

export const MapViewTypesEnum = {
  MULTIPLE_DRIVERS: 0,
  FULL_ROUTE: 1,
  ROUTE_IN_PROGRESS: 2,
}

const MapControls = ({
  mapStyle,
  etaType,
  onMapStyleChanged,
  onEtaTypeChanged,
  theme,
}) => {
  const onMaxViewClick = () => {
    onMapStyleChanged(MapStyles.Maximized)
  }

  const onExpandClick = () => {
    onMapStyleChanged(MapStyles.Expanded)
  }

  const onDefaultClick = () => {
    onMapStyleChanged(MapStyles.Default)
  }

  const onEtaChanged = checked => {
    onEtaTypeChanged(checked ? ETATypes.JobEta : ETATypes.PickEta)
  }

  const expandIcon =
    mapStyle === MapStyles.Expanded ? 'fa-compress' : 'fa-expand'

  return (
    <>
      <ButtonGroup>
        <SwitchControl
          onChange={onEtaChanged}
          size={'small'}
          checkedLabel="PICK-UP ETA"
          uncheckedLabel="JOB ETA"
          checked={etaType === ETATypes.JobEta}
          theme={theme}
        />
        {mapStyle !== MapStyles.Maximized ? (
          <>
            <Button ghost onClick={onMaxViewClick}>
              <i className="fas fa-external-link-alt" />
            </Button>
            <Button ghost onClick={onExpandClick}>
              <i className={`fas ${expandIcon}`} />
            </Button>
          </>
        ) : (
          <Button ghost onClick={onDefaultClick}>
            <i className="far fa-times-circle" />
          </Button>
        )}
      </ButtonGroup>
    </>
  )
}

const DispatchMap = ({
  defaultCenter,
  pickDestination,
  dropDestination,
  currentDriver,
  vehicles,
  estimatedArrivals,
  onMapStyleChanged,
  mapStyle,
  mapView,
}) => {
  const [activeMarker, setActiveMarker] = useState(null)
  const [hiddenPopover, setHiddenPopover] = useState(false)
  const [center, setCenter] = useState(defaultCenter)
  const [etaType, setEtaType] = useState(ETATypes.JobEta)
  const [theme] = useSelectedTheme()

  useEffect(() => {
    setCenter(defaultCenter)
  }, [defaultCenter])

  useEffect(() => {
    const timerID = setInterval(() => {
      if (hiddenPopover) {
        setActiveMarker(null)
        setHiddenPopover(false)
      }
    }, 500)

    return function cleanup() {
      clearInterval(timerID)
    }
  }, [hiddenPopover])

  const onMarkerHover = useCallback((marker, driver) => {
    const markerSize = marker.div.getBoundingClientRect()
    setActiveMarker({ marker, driver, markerSize })
    setHiddenPopover(false)
  }, [])

  const onMapPopupHover = () => {
    setHiddenPopover(false)
  }

  const onMarkerHoverEnd = useCallback(() => {
    setHiddenPopover(true)
  }, [])

  let mapViewComponent
  switch (mapView) {
    case MapViewTypesEnum.MULTIPLE_DRIVERS:
      mapViewComponent = (
        <MultipleDriversMapView
          pickDestination={pickDestination}
          dropDestination={dropDestination}
          vehicles={vehicles}
          estimatedArrivals={estimatedArrivals}
          onMarkerHover={onMarkerHover}
          onMarkerHoverEnd={onMarkerHoverEnd}
          etaType={etaType}
        />
      )
      break

    case MapViewTypesEnum.FULL_ROUTE:
      mapViewComponent = (
        <FullRouteView
          pickDestination={pickDestination}
          dropDestination={dropDestination}
          onMarkerHover={onMarkerHover}
          onMarkerHoverEnd={onMarkerHoverEnd}
          estimatedArrivals={estimatedArrivals}
          vehicle={currentDriver}
          etaType={etaType}
        />
      )
      break
    default:
      mapViewComponent = null
  }

  return (
    <GoogleMapsComponent center={center} theme={theme}>
      <div className="map-controls">
        <MapControls
          mapStyle={mapStyle}
          onMapStyleChanged={onMapStyleChanged}
          etaType={etaType}
          onEtaTypeChanged={setEtaType}
          theme={theme}
        />
      </div>

      {activeMarker && (
        <InfoWindow
          key={activeMarker.driver.id}
          anchor={activeMarker.marker}
          options={{
            pixelOffset: { width: 0, height: -activeMarker.markerSize.height },
          }}
        >
          <AssignedCardsMapPopover
            driver={activeMarker.driver}
            theme={theme}
            onMouseOver={onMapPopupHover}
            onMouseLeave={onMarkerHoverEnd}
          />
        </InfoWindow>
      )}

      {mapViewComponent}
    </GoogleMapsComponent>
  )
}

MapControls.propTypes = {
  onMapStyleChanged: PropTypes.func.isRequired,
}

DispatchMap.propTypes = {
  onMapStyleChanged: PropTypes.func.isRequired,
  defaultCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  pickDestination: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  dropDestination: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  vehicles: PropTypes.array,
  currentDriver: PropTypes.object,
  mapStyle: PropTypes.oneOf([
    MapStyles.Default,
    MapStyles.Expanded,
    MapStyles.Maximized,
  ]).isRequired,
  mapView: PropTypes.oneOf([
    MapViewTypesEnum.MULTIPLE_DRIVERS,
    MapViewTypesEnum.FULL_ROUTE,
    MapViewTypesEnum.ROUTE_IN_PROGRESS,
  ]).isRequired,
}

export default DispatchMap
