import { useIntl } from 'react-intl'

import ThemableModal from './ThemableModal'
import { useBackgroundBlur } from '../../hooks/useBackgroundBlur'
import { themedClass } from '../../utils/style-utils'
import { WarningIcon } from '../Icons'

export const WarningWindow = ({ header, body, onClosed, visible, theme }) => {
  const { formatMessage: f } = useIntl()
  useBackgroundBlur(visible)

  return (
    <ThemableModal
      className={themedClass('warning-modal', theme)}
      title={header}
      open={visible}
      closable={false}
      width={470}
      cancelText={f({ id: 'close' })}
      onCancel={onClosed}
      centered={true}
      cancelButtonProps={{ ghost: true }}
      okButtonProps={{ hidden: true }}
      destroyOnClose={true}
      theme={theme}
    >
      <div>
        <WarningIcon className="text-warning" />
        <div className={'warning-text'}>{body}</div>
      </div>
    </ThemableModal>
  )
}

export default WarningWindow
