import classNames from 'classnames'
import _ from 'lodash'
import { forwardRef, memo, useCallback, useEffect, useState } from 'react'

import { GroupHeader, ManifestHeader } from './CardHeaders'
import MultiPartCard from './MultiPartCard'
import { useCardGroupDrop } from '../../hooks/useCardGroupDrop'
import {
  getAirwayBill,
  getCardsGroupSize,
  hasAlarmFired,
  isHighlighted,
  isInManifest,
} from '../../utils/card-utils'

const CardsGroup = (props, ref) => {
  const {
    data,
    onGroupCards,
    header,
    countBadge,
    highlightInfo,
    onClick,
    selectedChildren,
    forceExpand,
    onSplitCardsGroup,
    onCardExpandToggle,
  } = props
  const [expanded, setExpanded] = useState(false)
  const [isHovered, cardDrop] = useCardGroupDrop(data, onGroupCards)

  useEffect(() => {
    if (forceExpand) setExpanded(true)
  }, [forceExpand])

  const onCardExpand = useCallback(
    e => {
      setExpanded(e => !e)
      onCardExpandToggle && onCardExpandToggle(e)
      e.preventDefault()
      e.stopPropagation()
    },
    [onCardExpandToggle]
  )

  const onCloseClick = useCallback(
    e => {
      onSplitCardsGroup &&
        onSplitCardsGroup([...data.groupChildren, data], data)
      e.preventDefault()
      e.stopPropagation()
    },
    [data, onSplitCardsGroup]
  )

  const onGroupedCardClick = useCallback(
    (card, event, parent) => {
      onClick && onClick(card, event, parent)
    },
    [onClick]
  )

  /* reversing and ordering using css (so upper card has shadow "over" lower one) */
  const expandedCards = _.reverse([...data.groupChildren])
  const isGroupSelected = _.some(selectedChildren)

  const groupSize = countBadge || getCardsGroupSize(data, data.groupChildren)
  const groupCss = classNames(
    { 'card-group-collapsed': !expanded },
    { 'card-group-expanded': expanded },
    { 'cards-list': expanded },
    { 'card-highlight': isHovered },
    { 'card-selected': !expanded && (isGroupSelected || props.selected) }
  )
  const getHeader = () => {
    if (header) return header
    const isManifest = isInManifest(data)
    const alarmedHeader =
      hasAlarmFired(data) || _.some(data.groupChildren, hasAlarmFired)
    const dangerousGoods =
      data.isDangerousGoods ||
      (!expanded && _.some(data.groupChildren, g => g.isDangerousGoods))

    if (isManifest) {
      return (
        <ManifestHeader
          onToggleClick={onCardExpand}
          expanded={expanded}
          onCloseClick={onCloseClick}
          alarmed={alarmedHeader}
          dangerousGoods={dangerousGoods}
          flightManifest={getAirwayBill(data)}
          cardsCount={groupSize}
        />
      )
    }

    return (
      <GroupHeader
        onToggleClick={onCardExpand}
        expanded={expanded}
        onCloseClick={onCloseClick}
        alarmed={alarmedHeader}
        dangerousGoods={dangerousGoods}
      />
    )
  }

  const groupHeader = getHeader()

  return (
    <div className={groupCss} ref={ref}>
      {expanded &&
        expandedCards.map(g => (
          <MultiPartCard
            {...props}
            onClick={onGroupedCardClick}
            data={g}
            key={g.id}
            selected={_.some(selectedChildren, c => c.id === g.id)}
            forceExpand={true}
            highlighted={isHighlighted(g, highlightInfo)}
          />
        ))}

      <MultiPartCard
        {...props}
        ref={cardDrop}
        countBadge={expanded ? null : groupSize}
        header={groupHeader}
        forceExpand={expanded}
        highlighted={isHighlighted(data, highlightInfo)}
      />
    </div>
  )
}

export default memo(forwardRef(CardsGroup), _.isEqual)
