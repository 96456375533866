import classNames from 'classnames'

import NotImplementedWarning from './NotImplementedWarning'
import { CardPopupState } from '../../app/enums/CardPopupState'
import { MoveDirectionsEnum } from '../../app/enums/MoveDirectionsEnum'
import { useCardContext } from '../../hooks/useCardPopupContext'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { themedClass } from '../../utils/style-utils'

const FlowControlArrow = ({ direction, onClick, enabled }) => {
  const onArrowClicked = () => {
    if (enabled) {
      onClick()
    }
  }

  const css = classNames('flow-control-arrow', {
    'flow-control-arrow-disabled': !enabled,
  })

  if (direction === MoveDirectionsEnum.Back) {
    return (
      <div className={css} onClick={onArrowClicked}>
        <i className="fas fa-caret-square-left" />
      </div>
    )
  }
  if (direction === MoveDirectionsEnum.Forward) {
    return (
      <div className={css} onClick={onArrowClicked}>
        <i className="fas fa-caret-square-right" />
      </div>
    )
  }
}

const FlowControls = () => {
  const { canGoBack, canGoForward, moveBack, moveForward, currentPage } =
    useCardContext()
  const [theme] = useSelectedTheme()

  const showNotImplementedWarning = [
    CardPopupState.ReIce.Page,
    CardPopupState.ReIceEdit.Page,
  ].includes(currentPage)
  return (
    <div className={themedClass('flow-controls', theme)}>
      <FlowControlArrow
        direction={MoveDirectionsEnum.Back}
        onClick={moveBack}
        enabled={canGoBack}
      />

      <FlowControlArrow
        direction={MoveDirectionsEnum.Forward}
        onClick={moveForward}
        enabled={canGoForward}
      />

      {showNotImplementedWarning && <NotImplementedWarning />}
    </div>
  )
}

export default FlowControls
