import { toServerDate } from './date-utils'

export function convertFilterToQuery(filter, station) {
  const { from, to } = filter
  return {
    filter: {
      // jobId: filter.jobId,
      from: from ? toServerDate(from) : null,
      to: to ? toServerDate(to) : null,
      station,
    },
  }
}
