import { FormattedMessage } from 'react-intl'

import { formatNullableString } from '../../utils/string-utils'

export const DistanceUnits = {
  Miles: 'units.mile',
  Kilometres: 'units.kilometer',
}

const SelectedDistanceUnit = ({ value }) => (
  // todo: read unit selected by user on top of page
  <DistanceUnit
    value={value}
    unit={DistanceUnits.Miles}
    // sourceUnit={sourceUnit}
  />
)

export const DistanceUnit = ({ value, unit }) => (
  // TODO convert from sourceUnit to unit
  <FormattedMessage
    id={unit}
    defaultMessage={unit}
    values={{ value: formatNullableString(value) }}
  />
)

export default SelectedDistanceUnit
