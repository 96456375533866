import { Upload } from 'antd'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

import { themedClass } from '../../utils/style-utils'

const { Dragger } = Upload

const FileUpload = props => {
  const filesToUpload = props.fileList
  const renderPreview = props.renderPreview
  const theme = props.theme
  return (
    <div className={themedClass('file-uploader', theme)}>
      <Dragger {...props} fileList={filesToUpload}>
        <p className="ant-upload-hint">
          <FormattedMessage id={props.uploadHintId} />
        </p>
      </Dragger>

      {_.some(filesToUpload) && (
        <div className="file-uploader-list">
          {_.map(filesToUpload, file => renderPreview(file))}
        </div>
      )}
    </div>
  )
}

export default FileUpload
