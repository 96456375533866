import { Collapse } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { CardsDateDivider, ColumnDividerString } from './CardsColumnDivider'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { getTotalCount, hasAlarmFired } from '../../utils/card-utils'
import { getCardDate } from '../../utils/common-utils'
import { getStartOfDay, toServerDate } from '../../utils/date-utils'
import { emptyArray, makeEqualFn } from '../../utils/perf-utils'
import CardsColumnInner from '../CardsColumnInner'
import { ExpandIcon } from '../Icons'
import DraggableJobCard from '../job-cards/DraggableJobCard'

const { Panel } = Collapse

const PanelKeys = {
  CTUpdate: 'CTUPDATE',
  ALARMS: 'ALARMS',
}

function getSelectionProps(card, currentSelection, groupChildren) {
  if (!currentSelection) {
    return { selected: false, selectedChildren: emptyArray }
  }
  let selectedChildren
  let isCardSelected

  if (currentSelection.selectedParent) {
    isCardSelected = false
    selectedChildren = _.intersectionBy(
      currentSelection.selectedCards,
      [
        ...(card.subjobs || []),
        ..._.flatMap(groupChildren, g => g.subjobs || []),
      ],
      'id'
    )
  } else {
    isCardSelected = _.some(
      currentSelection.selectedCards,
      x => x.id === card.id
    )
    selectedChildren = _.intersectionBy(
      currentSelection.selectedCards,
      groupChildren,
      'id'
    )
  }

  if (_.isEmpty(selectedChildren)) selectedChildren = emptyArray

  return { selected: isCardSelected, selectedChildren }
}

function groupCards(cards, parseDateFunc) {
  let alarmedCards = cards?.alarmed || []
  let changedCards = cards?.changed || []
  let cardsToRender = cards?.nonAlarmed || []

  const dateGrouped = _.groupBy(cardsToRender, x =>
    toServerDate(getStartOfDay(parseDateFunc(getCardDate(x))))
  )

  return {
    alarmedCards,
    changedCards,
    dateGrouped,
  }
}

const AlarmedPanelHeader = ({ openedAlarmed, alarmedCards }) => {
  const messageKey = openedAlarmed
    ? 'card-columns.alarmed-expanded'
    : 'card-columns.alarmed-collapsed'

  return (
    <div className="alarmed-cards-collapse-header">
      <ExpandIcon expanded={openedAlarmed} />
      <FormattedMessage
        id={messageKey}
        values={{
          alarmsCount: getTotalCount(alarmedCards),
        }}
      />
    </div>
  )
}

const ChangedPanelHeader = ({ expanded, changedCards }) => {
  const messageKey = expanded
    ? 'card-columns.changed'
    : 'card-columns.changed-collapsed'

  return (
    <div className="changed-cards-collapse-header">
      <ExpandIcon expanded={expanded} />
      <span className="changed-cards-collapse-header-text">
        <FormattedMessage
          id={messageKey}
          values={{
            alarmsCount: getTotalCount(changedCards),
          }}
        />
      </span>
    </div>
  )
}

const CardsColumn = ({
  cards,
  cardClicked,
  cardsSelection,
  onCardDragStart,
  lastDroppedCard,
  DragPreview,
  onGroupCards,
  highlightInfo,
  onSubJobsHighlight,
  onLegsHighlight,
  groupAlarmedByStack,
  onSplitCardsGroup,
  renderDateDividers,
  theme,
  reverseOrder,
}) => {
  const { fromServerValue } = useLocalDateTime()
  const scrollAreaRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [openedPanels, setOpenedPanels] = useState(_.values(PanelKeys))

  const [scrollOffset, setScrollOffset] = useState(null)
  const [scrollCard, setScrollCard] = useState(lastDroppedCard)

  const onDragStart = useCallback(
    c => {
      setIsDragging(true)
      onCardDragStart && onCardDragStart(c)
    },
    [onCardDragStart]
  )

  const onDragEnd = useCallback(() => {
    setIsDragging(false)
  }, [])

  useEffect(() => {
    setScrollCard(lastDroppedCard)
  }, [lastDroppedCard])

  useEffect(() => {
    if (scrollAreaRef.current && scrollOffset && scrollCard) {
      scrollAreaRef.current.scrollTop(scrollOffset)
      setScrollCard(null)
      setScrollOffset(null)
    }
  }, [scrollCard, scrollOffset])

  const setDroppedCardOffset = cardElement => {
    if (scrollCard && cardElement) {
      setScrollOffset(cardElement.offsetTop)
    }
  }

  const onAlarmedToggle = panels => {
    setOpenedPanels(panels)
  }

  const onCardsGrouping = useCallback(
    droppedCard => {
      onGroupCards && onGroupCards(droppedCard)
    },
    [onGroupCards]
  )
  // console.log('TEMP render cards', topLabel)

  const cardsSplitted = useMemo(
    () => groupCards(cards, fromServerValue),
    [cards, fromServerValue]
  )

  useEffect(() => {
    if (cardsSelection && _.some(cardsSelection.selectedCards, hasAlarmFired)) {
      setOpenedPanels(current => _.union(current, PanelKeys.ALARMS))
    }
  }, [cardsSelection])

  function needStackDivider(currentIndex, currentCard) {
    if (!groupAlarmedByStack) return false
    const prevCard = cardsSplitted.alarmedCards[currentIndex - 1]

    return !prevCard || prevCard.stack.name !== currentCard.stack.name
  }

  const renderCard = card => {
    const selectionProps = getSelectionProps(
      card,
      cardsSelection,
      card.groupChildren
    )
    const startsExpanded =
      hasAlarmFired(card) ||
      _.some(card.subjobs, hasAlarmFired) ||
      _.some(card.groupChildren, hasAlarmFired)

    return (
      <DraggableJobCard
        ref={card.id === lastDroppedCard?.id ? setDroppedCardOffset : null}
        key={card.id}
        data={card}
        onClick={cardClicked}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        dragged={
          isDragging &&
          (selectionProps.selected || _.some(selectionProps.selectedChildren))
        }
        onGroupCards={onCardsGrouping}
        onSplitCardsGroup={onSplitCardsGroup}
        highlightInfo={highlightInfo}
        onSubJobsHighlight={onSubJobsHighlight}
        onLegsHighlight={onLegsHighlight}
        forceExpand={startsExpanded}
        DragPreview={DragPreview}
        theme={theme}
        {...selectionProps}
      />
    )
  }

  const collapseCss = classNames('alarmed-cards', {
    'alarmed-cards-collapsed': !_.includes(openedPanels, PanelKeys.ALARMS),
  })

  const dateKeys = _.sortBy(_.keys(cardsSplitted.dateGrouped))
  if (reverseOrder) dateKeys.reverse()

  return (
    <CardsColumnInner ref={scrollAreaRef} scrollable theme={theme}>
      <Collapse
        onChange={onAlarmedToggle}
        className={collapseCss}
        activeKey={openedPanels}
      >
        {_.some(cardsSplitted.changedCards) && (
          <Panel
            showArrow={false}
            className={'changed-cards-panel'}
            key={PanelKeys.CTUpdate}
            header={
              <ChangedPanelHeader
                openedPanels={openedPanels}
                changedCards={cardsSplitted.changedCards}
                expanded={_.includes(openedPanels, PanelKeys.CTUpdate)}
              />
            }
          >
            {cardsSplitted.changedCards.map((card, index) => {
              if (needStackDivider(index, card))
                return (
                  <span key={card.stack.name}>
                    <ColumnDividerString label={card.stack.name} />
                    {renderCard(card)}
                  </span>
                )
              return renderCard(card)
            })}
          </Panel>
        )}
        {_.some(cardsSplitted.alarmedCards) && (
          <Panel
            showArrow={false}
            key={PanelKeys.ALARMS}
            header={
              <AlarmedPanelHeader
                openedAlarmed={_.includes(openedPanels, PanelKeys.ALARMS)}
                alarmedCards={cardsSplitted.alarmedCards}
              />
            }
          >
            {cardsSplitted.alarmedCards.map((card, index) => {
              if (needStackDivider(index, card))
                return (
                  <span key={card.stack.name}>
                    <ColumnDividerString label={card.stack.name} />
                    {renderCard(card)}
                  </span>
                )
              return renderCard(card)
            })}
          </Panel>
        )}
      </Collapse>

      {_.map(dateKeys, (key, i) => (
        <span key={i}>
          {renderDateDividers && (
            <CardsDateDivider value={fromServerValue(key)} />
          )}
          {_.map(cardsSplitted.dateGrouped[key], renderCard)}
        </span>
      ))}
    </CardsColumnInner>
  )
}

// CardsColumn.whyDidYouRender = true
const loggingOpt = undefined
// const loggingOpt = { failed: true, ok: true, label: 'topLabel' }

export default memo(CardsColumn, makeEqualFn({ expandIcon: true }, loggingOpt))
