/** immutable empty array to be used as default to allow effective memoization */
export const emptyArray = Object.freeze([])
/** immutable empty obj to be used as default to allow effective memoization */
export const emptyObj = Object.freeze({})

/**
 * Make equal fn for React.memo performing shallow comparision
 * @param {*} ignoring allows to ignore certain keys
 * @param {*} logging allows to enable loggin
 */
export function makeEqualFn(ignoring = {}, logging = {}) {
  return function areEqual(a, b) {
    function checkFailed(key) {
      if (logging.failed) {
        console.warn('NOT equal', a[logging.label], key, a[key], b[key])
      }
    }

    for (let key in a) {
      if (ignoring[key]) {
        continue
      }
      if (!(key in b) || a[key] !== b[key]) {
        checkFailed(key)
        return false
      }
    }
    for (let key in b) {
      if (ignoring[key]) {
        continue
      }
      if (!(key in b) || a[key] !== b[key]) {
        checkFailed(key)
        return false
      }
    }
    if (logging.ok) {
      console.log('equal', a[logging.label])
    }
    return true
  }
}
