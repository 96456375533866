/* global google */

const createHTMLMapMarker = ({ ...args }) => {
  class HTMLMapMarker extends google.maps.OverlayView {
    constructor(args) {
      super()
      this.latlng = args.latlng
      this.html = args.html
      this.setMap(args.map)
      this.div = null
      this.drawCallback = args.onDrawFinished
    }

    onAdd() {
      if (!this.div) {
        this.div = document.createElement('div')
        this.div.style.position = 'absolute'
        this.div.style.transform = 'translate(-50%, -100%)'
        if (this.html) {
          this.div.innerHTML = this.html
        }
        google.maps.event.addDomListener(this.div, 'click', _event => {
          google.maps.event.trigger(this, 'click')
        })
        const panes = this.getPanes()
        panes.overlayImage.appendChild(this.div)
        this.drawCallback && this.drawCallback(this.div)
      }
    }

    draw() {
      if (!this.div) return

      const point = this.getProjection().fromLatLngToDivPixel(this.latlng)
      if (point) {
        this.div.style.left = `${point.x}px`
        this.div.style.top = `${point.y}px`
      }
    }

    remove() {
      if (this.div) {
        this.div.parentNode.removeChild(this.div)
        this.div = null
      }
    }

    getDraggable() {
      return false
    }

    getPosition() {
      return this.latlng
    }
  }

  return new HTMLMapMarker(args)
}

export default createHTMLMapMarker
