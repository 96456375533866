import _ from 'lodash'

export const ChargeTypes = {
  Parking: 'PRK',
  Tolls: 'TOL',
  ExtraStop: 'PPWK',
}

export const ChargeAmountSources = {
  ATS: 'ATS_CALCULATED',
  FILE: 'ON_FILE',
  USER_DEFINED: 'USER_DEFINED',
}

export const ChargeTypesLabels = {
  [ChargeTypes.Parking]: 'card-popup.charges-parking',
  [ChargeTypes.Tolls]: 'card-popup.charges-tolls',
  [ChargeTypes.ExtraStop]: 'card-popup.charges-extra-stop',
}

export const getChargesOptions = charges =>
  _.map(charges, charge => ({
    value: charge.id,
    label: charge.description,
    type: charge.type,
  }))

export const ChargeTypesDefaults = {
  [ChargeTypes.ExtraStop]: 10,
}

export const isChargeEditable = type =>
  type?.amountSource === ChargeAmountSources.USER_DEFINED
export const getChargeAmount = type => type?.amount
