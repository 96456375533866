import { useCallback, useEffect, useState } from 'react'

const eventName = 'localStorageChanged_'

const isSupported = () => {
  try {
    const testKey = '__some_random_key_you_are_not_going_to_use__'
    localStorage.setItem(testKey, testKey)
    localStorage.removeItem(testKey)
    return true
  } catch (e) {
    return false
  }
}

/* in-memory cache for local storage reading is quite slow (and we use it a lot - each useLocalStorage) */
const localCache = {}

window.addEventListener('storage', ev => {
  try {
    const parsed = JSON.parse(ev.newValue)
    localCache[ev.key] = parsed
    dispatchValueChangedEvent(ev.key, parsed)
  } catch {}
})

const dispatchValueChangedEvent = (key, value) => {
  window.dispatchEvent(new CustomEvent(eventName + key, { detail: { value } }))
}

export const setItem = (key, value) => {
  if (isSupported()) localStorage.setItem(key, JSON.stringify(value))
  localCache[key] = value
  dispatchValueChangedEvent(key, value)
}

export const getItem = key => {
  if (localCache[key] !== undefined) {
    return localCache[key]
  }
  let result = null
  if (isSupported()) {
    try {
      result = JSON.parse(localStorage.getItem(key))
    } catch (e) {
      console.log('exception', e)
      result = undefined
    }
  }
  localCache[key] = result
  return result
}

export const removeItem = key => {
  if (isSupported()) localStorage.removeItem(key)
  localCache[key] = null
  dispatchValueChangedEvent(key, null)
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(getItem(key) || defaultValue)

  const onValueChange = useCallback(event => {
    setValue(event.detail.value)
  }, [])

  useEffect(() => {
    window.addEventListener(eventName + key, onValueChange)
    return () => window.removeEventListener(eventName + key, onValueChange)
  }, [key, onValueChange])

  useEffect(() => {
    if (defaultValue && !getItem(key)) {
      setItem(key, defaultValue)
    }
  }, [defaultValue, key])

  const saveValue = useCallback(
    value => {
      setValue(value)
      setItem(key, value)
    },
    [key]
  )

  const removeValue = useCallback(() => {
    setValue(null)
    removeItem(key)
  }, [key])

  return [value, saveValue, removeValue]
}
