import _ from 'lodash'

import { AssignedCardsPopover } from './AssignedCardsPopover'
import VehicleIcon from './VehicleIcon'
import { DriverTypesEnum } from '../../app/enums/DriverType'
import { ThemesEnum } from '../../app/enums/Themes'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import notShareImgDark from '../../styles/img/Dark _ Do Not Share.png'
import notShareImg from '../../styles/img/Light _ Do Not Share.png'
import { isLoaded } from '../../utils/driver-utils'
import { themedClass } from '../../utils/style-utils'
import ScrollArea from '../ScrollArea'
import ThemedImage from '../ThemedImage'

const topImageConfig = {
  [ThemesEnum.LIGHT]: notShareImg,
  [ThemesEnum.DARK]: notShareImgDark,
}

const LSPDriversList = ({ drivers }) => {
  const [selectedTheme] = useSelectedTheme()
  const lspDrivers = _.filter(
    drivers,
    driver => driver.driverType === DriverTypesEnum.LOGISTIC_SERVICE_PROVIDER
  )

  if (!_.some(lspDrivers)) return null

  return (
    <div className={themedClass('drivers-list-floating', selectedTheme)}>
      <div className="driver-without-gps-list">
        <div className="no-shared-icon">
          <p>LSP</p>
          <ThemedImage
            theme={selectedTheme}
            themes={topImageConfig}
            alt="not-sharing-icon"
          />
        </div>
        <ScrollArea
          autoHeight
          autoHeightMin={0}
          autoHeightMax="100%"
          wrapClassName="drivers-list-floating-scrollarea"
        >
          <div className="vehicles-list">
            {_.map(lspDrivers, driver => (
              <div key={driver.code}>
                <VehicleIcon
                  driver={driver}
                  displayDriverCode
                  hideLabel
                  PopoverContent={
                    isLoaded(driver) ? AssignedCardsPopover : null
                  }
                />
              </div>
            ))}
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}

export default LSPDriversList
