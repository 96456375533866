import { FormattedMessage, useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'

import { SWITCH_PAGE } from '../app/keymaps'
import Sitemap from '../app/sitemap'
import { useCurrentStation } from '../hooks/useCurrentStation'

const ViewSwitch = () => {
  const { stationId } = useCurrentStation()
  const { formatMessage: f } = useIntl()
  return (
    <div className="segmented-navigation">
      <NavLink
        className="ant-btn ant-btn-lg ant-btn-block"
        to={Sitemap.createDispatchLink(stationId)}
        activeClassName="btn-active"
        title={f(
          { id: 'sitemap.shortcut-hint' },
          { keymap: SWITCH_PAGE.label }
        )}
      >
        <FormattedMessage id="sitemap.dispatch" />
      </NavLink>

      <NavLink
        className="ant-btn ant-btn-lg ant-btn-block"
        to={Sitemap.createMonitorLink(stationId)}
        activeClassName="btn-active"
        title={f(
          { id: 'sitemap.shortcut-hint' },
          { keymap: SWITCH_PAGE.label }
        )}
      >
        <FormattedMessage id="sitemap.monitor" />
      </NavLink>
    </div>
  )
}

export default ViewSwitch
