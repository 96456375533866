import _ from 'lodash'

import { CompanyInfo } from './CompanyInfo'
import { FlightInfo } from './FlightInfo'
import { LocationInfo } from './LocationInfo'
import MissingShipmentData from './MissingShipmentData'
import OfficeInfo from './OfficeInfo'
import { isSubJobCard } from '../../utils/card-utils'

const PickUpInfo = ({ card, rowClass, fixedRowsCount = true }) => {
  if (isSubJobCard(card))
    return (
      <LocationInfo
        company={card?.from}
        rowClass={rowClass}
        fixedRowsCount={fixedRowsCount}
      />
    )

  if (!card || !card.pickup) return <MissingShipmentData rowClass={rowClass} />
  switch (card.pickup.type) {
    case 'AIR':
      return (
        <FlightInfo
          flight={_.head(card.flights)}
          card={card}
          rowClass={rowClass}
          fixedRowsCount={fixedRowsCount}
        />
      )
    case 'SHIPPER':
      return (
        <CompanyInfo
          name={card.pickup.name}
          address={card.pickup.address}
          rowClass={rowClass}
          fixedRowsCount={fixedRowsCount}
        />
      )
    case 'OFFICE':
      return (
        <OfficeInfo
          rowClass={rowClass}
          card={card}
          location={card.pickup}
          address={card.pickup.address}
          fixedRowsCount={fixedRowsCount}
        />
      )
    default:
      throw new Error('Unknown pickup type!')
  }
}

export default PickUpInfo
