import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAuthentication } from './useAuthentication'

const getDefaultStation = user => {
  if (_.includes(user.allowedStations, user.defaultStation)) {
    return user.defaultStation
  }
  return _.head(user.allowedStations)
}

const isValidStationId = (user, station) =>
  _.includes(user.allowedStations, station)

export function useCurrentStation() {
  const { station } = useParams()
  const [stationId, setStationId] = useState(station || '')
  const [currentUser] = useAuthentication(false)

  useEffect(() => {
    let newStationId = stationId

    if (currentUser) {
      if (isValidStationId(currentUser, station)) {
        newStationId = station
      }

      if (!newStationId) {
        newStationId = getDefaultStation(currentUser)
      }

      if (stationId !== newStationId) {
        setStationId(newStationId)
      }
    }
  }, [currentUser, station, stationId])

  return {
    stationId,
  }
}
