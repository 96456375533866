import { Table } from 'antd'
import _ from 'lodash'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'

import { FlowContentHeaderSmall } from '../ModalsTypography'

const columns = [
  {
    title: 'Device Number',
    dataIndex: 'rowIndex',
    render: value => (
      <FormattedHTMLMessage
        id="sensor-device.device-number"
        values={{ number: value }}
      />
    ),
  },
  {
    title: <FormattedMessage id="sensor-device.manufacturer" />,
    dataIndex: 'brand',
  },
  {
    title: <FormattedMessage id="sensor-device.model" />,
    dataIndex: 'model',
  },
  {
    title: <FormattedMessage id="sensor-device.source" />,
    dataIndex: 'source',
  },
  {
    title: <FormattedMessage id="sensor-device.serial-number" />,
    dataIndex: 'serialNumber',
  },
]

const SensorDevices = ({ devices, selectedDevice, onDeviceClick }) => (
  <div>
    <FlowContentHeaderSmall>
      <FormattedMessage id="card-popup.sensors" />
    </FlowContentHeaderSmall>

    <Table
      columns={columns}
      pagination={false}
      dataSource={_.map(devices, (row, rowIndex) => ({
        key: row.id,
        rowIndex: rowIndex + 1,
        ...row,
      }))}
      rowClassName={record =>
        record.key === selectedDevice?.id ? 'row-active' : ''
      }
      onRow={(__, rowIndex) => ({
        onClick: () => {
          onDeviceClick && onDeviceClick(rowIndex)
        },
      })}
    />
  </div>
)

export default SensorDevices
