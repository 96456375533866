import classNames from 'classnames'
import _ from 'lodash'

import { AddressArray } from './CommonAddress'
import MissingShipmentData from './MissingShipmentData'
import { TextImportant } from '../Typography'

export const CompanyInfo = ({ address, name, rowClass, fixedRowsCount }) => {
  if (!address) return <MissingShipmentData />

  const css = classNames(rowClass, 'text-ellipsed')
  return (
    <>
      <p className={css} title={name}>
        <TextImportant className="text-capitalized">
          {_.toLower(name)}
        </TextImportant>
      </p>

      <AddressArray
        rowCss={css}
        address={address}
        fixedRowsCount={fixedRowsCount}
      />
    </>
  )
}
