import { useQuery } from '@apollo/client'

import { GET_DRIVER_ROUTE_QUERY } from '../../app/graphQl/schema/DriverRoute'
import { isUnloaded } from '../../utils/card-utils'

export function useDriverRoute(card) {
  const { data, loading, error } = useQuery(GET_DRIVER_ROUTE_QUERY, {
    variables: {
      cardId: card?.id,
    },
    skip: !isUnloaded(card),
  })

  const result = data?.getDriverRoute
  return [result, loading, error]
}
