import jwtDecode from 'jwt-decode'
import moment from 'moment'

import { config } from '../app/config'
import apolloClient, { subcriptionClient } from '../app/graphQl/apolloClient'
import {
  getItem,
  removeItem,
  setItem,
  useLocalStorage,
} from '../hooks/useLocalStorage'
import { compareUtcDates, isDateInPast, unixToDate } from '../utils/date-utils'

const userKey = 'qdd-user'

export function getCurrentUser() {
  return getItem(userKey)
}

const tokenRefreshInterval = config.tokenRefreshInterval * 60 * 1000

let timerId = undefined

export function setNextTokenRefresh() {
  if (timerId) clearInterval(timerId)
  const currentUser = getCurrentUser()

  if (!currentUser) {
    return
  }

  if (isDateInPast(unixToDate(currentUser.exp))) {
    refreshToken()
    return
  }

  if (
    compareUtcDates(unixToDate(currentUser.exp), moment()) <
    tokenRefreshInterval
  ) {
    refreshToken()
  }

  timerId = setTimeout(async () => {
    await refreshToken()
    setNextTokenRefresh()
  }, tokenRefreshInterval)
}

export async function processAuthResponse(response, refreshToken) {
  try {
    if (response.status === 200) {
      const value = await response.json()
      let user = {
        accessToken: value.jwt,
        refreshToken: value.refreshToken || refreshToken,
        ...jwtDecode(value.jwt),
      }
      setItem(userKey, user)
      return true
    } else {
      clearCurrentUser()
      return false
    }
  } catch (e) {
    clearCurrentUser()
    return false
  }
}

export async function login(data) {
  const response = await fetch('/login-user', {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    body: data,
  })

  const loginSuccess = await processAuthResponse(response)
  setNextTokenRefresh()
  return loginSuccess
}

export async function logout() {
  const user = getCurrentUser()
  if (user) {
    await fetch('/logout', {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        authorization: `Bearer ${user.accessToken}`,
      },
    })
  }
  clearCurrentUser()
}

export async function clearCurrentUser() {
  setTimeout(() => removeItem(userKey), [0])

  // closes websocket connection and stop reconnecing
  // calling manually to prevent endless connection attempts after user had logged out
  // (seems like apollo client does not do that automatically)
  subcriptionClient.close(true)
  apolloClient.clearStore()
}

export async function refreshToken() {
  console.log('calling refresh token')
  const user = getCurrentUser()
  if (!user) {
    clearCurrentUser()
    return
  }

  const response = await fetch('/refresh-token', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${user.refreshToken}`,
    },
  })
  await processAuthResponse(response, user.refreshToken)
}

export function useAuthentication() {
  const [currentUser] = useLocalStorage(userKey, null)
  const validToken = !isDateInPast(unixToDate(currentUser?.exp))
  return [currentUser, validToken]
}
