import { useCallback, useEffect, useState } from 'react'

import ChooseAlarmHeader from './common/ChooseAlarmHeader'
import FlowWindow from './common/FlowWindow'
import { ModifyHeader } from './common/ModifyHeader'
import { CardPopupState } from '../../app/enums/CardPopupState'
import { useCardContext } from '../../hooks/useCardPopupContext'
import useDirtyState from '../../hooks/useDirtyState'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { useModalWindow } from '../../hooks/useModalWindow'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { getAlarmDateTime, hasClosableAlarm } from '../../utils/card-utils'
import { areDatesEqual } from '../../utils/date-utils'
import { AlarmBadge } from '../AlarmBadge'
import CardDateTimePicker from '../calendar-picker/CardDateTimePicker'
import CardAlarmModal from '../modals/CardAlarmModal'

const AlarmScreen = ({ card }) => {
  const { addCurrentPageToHistory, readOnly } = useCardContext()
  const { getCurrentTime, fromServerValue } = useLocalDateTime()
  const [showConfirmModal, openConfirm, closeConfirm] = useModalWindow()
  const [editing, setEditing] = useState(!card.reminder)
  const [selectedDate, setSelectedDate, isDirty, resetDate] = useDirtyState(
    getAlarmDateTime(card.reminder, getCurrentTime(), fromServerValue),
    areDatesEqual
  )
  const [theme] = useSelectedTheme()

  const resetForm = useCallback(() => {
    resetDate(
      getAlarmDateTime(card.reminder, getCurrentTime(), fromServerValue)
    )
  }, [card.reminder, fromServerValue, getCurrentTime, resetDate])

  useEffect(() => {
    resetForm()
  }, [card.reminder, resetForm])

  const onCancelClick = () => {
    setEditing(!card.reminder)
    resetForm()
  }

  const onModifyClicked = () => {
    setEditing(true)
  }

  const afterConfirmed = () => {
    addCurrentPageToHistory()
    setEditing(false)
  }

  const cancelEnabled = isDirty || (editing && card?.reminder)
  return (
    <FlowWindow
      card={card}
      page={CardPopupState.Alarm.Page}
      contentClassName="flow-content-alarm-settings"
      header={
        <ModifyHeader
          messageId={'card-popup.alarm'}
          secondaryMessageId={!editing ? 'alarm-modal.alarm-confirmed' : null}
          onConfirm={openConfirm}
          onCancel={onCancelClick}
          onModify={onModifyClicked}
          confirmMsg={card.reminder ? 'confirm.update' : 'confirm.set'}
          editing={editing}
          readOnly={readOnly}
          canCancel={cancelEnabled}
        >
          {!editing && hasClosableAlarm(card) && (
            <AlarmBadge value={card.reminder.iso} inline />
          )}
        </ModifyHeader>
      }
    >
      <CardAlarmModal
        visible={showConfirmModal}
        onClosed={closeConfirm}
        dateTime={selectedDate}
        card={card}
        afterConfirmed={afterConfirmed}
        headerMsg="alarm-settings.confirm-alarm"
      />

      <ChooseAlarmHeader card={card} isEditing={editing} theme={theme} />

      <CardDateTimePicker
        value={selectedDate}
        onValueChanged={setSelectedDate}
        collapsed={readOnly || !editing}
        theme={theme}
        dirty={isDirty}
      />
    </FlowWindow>
  )
}

export default AlarmScreen
