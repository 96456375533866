export const CardPopupState = {
  Overview: { Page: 0, Icon: 'fa-file-alt', Label: 'card-popup.overview' },
  Alarm: { Page: 1, Icon: 'fa-clock', Label: 'card-popup.alarm' },
  DriverAssign: {
    Page: 2,
    Icon: 'fa-user',
    Label: 'card-popup.driver-assign',
  },
  SubJob: { Page: 4, Icon: 'fa-plus-square', Label: 'card-popup.sub-job' },
  CreateReIce: {
    Page: 7,
    Label: 'card-popup.reice-create',
    Icon: 'fa-cube',
  },
  CreatePurchaseItem: {
    Page: 8,
    Label: 'card-popup.purchase-create',
    Icon: 'fa-shopping-cart',
  },
  ReIce: { Page: 7, Label: 'card-popup.reice-alarm', Icon: 'fa-cube' },
  PurchaseItem: {
    Page: 8,
    Label: 'card-popup.purchase-alarm',
    Icon: 'fa-shopping-cart',
  },
  ReIceEdit: { Page: 9, Label: 'card-popup.reice-alarm', Icon: 'fa-cube' },
  PurchaseItemEdit: {
    Page: 10,
    Label: 'card-popup.purchase-alarm',
    Icon: 'fa-shopping-cart',
  },
  ReIceDriver: {
    Page: 11,
    Label: 'card-popup.reice-driver',
    Icon: 'fa-user',
  },
  PurchaseDriver: {
    Page: 11,
    Label: 'card-popup.purchase-driver',
    Icon: 'fa-user',
  },
  Resolution: {
    Page: 20,
    Icon: 'fa-dollar-sign',
    Label: 'card-popup.resolution',
  },
}
