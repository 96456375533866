import _ from 'lodash'

import PlaceholderRepeater from './common/PlaceholderRepeater'
import ScrollArea from '../../components/ScrollArea'
import { formatNullableString } from '../../utils/string-utils'
import { JobHeaderLabel } from '../ModalsTypography'

const MIN_CELLS_COUNT = 6

const EmptyCellPlaceholder = () => <div className="shipper-col" />

const ShipperSpecificInfo = ({ job }) => (
  <ScrollArea style={{ height: 90 }}>
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {_.map(job?.shipperSpecificInfo, (info, index) => (
        <div className="shipper-col" key={index}>
          <JobHeaderLabel>{info.label}</JobHeaderLabel>
          {formatNullableString(info.value)}
        </div>
      ))}
      <PlaceholderRepeater
        existingCount={_.size(job?.shipperSpecificInfo)}
        totalCount={MIN_CELLS_COUNT}
        ItemComponent={EmptyCellPlaceholder}
      />
    </div>
  </ScrollArea>
)

export default ShipperSpecificInfo
