import { useQuery } from '@apollo/client'

import { GET_STACKS } from '../../app/graphQl/schema/Stacks'
import { emptyArray } from '../../utils/perf-utils'
import { useAuthentication } from '../useAuthentication'
import { useCurrentStation } from '../useCurrentStation'

const useStacksList = () => {
  const { stationId } = useCurrentStation()
  const [currentUser, validToken] = useAuthentication()

  const { data, loading, error } = useQuery(GET_STACKS, {
    variables: { stationId },
    skip: !currentUser || !stationId || !validToken,
  })

  return {
    stacksData: data?.listStacks || emptyArray,
    loading,
    error,
  }
}

export default useStacksList
