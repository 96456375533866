import GoogleMapsComponent from './GoogleMapsComponent'
import { FullRouteView } from './MapViews'

const FullRouteMap = ({
  pickupPoint,
  dropPoint,
  lineColor,
  route,
  driver,
  theme,
}) => (
  <GoogleMapsComponent theme={theme}>
    <FullRouteView
      pickDestination={pickupPoint}
      dropDestination={dropPoint}
      lineColor={lineColor}
      route={route}
      vehicle={driver}
    />
  </GoogleMapsComponent>
)
export default FullRouteMap
