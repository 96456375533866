import { DirectionsRenderer } from '@react-google-maps/api'

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 0.75,
  scale: 3,
}

const VehicleRoute = ({ lineColor, directionData }) => (
  <>
    {directionData !== null && (
      <DirectionsRenderer
        options={{
          directions: directionData,
          preserveViewport: true,
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: lineColor,
            strokeOpacity: 0.8,
            strokeWeight: 0,
            icons: [
              {
                icon: lineSymbol,
                offset: '0',
                repeat: '10px',
              },
            ],
          },
        }}
      />
    )}
  </>
)

export default VehicleRoute
