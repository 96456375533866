import { themedClass } from '../utils/style-utils'

const BorderOutset = ({ children, className, theme }) => {
  const css = themedClass('border-outset', theme, className)
  return <div className={css}>{children} </div>
}

export const BorderOutsetSeparator = ({ theme }) => (
  <BorderOutset className="solid-separator-empty" theme={theme} />
)

export default BorderOutset
