import { gql } from '@apollo/client'

export const MUTATION_RESPONSE = gql`
  fragment responseDetail on MutationResponse {
    success
    message
    errorCode
  }
`

export const COMPANY_INFO = gql`
  fragment companyInfo on Company {
    name
    address {
      street
      city
      state
      country
      zip
    }
    transport {
      distance
      time {
        hours
        minutes
      }
    }
  }
`

export const PICKUP_POINT = gql`
  fragment pickupPoint on Pickup {
    value
    type
    name
    position {
      lat
      lng
    }
    address
    dockLocation
  }
`

export const DROP_POINT = gql`
  fragment dropPoint on Drop {
    value
    type
    name
    position {
      lat
      lng
    }
    address
    dockLocation
  }
`

export const FLIGHT_DETAIL = gql`
  fragment flightDetail on Flight {
    airline
    flightNumber
    airlineServiceType
    airlineServiceTypeDesc
    origin
    destination
    status
    departure {
      iso
    }
    departureScheduled {
      iso
    }
    departureActual {
      iso
    }
    arrival {
      iso
    }
    arrivalScheduled {
      iso
    }
    arrivalActual {
      iso
    }
    manifest
    airwayBill
  }
`

export const FLIGHT_OVERVIEW = gql`
  fragment flightOverview on Flight {
    airline
    flightNumber
    departure {
      iso
    }
    arrival {
      iso
    }
    manifest
    airwayBill
  }
`
