import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

import { CardPopupState } from '../../app/enums/CardPopupState'
import { useGetCard } from '../../hooks/graphQl/useGetCard'
import { useCardContext } from '../../hooks/useCardPopupContext'
import ScrollArea from '../ScrollArea'
import Card from '../job-cards/Card'

const getPanelHeader = currentPage =>
  currentPage === CardPopupState.Alarm.Page
    ? 'card-popup.batch-header-alarm'
    : 'card-popup.batch-header-driver'

const CardsGroupPanel = () => {
  const { currentPage, currentCard, changeCard } = useCardContext()

  const { card: parentCard } = useGetCard(
    currentCard?.groupParent || currentCard?.id
  )

  const onCardClick = card => {
    changeCard(card)
  }

  const cardChildren = parentCard.groupChildren || []
  return (
    <div className="card-popup-group">
      <div className="card-popup-group-header">
        <FormattedMessage
          id={getPanelHeader(currentPage)}
          values={{ cardsCount: cardChildren.length + 1 }}
        />
        <i className={'fas fa-layer-group'} />
      </div>
      <ScrollArea>
        <div className="card-popup-group-cards">
          {_.map([parentCard, ...cardChildren], card => (
            <Card
              data={card}
              onClick={onCardClick}
              key={card.id}
              selected={currentCard && card.id === currentCard.id}
            />
          ))}
        </div>
      </ScrollArea>
    </div>
  )
}

export default CardsGroupPanel
