import classNames from 'classnames'

import { themedClass } from '../utils/style-utils'

export const ContentPanel = ({
  children,
  className,
  danger,
  darkShadow,
  theme,
}) => {
  const css = classNames(
    themedClass('content-panel', theme),
    { 'content-panel-dark-shadow': darkShadow },
    { 'content-panel-danger': danger },
    className
  )
  return <div className={css}>{children}</div>
}
