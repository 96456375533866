import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { formatNullableString } from '../utils/string-utils'

const CardsStatus = ({
  label,
  labelId,
  value,
  valueStatus,
  className,
  title,
}) => (
  <div className={classNames('card-status-box', className)} title={title}>
    <div className="card-status-type">
      {label || <FormattedMessage id={labelId} />}
    </div>
    <div
      className={classNames(
        'text-capitalized',
        'card-status',
        'card-status-' + (valueStatus || 'default')
      )}
    >
      {formatNullableString(value)}
    </div>
  </div>
)

CardsStatus.propTypes = {
  label: PropTypes.string,
  labelId: PropTypes.string,
  value: PropTypes.node,
  valueStatus: PropTypes.oneOf(['default', 'success', 'danger', 'warning']),
}

export default CardsStatus
