import SubJobBase from './SubJobBase'
import { CardPopupState } from '../../app/enums/CardPopupState'
import { SubJobTypeEnum } from '../../app/enums/SubJobTypeEnum'
import { useCardContext } from '../../hooks/useCardPopupContext'
import { useCards } from '../../hooks/useCards'
import { useCurrentStation } from '../../hooks/useCurrentStation'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { isSubJobCard } from '../../utils/card-utils'
import { toDateTimeVariable } from '../../utils/date-utils'

const PurchaseItemSubjob = ({ card, page }) => {
  const { changeView, addCurrentPageToHistory } = useCardContext()
  const { createSubJob, updateSubJob } = useCards()
  const { currentTimeZone } = useLocalDateTime()
  const { stationId } = useCurrentStation()

  const onConfirmClick = (cardId, stackId, parameters) => {
    if (isSubJobCard(card)) {
      updateSubJob({
        id: card.id,
        from: parameters.from,
        to: parameters.to,
        stack: stackId,
        reminder: toDateTimeVariable(parameters.date, currentTimeZone),
      })
      addCurrentPageToHistory()
    } else {
      createSubJob({
        subjobType: SubJobTypeEnum.PURCHASE,
        parentId: cardId,
        stack: stackId,
        from: parameters.from,
        to: parameters.to,
        reminder: toDateTimeVariable(parameters.date, currentTimeZone),
        station: stationId,
      })
      changeView(CardPopupState.Overview.Page)
    }
  }
  return (
    <SubJobBase
      card={card}
      onConfirmClick={onConfirmClick}
      header="card-popup.sub-job-pud"
      subjobLabel="card-popup.purchase-subjob"
      page={page}
    />
  )
}

export default PurchaseItemSubjob
