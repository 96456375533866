import classNames from 'classnames'
import _ from 'lodash'
import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import CardBadge from './CardBadge'
import CardDestinations from './CardDestinations'
import { SubJobHeader } from './CardHeaders'
import CardRemovalTransition from './CardRemovalTransition'
import MilestoneBadge from './MilestoneBadge'
import {
  getCardIdLabel,
  getDriverLabel,
  isCardFinished,
} from '../../utils/card-utils'
import { getCardDate } from '../../utils/common-utils'
import { themedClass } from '../../utils/style-utils'
import { ShortDateFormat } from '../DateTimeFormats'

const SubJobCard = ({
  data,
  standAlone,
  selected,
  onClick,
  header,
  countBadge,
  onSubJobsHighlight,
  highlighted,
  theme,
  roundedCorners = true,
}) => {
  const driverText = getDriverLabel(data)

  const wrapCss = classNames('card-wrap', { 'cards-group': !!countBadge })
  const innerCss = classNames(
    'subjob-card',
    'card-with-header',
    themedClass('card', theme),
    {
      'subjob-expanded': !standAlone,
    },
    { 'card-selected': selected },
    { 'subjob-highlight': highlighted },
    { 'card-normal-corners': !roundedCorners }
  )

  const onHighlightClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onSubJobsHighlight && onSubJobsHighlight(data)
    },
    [data, onSubJobsHighlight]
  )

  const onCardClick = e => {
    onClick && onClick(data, e)
    e.preventDefault()
    e.stopPropagation()
  }

  const cardIndexNumber = _.last(data.id.split('.'))
  return (
    <CardRemovalTransition
      className={wrapCss}
      visible={!isCardFinished(data)}
      card={data}
    >
      <div className={innerCss}>
        {standAlone && (
          <>
            {header || (
              <SubJobHeader
                onSubJobsHighlight={onHighlightClick}
                order={cardIndexNumber}
              />
            )}
          </>
        )}
        <div className="card-body" onClick={onCardClick}>
          <div className="card-details">
            {standAlone && (
              <p className="subjob-parentNumber">
                [{getCardIdLabel(data.parent)}]
              </p>
            )}
            <p className="card-number text-ellipsed text-uppercase">
              {!standAlone && <>{cardIndexNumber}:</>}{' '}
              <FormattedMessage id={`card.subjob-${data.subjobType}`} />
            </p>
            <CardDestinations card={data} />
            {standAlone && <p className="subjob-driver">{driverText}</p>}
          </div>

          <div className="card-badges flexbox-multiline">
            <CardBadge card={data} />
            {!standAlone && (
              <p className="subjob-action-date">
                <ShortDateFormat date={getCardDate(data)} />
              </p>
            )}
            {standAlone && (
              <MilestoneBadge
                milestone={data.milestone}
                lockData={data.lockData}
                pendingUpdate={data.pendingUpdate}
              />
            )}
          </div>
        </div>
        {!!countBadge && <div className="cards-count-badge">{countBadge}</div>}
      </div>
    </CardRemovalTransition>
  )
}

export default SubJobCard
