import classNames from 'classnames'

import DispatcherNotesCollapse from './DispatcherNotesCollapse'
import { NavigationMenu } from './NavigationMenu'
import { SidePanelSectionHeader } from './SidePanelSectionHeader'
import SpecialInstructions from './SpecialInstructions'
import { useGetCard } from '../../../hooks/graphQl/useGetCard'
import { useCardContext } from '../../../hooks/useCardPopupContext'
import { useSelectedTheme } from '../../../hooks/useUserSettings'
import { extractTopParent, isInGroup } from '../../../utils/card-utils'
import { themedClass } from '../../../utils/style-utils'
import DeclineCardButton from '../../action-buttons/DeclineCardButton'
import DismissAlarmButton from '../../action-buttons/DismissAlarm'
import CardsGroupPanel from '../CardsGroupPanel'

const SidePanelSection = ({ className, children }) => {
  const css = classNames('side-panel-section', className)
  return <div className={css}>{children}</div>
}

const CardSidePanel = ({ cardData, job, innerModalRef }) => {
  const { closePopup } = useCardContext()
  const { card: topCard } = useGetCard(extractTopParent(cardData)?.id)
  const [theme] = useSelectedTheme()

  const afterDeclined = () => {
    closePopup()
  }

  const isGroup = isInGroup(cardData)

  return (
    <div className={themedClass('card-side-panel', theme)}>
      <SidePanelSection>
        <NavigationMenu parentCard={topCard} />
      </SidePanelSection>

      <SidePanelSection className="side-panel-buttons">
        <DeclineCardButton card={cardData} afterDeclined={afterDeclined} />
        <DismissAlarmButton card={cardData} />
      </SidePanelSection>

      {isGroup && (
        <SidePanelSection>
          <CardsGroupPanel />
        </SidePanelSection>
      )}

      <SidePanelSection className="dispatcher-notes-section">
        <SidePanelSectionHeader
          messageId="card.special-instructions"
          theme={theme}
        />
        <SpecialInstructions
          card={cardData}
          className={themedClass('special-instructions-popup', theme)}
          theme={theme}
        />
      </SidePanelSection>

      <SidePanelSection className="dispatcher-notes-section">
        <DispatcherNotesCollapse card={cardData} job={job} theme={theme} />
      </SidePanelSection>
    </div>
  )
}

export default CardSidePanel
