import classNames from 'classnames'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  ChargeTypes,
  getChargeAmount,
  getChargesOptions,
  isChargeEditable,
} from '../../app/enums/ChargeTypes'
import { CURRENCIES, getCurrencySign } from '../../app/enums/Currencies'
import { removeChar } from '../../utils/string-utils'
import { HintTooltip } from '../HintTooltip'
import * as Icons from '../Icons'
import { DotsLoader } from '../LoadingIndicators'
import ScrollArea from '../ScrollArea'
import { SelectComponent, TextBox } from '../form/FormInputs'
import CurrencyFormat from '../user-units/CurrencyFormat'

const defaultCurrency = CURRENCIES.US_DOLLAR

const AdditionaChargeIcon = ({ id, label, chargeType }) => {
  let icon = null
  if (id === ChargeTypes.Parking) {
    icon = <Icons.ParkingIcon />
  } else {
    icon =
      chargeType === 'Tolls' ? <Icons.TollsIcon /> : <Icons.ExtraStopIcon />
  }

  return (
    <div className="additional-charge-icon">
      {icon}
      <span className="icon-label text-ellipsed">
        <HintTooltip tooltip={label} size="small" arrow>
          {label}
        </HintTooltip>
      </span>
    </div>
  )
}

const NewChargeRow = ({
  onChange,
  onCancel,
  addBorder,
  theme,
  possibleCharges,
}) => {
  const { formatMessage: f } = useIntl()
  const [amount, setAmount] = useState('')
  const [chargeType, setChargeType] = useState(null)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const inputRef = useRef()

  const currencySign = getCurrencySign(defaultCurrency)

  const onSaveValue = () => {
    const value = removeChar(amount, currencySign)
    if ((!_.isEmpty(value) && !isNaN(value)) || !isChargeEditable(chargeType)) {
      !isMouseDown &&
        onChange &&
        onChange({ amount: value, type: chargeType?.id })
    }
  }

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus()
  }, [chargeType])

  const onTypeChanged = value => {
    const charge = _.find(possibleCharges, charge => charge.id === value)
    setChargeType(charge)

    if (!isChargeEditable(charge)) {
      setAmount(currencySign + getChargeAmount(charge))
    }
  }

  const onValueChanged = e => {
    const value = removeChar(e.target.value, currencySign)
    if (!_.isEmpty(value)) {
      setAmount(currencySign + value)
    } else {
      setAmount('')
    }
  }

  const getContent = () => {
    if (chargeType) {
      return (
        <>
          <AdditionaChargeIcon
            id={chargeType?.id}
            chargeType={chargeType?.chargeType}
            label={chargeType?.description}
          />
          <TextBox
            className="flexbox-force-left"
            ref={inputRef}
            value={amount}
            size="large"
            onBlur={onSaveValue}
            onChange={onValueChanged}
            onPressEnter={onSaveValue}
            labelText={f({ id: 'card-popup.new-charge-amount-label' })}
            readOnly={!isChargeEditable(chargeType)}
          />
          <Icons.TimesIcon
            onClick={onCancel}
            onMouseDown={() => setIsMouseDown(true)}
            onMouseUp={() => setIsMouseDown(false)}
          />
        </>
      )
    }

    return (
      <SelectComponent
        onChange={onTypeChanged}
        value={chargeType}
        options={getChargesOptions(possibleCharges)}
        labelText={f({ id: 'card-popup.new-charge-type-label' })}
        className="full-width"
        data-cy="new-charge-select"
        theme={theme}
        dropdownMatchSelectWidth={false}
      />
    )
  }

  const rowCss = classNames('expenses-row', 'expenses-row-new', {
    'expenses-row-borderless': !addBorder,
  })
  return <div className={rowCss}>{getContent()}</div>
}

const AdditionalChargeRow = ({ charge, possibleCharges }) => {
  const selectedCharge = _.find(possibleCharges, c => c.id === charge.type)
  return (
    <div className="expenses-row">
      <AdditionaChargeIcon
        id={selectedCharge?.id}
        chargeType={selectedCharge?.chargeType}
        label={selectedCharge?.description}
      />
      <p className="additional-charge-value">
        <CurrencyFormat value={charge.amount} currency={CURRENCIES.US_DOLLAR} />
      </p>
      {charge.loading ? (
        <DotsLoader />
      ) : (
        <i className="fas fa-times-circle disabled-icon" />
      )}
    </div>
  )
}

const AdditionalCharges = ({
  charges,
  onChargeAdded,
  onChargeRemoved,
  theme,
  possibleCharges,
}) => {
  const [opened, setOpened] = React.useState(false)

  const onRemoveClick = index => {
    onChargeRemoved && onChargeRemoved(index, charges[index])
  }

  const onSaveCharge = value => {
    onChargeAdded([value])
    setOpened(false)
  }

  const onAddNewClicked = () => {
    setOpened(true)
  }

  return (
    <div className="expenses-column">
      <div className="expenses-row">
        <h2 className="text-uppercase">
          <FormattedMessage id="card-popup.charges-additional" />
        </h2>
        <Icons.PlusIcon
          onClick={onAddNewClicked}
          className="flexbox-force-left"
        />
      </div>

      {opened && (
        <NewChargeRow
          onChange={onSaveCharge}
          addBorder={_.some(charges)}
          theme={theme}
          possibleCharges={possibleCharges}
          onCancel={() => {
            setOpened(false)
          }}
        />
      )}

      <ScrollArea>
        {_.reverse(
          _.map(charges, (charge, i) => (
            <AdditionalChargeRow
              key={i}
              charge={charge}
              onRemove={onRemoveClick}
              index={i}
              possibleCharges={possibleCharges}
            />
          ))
        )}
      </ScrollArea>
    </div>
  )
}

export default AdditionalCharges
