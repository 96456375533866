import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import ConfirmModalBase from './ConfirmModalBase'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import { WarningIcon } from '../Icons'
import { TextInputMultiline } from '../form/TextInput'

const DeclineJobConfirm = ({ onDeclined, onCancel }) => {
  const { formatMessage: f } = useIntl()
  const [comment, setComment] = useState('')
  const [theme] = useSelectedTheme()

  const onCommentChanged = e => {
    setComment(e.target.value)
  }

  const onOkClicked = () => {
    onDeclined(comment)
  }

  return (
    <ConfirmModalBase
      className="decline-job-confirm"
      width={360}
      okText={f({ id: 'card.decline' })}
      onOk={onOkClicked}
      onCancel={onCancel}
      title={<WarningIcon className="opaque-icon text-warning" />}
    >
      <h1>
        <FormattedMessage id="confirm.attention" />
      </h1>
      <h2>
        <FormattedMessage id="decline-card.decline-confirm" />
      </h2>
      <div className="text-warning">
        <FormattedMessage id="decline-card.irreversible-warning" />
      </div>
      <TextInputMultiline
        labelId="decline-card.leave-comment"
        value={comment}
        onChange={onCommentChanged}
        theme={theme}
      />
    </ConfirmModalBase>
  )
}

export default DeclineJobConfirm
