import _ from 'lodash'

import useDropWithContext from './dragAndDrop/useDropWithContext'
import { useDrivers } from './useDrivers'
import { DriverDragType } from '../components/drivers/DriverCard'
import { isPossibleToOverrideStatus } from '../utils/driver-utils'

function useChangeDriverStateDrop(state, selectedDrivers) {
  const { setDriverState } = useDrivers()

  const [{ isHovered }, cardDrop] = useDropWithContext(state, {
    accept: DriverDragType,
    drop: item => {
      setDriverState(_.unionBy([item.data], selectedDrivers, 'id'), state)
    },
    collect: monitor => ({
      isHovered: monitor.isOver() && monitor.canDrop(),
    }),
    canDrop: item =>
      item.data.state !== state && isPossibleToOverrideStatus(item.data),
  })

  return [isHovered, cardDrop]
}

export default useChangeDriverStateDrop
