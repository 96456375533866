import { useQuery } from '@apollo/client'

import { GET_CARDS_FOR_BOARD } from '../../app/graphQl/schema/Cards'
import { convertFilterToQuery } from '../../utils/filter-utils'
import useCardsFilter from '../useCardsFilter'
import { useCurrentStation } from '../useCurrentStation'

// filters cards by station and filter
export function useActiveCardsList() {
  const [cardsFilter] = useCardsFilter()
  const { stationId } = useCurrentStation()

  const {
    data: cardsData,
    loading,
    error,
  } = useQuery(GET_CARDS_FOR_BOARD, {
    variables: convertFilterToQuery(cardsFilter, stationId),
    skip: !stationId,
  })

  return { cardsData: cardsData?.getCardsForBoard || [], loading, error }
}
