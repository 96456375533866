import { ErrorAlert } from './Alerts'
import { useSelectedTheme } from '../hooks/useUserSettings'
import { themedClass } from '../utils/style-utils'

const LoadingIndicator = ({
  className,
  loading,
  error,
  children,
  loadingMsg,
  theme,
}) => {
  const [defaultTheme] = useSelectedTheme()
  return (
    <>
      <ErrorAlert errorId={error} />
      <div className={className}>
        {loading && (
          <div
            className={themedClass('loading-spinner', theme || defaultTheme)}
          >
            <div className="splash-screen-loader">
              {loadingMsg}
              <div className="splash-screen-loader-gif" />
            </div>
          </div>
        )}
        {children}
      </div>
    </>
  )
}

export default LoadingIndicator

export const DotsLoader = () => (
  <div className="dots-loader">
    <span />
  </div>
)
