import { Popover } from 'antd'
import classNames from 'classnames'
import { useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { DriverTypesEnum } from '../../app/enums/DriverType'
import { ThemesEnum } from '../../app/enums/Themes'
import { VehicleTypesEnum } from '../../app/enums/VehicleTypes'
import { countLoadedCards, isLoaded } from '../../utils/driver-utils'
import { themedClass } from '../../utils/style-utils'

function getVehicleTypeById(id) {
  const resultKey = Object.keys(VehicleTypesEnum).find(
    k => VehicleTypesEnum[k].id === id
  )
  if (resultKey) return VehicleTypesEnum[resultKey]
  return null
}

function getVehicleImg(driver, vehicle) {
  switch (driver.driverType) {
    case DriverTypesEnum.INDEPENDENT_CONTRACTOR:
      return vehicle.contractor
    case DriverTypesEnum.LOGISTIC_SERVICE_PROVIDER:
      return vehicle.lsp
    default:
      return vehicle.default
  }
}

const VehicleIcon = ({
  hideLabel,
  displayDriverCode,
  theme,
  driver,
  PopoverContent,
}) => {
  const {
    vehicle: { type: typeId },
    driverType,
    code: driverId,
  } = driver

  const loaded = isLoaded(driver)
  const assignedCardsCount = countLoadedCards(driver)
  const popoverRef = useRef()
  const vehicle = getVehicleTypeById(typeId)

  if (!vehicle) return <div>-</div>

  const iconCss = classNames('vehicle-icon', 'fas', vehicle.icon)
  const iconImgCss = classNames('vehicle-icon')

  const mainClass = themedClass(
    'drivers-vehicle-info',
    theme,
    classNames({
      'driver-contractor':
        driverType === DriverTypesEnum.INDEPENDENT_CONTRACTOR,
      'driver-lsp': driverType === DriverTypesEnum.LOGISTIC_SERVICE_PROVIDER,
    })
  )

  const iconClass = classNames('driver-status-icon', {
    'driver-status-icon-hidden': !loaded || !assignedCardsCount,
  })

  const vehicleImg =
    theme === ThemesEnum.DARK ? vehicle.imgDarkTheme : vehicle.img

  const innerContent = (
    <div className={mainClass}>
      <div className={iconClass}>
        <i className="fas fa-layer-group" />
        {assignedCardsCount > 1 && (
          <span className="multiple-loads">({assignedCardsCount})</span>
        )}
      </div>
      <div>
        {displayDriverCode && <p className="drivers-code">{driverId}</p>}
        {vehicleImg ? (
          <img
            alt="vehicle-icon"
            className={iconImgCss}
            src={getVehicleImg(driver, vehicleImg)}
          />
        ) : (
          <i className={iconCss} />
        )}
        {!hideLabel && (
          <p className="vehicle-type-label">
            <FormattedMessage id={vehicle.label} />
          </p>
        )}
      </div>
    </div>
  )

  if (PopoverContent)
    return (
      <Popover
        overlayClassName={themedClass('loaded-cards-popover', theme)}
        destroyTooltipOnHide
        content={
          <PopoverContent
            driver={driver}
            theme={theme}
            popoverRef={popoverRef}
          />
        }
        ref={popoverRef}
        placement="topRight"
        arrowPointAtCenter
        align={{
          offset: [32, 2],
        }}
      >
        {innerContent}
      </Popover>
    )
  else return innerContent
}

export default VehicleIcon
