import { Modal } from 'antd'

import { CssValues } from '../../app/constants'
import { ThemesEnum } from '../../app/enums/Themes'

const ThemableModal = props => {
  const { theme, ...rest } = props
  return (
    <Modal
      {...rest}
      maskStyle={
        theme === ThemesEnum.DARK
          ? { backgroundColor: CssValues.modalMaskDarkTheme }
          : {}
      }
    />
  )
}

export default ThemableModal
