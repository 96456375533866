import { Switch } from 'antd'
import classNames from 'classnames'

import { themedClass } from '../../utils/style-utils'

export const SwitchControl = props => {
  const { checkedLabel, uncheckedLabel, theme, ...switchProps } = props
  const checkedCss = classNames(
    { 'switch-label-checked': props.checked },
    { 'switch-label': !props.checked }
  )
  const uncheckedCss = classNames(
    {
      'switch-label-checked': !props.checked,
    },
    { 'switch-label': props.checked }
  )
  return (
    <div className={themedClass('switch-checkbox', theme)}>
      <label className={uncheckedCss}>{checkedLabel}</label>
      <Switch {...switchProps} />
      <label className={checkedCss}>{uncheckedLabel}</label>
    </div>
  )
}
