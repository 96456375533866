import _ from 'lodash'
import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDrag, useDragLayer } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { FormattedMessage } from 'react-intl'

import useStacksList from '../../hooks/graphQl/useStacksQuery'
import { useMonitorContext } from '../../hooks/useMonitorContext'
import { useSelectedTheme } from '../../hooks/useUserSettings'
import {
  findDecideStack,
  findNewJobsStack,
  isStackScrollable,
  sortStacksByOrder,
} from '../../utils/stacks-utils'
import { themedClass } from '../../utils/style-utils'

const CARD_PATTERN_GAP = 2
const CARD_PATTERN_HEIGHT = 5 + CARD_PATTERN_GAP

const isAnyAlarmed = cards => cards && _.some(cards.alarmed)
const isAnyUpdated = cards => cards && _.some(cards.changed)

const MinimapColumn = ({ doubleTop, isAlarmed, hasChangedCards }) => {
  let alarmedY = hasChangedCards ? CARD_PATTERN_HEIGHT : 0
  let changedY = 0
  if (doubleTop) {
    alarmedY += CARD_PATTERN_HEIGHT * 2
    changedY += CARD_PATTERN_HEIGHT * 2
  }
  return (
    <div className="minimap-column">
      <svg>
        <defs>
          <pattern
            id="card-pictogram"
            width="100%"
            height={CARD_PATTERN_HEIGHT}
            patternUnits="userSpaceOnUse"
          >
            <rect className="minimap-rect" />
          </pattern>
        </defs>

        <rect className="minimap-column-rect" fill="url(#card-pictogram)" />
        {doubleTop && <rect className="minimap-rect-double" />}
        {hasChangedCards && (
          <rect className="minimap-rect-changed" y={changedY} />
        )}
        {isAlarmed && <rect className="minimap-rect-alarmed" y={alarmedY} />}
      </svg>
    </div>
  )
}

const CardsMinimap = ({ cardColumns: stackGrouped }) => {
  const [theme] = useSelectedTheme()
  const { currentScroll, updateScroll, newColumnOpened } = useMonitorContext()
  const [scrollRation, setScrollRation] = useState(1)
  const [pageOffset, setPageOffset] = useState(1)
  const sliderRef = useRef()

  const { stacksData: listStacks } = useStacksList()

  const currentDragX = useDragLayer(monitor => {
    const initialOffsetDifference = monitor.getDifferenceFromInitialOffset()
    const initialOffset = monitor.getInitialSourceClientOffset()
    return initialOffsetDifference && initialOffset
      ? initialOffsetDifference.x + initialOffset.x - pageOffset
      : 0
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'minimap-slider' },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const sliderLeft =
    (currentScroll.scrollLeft / currentScroll.scrollWidth) * 100
  const sliderWidth =
    (currentScroll.clientWidth / currentScroll.scrollWidth) * 100

  useLayoutEffect(() => {
    const frame = requestAnimationFrame(() => {
      if (isDragging) {
        updateScroll({
          ...currentScroll,
          needManualUpdate: true,
          scrollLeft: Math.round(scrollRation * currentDragX),
        })
      }
    })
    return () => cancelAnimationFrame(frame)
  }, [currentScroll, isDragging, scrollRation, updateScroll, currentDragX])

  useLayoutEffect(() => {
    if (!sliderRef.current) return
    const bounds = sliderRef.current.getBoundingClientRect()

    setScrollRation(currentScroll.scrollWidth / bounds.width)
    setPageOffset(bounds.x)
  }, [currentScroll.scrollWidth])

  useEffect(() => {
    preview(getEmptyImage())
  }, [preview])

  const scrollableStacks = sortStacksByOrder(
    listStacks.filter(isStackScrollable)
  ).map(s => ({
    stack: s,
    alarmed: isAnyAlarmed(stackGrouped[s.id]),
    changed: isAnyUpdated(stackGrouped[s.id]),
  }))

  const newJobsStack = findNewJobsStack(listStacks) || {}
  const decideStack = findDecideStack(listStacks) || {}

  return (
    <>
      <div className={themedClass('minimap-wrapper', theme)}>
        <p className="minimap-header">
          <FormattedMessage id="columns-minimap.header" />
        </p>
        <div className="minimap-view">
          <div className="minimap-static-columns">
            <MinimapColumn
              doubleTop
              isAlarmed={isAnyAlarmed(stackGrouped[newJobsStack.id])}
              hasChangedCards={isAnyUpdated(stackGrouped[newJobsStack.id])}
            />
            <MinimapColumn
              isAlarmed={isAnyAlarmed(stackGrouped[decideStack.id])}
              hasChangedCards={isAnyUpdated(stackGrouped[decideStack.id])}
            />
          </div>
          <div className="minimap-scroll-area" ref={sliderRef}>
            {currentScroll.scrollWidth > 0 && (
              <div
                className="minimap-slider"
                ref={drag}
                style={{
                  width: `${sliderWidth}%`,
                  left: `${sliderLeft}%`,
                }}
              />
            )}
            {scrollableStacks.map(s => (
              <MinimapColumn
                key={s.stack.id}
                isAlarmed={s.alarmed}
                hasChangedCards={s.changed}
              />
            ))}
            {newColumnOpened && <div className="miminmap-empty-column" />}
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(CardsMinimap)
