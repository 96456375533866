import { FormattedMessage } from 'react-intl'

import { LayoutColumn, LayoutGrid, LayoutRow } from './common/MultiColumnLayout'
import { PackageInformationPiece } from './common/ValueWithLabel'
import { useLocalDateTime } from '../../hooks/useLocalDateTime'
import { formatNullableString } from '../../utils/string-utils'
import { ShortMonthTimeFormat } from '../DateTimeFormats'
import { FlowContentHeaderSmall } from '../ModalsTypography'
import { Weight } from '../user-units/WeightUnits'

const TempSensorHeader = ({ messageId }) => (
  <p className="temperature-sensors-header">
    <FormattedMessage id={messageId} />
  </p>
)

export const TemperatureAndSensors = ({ packageInfo, theme }) => {
  const { fromServerValue } = useLocalDateTime()

  return (
    <div className="temperature-sensors">
      <FlowContentHeaderSmall theme={theme}>
        <FormattedMessage id="card-popup.temperature" />
      </FlowContentHeaderSmall>
      <LayoutGrid className="space-between temperature-sensors-content">
        <LayoutRow>
          <LayoutColumn className="temperature-sensors-col">
            <TempSensorHeader messageId="card-popup.temperature" />
            <div className="space-between ">
              <PackageInformationPiece labelId="card-popup.packout-datetime">
                {packageInfo?.packoutDate?.iso && (
                  <ShortMonthTimeFormat
                    date={fromServerValue(packageInfo?.packoutDate.iso)}
                    separator=" "
                  />
                )}
              </PackageInformationPiece>
              <PackageInformationPiece labelId="card-popup.pkg-qual-time">
                <FormattedMessage
                  id="units.hours"
                  values={{
                    value: formatNullableString(packageInfo?.packoutHours),
                  }}
                />
              </PackageInformationPiece>
            </div>
          </LayoutColumn>

          <LayoutColumn className="temperature-sensors-col coolant-addons-col">
            <TempSensorHeader messageId="card-popup.coolant-addons" />
            <div className="space-between ">
              <PackageInformationPiece labelId="card-popup.coolant-type">
                {formatNullableString(packageInfo?.coolantType)}
              </PackageInformationPiece>

              <PackageInformationPiece labelId="card-popup.amount">
                <Weight weight={packageInfo?.coolantAmount} />
              </PackageInformationPiece>

              <PackageInformationPiece labelId="sensor-device.source">
                {formatNullableString(packageInfo?.coolantSource)}
              </PackageInformationPiece>

              <PackageInformationPiece labelId="card-popup.replenish-time">
                <FormattedMessage
                  id="units.hours"
                  values={{
                    value: formatNullableString(packageInfo?.coolantInterval),
                  }}
                />
              </PackageInformationPiece>
            </div>
          </LayoutColumn>
        </LayoutRow>
      </LayoutGrid>
    </div>
  )
}
