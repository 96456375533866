import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { generatePath } from 'react-router'
import { useHistory, useRouteMatch } from 'react-router-dom'

import useStationsList from './graphQl/useStationsQuery'
import { useCurrentStation } from './useCurrentStation'

export const useActiveStation = () => {
  const { stationId } = useCurrentStation()
  const history = useHistory()
  const { path } = useRouteMatch()

  const { data: listStations } = useStationsList()
  const changeStation = useCallback(
    newStation => {
      const newLocation = generatePath(path, {
        station: newStation,
      })
      if (newLocation !== history.location.pathname) {
        history.replace(newLocation)
      }
    },
    [history, path]
  )

  const currentStation = useMemo(
    () => _.find(listStations, s => s.id === stationId),
    [stationId, listStations]
  )

  return {
    currentStation,
    changeStation,
  }
}
